import { ITheme } from '../models';

/***
 * (?:         # begin non-capturing group
 *   \.        #   a dot
 *   (         #   begin capturing group (captures the actual extension)
 *     [^.]+   #     anything except a dot, multiple times
 *   )         #   end capturing group
 * )?          # end non-capturing group, make it optional
 * $           # anchor to the end of the string
 *
 * @param fileName
 * @return fileExtension
 */
export const getExtensionFromFilename = (fileName: string): string => {
  const re = /(?:.([^.]+))?$/;
  return re.exec(fileName)[1];
};

/***
 * @param filePath
 * @return fileName
 */
export const getNameFromFilePath = (filePath: string): string => {
  // eslint-disable-next-line
  const re = /[\/\\]([^\/\\]+)\.\w+$/;
  return re.exec(filePath)[1];
};

/**
 * example1 resident-saas://app/auth/reset-register/89892930-6d53-4593-q6d1-ede5cc25ed1b
 *
 * example2 resident-saas://app/auth/landing/VERIFY_EMAIL/SUCCESS/eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyUHJvZmlsZUlkIjo3Njc1OTQ2MCwiZXhwaXJlZCI6MTcwNjAyMzAxOTA0NCwiY3JlYXRlZCI6MTcwNDgxMzQxOTA0NH0.ZqVbh1CYK87LS3SoYdYRxeNCvmAKd0Ewj8cOUcbD2Yo
 *
 * 'resident-saas://app' is the app identification;
 *
 * 'auth' is the route url to the authentication module;
 *
 * 'reset-register/89892930-6d53-4593-q6d1-ede5cc25ed1b' is the route url to the page we want to show to the user
 *  and the return value of the function
 *
 * @param deepLink
 * @return route
 */
export const deepLinkToRoute = (deepLink: string): string => {
  const splitted = deepLink.split('/');
  return splitted?.splice(3, splitted.length)?.join('/') as string;
};

export const obfuscateString = (str = '', replaceChar = 'X', start = 4, end = -4): string => {
  const slice = str.slice(start, end);
  return str.replace(slice, replaceChar.repeat(slice.length));
};

/**
 * returns true if the string contains HTML-tag elements
 * @param str
 */
export const containsHTML = (str: string) => {
  const htmlTagPattern = /<\/?[a-z][\s\S]*>/i; // Matches opening and closing tags
  return htmlTagPattern.test(str);
};

/**
 * returns new string without HTML-tag elements
 * @param str
 */
export const removeHTML = (
  str: string,
  isMobile = false,
  keepLineBreaksForMobile = false
): string => {
  let updatedString = str;

  if (isMobile) {
    if (containsHTML(str)) {
      if (keepLineBreaksForMobile) {
        // remove all html tags except <p> tags and replace with ' '
        updatedString = updatedString.replace(/<(?!\/?p\b)(?!p\b)[^>]+>/g, '').trim();

        // remove <p> tags and replace </p> tags with double line breaks
        updatedString = updatedString.replace(/<p>/g, '').replace(/<\/p>/g, '\n\n');
      } else {
        // remove all html tags and replace with ' '
        updatedString = str.replace(/<[^>]+>/g, ' ');

        // reduce several consecutive spaces to a single space
        updatedString = updatedString.replace(/\s+/g, ' ');
      }

      // remove whitespaces at the beginning and the end of the string
      return updatedString.trim();
    }
    return str;
  }

  // for web we keep the <p> tags for the linebreaks
  return updatedString.replace(/<(?!\/?p\b)(?!p\b)[^>]+>/g, '').trim();
};

export const removeWhitespace = (text: string): string => {
  return text.replace(/\s/g, '');
};
/**
 * Return a html string that uses the apps main font-family and the whitelabel primaryTextColor
 * @param htmlString
 */
export const htmlStyling = (htmlString: string, theme?: ITheme): string => {
  return `
      <html>
        <head>
          <style>
          a {
              color: blue !important;
            }
            * {
              font-family: 'Source Sans Pro', sans-serif;
              color: ${theme.colorTextPrimary} !important;
            }

          </style>
        </head>
        <body>
          <div>
            ${htmlString}
          </div>
        </body>
      </html>`;
};
