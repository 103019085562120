<div class="d-flex flex-row align-items-center justify-content-center h-100">
  <div class="content d-flex flex-column align-items-center text-center">
    <rs-web-image [defaultSrc]="'/web/images/svg/success.svg'"></rs-web-image>
    <h2 class="title">{{ 'ticket_creation.success.title_l' | translate }}</h2>
    <p>{{ 'user_edit_success_desc_1' | translate }}</p>

    <p class="fw-bold">
      {{ 'user_edit_success_desc_2' | translate }}
    </p>
  </div>
</div>
