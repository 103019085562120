import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

import { FeedbackService, RouterService } from '../../services';
import { ResidentMailActionType, ResidentMailStatusType } from '../../models';

@Directive()
export class LandingPageBaseComponent implements OnInit {
  constructor(
    private routerService: RouterService,
    private route: ActivatedRoute,
    private feedbackService: FeedbackService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    const { token, action, verificationCode, status, appRedirectPath } = this.route.snapshot
      .params as {
      token: string;
      verificationCode: string;
      appRedirectPath: string;
      action: ResidentMailActionType;
      status: ResidentMailStatusType;
    };

    this.handleAction(action, status, token, appRedirectPath, verificationCode);
  }
  private handleAction(
    action: ResidentMailActionType,
    status: ResidentMailStatusType,
    token: string,
    appRedirectPath: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    verificationCode?: string
  ) {
    const defaultRedirect = 'auth';

    if (action === ResidentMailActionType.VERIFY_EMAIL) {
      let feedbackMessageKey: string;
      let feedbackType: 'success' | 'error' | 'info';

      switch (status) {
        case ResidentMailStatusType.SUCCESS:
          feedbackMessageKey = 'email_confirmation.success_l';
          feedbackType = 'success';
          break;
        case ResidentMailStatusType.ERROR:
          feedbackMessageKey = 'general.error_undefined';
          feedbackType = 'error';
          break;
        default:
          feedbackMessageKey = 'general.error';
          feedbackType = 'info';
          break;
      }

      this.translate
        .get(feedbackMessageKey)
        .pipe(take(1))
        .subscribe((translated: string) => {
          void this.feedbackService[feedbackType]({ message: translated });
        });

      this.goTo(defaultRedirect);
    } else if (appRedirectPath === 'app*reset/register') {
      this.goTo('auth/reset-register');
    }
  }

  private goTo = (route: string) => {
    void this.routerService.navigate([route]);
  };
}
