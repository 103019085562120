import { Directive } from '@angular/core';
import {
  IMasterDataChange,
  ImmomioIconName,
  IRootQuestionTicketOverview,
  ITicketCreationSummary,
  TicketCreationSummary,
} from '../../models';
import { RouterService, ticketHierarchicalQuestionsConverter } from '../../services';
import { Store } from '@ngrx/store';
import { IMasterDataState } from '../../+state';
import * as fromMasterDataState from '../../+state';
import * as fromTicketState from '../../+state/tickets';
import { filter, map, take, tap } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class UserEditTicketPageBaseComponent {
  public immomioIconName = ImmomioIconName;
  public closeButton: ImmomioIconName = ImmomioIconName.Close;

  public questionsForm: FormGroup;
  public step = { index: 0 };
  public ticketCreationSummary: ITicketCreationSummary;

  constructor(
    protected routerService: RouterService,
    private masterDataStore: Store<IMasterDataState>,
    private ticketStore: Store<fromTicketState.ITicketsState>
  ) {
    this.ticketCreationSummary = new TicketCreationSummary();
    this.loadCategoryDetailQuestions();
    this.getCategoryDetailQuestions();
  }

  private loadCategoryDetailQuestions() {
    this.masterDataStore
      .select(fromMasterDataState.getMasterDataChange)
      .pipe(
        take(1),
        tap((masterDataChange: IMasterDataChange) => {
          const { ticketCategoryId } = masterDataChange.selectedChangeConfiguration || {};
          if (ticketCategoryId) {
            this.ticketStore.dispatch(
              fromTicketState.LoadCategoryDetailQuestions({ ticketCategoryId })
            );
          }
        }),
        map(({ selectedChangeConfiguration }) => selectedChangeConfiguration?.changeType)
      )
      .subscribe();
  }

  private getCategoryDetailQuestions() {
    this.ticketStore
      .select(fromTicketState.getCategoryDetailQuestions)
      .pipe(
        untilDestroyed(this),
        filter(res => !!res)
      )
      .subscribe({
        next: (response: IRootQuestionTicketOverview) => this.formatCategoriesResponse(response),
        error: error => console.error(error),
      });
  }

  private formatCategoriesResponse(response: IRootQuestionTicketOverview): void {
    if (!response?.mainQuestionIds?.length) {
      return;
    }

    const { id, questions } = response;
    this.ticketCreationSummary.detailedQuestionId = id;

    this.ticketCreationSummary.detailedResponses = [...questions]
      .sort((a, b) => a.data.order - b.data.order)
      .map(q => ticketHierarchicalQuestionsConverter.formatQuestionToSummaryItem(q));

    this.questionsForm = ticketHierarchicalQuestionsConverter.createQuestionsAndAnswersForm(
      this.ticketCreationSummary
    );
  }

  private getTicketSummaryToDispatch(): ITicketCreationSummary {
    return {
      ...this.ticketCreationSummary,
      detailedResponses: ticketHierarchicalQuestionsConverter.formatFormAnswersToDetailedResponses(
        this.ticketCreationSummary,
        this.questionsForm
      ),
    };
  }

  protected navigateToOverviewPage(): void {
    void null;
  }

  public createUserEditTicket(): void {
    this.masterDataStore.dispatch(
      fromMasterDataState.SetMasterDataChange({
        masterDataChange: {
          ticketCreationSummary: this.getTicketSummaryToDispatch(),
        },
      })
    );

    this.navigateToOverviewPage();
  }

  public resetMasterDataChange() {
    this.masterDataStore.dispatch(fromMasterDataState.ResetMasterDataChange());
  }
}
