import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ImmomioIconName } from '../../models';
import { IconFontVariant } from '../atoms';

@Directive()
export abstract class FaqWidgetBaseComponent {
  @Output() tapped = new EventEmitter();
  public immomioIconName = ImmomioIconName;
  public iconFontVariant = IconFontVariant;

  private _faqHeadline: string;
  private _faqText: string;

  @Input()
  set faqBannerHeadline(value: string) {
    this._faqHeadline = value || 'faq_widget_l';
  }
  get faqBannerHeadline(): string {
    return this._faqHeadline;
  }

  @Input()
  set faqBannerText(value: string) {
    this._faqText = value || 'faq_widget_description';
  }
  get faqBannerText(): string {
    return this._faqText;
  }
}
