import { Directive } from '@angular/core';
import { ImmomioIconName, ITheme } from '../../models';
import { Store } from '@ngrx/store';
import { getTheme } from '../../+state';
import { filter, take } from 'rxjs';

@Directive()
export class AccordionBaseComponent {
  selectedIndex: number;
  public immomioIconName = ImmomioIconName;
  protected theme: ITheme;

  constructor(store: Store) {
    store
      .select(getTheme)
      .pipe(
        filter(theme => theme !== null),
        take(1)
      )
      .subscribe(theme => {
        this.theme = theme;
      });
  }

  toggleSelection(index: number) {
    if (this.selectedIndex && this.selectedIndex === index) {
      this.selectedIndex = null;
    } else {
      this.selectedIndex = index;
    }
  }
}
