import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { CAMERA_DIALOG_ACTIONS, ITicketAttachment } from '../../models';

@Directive()
export class SelectAttachmentBaseComponent {
  @Input() className?: string;
  @Input() attachments: ITicketAttachment[] = [];
  @Input() disableUploadButton = false;
  @Input() displayNumberOfImages = false;
  @Input() multipleSelection = true;
  @Input() title?: string;
  @Output() getAttachments: EventEmitter<ITicketAttachment[]> = new EventEmitter<
    ITicketAttachment[]
  >();
  @Input() uploadType: CAMERA_DIALOG_ACTIONS[] = [];
}
