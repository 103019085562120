import { Directive, Input } from '@angular/core';
import {
  AccentColors,
  IConsumptionChartColor,
  IConsumptionInfoItem,
  IConsumptionItem,
  IMonth,
  ITheme,
} from '../../models';
import { lightenColor } from '../../utils';
import { Store } from '@ngrx/store';
import { IAppSettingsState } from '../../+state';
import * as fromAppSettingsState from '../../+state/general/app-settings';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class ConsumptionDashboardChartBaseComponent {
  @Input() consumptions: IConsumptionItem[];
  @Input() meter: IConsumptionInfoItem;
  @Input() months: IMonth[];
  public theme: ITheme;

  public color: IConsumptionChartColor = {
    power: {
      stroke: AccentColors.MUSTARD,
      fill: lightenColor(AccentColors.MUSTARD, 80),
    },
    cold_water: {
      stroke: AccentColors.BLUEHOUSE,
      fill: lightenColor(AccentColors.BLUEHOUSE, 80),
    },
    hot_water: {
      stroke: AccentColors.SIGNAL,
      fill: lightenColor(AccentColors.SIGNAL, 80),
    },
    heating: {
      stroke: AccentColors.FLIEDER,
      fill: lightenColor(AccentColors.FLIEDER, 80),
    },
    photovoltaic: {
      stroke: AccentColors.SPINACH,
      fill: lightenColor(AccentColors.SPINACH, 80),
    },
  };

  constructor(private appSettingsStore: Store<IAppSettingsState>) {
    this.appSettingsStore
      .select(fromAppSettingsState.getTheme)
      .pipe(untilDestroyed(this))
      .subscribe(res => (this.theme = res));
  }
}
