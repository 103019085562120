import { Directive, Inject, OnInit } from '@angular/core';

import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import {
  getSelectedCustomerIdent,
  IEnvironment,
  LoadCustomerIdent,
  LoadCustomerIdentSuccess,
  ThemeService,
} from '@resident-nx/shared';
import { take } from 'rxjs/operators';
import { NewRelicWebService } from './services';

@UntilDestroy()
@Directive({
  standalone: true,
})
export abstract class AppWebBaseComponent implements OnInit {
  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    protected translate: TranslateService,
    private store: Store,
    private themeService: ThemeService,
    private newRelicService: NewRelicWebService
  ) {
    this.translate.setDefaultLang('de');
  }

  ngOnInit() {
    this.handleCustomerIdent();
    this.themeService.init();
    this.newRelicService.init();
  }

  private handleCustomerIdent() {
    this.store
      .select(getSelectedCustomerIdent)
      .pipe(take(1))
      .subscribe(selectedCustomerIdent => {
        if (!selectedCustomerIdent) {
          if (this.environment.customerIdent) {
            // only for local environment
            this.store.dispatch(
              LoadCustomerIdentSuccess({ customerIdent: this.environment.customerIdent })
            );
          } else {
            this.store.dispatch(LoadCustomerIdent({ domain: window.location.host }));
          }
        }
      });
  }
}
