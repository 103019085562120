import { UntilDestroy } from '@ngneat/until-destroy';
import { Directive, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ImmomioIconName, ISepaMandates } from '../../models';

@UntilDestroy()
@Directive()
export abstract class PaymentInformationCardBaseComponent {
  @Output() tapped = new EventEmitter();
  @Input() className = '';
  @Input() item: ISepaMandates;
  @Input() isCollapsable = false;
  @Input() expandAll = false;
  public collapsed = true;
  protected readonly ImmomioIconName = ImmomioIconName;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['expandAll']) {
      this.collapsed = !this.expandAll;
    }
  }

  toggleCollapse() {
    if (this.isCollapsable) {
      this.collapsed = !this.collapsed;
    }
  }
}
