import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { NgClass } from '@angular/common';

import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { startOfYear } from 'date-fns';

import {
  ConsumptionIconPipe,
  ConsumptionNamePipe,
  ConsumptionTimeframe,
  ConsumptionUnitType,
  ConsumptionUnitTypePipe,
  IConsumptionInfoItem,
  IConsumptionState,
  ImmomioIconName,
  NameDisplayMode,
  NgChanges,
  SelectPeriod,
} from '@resident-nx/shared';
import { ConsumptionChartWebComponent } from '../consumption-chart/consumption-chart.component';
import { IconWebComponent } from '../../atoms/icon/icon.component';
import { CardWebComponent } from '../../atoms/card/card.component';

@Component({
  selector: 'rs-web-consumption-dashboard-card',
  templateUrl: './consumption-dashboard-card.component.html',
  styleUrls: ['./consumption-dashboard-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    IconWebComponent,
    ConsumptionChartWebComponent,
    TranslateModule,
    ConsumptionIconPipe,
    ConsumptionNamePipe,
    ConsumptionUnitTypePipe,
    CardWebComponent,
  ],
})
export class ConsumptionDashboardCardWebComponent implements OnChanges {
  @Input() meter: IConsumptionInfoItem;

  public readonly ImmomioIconName = ImmomioIconName;
  public readonly NameDisplayMode = NameDisplayMode;
  public readonly ConsumptionTimeframe = ConsumptionTimeframe;

  protected readonly ConsumptionUnitType = ConsumptionUnitType;

  constructor(private store: Store<IConsumptionState>) {}

  ngOnChanges(changes: NgChanges<ConsumptionDashboardCardWebComponent>) {
    if (changes?.meter) {
      this.setConsumptionPeriod();
    }
  }

  /**
   * Example:
   * today          22.02.2024
   * twoMonthsAgo:  22.12.2023
   * start:         01.12.2023
   * end:           22.02.2024
   */
  setConsumptionPeriod(): void {
    const { deviceId, lastEntry } = this.meter;
    const end = new Date(lastEntry);
    const start = startOfYear(end);
    /**
     * to manually shift into the previous month (BE seems to not respect timezone correctly
     * SelectPeriod({ deviceId, start: new Date(start.getTime() - 10000), end })
     */
    if (deviceId) {
      this.store.dispatch(SelectPeriod({ deviceId, start, end }));
    }
  }
}
