import { Pipe, PipeTransform } from '@angular/core';
import { IUserData } from '../models';

@Pipe({
  name: 'username',
  standalone: true,
})
export class UsernamePipe implements PipeTransform {
  transform(value: IUserData): string | null {
    if (!value) {
      return null;
    }
    let username = '';
    if (value.firstName === value.name) {
      username = value.name;
    } else {
      if (value.firstName) {
        username += value.firstName;
      }
      if (value.name) {
        username.length && (username += ' ');
        username += value.name;
      }
    }
    return username;
  }
}

@Pipe({
  name: 'userInitials',
  standalone: true,
})
export class UserInitialsPipe implements PipeTransform {
  transform(value: IUserData): string | null {
    if (!value) {
      return null;
    }
    let userInitials = '';
    if (value.firstName === value.name) {
      userInitials += value.name.charAt(0).toUpperCase();
    } else {
      if (value.firstName) {
        userInitials += value.firstName.charAt(0).toUpperCase();
      }
      if (value.name) {
        userInitials += value.name.charAt(0).toUpperCase();
      }
    }
    return userInitials;
  }
}
