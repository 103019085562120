import { inject, Injectable } from '@angular/core';
import { UserProfileEditForm } from './types/user-profile-edit-form';
import {
  IMasterdataChangeConfiguration,
  IMasterdataChangeRequest,
  IMasterdataNameChangeAttachment,
  MasterdataChangeType,
  nameValidator,
  phoneNumberValidator,
  phonePrefixValidator,
  zipCodeValidator,
} from '@resident-nx/shared';
import {
  AbstractControl,
  FormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAX_FILE_SIZE } from '../../../../services/tickets/ticket-creation.service';
import { fileToBase64, maxFileSize } from '../../../../utils';

@Injectable({
  providedIn: 'root',
})
export class UserProfileEditService {
  #fb = inject(FormBuilder);

  public async createPayload(
    formValues: UserProfileEditForm['value']
  ): Promise<IMasterdataChangeRequest> {
    const type = formValues.selection.changeType;
    switch (type) {
      case MasterdataChangeType.NAME: {
        const { name, lastName, verificationFile } = formValues.name;
        const file = verificationFile[0];

        const attachments: IMasterdataNameChangeAttachment[] = [
          {
            name: file.name,
            extension: 'pdf',
            content: await fileToBase64(file),
          },
        ];
        return {
          type,
          name: {
            name,
            lastName,
            attachments,
          },
        };
      }
      case MasterdataChangeType.ADDRESS: {
        const { city, zipCode, street, houseNumber } = formValues.address;
        return {
          type,
          address: {
            city,
            zipCode,
            street,
            houseNumber,
          },
        };
      }
      case MasterdataChangeType.EMAIL: {
        const { email } = formValues;
        return {
          type,
          email: {
            email,
          },
        };
      }
      case MasterdataChangeType.DATE_OF_BIRTH: {
        const { dateOfBirth } = formValues;

        return {
          type,
          dateOfBirth: {
            dateOfBirth: new Date(dateOfBirth).toISOString(),
          },
        };
      }
      case MasterdataChangeType.PHONE: {
        const { countryCode, prefix, phoneNumber } = formValues.phone;
        return {
          type,
          phone: {
            countryCode,
            prefix,
            phoneNumber,
          },
        };
      }
      case MasterdataChangeType.MOBILE: {
        const { countryCode, prefix, phoneNumber } = formValues.mobile;
        return {
          type,
          mobile: {
            countryCode,
            prefix,
            phoneNumber,
          },
        };
      }
    }
  }

  public createForm(): UserProfileEditForm {
    return this.#fb.group({
      selection: this.#fb.control<IMasterdataChangeConfiguration>(null, Validators.required),
      dateOfBirth: [
        '',
        [Validators.required, this.noFutureDateValidator(), this.noDateBefore1900Validator()],
      ],
      name: this.#fb.group({
        name: ['', [Validators.required, nameValidator()]],
        lastName: ['', [Validators.required, nameValidator()]],
        verificationFile: this.#fb.control<FileList>(null, [
          Validators.required,
          maxFileSize(MAX_FILE_SIZE),
        ]),
      }),
      address: this.#fb.group({
        street: ['', Validators.required],
        houseNumber: ['', Validators.required],
        zipCode: [
          '',
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(5),
            zipCodeValidator(),
          ],
        ],
        city: ['', Validators.required],
      }),
      phone: this.createPhoneGroup(),
      mobile: this.createPhoneGroup(),
      email: ['', [Validators.required, Validators.email]],
    });
  }

  private createPhoneGroup() {
    return this.#fb.group({
      countryCode: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(4),
          phonePrefixValidator(),
        ],
      ],
      prefix: this.#fb.control<number>(null, [Validators.required, phoneNumberValidator()]),
      phoneNumber: this.#fb.control<string>('', [Validators.required, phoneNumberValidator()]),
    });
  }

  private noFutureDateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const inputDate = new Date(control.value);
      const today = new Date();
      return inputDate > today ? { ngbDate: { invalid: true } } : null;
    };
  }

  private noDateBefore1900Validator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const inputDate = new Date(control.value);
      const isDateVeryOld = inputDate.getFullYear() < 1900;

      return isDateVeryOld ? { ngbDate: { invalid: true } } : null;
    };
  }
}
