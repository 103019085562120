@if ((announcementsActionState$ | async).pending) {
  <rs-web-loading-skeleton
    [show]="true"
    [types]="[LoadingType.HEADER, LoadingType.MULTIPLECARD]"
  ></rs-web-loading-skeleton>
} @else {
  @if ((announcements$ | async).length > 0) {
    <div class="announcements-widget">
      <rs-web-cards-container-header
        (tapped)="onTapOverview()"
        [buttonText]="'general.overview_a'"
        [buttonTypeEnum]="ButtonEnumTypes.LIGHT_BORDERED"
        [icon]="icon"
        [title]="title"
      ></rs-web-cards-container-header>
      <div class="announcements-widget__overflow">
        <div class="announcements-widget__content">
          @for (
            announcement of announcements$ | async;
            track announcement.node.id;
            let i = $index
          ) {
            @if (announcementsLimit - 1 > i) {
              <rs-web-content-card
                (tapped)="onTap($event)"
                [date]="announcement.node.publishedAt"
                [id]="announcement.node.id"
                [text]="announcement.node.text"
                [title]="announcement.node.title"
                class="announcements-widget__content-card"
                [preset]="preset.ANNOUNCEMENT"
              ></rs-web-content-card>
            } @else {
              @if (announcementsLimit - 1 === i) {
                <rs-web-card
                  (click)="onTapOverview()"
                  [elevation]="3"
                  [hover]="true"
                  class="more-announcements-card"
                >
                  <div class="announcements-widget__extra-card">
                    {{
                      showAmountOfContent
                        ? '+ ' + ((announcements$ | async).length - announcementsLimit + 1)
                        : ''
                    }}
                    {{ buttonText | translate }}
                  </div>
                </rs-web-card>
              }
            }
          }
        </div>
      </div>
    </div>
  }
}
