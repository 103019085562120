import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CustomerService, TicketCreationBAndOPageBaseComponent } from '@resident-nx/shared';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@UntilDestroy()
@Component({
  selector: 'rs-web-ticket-b-and-o-creation-page',
  standalone: true,
  templateUrl: './ticket-b-and-o-creation-page.component.html',
  styleUrl: './ticket-b-and-o-creation-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketBandOCreationPageWebComponent
  extends TicketCreationBAndOPageBaseComponent
  implements OnInit
{
  public iframeUrl: SafeResourceUrl;
  #sanitizer = inject(DomSanitizer);
  #cutsomerService = inject(CustomerService);

  ngOnInit(): void {
    const url = this.#cutsomerService.getBandODomain();

    this.iframeUrl = this.#sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
