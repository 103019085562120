import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromMasterDataState from '../../+state/account/masterdata';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  IMasterdataChangeConfiguration,
  MasterdataChangeRequestType,
  masterDataSelectionTranslateKeys,
} from '../../models';
import { RouterService } from '../../services';
import { take, timer } from 'rxjs';

@UntilDestroy()
@Directive()
export abstract class UserEditSelectionCardBaseComponent {
  public masterDataChangeConfiguration: IMasterdataChangeConfiguration[];
  public masterDataChangeRequestType = MasterdataChangeRequestType;
  public masterDataSelectionTranslateKeys = masterDataSelectionTranslateKeys;
  public activeHint = false;
  public selectedCheckboxes: { [key: string]: boolean } = {};

  constructor(
    protected routerService: RouterService,
    protected masterDataStore: Store<fromMasterDataState.IMasterDataState>
  ) {
    this.masterDataStore.dispatch(fromMasterDataState.ResetMasterDataChange());
    this.masterDataStore
      .select(fromMasterDataState.getMasterDataChangeConfiguration)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.masterDataChangeConfiguration = res;
        this.activeHint = this.masterDataChangeConfiguration?.some(
          config => config.requestType === MasterdataChangeRequestType.TICKET
        );
      });
  }

  select(conf: IMasterdataChangeConfiguration) {
    this.selectedCheckboxes = { ...this.selectedCheckboxes, [conf.changeType]: true };

    this.masterDataStore.dispatch(
      fromMasterDataState.SetMasterDataChange({
        masterDataChange: { selectedChangeConfiguration: conf },
      })
    );

    timer(100)
      .pipe(take(1))
      .subscribe(() => {
        this.selectedCheckboxes = { ...this.selectedCheckboxes, [conf.changeType]: false };
        this.handleRouting(conf);
      });
  }

  protected handleRouting(_conf: IMasterdataChangeConfiguration): void {
    void _conf;
  }
}
