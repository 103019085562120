import { Directive, OnInit } from '@angular/core';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  ImmomioIconName,
  IMonth,
  PeriodType,
  ITheme,
  IConsumptionInfoItem,
  IPeriod,
  IConsumptionsByPeriod,
  ConsumptionTimeframe,
  ByDeviceId,
  DeviceId,
} from '../../models';
import { IActionState, Milliseconds } from '../../utils';
import * as fromConsumptionState from '../../+state/consumption';
import * as fromAppSettingsState from '../../+state/general/app-settings';
import {
  getFirstSelectedMeter,
  getConsumptionActionStateForFirstMeter,
  IConsumptionState,
  IAppSettingsState,
  getChartMonthsForFirstMeter,
  getSavedDataForContractDeviceAndPeriodForFirstMeter,
  getPeriodForChartForFirstMeter,
  getSelectedTimeframe,
} from '../../+state';

@UntilDestroy()
@Directive()
export abstract class ConsumptionChartsBaseComponent implements OnInit {
  public selectedPeriodConsumptionActionState$: Observable<IActionState>;
  public selectedMeter$: Observable<IConsumptionInfoItem>;
  public immomioIconName = ImmomioIconName;
  public selectedDate: Milliseconds;
  public showPreviousArrow$: Observable<boolean>;
  public showNextArrow$: Observable<boolean>;
  public periodType = PeriodType;
  public theme: ITheme;

  public chartMonths$: Observable<IMonth[]>;
  public consumptionData$: Observable<IConsumptionsByPeriod>;
  public selectedPeriodForChart$: Observable<IPeriod>;
  public selectedTimeframe$: Observable<ByDeviceId<ConsumptionTimeframe>>;
  public consumptionTimeframe = ConsumptionTimeframe;

  constructor(
    protected store: Store<IConsumptionState>,
    protected appSettingsStore: Store<IAppSettingsState>
  ) {}

  ngOnInit() {
    this.selectedMeter$ = this.store.select(getFirstSelectedMeter);
    this.selectedPeriodConsumptionActionState$ = this.store.select(
      getConsumptionActionStateForFirstMeter
    );

    this.showPreviousArrow$ = this.store.select(
      fromConsumptionState.getShowPreviousArrowForFirstMeter
    );
    this.showNextArrow$ = this.store.select(fromConsumptionState.getShowNextArrowForFirstMeter);

    this.consumptionData$ = this.store.select(getSavedDataForContractDeviceAndPeriodForFirstMeter);
    this.chartMonths$ = this.store.select(getChartMonthsForFirstMeter);
    this.selectedPeriodForChart$ = this.store.select(getPeriodForChartForFirstMeter);
    this.selectedTimeframe$ = this.store.select(getSelectedTimeframe);

    this.appSettingsStore
      .select(fromAppSettingsState.getTheme)
      .pipe(untilDestroyed(this))
      .subscribe(res => (this.theme = res));
  }

  protected selectConsumption(period: IPeriod, periodType: PeriodType, deviceId: DeviceId): void {
    this.store.dispatch(
      fromConsumptionState.SelectConsumption({ selectedPeriod: period, periodType, deviceId })
    );
  }
}
