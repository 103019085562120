import { Pipe, PipeTransform } from '@angular/core';

import { DatePipe } from '@angular/common';
import { IMasterDataChange, MasterdataChangeType } from '../models';

@Pipe({
  name: 'masterDataDisplayText',
  standalone: true,
})
export class MasterDataDisplayTextPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}

  transform(change: IMasterDataChange): string {
    if (!change) {
      return '';
    }

    const { selectedChangeConfiguration, masterDataChangeRequest } = change;
    const changeType = selectedChangeConfiguration?.changeType;

    if (!changeType || !masterDataChangeRequest) {
      return '';
    }

    switch (changeType) {
      case MasterdataChangeType.NAME: {
        const {
          name: { name, lastName },
        } = masterDataChangeRequest;
        return `${name} ${lastName}`;
      }
      case MasterdataChangeType.DATE_OF_BIRTH: {
        const dob = masterDataChangeRequest.dateOfBirth?.dateOfBirth;
        return dob ? this.datePipe.transform(dob, 'dd.MM.yyyy') || '' : '';
      }
      case MasterdataChangeType.EMAIL:
        return masterDataChangeRequest.email?.email || '';
      case MasterdataChangeType.MOBILE: {
        const { countryCode, prefix, phoneNumber } = masterDataChangeRequest.mobile || {};
        return [countryCode, prefix, phoneNumber].filter(Boolean).join(' ');
      }
      case MasterdataChangeType.ADDRESS: {
        const { street, houseNumber, zipCode, city } = masterDataChangeRequest.address || {};
        const addressPart1 = [street, houseNumber].filter(Boolean).join(' ');
        const addressPart2 = [zipCode, city].filter(Boolean).join(' ');
        return addressPart1 + (addressPart2 ? `, ${addressPart2}` : '');
      }
      case MasterdataChangeType.PHONE: {
        const { countryCode, prefix, phoneNumber } = masterDataChangeRequest.phone || {};
        return [countryCode, prefix, phoneNumber].filter(Boolean).join(' ');
      }
      default:
        return '';
    }
  }
}
