import { Directive, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import {
  DeleteUser,
  getDeleteUserActionState,
  getUser,
  IMasterDataState,
  IUserProfileState,
} from '../../+state';
import { isLastnameAsyncValidator } from '../../services';
import { IActionState } from '../../utils';
import { AuthService } from '../../infrastructure';
import { IMasterDataUser, ImmomioIconName, InputType } from '../../models';

@Directive()
export class UserDeletePageBaseComponent implements OnInit {
  private $user: Observable<IMasterDataUser> = this.store.select(getUser);
  public $deleteUserActionState: Observable<IActionState> =
    this.store.select(getDeleteUserActionState);
  public inputType = InputType;
  public immomioIconName = ImmomioIconName;

  public fg: FormGroup = this.fb.group({
    deletionConfirmAnswer: ['', [Validators.required], [isLastnameAsyncValidator(this.$user)]],
  });
  constructor(
    private authService: AuthService,
    private store: Store<IUserProfileState | IMasterDataState>,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.store.select(getDeleteUserActionState).subscribe((actionState: IActionState) => {
      if (actionState.done) {
        this.authService.logout('auth');
      }
    });
  }

  public deleteAccount() {
    this.store.dispatch(DeleteUser());
  }
}
