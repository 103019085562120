import {
  ConsumptionAggregateType,
  ConsumptionDataVersion,
  ConsumptionsByContractR,
  ConsumptionsByDeviceR,
  ConsumptionTimeframe,
  ConsumptionType,
  ConsumptionUnitType,
  ContractId,
  DefaultAggregationType,
  DeviceId,
  IConsumption,
  IConsumptionBenchmarkItemResponse,
  IConsumptionInfo,
  IConsumptionInfoDto,
  IConsumptionInfoItem,
  IConsumptionInfoItemDto,
  IConsumptionItem,
  IConsumptionItemDto,
  IConsumptionResponse,
  IConsumptionsByPeriod,
  IContract,
  IMonth,
  IPeriod,
  PeriodType,
} from '../../models';
import {
  fromUtcToLocalTimeZone,
  getLengthOfNumber,
  Milliseconds,
  secondsToMilliseconds,
  startOfMonthMilliseconds,
} from '../../utils';
import { eachMonthOfInterval, endOfMonth, getMonth, startOfMonth } from 'date-fns';

/***
 * with the legacy ATLAS approach we got our date in Seconds and UTC and with UVI API
 * in Milliseconds and already localized
 * @param date
 */
const getCorrectedTimestamp = (date: EpochTimeStamp | Milliseconds): Milliseconds =>
  getLengthOfNumber(date) > 10 ? date : fromUtcToLocalTimeZone(secondsToMilliseconds(date));

export const consumptionInfoConverter = {
  fromItemDto: (
    item: IConsumptionInfoItemDto,
    consumptionDataVersion: ConsumptionDataVersion
  ): IConsumptionInfoItem => {
    return {
      ...item,
      deviceId:
        consumptionDataVersion == ConsumptionDataVersion.INTERNAL
          ? item.deviceId + '+' + item.type
          : item.deviceId, // todo: refactor on consumption rebuild -> only if uvi api consumption #ART-12117
      defaultAggregation: item.defaultAggregation.toLocaleUpperCase() as DefaultAggregationType,
      firstEntry: getCorrectedTimestamp(Number(item.firstEntry)),
      lastEntry: getCorrectedTimestamp(Number(item.lastEntry)),
      type: item.type.toLowerCase() as ConsumptionType,
      updateInterval: item.updateInterval === 0 ? null : item.updateInterval,
      currentValue: item.currentValue === 0 ? null : item.currentValue,
      max: item.max === 0 ? null : item.max,
    };
  },

  fromDto: (
    response: IConsumptionInfoDto,
    consumptionDataVersion: ConsumptionDataVersion
  ): IConsumptionInfo => {
    const consumptionInfoItems: IConsumptionInfoItem[] =
      response?.meters?.length > 0
        ? response.meters.map(v => consumptionInfoConverter.fromItemDto(v, consumptionDataVersion))
        : [];

    return { meters: consumptionInfoItems };
  },
};

export const consumptionConverter = {
  fromItemDto: (item: IConsumptionItemDto, i): IConsumptionItem => {
    const start = getCorrectedTimestamp(Number(item.periodStart));
    const end = getCorrectedTimestamp(Number(item.periodEnd));
    return {
      amount: item.amount,
      consumptionType: item.consumptionType.toLowerCase() as ConsumptionType,
      period: {
        start: start,
        end: end,
      },
      unit: item.unit as ConsumptionUnitType,
      date: i === 0 ? start : end,
      periodStartValue: item.periodStartValue,
      periodEndValue: item.periodEndValue,
    };
  },

  fromDto: (response: IConsumptionResponse | null): IConsumptionItem[] => {
    let consumptionItemsDTO = [];
    if (response?.consumptionList?.length > 1) {
      consumptionItemsDTO = response.consumptionList;
    } else if (response?.consumptionList?.length === 1) {
      consumptionItemsDTO = response.consumptionList.map(item => {
        const startLength = getLengthOfNumber(Number(item.periodStart));
        // Todo not sure about this + 1296000 thing
        const start =
          startLength > 10 ? Number(item.periodStart) : Number(item.periodStart) + 1296000;

        return {
          periodStart: start,
          periodEnd: Number(item.periodEnd),
          consumptionType: item.consumptionType,
          amount: item.amount,
          unit: item.unit,
          periodStartValue: item.periodStartValue,
          periodEndValue: item.periodEndValue,
        };
      });
    }

    const consumptionItems: IConsumptionItem[] = consumptionItemsDTO
      .map((item, index) => consumptionConverter.fromItemDto(item, index))
      .sort((a, b) => a.period.start - b.period.start);
    return consumptionItems;
  },
};

/***
 * For problematic offset timezone calculations on ATLAS
 * ATLAS sometimes sends date with the wrong day, so we get date of the last day of previous month, instead of first day of this month.
 * That is why we add five whole days to the first period on monthly consumptions.
 * Seems like it's calculated in 30 days period
 *
 * We request monthly data from BGM from 1680307200000 (1.4.2023. 00:00:00) but get data from 1680220800000(31.3.2023. 00:00:00)
 * We request data for 1.2.2023. and receive data for 28.1.2023.
 * @param date
 */
export const addFiveDaysToFirstPeriod = (
  date: Milliseconds,
  defaultAggregationType?: DefaultAggregationType
): Milliseconds => {
  if (defaultAggregationType === ConsumptionAggregateType.MONTH) {
    return startOfMonthMilliseconds(Number(date) + 86400 * 5);
  }
  return date;
};

/***
 * Filters all consumptionItem that are allowed due to the contract
 * @param response consumption response from the GraphQL EP
 * @param contract contract data from the internal store
 * @return response consumptions filtered by contract period
 */
export const consumptionLegalFilter = (
  response: IConsumptionResponse,
  contract: IContract
): IConsumptionResponse => {
  if (!response?.consumptionList) {
    return response;
  }

  return {
    consumptionList: response.consumptionList.filter((item: IConsumptionItemDto) => {
      if (!contract?.contractStartTime) {
        return false;
      }

      const start = getCorrectedTimestamp(Number(item.periodStart));
      const end = getCorrectedTimestamp(Number(item.periodEnd));

      if (contract.contractStartTime && contract.contractEndTime) {
        return start >= contract.contractStartTime && end <= contract.contractEndTime;
      }

      return start > contract.contractStartTime;
    }),
  };
};

/***
 * Calculates selected period consumption amount
 * For selected day return consumption item
 * For selected month filter value of just the selected month,
 * subtract periodStartValue of first day of month from periodEndValue of last day
 * @param consumptions consumption response from the GraphQL EP
 * @param selectedPeriod IPeriod when day is selected
 * @param selectedMonth number when month is selected
 * @return response consumption item with calculated amount
 */
export const consumptionCalculator = {
  dayConsumption: (
    consumptions: IConsumptionItem[],
    date: number,
    month: number,
    year: number
  ): IConsumptionItem => {
    return consumptions?.find(el => {
      const elDate = new Date(el.date);
      if (
        elDate.getFullYear() === year &&
        elDate.getMonth() === month &&
        elDate.getDate() === date
      ) {
        return el;
      }
      return false;
    });
  },
  monthConsumption: (
    consumptions: IConsumptionItem[],
    month: number,
    year: number
  ): IConsumptionItem => {
    return consumptions?.find(
      el =>
        new Date(el.period.start).getMonth() === month &&
        new Date(el.period.start).getFullYear() === year
    );
  },
  benchmarkMonthConsumption: (
    benchmarks: IConsumptionBenchmarkItemResponse[],
    month: number,
    year: number
  ): IConsumptionBenchmarkItemResponse => {
    const benchmarkMonthConsumption = benchmarks?.find(el => {
      // TODO move the date converting to a separate function, similar to consumption data fromDto
      const periodStart = getCorrectedTimestamp(Number(el.periodStart));
      const elMonth = new Date(periodStart).getMonth();
      const elYear = new Date(periodStart).getFullYear();
      return elMonth === month && elYear === year;
    });
    return benchmarkMonthConsumption;
  },
};

// checks whether selectedPeriod is allowed and available for the selected contract and meter
export const isSelectedPeriodAllowedAndAvailable = (
  contract: IContract,
  selectedPeriod: IPeriod,
  selectedMeter: IConsumptionInfoItem
): boolean => {
  if (!contract?.contractStartTime) {
    return false;
  }

  const contractEndTime: number = contract.contractEndTime || Date.now();
  if (
    selectedPeriod.start >= contract.contractStartTime &&
    selectedPeriod.start < contractEndTime &&
    selectedPeriod.end > contract.contractStartTime
  ) {
    return (
      selectedPeriod.start >= selectedMeter.firstEntry &&
      selectedPeriod.start <= selectedMeter.lastEntry &&
      selectedPeriod.end > selectedMeter.firstEntry
    );
  } else {
    return false;
  }
};

// checks if any data for the meter falls within contract period
export const isSelectedMeterAllowedAndAvailable = (
  contract: IContract,
  selectedMeter: IConsumptionInfoItem
): boolean => {
  if (!contract?.contractStartTime) {
    return false;
  }

  const contractEndTime: number = contract.contractEndTime || Date.now();

  return (
    selectedMeter.firstEntry < contractEndTime &&
    selectedMeter.lastEntry > contract.contractStartTime
  );
};

export const getSavedConsumption = (
  savedConsumptions: ConsumptionsByContractR,
  contractId: ContractId,
  deviceId: DeviceId,
  selectedPeriod: IPeriod
): IConsumptionsByPeriod => {
  let savedConsumptionPeriod: IConsumptionsByPeriod;
  const savedConsumptionByContract = savedConsumptions[contractId];
  if (savedConsumptionByContract) {
    const savedConsumptionByMeter = savedConsumptionByContract[deviceId];
    savedConsumptionPeriod = savedConsumptionByMeter?.find(
      el =>
        el.selectedPeriod.start === selectedPeriod.start &&
        el.selectedPeriod.end === selectedPeriod.end
    );
  }
  return savedConsumptionPeriod;
};

export const splitPeriodToMonths = (period: IPeriod): IMonth[] => {
  const interval = eachMonthOfInterval({
    start: period?.start,
    end: period?.end,
  });
  const months = [];
  interval.forEach(m =>
    months.push({
      month: getMonth(m),
      selected: false,
      monthStartTimestamp: startOfMonth(m).getTime(),
      monthEndTimestamp: endOfMonth(m).getTime(),
    })
  );
  return months;
};

export const calculateSelectedConsumption = (
  data: IConsumptionsByPeriod,
  selectedTimeframe: ConsumptionTimeframe,
  selectedMonth?: IMonth
): IConsumption => {
  let pastConsumption;
  let benchmark;
  let consumption;

  if (data?.consumption?.length) {
    let month;
    let year;
    if (selectedTimeframe === ConsumptionTimeframe.YEAR && selectedMonth) {
      // in case of switching from monthly to yearly view get previously selected month
      month = selectedMonth.month;
      year = new Date(selectedMonth.monthStartTimestamp).getFullYear();
    } else {
      // in other cases take last available consumption
      const selectedConsumption = data.consumption.reduce((prev, current) =>
        prev?.period.start > current?.period.start ? prev : current
      );
      month = new Date(selectedConsumption.period.start).getMonth();
      year = new Date(selectedConsumption.period.start).getFullYear();
    }

    consumption = consumptionCalculator.monthConsumption(data.consumption, month, year);

    if (data.pastConsumption) {
      pastConsumption = consumptionCalculator.monthConsumption(
        data.pastConsumption,
        month,
        year - 1
      );
    }
    if (data.benchmark) {
      benchmark = consumptionCalculator.benchmarkMonthConsumption(data.benchmark, month, year);
    }
  }

  return { consumption, pastConsumption, benchmark, periodType: PeriodType.MONTH };
};

export const findSavedConsumptionDataForContract = (
  savedData: ConsumptionsByContractR,
  contractId: ContractId
): ConsumptionsByDeviceR => {
  // if no saved data for this contract create empty object
  if (!savedData[contractId]) {
    savedData[contractId] = {} as ConsumptionsByDeviceR;
  }
  return { ...savedData[contractId] };
};

export const findSavedConsumptionDataForDevice = (
  savedDataForContract: ConsumptionsByDeviceR,
  deviceId: DeviceId
): IConsumptionsByPeriod[] => {
  // if no saved data for this meter create empty array
  if (!savedDataForContract[deviceId]) {
    savedDataForContract[deviceId] = [];
  }
  return [...savedDataForContract[deviceId]];
};

export const consumptionTimeframeToAggregate = (
  timeframe: ConsumptionTimeframe
): DefaultAggregationType => {
  switch (timeframe) {
    case ConsumptionTimeframe.YEAR:
      return ConsumptionAggregateType.MONTH;
    case ConsumptionTimeframe.MONTH:
      return ConsumptionAggregateType.DAY;
    default:
      return ConsumptionAggregateType.MONTH;
  }
};
