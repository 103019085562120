import { Directive } from '@angular/core';
import { ImmomioIconName } from '../../models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { RouterService } from '../../services';

@UntilDestroy()
@Directive()
export abstract class UserEditOverviewPageBaseComponent {
  public immomioIconName = ImmomioIconName;
  public closeButton: ImmomioIconName = ImmomioIconName.Close;

  constructor(protected routerService: RouterService) {}
}
