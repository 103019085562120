import { createReducer, on } from '@ngrx/store';

import * as fromActions from './loading-indicator.actions';

export interface ILoadingIndicatorState {
  isLoading: boolean;
}

export const initialState: ILoadingIndicatorState = {
  isLoading: false,
};

export const loadingIndicatorReducer = createReducer(
  initialState,

  on(fromActions.SetLoadingIndicatorStatus, (state, { isLoading }) => {
    return {
      ...state,
      isLoading,
    };
  })
);

export const getLoadingIndicatorStatus = (state: ILoadingIndicatorState) => state.isLoading;
