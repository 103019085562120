import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImmomioIconName, ITicketMessage } from '../../models';

@Directive()
export abstract class MessageBaseComponent implements OnInit {
  @Input() message: ITicketMessage;
  @Input() first = false;
  @Output() retryMessageEvent = new EventEmitter<ITicketMessage>();
  public messageLines: string[] = [];
  public immomioIconName = ImmomioIconName;

  ngOnInit() {
    this.messageLines = this.message.message.split('<br />');
  }
}
