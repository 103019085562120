import { createSelector } from '@ngrx/store';

import { getConsumptionState } from '../reducers';

export const getActionStates = createSelector(getConsumptionState, state => state.actionStates);

export const getCurrentMonthConsumption = createSelector(
  getConsumptionState,
  state => state.currentMonthConsumption
);
export const getCurrentMonthConsumptionActionState = createSelector(
  getActionStates,
  actionStates => actionStates.currentMonthConsumption
);

export const getSelectedMeters = createSelector(getConsumptionState, state => state.selectedMeters);

export const getFirstSelectedMeter = createSelector(
  getSelectedMeters,
  selectedMeters => selectedMeters[0]
);

export const getSelectedConsumptions = createSelector(
  getConsumptionState,
  state => state.selectedConsumptions
);

export const getSelectedConsumptionForMeter = (deviceId: string) =>
  createSelector(getSelectedConsumptions, selectedConsumptions => selectedConsumptions[deviceId]);

export const getSelectedConsumptionForFirstMeter = createSelector(
  getSelectedConsumptions,
  getFirstSelectedMeter,
  (selectedConsumptions, meter) => selectedConsumptions[meter.deviceId]
);

export const getConsumption = createSelector(getConsumptionState, state => state.consumption);

export const getConsumptionForMeter = (deviceId: string) =>
  createSelector(getConsumption, consumptions => consumptions[deviceId]);

export const getConsumptionForFirstMeter = createSelector(
  getConsumption,
  getFirstSelectedMeter,
  (consumption, meter) => consumption[meter.deviceId]
);

export const getPastConsumption = createSelector(
  getConsumptionState,
  state => state.pastConsumption
);

export const getPastConsumptionForMeter = (deviceId: string) =>
  createSelector(getPastConsumption, consumptions => consumptions[deviceId]);

export const getPastConsumptionForFirstMeter = createSelector(
  getPastConsumption,
  getFirstSelectedMeter,
  (pastConsumption, meter) => pastConsumption[meter.deviceId]
);

export const getBenchmark = createSelector(getConsumptionState, state => state.benchmark);

export const getBenchmarkForFirstMeter = createSelector(
  getBenchmark,
  getFirstSelectedMeter,
  (benchmark, meter) => benchmark[meter.deviceId]
);

export const getConsumptionActionStates = createSelector(
  getActionStates,
  actionStates => actionStates.consumption
);

export const getConsumptionActionStateForFirstMeter = createSelector(
  getConsumptionActionStates,
  getFirstSelectedMeter,
  (consumptionActionStates, meter) => consumptionActionStates[meter.deviceId]
);

export const getPastConsumptionActionStates = createSelector(
  getActionStates,
  actionStates => actionStates.pastConsumption
);

export const getPastConsumptionActionStateForFirstMeter = createSelector(
  getPastConsumptionActionStates,
  getFirstSelectedMeter,
  (pastConsumptionActionStates, meter) => pastConsumptionActionStates[meter.deviceId]
);

export const getBenchmarkActionStates = createSelector(
  getActionStates,
  actionStates => actionStates.benchmark
);

export const getBenchmarkActionStateForFirstMeter = createSelector(
  getBenchmarkActionStates,
  getFirstSelectedMeter,
  (benchmarkActionStates, meter) => benchmarkActionStates[meter.deviceId]
);

export const getShowPreviousArrow = createSelector(
  getConsumptionState,
  state => state.showPreviousArrow
);

export const getShowPreviousArrowForMeter = (deviceId: string) =>
  createSelector(getShowPreviousArrow, showPreviousArrow => showPreviousArrow[deviceId]);

export const getShowPreviousArrowForFirstMeter = createSelector(
  getShowPreviousArrow,
  getFirstSelectedMeter,
  (showPreviousArrow, meter) => showPreviousArrow[meter.deviceId]
);
export const getShowNextArrow = createSelector(getConsumptionState, state => state.showNextArrow);

export const getShowNextArrowForMeter = (deviceId: string) =>
  createSelector(getShowNextArrow, showNextArrow => showNextArrow[deviceId]);

export const getShowNextArrowForFirstMeter = createSelector(
  getShowNextArrow,
  getFirstSelectedMeter,
  (showNextArrow, meter) => showNextArrow[meter.deviceId]
);

export const getMetersByContract = createSelector(
  getConsumptionState,
  state => state.metersByContract
);

export const getMetersByContractActionState = createSelector(
  getActionStates,
  actionStates => actionStates.metersByContract
);

export const getAvailableMeters = createSelector(
  getConsumptionState,
  state => state.availableMeters
);

export const getConsumptionCache = createSelector(
  getConsumptionState,
  state => state.consumptionCache
);

export const getPeriodsForCharts = createSelector(
  getConsumptionState,
  state => state.periodsForCharts
);

export const getPeriodForChartForMeter = (deviceId: string) =>
  createSelector(getPeriodsForCharts, periodsForCharts => periodsForCharts[deviceId]);

export const getPeriodForChartForFirstMeter = createSelector(
  getPeriodsForCharts,
  getFirstSelectedMeter,
  (periodsForCharts, meter) => periodsForCharts[meter?.deviceId]
);

export const getPeriodsForRequests = createSelector(
  getConsumptionState,
  state => state.periodsForRequests
);

export const getPeriodForRequestForMeter = (deviceId: string) =>
  createSelector(getPeriodsForRequests, periodsForRequests => periodsForRequests[deviceId]);

export const getPeriodForRequestForFirstMeter = createSelector(
  getConsumptionState,
  getFirstSelectedMeter,
  (state, meter) => state.periodsForRequests[meter?.deviceId]
);

export const getSavedDataForContractDeviceAndPeriodForFirstMeter = createSelector(
  getConsumptionState,
  getFirstSelectedMeter,
  (state, meter) => {
    let consumptionData = undefined;
    if (state.consumptionCache) {
      const contractId = state.selectedContract.id;
      const contractData = state.consumptionCache[contractId];
      if (contractData) {
        const deviceData = contractData[meter.deviceId];
        if (deviceData) {
          const periodForChart = state.periodsForCharts[meter.deviceId];
          consumptionData = deviceData.find(
            el =>
              el.selectedPeriod.start === periodForChart?.start &&
              el.selectedPeriod.end === periodForChart?.end
          );
        }
      }
    }
    return consumptionData;
  }
);

export const getChartMonthsForFirstMeter = createSelector(
  getConsumptionState,
  getFirstSelectedMeter,
  (state, meter) => state.chartsMonths[meter.deviceId]
);

export const getDashboardDataForContractDeviceAndPeriodForFirstMeter = createSelector(
  getConsumptionState,
  getFirstSelectedMeter,
  (state, meter) => {
    let consumptionData = undefined;
    if (state.consumptionCache) {
      const contractId = state.selectedContract.id;
      const contractData = state.consumptionCache[contractId];
      if (contractData) {
        const deviceData = contractData[meter.deviceId];
        if (deviceData) {
          consumptionData = deviceData[0];
        }
      }
    }
    return consumptionData;
  }
);

export const getDashboardChartMonthsForFirstMeter = createSelector(
  getConsumptionState,
  getFirstSelectedMeter,
  (state, meter) => state.dashboardChartsMonths[meter.deviceId]
);

export const getSelectedTimeframe = createSelector(
  getConsumptionState,
  state => state.selectedConsumptionTimeframe
);
