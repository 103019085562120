import { Directive, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HexColorCode, ImmomioIconName } from '../../models';

export enum IconFontVariant {
  REGULAR = '',
  BOLD = 'icon-bold',
  LIGHT = 'icon-light',
}

@Directive()
export abstract class IconBaseComponent implements OnInit {
  @Input() icon: ImmomioIconName;
  @Input() size = 26;
  @Input() variant: IconFontVariant = IconFontVariant.REGULAR;
  iconBaseClass = 'icon';
  @Input() color: HexColorCode;
  @Input() otherClasses: string[] = [];
  @Output() tapped = new EventEmitter();

  ngOnInit(): void {
    if (!this.variant) {
      if (this.size > 36) {
        this.variant = IconFontVariant.LIGHT;
      } else if (this.size < 13) {
        this.variant = IconFontVariant.BOLD;
      }
    }
  }
}
