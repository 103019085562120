import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ImmomioIconName } from '../../models';

@Directive()
export class EmergencyContactBannerBaseComponent {
  private _emergencyBannerHeadline: string;
  private _emergencyBannerText: string;
  @Input() isDashboardBanner = true;
  @Input() emergencyHelpDescriptionText: string;
  @Output() tapped = new EventEmitter();
  public immomioIconName = ImmomioIconName;

  @Input()
  set emergencyBannerHeadline(value: string) {
    this._emergencyBannerHeadline = value || 'emergency.emergency_l';
  }
  get emergencyBannerHeadline(): string {
    return this._emergencyBannerHeadline;
  }

  @Input()
  set emergencyBannerText(value: string) {
    this._emergencyBannerText = value || 'emergency.emergency_desc';
  }
  get emergencyBannerText(): string {
    return this._emergencyBannerText;
  }
}
