import { Directive } from '@angular/core';

import { filter, map, Observable, tap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import { IBookedModulesPermissions, IResidentAppSettings, TicketingVersion } from '../../models';
import { getPermissions, getResidentAppSettings } from '../../+state';
import { CustomerService } from '../../services';
import { BaseComponent } from '../../base-component';

@UntilDestroy()
@Directive()
export class DashboardPageBaseComponent extends BaseComponent {
  constructor(
    protected store: Store,
    protected customerService: CustomerService
  ) {
    super();
  }

  public residentAppSettings: IResidentAppSettings;
  public $showWidget: Observable<IBookedModulesPermissions> = this.store
    .select(getPermissions)
    .pipe(
      map(permissions => permissions),
      filter(permission => permission !== null),
      untilDestroyed(this)
    );
  public $showTicketing: Observable<boolean> = this.store.select(getResidentAppSettings).pipe(
    filter(residentAppSettings => residentAppSettings !== null),
    tap(residentAppSettings => {
      this.residentAppSettings = residentAppSettings;
    }),
    map(
      ({ ticketingVersion }) =>
        !this.customerService.hideTicketManagement() &&
        ticketingVersion === TicketingVersion.METADATA
    ),
    untilDestroyed(this)
  );
}
