import { ChangeDetectionStrategy, Component, inject, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AddressPipe,
  ContractIconPipe,
  getMasterDataChangeConfiguration,
  MasterdataChangeRequestType,
  masterDataSelectionTranslateKeys,
} from '@resident-nx/shared';
import { IconWebComponent } from '../../../../atoms/icon/icon.component';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

@Component({
  selector: 'rs-web-user-profile-edit-selection',
  standalone: true,
  imports: [
    CommonModule,
    AddressPipe,
    ContractIconPipe,
    IconWebComponent,
    ReactiveFormsModule,
    RouterLink,
    TranslateModule,
  ],
  templateUrl: './user-profile-edit-selection.component.html',
  styleUrl: './user-profile-edit-selection.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class UserProfileEditSelectionComponent {
  public readonly MasterDataChangeRequestType = MasterdataChangeRequestType;
  goToConcerns = output();
  activeHint = false;
  protected readonly translateKeys = masterDataSelectionTranslateKeys;
  #store = inject(Store);
  config = this.#store.selectSignal(getMasterDataChangeConfiguration);

  constructor() {
    this.activeHint = this.config()?.some(
      config => config.requestType === MasterdataChangeRequestType.TICKET
    );
  }
}
