export enum THEME_VARIABLES {
  PRIMARY = '--color-primary',
  PRIMARY_HOVER = '--color-primary-hover',
  SECONDARY = '--color-secondary',
  SECONDARY_HOVER = '--color-secondary-hover',
  SURFACE = '--color-surface',
  SURFACE_HOVER = '--color-surface-hover',
  BACKGROUND_LIGHT = '--color-background-light',
  BACKGROUND_MEDIUM = '--color-background-medium',
  TEXT_PRIMARY = '--color-text-primary',
  TEXT_SECONDARY = '--color-text-secondary',
  TEXT_BUTTON_PRIMARY = '--color-text-button-primary',
  TEXT_BUTTON_SECONDARY = '--color-text-button-secondary',
  MENU_BAR_BACKGROUND = '--color-menu-bar-background',
  MENU_BAR_TEXT_ACTIVE = '--color-menu-bar-text-active',
  MENU_BAR_TEXT_INACTIVE = '--color-menu-bar-text-inactive',
  MENU_BAR_TEXT_ACTIVE_BG = '--color-menu-bar-text-active-bg',
}

export type HexColorCode = string;
export const UNSET = 'unset';

export interface ITheme {
  colorPrimary: HexColorCode; // whiteLabel
  colorPrimaryHover: HexColorCode; // inherit from whiteLabel
  colorSecondary: HexColorCode; // whiteLabel
  colorSecondaryHover: HexColorCode; // inherit from whiteLabel
  colorSurface: HexColorCode; // whiteLabel
  colorSurfaceHover: HexColorCode; // inherit from whiteLabel
  colorBackgroundLight: HexColorCode; // whiteLabel
  colorBackgroundMedium: HexColorCode; // inherit from whiteLabel
  colorTextPrimary: HexColorCode; // whiteLabel
  colorTextSecondary: HexColorCode; // whiteLabel
  colorTextButtonPrimary: HexColorCode; // #fff or primaryTextColor depending on primary color
  colorTextButtonSecondary: HexColorCode; // #fff or primaryTextColor depending on secondary color
  colorMenuBarBackground: HexColorCode;
  colorMenuBarTextActive: HexColorCode;
  colorMenuBarTextInactive: HexColorCode;
}

export interface IAppSettings {
  theme: ITheme;
  logo: IResidentFile | null;
  landingPageImage: IResidentFile | null;
}

export interface ICustomerIdentBean {
  customerIdent: string;
}

// new EP
export interface IResidentAppWhiteLabelResponse {
  theme: IResidentBrandingTheme;
  branding: IResidentAppBranding;
}

export interface IResidentBrandingTheme {
  name: string;
  primaryColor: HexColorCode;
  secondaryColor: HexColorCode;
  primaryTextColor: HexColorCode;
  secondaryTextColor: HexColorCode;
  buttonTextColor: HexColorCode;
  backgroundColor: HexColorCode;
  cardBackgroundColor: HexColorCode;
  menuBarBackgroundColor: HexColorCode;
  menuBarTextColorActive: HexColorCode;
  menuBarTextColorInactive: HexColorCode;
}

export interface IResidentAppBranding {
  appLogo: IResidentFile;
  landingPageImage: IResidentFile;
}

export interface IResidentFile {
  url: string;
  name: string;
}
