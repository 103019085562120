import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromAnnouncementsState from '../../+state/news/announcements';
import { getTheme } from '../../+state';
import { filter, take } from 'rxjs';
import { ITheme } from '../../models';

@Directive()
export abstract class AnnouncementDetailsBaseComponent {
  announcement$ = this.announcementsStore.select(fromAnnouncementsState.getAnnouncement);
  announcementActionState$ = this.announcementsStore.select(
    fromAnnouncementsState.getAnnouncementActionState
  );
  protected theme: ITheme;

  constructor(private announcementsStore: Store<fromAnnouncementsState.IAnnouncementsState>) {
    announcementsStore
      .select(getTheme)
      .pipe(
        filter(theme => theme !== null),
        take(1)
      )
      .subscribe(theme => {
        this.theme = theme;
      });
  }
}
