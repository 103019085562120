@if ((offersActionState$ | async).pending) {
  <rs-web-loading-skeleton
    [show]="true"
    [types]="[LoadingType.HEADER, LoadingType.MULTIPLECARD]"
  ></rs-web-loading-skeleton>
} @else {
  <div class="offers-widget">
    <rs-web-cards-container-header
      (tapped)="onTapOverview()"
      [buttonText]="'general.overview_a'"
      [buttonTypeEnum]="ButtonEnumTypes.LIGHT_BORDERED"
      [icon]="icon"
      [title]="title"
    ></rs-web-cards-container-header>
    <div class="offers-widget__overflow">
      <div class="offers-widget__content">
        @let homepageToken = token$ | async;

        @if (homepageToken) {
          <rs-web-content-card
            (click)="onTapHomepage()"
            [image]="'/web/images/homepage-card.svg'"
            [preset]="preset.OFFER"
            [textOverflow]="false"
            [text]="'to_apartments'"
            [title]="'apartments'"
            class="offers-widget__content-card"
          >
          </rs-web-content-card>
        }
        @for (offer of offers$ | async; track offer.node.id; let i = $index) {
          @if (
            (homepageToken === null && offersLimit - 1 > i) ||
            (homepageToken && offersLimit - 2 > i)
          ) {
            <rs-web-content-card
              (tapped)="onTap($event)"
              [id]="offer.node.id"
              [image]="offer.node.imageUrl"
              [preset]="preset.OFFER"
              [text]="offer.node.body"
              [title]="offer.node.title"
              class="offers-widget__content-card"
            ></rs-web-content-card>
          } @else {
            @if (
              (homepageToken === null && offersLimit - 1 === i) ||
              (homepageToken && offersLimit - 2 === i)
            ) {
              <rs-web-card
                (click)="onTapOverview()"
                [elevation]="3"
                [hover]="true"
                class="more-offers-card"
              >
                <div class="offers-widget__extra-card">{{ buttonText | translate }}</div>
              </rs-web-card>
            }
          }
        }
      </div>
    </div>
  </div>
}
