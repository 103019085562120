import { Inject, Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IAppSettings, IEnvironment, IResidentAppWhiteLabelResponse } from '../../../models';
import { appSettingsConverter } from './app-settings.converter';
import { appSettingsQuery } from '../../../queries';

@Injectable()
export class AppSettingsFacade {
  constructor(
    private apollo: Apollo,
    @Inject('ENVIRONMENT') private environment: IEnvironment
  ) {}

  public getAppSettings(): Observable<IAppSettings> {
    return this.apollo
      .query<{ getWhiteLabelSettings: IResidentAppWhiteLabelResponse }>({
        query: appSettingsQuery,
      })
      .pipe(
        map(response => response?.data?.getWhiteLabelSettings),
        map(appSettingsConverter.fromDto),
        catchError((error: Error) => {
          throw new Error(error.message);
        })
      );
  }
}
