<div class="image-container" [ngClass]="{ small: small(), error: isFileTooBig() }">
  <rs-web-image
    [defaultSrc]="file()"
    [fileReader]="true"
    [borderRadius]="ImageBorderRadiusEnum.BIG"
  ></rs-web-image>
  @if (isRemovable()) {
    <div (click)="remove.emit()" class="image-delete-button">
      <rs-web-icon [color]="'#ffffff'" [icon]="ImmomioIconName.Trash" [size]="16"></rs-web-icon>
    </div>
  }
</div>

@if (isFileTooBig()) {
  <div class="error-message">
    <rs-web-icon [size]="12" [icon]="ImmomioIconName.Info"></rs-web-icon>
    {{ 'upload_max_file_size_l' | translate: { value: '20MB' } }}
  </div>
}
