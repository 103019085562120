import { createSelector } from '@ngrx/store';

import { getPermissionsState } from '../../reducers';
import * as fromReducer from './permissions.reducer';
import { TicketAttachmentDocumentType } from '../../../models/tickets/ticket-attachment-document-type.enum';

export const getPermissionsActionState = createSelector(
  getPermissionsState,
  fromReducer.getPermissionsActionState
);
export const getPermissions = createSelector(getPermissionsState, fromReducer.getPermissions);
export const getResidentAppSettings = createSelector(
  getPermissionsState,
  fromReducer.getResidentAppSettings
);

export const getDamageTicketFileTypes = createSelector(
  getResidentAppSettings,
  residentAppSettings => residentAppSettings.allowedDamageTicketAttachmentFileTypes
);

export const getConcernTicketFileTypes = createSelector(
  getResidentAppSettings,
  residentAppSettings => residentAppSettings.allowedConcernTicketAttachmentFileTypes
);

export const getDamageMessengerAvailability = createSelector(
  getResidentAppSettings,
  residentAppsettings => residentAppsettings.enableTicketMessengerForDamages
);

export const getConcernMessengerAvailability = createSelector(
  getResidentAppSettings,
  residentAppsettings => residentAppsettings.enableTicketMessengerForConcerns
);

export const getDamageTicketFileInputAccept = createSelector(
  getDamageTicketFileTypes,
  documentTypes => convertDocumentTypesToAcceptAttribute(documentTypes)
);

export const getConcernTicketFileInputAccept = createSelector(
  getConcernTicketFileTypes,
  documentTypes => convertDocumentTypesToAcceptAttribute(documentTypes)
);

const convertDocumentTypesToAcceptAttribute = (
  documentTypes: TicketAttachmentDocumentType[]
): string =>
  documentTypes
    .map(documentType => {
      if (documentType === TicketAttachmentDocumentType.IMG) {
        return 'image/*';
      }
      if (documentType === TicketAttachmentDocumentType.PDF) {
        return '.pdf';
      }
      return '';
    })
    .toString();
