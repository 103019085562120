import { IKeyValue } from '../helpers';
import { IAddress } from '../general';
import { ISepaMandate, ISepaMandates } from '../masterdata';

export interface IUser {
  email: string;
  password: string;
  contractID: string;
}

export interface IUpdateUserMasterdataPayload {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  mobile: string;
}

export interface IForgotPasswordPayload {
  email: string;
}

export interface IResetPasswordPayload {
  password: string;
  passwordConfirmation: string;
}

export interface IResetPasswordBeanPayload {
  token: string;
  password: string;
  confirmedPassword: string;
}

export interface IPasswordResetFormValues {
  oldPassword: string;
  newPassword: {
    password: string;
    passwordConfirm: string;
  };
}

export interface IResetEmailBeanPayload {
  token: string;
}

export interface ILoginInput {
  email: string;
  password?: string;
}

export interface IChangePasswordPayload extends IKeyValue {
  token?: string;
  oldPassword?: string;
  newPassword: string;
  confirmedNewPassword: string;
}

export interface ILegalTexts {
  agb: string;
  imprint: string;
  privacy: string;
  digitalDocumentsReceival: string;
  digitalDocumentsReceivalMandatory: boolean;
}

export interface IPersonalDataResponse {
  userDataResponseBean: IUserData;
}

export interface IUserData {
  firstName: string;
  name: string;
  email: string;
  contactEmail: string;
  birthDate: string;
  phone: string;
  mobile: string;
  address: IAddress;
  sepaMandate: ISepaMandate;
  sepaMandates: ISepaMandates[];
}
