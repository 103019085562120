import { Directive } from '@angular/core';

import { Store } from '@ngrx/store';

import * as fromNewsState from '../../+state/news/news';
import { ITheme } from '../../models';
import { getTheme } from '../../+state';
import { filter, take } from 'rxjs';

@Directive()
export abstract class CurrentNewsDetailsBaseComponent {
  news$ = this.newsStore.select(fromNewsState.getSingleNews);
  newsActionState$ = this.newsStore.select(fromNewsState.getSingleNewsActionState);
  protected theme: ITheme;

  protected constructor(private newsStore: Store<fromNewsState.INewsState>) {
    newsStore
      .select(getTheme)
      .pipe(
        filter(theme => theme !== null),
        take(1)
      )
      .subscribe(theme => {
        this.theme = theme;
      });
  }
}
