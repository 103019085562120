import { TicketAttachmentDocumentType } from '../tickets/ticket-attachment-document-type.enum';

export interface ICustomerAppPermissions {
  permissions: IPermissions;
  residentAppSettings: IResidentAppSettings;
}

export interface ICustomerAppPermissionsResponse {
  bookedModulesResponseBean: IBookedModulesPermissions;
  residentAppSettings: IResidentAppSettings;
}

export interface IBookedModulesPermissions {
  whitelabelCustomerPortal: boolean;
  consumptionDataVisualization: boolean;
  internalTicketing: boolean;
  payment: boolean;
}

export interface IResidentAppSettings {
  ticketingVersion: TicketingVersion;
  consumptionDataVersion?: ConsumptionDataVersion;
  documentSortingSupported: boolean;
  allowContractlessTicketCreationForDamages: boolean;
  allowContractlessTicketCreationForOtherConcerns: boolean;
  showStartPageBanner: boolean;
  startPageBannerHeadline: string;
  startPageBannerText: string;
  showFaqCard: boolean;
  faqCardHeadline: string;
  faqCardText: string;
  allowedDamageTicketAttachmentFileTypes: TicketAttachmentDocumentType[];
  allowedConcernTicketAttachmentFileTypes: TicketAttachmentDocumentType[];
  enableTicketMessengerForDamages: boolean;
  enableTicketMessengerForConcerns: boolean;
}

export type IPermissions = IBookedModulesPermissions;

export enum TicketingVersion {
  HIERARCHICAL = 'HIERARCHICAL', // old ticketing
  METADATA = 'METADATA', // metadata ticketing
}

export enum ConsumptionDataVersion {
  INTERNAL = 'INTERNAL', // new internal uVI API
  EXTERNAL = 'EXTERNAL', // old standard for Gebausie/BGM/WGH etc
}
