<div class="d-flex flex-column">
  <span class="fw-bold"
    >{{ 'user_edit_overview_l' | translate }}:
    {{ selectionTranslateKeys[changeType()] | translate }}</span
  >

  <hr />

  <div class="d-flex flex-row align-items-center gap-2">
    <rs-web-icon [icon]="ImmomioIconName.Calendar" [size]="16"></rs-web-icon>
    <span> {{ now | date: 'EEEEEE dd.MM.YYYY' }} </span>
  </div>

  <span class="fw-bold mt-3">{{ 'user_edit_overview_title_l' | translate }}:</span>

  <div class="mt-1">
    <ng-content></ng-content>
  </div>
</div>
