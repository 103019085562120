<div class="container">
  <div class="mobile-subheader">
    <div class="d-flex align-items-center">
      <i class="icon-user me-2"></i>
      <h1>{{ 'user.personal_data' | translate }}</h1>
    </div>
    <rs-web-button
      class="mobile-button"
      [type]="buttonType['LIGHT_BORDERED']"
      [size]="buttonSize['SMALL']"
      (tapped)="editProfile()"
      >{{ 'account.edit_data_a' | translate }}
    </rs-web-button>
    <hr />
  </div>

  <div class="profile-container rounded">
    <div class="tab rounded profile-details">
      <rs-web-profile-details [userData]="userData" [noData]="noData"></rs-web-profile-details>
    </div>
    <div class="d-flex flex-column contact-payment-details">
      <div class="tab rounded">
        <rs-web-user-contact-details
          [userData]="userData"
          [noData]="noData"
        ></rs-web-user-contact-details>
      </div>
      @if (userData?.sepaMandate) {
        <div class="tab rounded">
          <rs-web-payment-details [userData]="userData" [noData]="noData"></rs-web-payment-details>
        </div>
      }
    </div>
  </div>
  @if (showProfileButton$ | async) {
    <div class="w-25 web-button">
      <rs-web-button (tapped)="editProfile()" [type]="buttonType['SECONDARY']" [ghost]="true">
        {{ 'account.edit_data_a' | translate }}
      </rs-web-button>
    </div>
  }
</div>

<ng-template #noData><span>---</span></ng-template>
