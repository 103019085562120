import { UntilDestroy } from '@ngneat/until-destroy';
import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { getPersonalData } from '../../+state';

@UntilDestroy()
@Directive()
export class PaymentInformationBaseComponent {
  public sepaMandates$ = this.store
    .select(getPersonalData)
    .pipe(map(personalData => personalData?.sepaMandates));
  public expandAll = false;

  constructor(private store: Store) {}

  toggleExpandAll() {
    this.expandAll = !this.expandAll;
  }
}
