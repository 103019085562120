export const enum ROUTE {
  HOME = 'home',
  DASHBOARD = 'dashboard',
  NEWS = 'news',
  TICKETS = 'tickets',
  SETTINGS = 'settings',
  LEGAL = 'legal',
  ANNOUNCEMENTS = 'announcements',
  OFFERS = 'offers',
  CURRENT_NEWS = 'current-news',
  OBJECTS = 'objects',
  ANNOUNCEMENTS_DETAILS = 'announcements/:id',
  OFFERS_DETAILS = 'offers/:id',
  CURRENT_NEWS_DETAILS = 'current-news/:id',
  LOGIN = 'login',
  FAQ = 'faq',
  LOGOUT = 'logout',
  PROFILE_PAGE = 'profile-page',
  DOCUMENTS = 'documents',
  CONTRACTS = 'contracts',
  PAYMENTS = 'payments',
  DAMAGES = 'damages',
  REQUESTS = 'requests',
  CONSUMPTION = 'consumption',
  NOTIFICATIONS = 'notifications',
  ACCOUNT = 'account',
}
