// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isArrayOfStrings = (arr: any): arr is string[] =>
  Array.isArray(arr) && !arr.some(e => typeof e !== 'string');

export const removeArrayDuplicatesByKey = <T>(array: T[], key: string): T[] => {
  const existingKeys = new Set<string>();
  return array.filter(item => {
    if (existingKeys.has(item[key])) {
      return false;
    }
    existingKeys.add(item[key]);
    return true;
  });
};
