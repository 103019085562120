import { Directive, Injector, Input } from '@angular/core';
import {
  ConsumptionUnitType,
  IConsumptionInfo,
  IConsumptionInfoItem,
  IConsumptionsByPeriod,
  ImmomioIconName,
  IMonth,
  NameDisplayMode,
} from '../../models';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { IActionState } from '../../utils';
import { RouterService } from '../../services';
import * as fromConsumptionState from '../../+state/consumption';
import {
  getConsumptionActionStateForFirstMeter,
  getDashboardDataForContractDeviceAndPeriodForFirstMeter,
  getDashboardChartMonthsForFirstMeter,
} from '../../+state';

@Directive()
export abstract class ConsumptionDashboardCardBaseComponent {
  @Input() metersByContract: IConsumptionInfo;
  public selectedMeter$: Observable<IConsumptionInfoItem>;
  public selectedPeriodConsumptionActionState$: Observable<IActionState>;
  public nameDisplayMode = NameDisplayMode;
  public immomioIconName = ImmomioIconName;
  public consumptionUnitType = ConsumptionUnitType;
  public selectedIndex = 0;
  protected store: Store<fromConsumptionState.IConsumptionState>;
  private routerService: RouterService;

  public savedConsumptionData$: Observable<IConsumptionsByPeriod>;
  public chartMonths$: Observable<IMonth[]>;

  constructor(protected injector: Injector) {
    this.store = injector.get(Store<fromConsumptionState.IConsumptionState>);
    this.routerService = injector.get(RouterService);
    this.selectedMeter$ = this.store.select(fromConsumptionState.getFirstSelectedMeter);
    this.selectedPeriodConsumptionActionState$ = this.store.select(
      getConsumptionActionStateForFirstMeter
    );

    this.savedConsumptionData$ = this.store.select(
      getDashboardDataForContractDeviceAndPeriodForFirstMeter
    );
    this.chartMonths$ = this.store.select(getDashboardChartMonthsForFirstMeter);
  }

  public navigateToConsumption(): void {
    void this.routerService.navigate(['content', 'consumption']);
  }

  public previousMeter(meters: IConsumptionInfoItem[], index: number): void {
    if (this.selectedIndex === 0) {
      this.selectedIndex = meters.length - 1;
    } else {
      this.selectedIndex = index - 1;
    }
    this.store.dispatch(
      fromConsumptionState.SelectMeters({ meters: [meters[this.selectedIndex]] })
    );
  }

  public nextMeter(meters: IConsumptionInfoItem[], index: number): void {
    if (this.selectedIndex === meters.length - 1) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex = index + 1;
    }
    this.store.dispatch(
      fromConsumptionState.SelectMeters({ meters: [meters[this.selectedIndex]] })
    );
  }
}
