import { IEnvironment } from '../models';
import { environment } from './environment';

export const environmentLocal: IEnvironment = {
  ...environment,
  production: false,
  envName: 'local',
  graphqlServerUri: 'http://api-rs.itg.immomio.com/graphql',
  graphqlHost: 'api-rs.itg.immomio.com',
  filerUrl: 'http://filer-rs.itg.immomio.com/rs/',
};
