import { createAction, props } from '@ngrx/store';
import {
  IContract,
  IMasterDataChange,
  IMasterdataChangeConfiguration,
  IMasterdataChangeRequest,
  IMasterdataChangeTicket,
  IMasterDataUser,
  IResidentS3File,
  IUpdateUserMasterdataPayload,
} from '../../../models';

export const LoadMasterData = createAction('[Account MasterData] Load MasterData');

export const LoadMasterDataSuccess = createAction(
  '[Account MasterData] Load MasterData Success',
  props<{ user: IMasterDataUser }>()
);

export const LoadMasterDataFailed = createAction(
  '[Account MasterData] Load MasterData Failed',
  props<{ error: Error }>()
);

export const UpdateMasterData = createAction(
  '[Account MasterData] Update MasterData',
  props<{ payload: IUpdateUserMasterdataPayload }>()
);

export const UpdateMasterDataSuccess = createAction(
  '[Account MasterData] Update MasterData Success',
  props<{ createdTicketId: string }>()
);

export const UpdateMasterDataFailed = createAction(
  '[Account MasterData] Update MasterData Failed',
  props<{ error: Error }>()
);
export const LoadContracts = createAction(
  '[Account MasterData] Load Contracts',
  props<{ forced?: boolean }>()
);

export const LoadContractsSuccess = createAction(
  '[Account MasterData] Load Contracts Success',
  props<{ contracts: IContract[] }>()
);

export const LoadContractsFailed = createAction(
  '[Account Contracts] Load Contracts Failed',
  props<{ error: Error }>()
);

export const SelectContract = createAction(
  '[Account Contracts] Select Contract',
  props<{ id: string }>()
);

export const LoadContractDocuments = createAction(
  '[Account Contract Documents] Load Contracts Documents',
  props<{ contractId: string }>()
);

export const LoadContractDocumentsSuccess = createAction(
  '[Account Contract Documents] Load Contracts Documents Success',
  props<{ documents: IResidentS3File[] }>()
);

export const LoadContractDocumentsFailed = createAction(
  '[Account Contract Documents] Load Contracts Failed',
  props<{ error: Error }>()
);

export const SelectContractDocument = createAction(
  '[Account Contract Documents] Select Document',
  props<{ identifier: string }>()
);

export const SetMovingAddressSent = createAction(
  '[Account Contract Address] Set Moving Address',
  props<{ contractId: string; movingAddressSent: boolean }>()
);

export const SetMovingAddressSentSuccess = createAction(
  '[Account Contract Address] Set Moving Address Success',
  props<{ success: boolean }>()
);

export const SetMovingAddressSentError = createAction(
  '[Account Contract Address] Set Moving Address Error',
  props<{ error: Error }>()
);

export const LoadMasterDataChangeConfiguration = createAction(
  '[Account MasterDataChange Config] Get MasterDataChange Config'
);

export const LoadMasterDataChangeConfigurationSuccess = createAction(
  '[Account MasterDataChange Config] Get MasterDataChange Config Success',
  props<{ masterDataChangeConfiguration: IMasterdataChangeConfiguration[] }>()
);

export const LoadMasterDataChangeConfigurationError = createAction(
  '[Account MasterDataChange Config] Get MasterDataChange Config Error',
  props<{ error: Error }>()
);

export const SetMasterDataChange = createAction(
  '[Account MasterDataChange Config] Set MasterDataChange Config',
  props<{ masterDataChange: Partial<IMasterDataChange> }>()
);

export const ResetMasterDataChange = createAction(
  '[Account MasterDataChange Config] Reset MasterDataChange Config'
);

export const SendMasterDataChangeRequest = createAction(
  '[Account MasterData] Send MasterData Change Request',
  props<{ payload: IMasterdataChangeRequest }>()
);

export const SendMasterDataChangeRequestSuccess = createAction(
  '[Account MasterData] Send MasterData Change Request Success',
  props<{ success: boolean }>()
);

export const SendMasterDataChangeRequestError = createAction(
  '[Account MasterData] Send MasterData Change Request Error',
  props<{ error: Error }>()
);

export const SendMasterDataChangeTicket = createAction(
  '[Account MasterData] Send MasterData Change Request Ticket',
  props<{ payload: IMasterdataChangeTicket }>()
);

export const SendMasterDataChangeTicketSuccess = createAction(
  '[Account MasterData] Send MasterData Change Request Ticket Success',
  props<{ success: boolean }>()
);

export const SendMasterDataChangeTicketError = createAction(
  '[Account MasterData] Send MasterData Change Request Ticket Error',
  props<{ error: Error }>()
);
