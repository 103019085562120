import {
  IConversationMessage,
  IMasterDataUser,
  ITicketConversationEdit,
  ITicketMessage,
  ITicketMessageGroup,
  ProfileButtonColors,
} from '../../models';

export const ticketChatConverter = {
  fromDto: (
    ticketMessages: IConversationMessage[],
    user: IMasterDataUser
  ): ITicketMessageGroup[] => {
    let messageGroups: ITicketMessageGroup[] = [];
    const colors = Object.values(ProfileButtonColors);
    const idToColorMap: { [id: string]: string } = {};

    if (ticketMessages?.length > 0) {
      messageGroups = ticketMessages.reduce((result, current) => {
        const currentName = current.created.editor.name;
        let msg: ITicketMessage = {
          id: current.id,
          message: current.message,
          attachments: current.attachments,
          timestamp: Number(current.created.date),
          isIncoming: !(
            current.created.editor.id === user.id ||
            user.firstName + ' ' + user.lastName === currentName ||
            user.firstName === currentName ||
            user.lastName === currentName
          ),
        };
        if (!msg.isIncoming) {
          msg = {
            ...msg,
            status: current.status,
            retryMessageId: current.retryMessageId,
            retriedOn: current.retriedOn,
          };
        }
        const lastGroup = result[result.length - 1];
        if (lastGroup && lastGroup.user.id === current.created.editor.id) {
          lastGroup.messages.push(msg);
        } else {
          if (msg.isIncoming) {
            // eslint-disable-next-line no-prototype-builtins
            if (!idToColorMap.hasOwnProperty(current.created.editor.id)) {
              idToColorMap[current.created.editor.id] =
                colors[Object.keys(idToColorMap).length % colors.length];
            }
          }
          result.push({
            user: current.created.editor,
            userInitials: msg.isIncoming
              ? current.created.editor?.name
                  ?.split(' ')
                  .map(word => word[0])
                  .join('')
                  .substring(0, 2) || ''
              : user.initials.substring(0, 2),
            isIncoming: msg.isIncoming,
            messages: [msg],
            color: idToColorMap[current.created.editor.id],
          });
        }

        return result;
      }, []);
    }
    return messageGroups;
  },
  toNewMessage: (
    sentMessageId: string,
    sentMessage: ITicketConversationEdit,
    ticketMessages: ITicketMessageGroup[],
    user: IMasterDataUser,
    internalTicketing: boolean
  ): ITicketMessageGroup[] => {
    if (ticketMessages?.length > 0) {
      const lastMessageGroup = {
        ...ticketMessages[ticketMessages.length - 1],
        messages: [...ticketMessages[ticketMessages.length - 1].messages],
      };
      if (!lastMessageGroup.isIncoming) {
        lastMessageGroup.messages.push({
          id: sentMessageId,
          message: sentMessage.message,
          attachments: sentMessage.attachments,
          timestamp: Date.now(),
          isIncoming: false,
          status: internalTicketing ? 'ACKNOWLEDGED' : 'PENDING',
        });
        ticketMessages[ticketMessages.length - 1] = lastMessageGroup;
      } else {
        ticketMessages.push(
          ticketChatConverter.toNewMessageGroup(user, sentMessage, sentMessageId, internalTicketing)
        );
      }
    } else {
      ticketMessages = [
        ticketChatConverter.toNewMessageGroup(user, sentMessage, sentMessageId, internalTicketing),
      ];
    }
    return ticketMessages;
  },
  toNewMessageGroup: (
    user: IMasterDataUser,
    message: ITicketConversationEdit,
    messageId: string,
    internalTicketing: boolean
  ): ITicketMessageGroup => {
    return {
      user: { id: user.id, name: user.firstName + user.lastName },
      userInitials: user.initials,
      isIncoming: false,
      messages: [
        {
          id: messageId,
          message: message.message,
          attachments: message.attachments,
          timestamp: Date.now(),
          isIncoming: false,
          status: internalTicketing ? 'ACKNOWLEDGED' : 'PENDING',
        },
      ],
    };
  },
  hasPendingMessage: (messageGroup: ITicketMessageGroup[]): boolean => {
    return !!messageGroup
      .findLast(group => !group.isIncoming)
      .messages.findLast(message => message.status === 'PENDING');
  },
};
