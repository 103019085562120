<rs-web-card role="button" [borderStyle]="'dashed'">
  <div class="upload-file">
    <rs-web-icon
      [icon]="ImmomioIconName.Plus"
      [color]="'var(--color-primary)'"
      [size]="12"
    ></rs-web-icon>
    <span class="ms-2 upload-file-label">{{ label() | translate }}</span>
  </div>
</rs-web-card>
