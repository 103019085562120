import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { CardWebComponent } from '../../../../atoms/card/card.component';
import { IconWebComponent } from '../../../../atoms/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { ImmomioIconName } from '@resident-nx/shared';
import { PdfAttachmentComponent } from '../../../pdf-attachment/pdf-attachment.component';
import { FileUploadValueAccessorDirective } from '../../../../../directives/form/file-upload-value-accessor.directive';
import { MAX_FILE_SIZE } from '../../../../../services/tickets/ticket-creation.service';

@Component({
  selector: 'rs-web-user-profile-edit-name',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CardWebComponent,
    IconWebComponent,
    TranslateModule,
    PdfAttachmentComponent,
    FileUploadValueAccessorDirective,
  ],
  templateUrl: './user-profile-edit-name.component.html',
  styleUrl: './user-profile-edit-name.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class UserProfileEditNameComponent {
  controlContainer = inject(ControlContainer);
  parentForm = this.controlContainer.control;
  protected readonly ImmomioIconName = ImmomioIconName;
  public maxFileSize = MAX_FILE_SIZE;

  public removeFile(): void {
    this.parentForm.patchValue({
      name: {
        verificationFile: null,
      },
    });
  }
}
