import { ChangeDetectionStrategy, Component, computed, inject, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MasterdataChangeType, masterDataSelectionTranslateKeys } from '@resident-nx/shared';
import { FormControlErrorsDirective } from '../../../../../directives/form/form-control-errors.directive';

@Component({
  selector: 'rs-web-user-profile-edit-phone',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, FormControlErrorsDirective],
  templateUrl: './user-profile-edit-phone.component.html',
  styleUrl: './user-profile-edit-phone.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class UserProfileEditPhoneComponent {
  formGroupName = input('phone');

  label = computed(() =>
    this.formGroupName() === 'mobile'
      ? masterDataSelectionTranslateKeys[MasterdataChangeType.MOBILE]
      : masterDataSelectionTranslateKeys[MasterdataChangeType.PHONE]
  );
}
