import { Directive, Injector, Input, OnInit } from '@angular/core';
import {
  ConsumptionUnitType,
  IConsumptionInfo,
  IConsumptionInfoItem,
  IConsumptionsByPeriod,
  ImmomioIconName,
  IMonth,
  IPeriod,
  ITheme,
  NameDisplayMode,
} from '../../models';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { IActionState } from '../../utils';
import { RouterService } from '../../services';
import * as fromConsumptionState from '../../+state/consumption';
import {
  getConsumptionActionStateForFirstMeter,
  getDashboardDataForContractDeviceAndPeriodForFirstMeter,
  getDashboardChartMonthsForFirstMeter,
  getTheme,
  IAppSettingsState,
} from '../../+state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  getChartMonthsForFirstMeter,
  getPeriodForChartForFirstMeter,
} from '../../+state/consumption';

@UntilDestroy()
@Directive()
export abstract class ConsumptionDashboardCardBaseComponent implements OnInit {
  @Input() metersByContract: IConsumptionInfo;
  public selectedMeter$: Observable<IConsumptionInfoItem>;
  public selectedPeriodConsumptionActionState$: Observable<IActionState>;
  public selectedPeriodForChart$: Observable<IPeriod>;
  public nameDisplayMode = NameDisplayMode;
  public immomioIconName = ImmomioIconName;
  public consumptionUnitType = ConsumptionUnitType;
  public selectedIndex = 0;
  public theme: ITheme;
  protected store: Store<fromConsumptionState.IConsumptionState>;
  private routerService: RouterService;

  public savedConsumptionData$: Observable<IConsumptionsByPeriod>;
  public chartMonths$: Observable<IMonth[]>;

  constructor(
    protected injector: Injector,
    protected appSettingsStore: Store<IAppSettingsState>
  ) {
    this.store = injector.get(Store<fromConsumptionState.IConsumptionState>);
    this.routerService = injector.get(RouterService);
  }

  ngOnInit() {
    this.appSettingsStore
      .select(getTheme)
      .pipe(untilDestroyed(this))
      .subscribe(res => (this.theme = res));

    this.chartMonths$ = this.store.select(getChartMonthsForFirstMeter);
    this.selectedPeriodForChart$ = this.store.select(getPeriodForChartForFirstMeter);
    this.selectedMeter$ = this.store.select(fromConsumptionState.getFirstSelectedMeter);
    this.selectedPeriodConsumptionActionState$ = this.store.select(
      getConsumptionActionStateForFirstMeter
    );

    this.savedConsumptionData$ = this.store.select(
      getDashboardDataForContractDeviceAndPeriodForFirstMeter
    );
  }

  public navigateToConsumption(): void {
    void this.routerService.navigate(['content', 'consumption']);
  }

  public previousMeter(meters: IConsumptionInfoItem[], index: number): void {
    if (this.selectedIndex === 0) {
      this.selectedIndex = meters.length - 1;
    } else {
      this.selectedIndex = index - 1;
    }
    this.store.dispatch(
      fromConsumptionState.SelectMeters({ meters: [meters[this.selectedIndex]] })
    );
  }

  public nextMeter(meters: IConsumptionInfoItem[], index: number): void {
    if (this.selectedIndex === meters.length - 1) {
      this.selectedIndex = 0;
    } else {
      this.selectedIndex = index + 1;
    }
    this.store.dispatch(
      fromConsumptionState.SelectMeters({ meters: [meters[this.selectedIndex]] })
    );
  }
}
