import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FaqWidgetBaseComponent } from '@resident-nx/shared';
import { IconWebComponent } from '../../atoms/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-faq-widget',
  templateUrl: './faq-widget.component.html',
  styleUrls: ['./faq-widget.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [IconWebComponent, TranslateModule],
})
export class FaqWidgetWebComponent extends FaqWidgetBaseComponent {}
