<ng-container formArrayName="detailQuestions">
  @for (question of questions(); track question.id; let index = $index) {
    <div class="mb-3">
      <div class="mb-1">
        {{ question.data.title }}
        @if (!question.data.required && question.data.type !== ScoreQuestionType.LABEL) {
          ({{ 'optional' | translate }})
        }
      </div>

      @if (question.answers.length > 0) {
        @for (answer of question.answers; track answer.id) {
          @switch (answer.data.type) {
            @case (ScoreQuestionType.INPUT_DATE) {
              <ng-container
                *ngTemplateOutlet="dateAnswer; context: { $implicit: index }"
              ></ng-container>
            }
            @case (ScoreQuestionType.SELECTION) {
              <ng-container
                *ngTemplateOutlet="selectionAnswer; context: { $implicit: answer, index: index }"
              ></ng-container>
            }
            @case (ScoreQuestionType.INPUT_ATTACHMENTS) {
              <ng-container
                *ngTemplateOutlet="attachmentAnswer; context: { $implicit: index }"
              ></ng-container>
            }
            @case (ScoreQuestionType.INPUT_TEXT) {
              <ng-container
                *ngTemplateOutlet="inputAnswer; context: { $implicit: answer, index: index }"
              ></ng-container>
            }
          }
        }
      } @else {
        @switch (question.data.type) {
          @case (ScoreQuestionType.INPUT_DATE) {
            <ng-container
              *ngTemplateOutlet="dateAnswer; context: { $implicit: index }"
            ></ng-container>
          }
          @case (ScoreQuestionType.SELECTION) {
            <ng-container
              *ngTemplateOutlet="selectionAnswer; context: { $implicit: question, index: index }"
            ></ng-container>
          }
          @case (ScoreQuestionType.INPUT_ATTACHMENTS) {
            <ng-container
              *ngTemplateOutlet="attachmentAnswer; context: { $implicit: index }"
            ></ng-container>
          }
          @case (ScoreQuestionType.INPUT_TEXT) {
            <ng-container
              *ngTemplateOutlet="inputAnswer; context: { $implicit: question, index: index }"
            ></ng-container>
          }
        }
      }
    </div>
  }

  <ng-template #dateAnswer let-index>
    <rs-web-date-picker [formControl]="formArray().at(index)"></rs-web-date-picker>
  </ng-template>

  <ng-template #selectionAnswer let-answer let-index="index">
    <label class="form-check form-check-label">
      <input
        [formControl]="formArray().at(index)"
        [value]="answer"
        class="form-check-input"
        name="answer"
        type="radio"
      />
      {{ answer.data.title }}
    </label>
  </ng-template>

  <ng-template #attachmentAnswer let-index>
    <input
      [accept]="'image/*'"
      [formControl]="formArray().at(index)"
      class="form-control"
      multiple
      type="file"
    />

    <div class="d-flex mt-3">
      @for (file of $any(formArray().at(index).value) | rsWebFileList; track file.lastModified) {
        <rs-web-image
          [defaultSrc]="file"
          [fileReader]="true"
          [borderRadius]="ImageBorderRadiusEnum.BIG"
        ></rs-web-image>
      }
    </div>
  </ng-template>

  <ng-template #inputAnswer let-answer let-index="index">
    @if (answer.data.multiline) {
      <textarea
        class="form-control"
        rows="3"
        [value]="answer"
        [formControl]="formArray().at(index)"
      ></textarea>
    } @else {
      <input
        type="text"
        class="form-control"
        [value]="answer"
        [formControl]="formArray().at(index)"
      />
    }
  </ng-template>
</ng-container>
