import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
  viewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';

import {
  AddressPipe,
  getPersonalData,
  getSendMasterDataChangeRequestResponse,
  IMasterdataChangeConfiguration,
  IMasterdataChangeRequest,
  IMasterdataNameChangeAttachment,
  IUserData,
  MasterdataChangeRequestType,
  MasterdataChangeType,
  SendMasterDataChangeRequest,
  snakeCaseToCamelCase,
} from '@resident-nx/shared';
import { SideSheetFormComponent } from '../../../organisms/side-sheet-form/side-sheet-form.component';
import { SideSheetContentDirective } from '../../../organisms/side-sheet-form/directives/side-sheet-content.directive';
import { UserProfileEditSelectionComponent } from './user-profile-edit-selection/user-profile-edit-selection.component';
import { UserProfileEditSuccessComponent } from './user-profile-edit-success/user-profile-edit-success.component';
import { UserProfileEditErrorComponent } from './user-profile-edit-error/user-profile-edit-error.component';
import { UserProfileEditNameComponent } from './user-profile-edit-name/user-profile-edit-name.component';
import { UserProfileEditDateOfBirthComponent } from './user-profile-edit-date-of-birth/user-profile-edit-date-of-birth.component';
import { UserProfileEditAddressComponent } from './user-profile-edit-address/user-profile-edit-address.component';
import { UserProfileEditPhoneComponent } from './user-profile-edit-phone/user-profile-edit-phone.component';
import { UserProfileEditEmailComponent } from './user-profile-edit-email/user-profile-edit-email.component';
import { UserProfileEditSummaryComponent } from './user-profile-edit-summary/user-profile-edit-summary.component';
import { UserProfileEditForm } from './types/user-profile-edit-form';
import { fileToBase64 } from '../../../../utils/file-helper';
import { maxFileSize } from '../../../../utils';
import { SideSheetService, TicketingGuardService } from '../../../../services';
import { MAX_FILE_SIZE } from '../../../../services/tickets/ticket-creation.service';
import { UserProfileEditByTicketComponent } from '../user-profile-edit-by-ticket/user-profile-edit-by-ticket.component';
import { PdfAttachmentComponent } from '../../pdf-attachment/pdf-attachment.component';
import { ROUTE } from '../../../../models';

@Component({
  selector: 'rs-web-user-profile-edit',
  standalone: true,
  imports: [
    CommonModule,
    SideSheetFormComponent,
    ReactiveFormsModule,
    TranslateModule,
    SideSheetContentDirective,
    UserProfileEditSelectionComponent,
    UserProfileEditSuccessComponent,
    UserProfileEditErrorComponent,
    UserProfileEditNameComponent,
    UserProfileEditDateOfBirthComponent,
    UserProfileEditAddressComponent,
    UserProfileEditPhoneComponent,
    UserProfileEditEmailComponent,
    UserProfileEditSummaryComponent,
    AddressPipe,
    PdfAttachmentComponent,
  ],
  templateUrl: './user-profile-edit.component.html',
  styleUrl: './user-profile-edit.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileEditComponent {
  protected readonly MasterDataChangeType = MasterdataChangeType;

  #fb = inject(FormBuilder);
  #router = inject(Router);
  #store = inject(Store);
  #sidesheetInstance = inject(NgbActiveOffcanvas);
  #sidesheetService = inject(SideSheetService);
  #ticketingGuard = inject(TicketingGuardService);

  public form = this.createForm();
  public sideSheetFormComponent = viewChild(SideSheetFormComponent);
  public submitted = signal(false);
  public response = this.#store.selectSignal(getSendMasterDataChangeRequestResponse);
  public showError = computed(() => this.submitted() && this.response() === false);
  public showSuccess = computed(() => this.submitted() && this.response() === true);
  public doneButtonText = computed(() => {
    if (this.showSuccess() || this.showError()) {
      return 'general.close';
    } else {
      return 'submit_l';
    }
  });

  constructor() {
    this.#store
      .select(getPersonalData)
      .pipe(take(1))
      .subscribe(personalData => this.prefillForms(personalData));
  }

  public goToConcerns(): void {
    this.#ticketingGuard.setPath(ROUTE.REQUESTS);
    void this.#router.navigate([ROUTE.TICKETS, ROUTE.REQUESTS]);
    this.#sidesheetInstance.dismiss();
  }

  public selectionFinished() {
    const { selection } = this.form.value;

    if (selection.requestType === MasterdataChangeRequestType.EXTERNAL) {
      this.disableUnselectedFormGroups(this.form.value.selection);
      this.sideSheetFormComponent().nextTab();
    } else {
      const { ticketCategoryId } = selection;
      this.#ticketingGuard.setPath(ROUTE.REQUESTS);
      void this.#router.navigate([ROUTE.TICKETS, ROUTE.REQUESTS]);
      this.#sidesheetInstance.dismiss();
      this.#sidesheetService.open(UserProfileEditByTicketComponent, {
        data: { ticketCategoryId, changeType: selection.changeType },
      });
    }
  }

  public lastStepDone(): void {
    if (this.showSuccess() || this.showError()) {
      this.closeSidesheet();
    } else {
      void this.submitMasterDataChange();
    }
  }

  public closeSidesheet(): void {
    this.#sidesheetInstance.dismiss();
  }

  private prefillForms(data: IUserData): void {
    this.form.patchValue({
      name: {
        name: data.firstName,
        lastName: data.name,
      },
      dateOfBirth: data.birthDate,
      email: data.contactEmail,
      address: data.address,
    });
  }

  /**
   * on radio btn selection and type === EXTERNAL, we disable all formGroups and re-enable
   * the formGroup that belongs to the selection. As this.form.value only
   * returns values of enabled forms, we will only have the selected
   * and changed data present at the end.
   * We can then also use the valid/invalid props of the main form group,
   * as only the edited form remains enabled and counting for validation.
   */
  private disableUnselectedFormGroups(selection: IMasterdataChangeConfiguration) {
    for (const controlName of Object.keys(this.form.controls)) {
      if (controlName === 'selection') {
        continue;
      }
      this.form.get(controlName).disable();
    }

    // UserProfileEditSelection and form control names are matching
    const controlNameToEnable = snakeCaseToCamelCase(selection.changeType);
    this.form.get(controlNameToEnable).enable();
  }

  private async submitMasterDataChange(): Promise<void> {
    const payload = await this.createPayload(this.form.value);
    this.submitted.set(true);
    this.#store.dispatch(SendMasterDataChangeRequest({ payload }));
  }

  private async createPayload(
    formValues: UserProfileEditForm['value']
  ): Promise<IMasterdataChangeRequest> {
    const type = formValues.selection.changeType;
    switch (type) {
      case MasterdataChangeType.NAME: {
        const { name, lastName, verificationFile } = formValues.name;
        const file = verificationFile[0];

        const attachments: IMasterdataNameChangeAttachment[] = [
          {
            name: file.name,
            extension: 'pdf',
            content: await fileToBase64(file),
          },
        ];
        return {
          type,
          name: {
            name,
            lastName,
            attachments,
          },
        };
      }
      case MasterdataChangeType.ADDRESS: {
        const { city, zipCode, street, houseNumber } = formValues.address;
        return {
          type,
          address: {
            city,
            zipCode,
            street,
            houseNumber,
          },
        };
      }
      case MasterdataChangeType.EMAIL: {
        const { email } = formValues;
        return {
          type,
          email: {
            email,
          },
        };
      }
      case MasterdataChangeType.DATE_OF_BIRTH: {
        const { dateOfBirth } = formValues;
        return {
          type,
          dateOfBirth: {
            dateOfBirth,
          },
        };
      }
      case MasterdataChangeType.PHONE: {
        const { countryCode, prefix, phoneNumber } = formValues.phone;
        return {
          type,
          phone: {
            countryCode,
            prefix,
            phoneNumber,
          },
        };
      }
      case MasterdataChangeType.MOBILE: {
        const { countryCode, prefix, phoneNumber } = formValues.phone;
        return {
          type,
          mobile: {
            countryCode,
            prefix,
            phoneNumber,
          },
        };
      }
    }
  }

  private createForm(): UserProfileEditForm {
    const createPhoneGroup = () =>
      this.#fb.group({
        countryCode: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(3)]],
        prefix: this.#fb.control<number>(null, Validators.required),
        phoneNumber: this.#fb.control<number>(null, Validators.required),
      });

    return this.#fb.group({
      selection: this.#fb.control<IMasterdataChangeConfiguration>(null, Validators.required),
      dateOfBirth: ['', Validators.required],
      name: this.#fb.group({
        name: ['', Validators.required],
        lastName: ['', Validators.required],
        verificationFile: this.#fb.control<FileList>(null, [
          Validators.required,
          maxFileSize(MAX_FILE_SIZE),
        ]),
      }),
      address: this.#fb.group({
        street: ['', Validators.required],
        houseNumber: ['', Validators.required],
        zipCode: ['', Validators.required],
        city: ['', Validators.required],
      }),
      phone: createPhoneGroup(),
      mobile: createPhoneGroup(),
      email: ['', [Validators.required, Validators.email]],
    });
  }
}
