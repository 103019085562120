import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import * as fromMasterDataState from '../../+state/account/masterdata';
import { IEnvironment } from '../../models';
import { getSelectedCustomerIdent } from '../../+state';
import { bAndOConfig } from './bAndODomainConfig';

@Injectable()
export class CustomerService {
  public customerIdent: string;
  private customerIdentsForBandODamageFlow: string[] = [
    'be32bb20-fd85-47af-8d1f-82785160d779', // LEG SBX
    '6b557caf-1766-4271-9489-d71873eac482', //VHW SBX
  ];
  private customerIdentsWithoutTicketManagement: string[] = [
    'e85c71a3-fc2d-4c7d-93ea-ede2922474c6', //GEBAUSIE STG
    'c73a5486-b9e5-3f28-31fa-a8ade4a2e061', // GEBAUSIE PROD
  ];
  private customerIdentsWithTicketImageEnabled: string[] = [
    '8c738452-348d-51a9-3f45-68981e4d8f6f', //GWH SBX
    '50712445-ab7b-8c4b-9e9f-59458bb0087c', //GWH PROD
    '9d5663a8-8506-4e25-8ef1-642304234c0d', //HANSA SBX
    '103cd729-3f3a-4dc7-a362-38eb158d3d5b', //HANSA PROD
    '6b557caf-1766-4271-9489-d71873eac482', //VHW SBX
    'c05d8e95-6891-401a-93da-bfd8d7cf28c4', //VHW PROD
  ];

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private store: Store
  ) {
    this.store.select(getSelectedCustomerIdent).subscribe(response => {
      this.customerIdent = response;
    });
  }

  public showBandODamageFlow() {
    return this.customerIdentsForBandODamageFlow.some(
      customerIdent => customerIdent === this.customerIdent
    );
  }

  public getBandODomain(isMobile?: boolean): string {
    return isMobile
      ? bAndOConfig[this.customerIdent]?.mobile
      : bAndOConfig[this.customerIdent]?.web;
  }

  public hideTicketManagement(): boolean {
    return this.customerIdentsWithoutTicketManagement.some(
      customerIdent => customerIdent === this.customerIdent
    );
  }

  public showTicketImage(): boolean {
    return this.customerIdentsWithTicketImageEnabled.some(
      customerIdent => customerIdent === this.customerIdent
    );
  }

  public appPreviewMode(): Observable<boolean> {
    return this.store.select(fromMasterDataState.getContracts).pipe(
      map(contracts => {
        // the user has no contracts or neither contract has started
        return !contracts || contracts.length === 0 || !contracts.some(c => c.contractStartTime);
      })
    );
  }
}
