<rs-web-card [borderStyle]="isFileTooBig() ? 'error' : 'none'" [elevation]="Elevation.TWO">
  <div class="d-flex flex-row gap-2 align-items-center">
    <div class="pdf-icon-container">
      <rs-web-icon [icon]="ImmomioIconName.Pdf" [size]="36"></rs-web-icon>
    </div>
    <div class="flex-fill pdf-text-container">
      <div class="d-flex flex-column gap-1">
        <div class="pdf-file-name">{{ name() }}</div>
        @if (file()?.size) {
          <div class="pdf-file-size">{{ file().size | fileSize }}</div>
        }
      </div>
    </div>
    @if (isRemovable()) {
      <div (click)="remove.emit()" role="button">
        <rs-web-icon [icon]="ImmomioIconName.Trash" [size]="16"></rs-web-icon>
      </div>
    }
  </div>
</rs-web-card>
@if (isFileTooBig()) {
  <div class="error-message">
    <rs-web-icon [size]="12" [icon]="ImmomioIconName.Info"></rs-web-icon>
    {{ 'upload_max_file_size_l' | translate }}
  </div>
}
