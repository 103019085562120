<div class="side-sheet-form">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title">
      <ng-content select="[sidesheet-title]"></ng-content>
    </h5>
    <button
      type="button"
      class="btn-close text-reset"
      aria-label="Close"
      (click)="onDismiss()"
    ></button>
  </div>
  <nav #nav="ngbNav" [ngClass]="{ 'nav-hidden': !showNav() }" class="offcanvas-nav nav-tabs" ngbNav>
    @for (content of contents; track content.label; let index = $index) {
      <ng-container
        [ngbNavItem]="index"
        [destroyOnHide]="true"
        [disabled]="content.disabled || (index > 0 && !contents.get(index - 1)?.currentStepValid())"
      >
        <button ngbNavLink [class.active]="index <= nav.activeId">
          {{ content.label | translate }}
        </button>
        <ng-template ngbNavContent>
          <ng-container [ngTemplateOutlet]="content.template"></ng-container>
        </ng-template>
      </ng-container>
    }
  </nav>
  <div class="offcanvas-body" [ngbNavOutlet]="nav"></div>

  <div class="offcanvas-footer">
    @if (showBackButton && nav.activeId !== 0) {
      <rs-web-button [type]="ButtonTypeEnum.LIGHT_BORDERED" (tapped)="nav.select(nav.activeId - 1)"
        >{{ 'general.back_a' | translate }}
      </rs-web-button>
    }

    @if (nav.activeId !== contents?.length - 1) {
      <rs-web-button
        class="ms-auto"
        [disabled]="!contents.get(nav.activeId)?.currentStepValid()"
        [loading]="loading"
        (tapped)="onNext()"
      >
        {{ 'next' | translate }}
      </rs-web-button>
    } @else {
      <rs-web-button
        class="ms-auto"
        [disabled]="!contents.get(nav.activeId)?.currentStepValid()"
        (tapped)="onDone()"
        [loading]="loading"
      >
        {{ contents.get(nav.activeId).customDoneButtonText || doneButtonText | translate }}
      </rs-web-button>
    }
  </div>
</div>
