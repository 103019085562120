<div formGroupName="address" class="d-flex flex-column">
  <p class="fw-bold">
    {{ 'user_edit_overview_l' | translate }}: {{ 'user_address_edit.form_l' | translate }}
  </p>

  <div class="mb-3 d-flex flex-row gap-3">
    <div class="flex-grow-1">
      <label for="street" class="form-label">{{ 'user_address_edit_street_l' | translate }}</label>
      <input type="text" class="form-control" id="street" formControlName="street" />
    </div>

    <div>
      <label for="houseNumber" class="form-label">
        {{ 'user_address_edit_house_number_l' | translate }}
      </label>
      <input type="text" class="form-control" id="houseNumber" formControlName="houseNumber" />
    </div>
  </div>

  <div class="mb-3 d-flex flex-row gap-3">
    <div>
      <label for="zipCode" class="form-label">
        {{ 'user_address_edit_postcode_l' | translate }}
      </label>
      <input type="text" class="form-control" id="zipCode" formControlName="zipCode" />
    </div>

    <div class="flex-grow-1">
      <label for="city" class="form-label">{{ 'user_address_edit_city_l' | translate }}</label>
      <input type="text" class="form-control" id="city" formControlName="city" />
    </div>
  </div>
</div>
