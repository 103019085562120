<div formGroupName="name">
  <p class="fw-bold">
    {{ 'user_edit_overview_l' | translate }}: {{ 'user_name_edit.form_l' | translate }}
  </p>

  <div class="mb-3">
    <label for="name" class="form-label">{{ 'user_name_edit_firstname_l' | translate }}</label>
    <input type="text" class="form-control" id="name" formControlName="name" />
  </div>

  <div class="mb-3">
    <label for="lastName" class="form-label">{{ 'user_name_edit_lastname_l' | translate }}</label>
    <input type="text" class="form-control" id="lastName" formControlName="lastName" />
  </div>

  <label for="verification" class="form-label">{{ 'verification_l' | translate }}</label>
  <rs-web-card role="button" [borderStyle]="'dashed'" (clickEvent)="fileInput.click()">
    <div class="upload-file">
      <rs-web-icon
        [icon]="ImmomioIconName.Plus"
        [color]="'var(--color-primary)'"
        [size]="12"
      ></rs-web-icon>
      <span class="ms-2 upload-file-label">{{ 'user_name_edit_attactment_l' | translate }}</span>
    </div>
  </rs-web-card>

  <input
    id="verification"
    [accept]="'.pdf'"
    #fileInput
    formControlName="verificationFile"
    class="upload-file-hidden"
    type="file"
  />

  @if (fileInput.files[0]) {
    <div class="d-flex flex-column mt-4">
      <rs-web-pdf-attachment
        [file]="fileInput.files[0]"
        [maxFileSize]="maxFileSize"
        (remove)="removeFile()"
      ></rs-web-pdf-attachment>
    </div>
  }
</div>
