import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  getCategoryDetailQuestions,
  getSendMasterDataChangeTicketResponse,
  IHierarchicalQuestionTicketOverview,
  IMasterdataChangeTicket,
  LoadCategoryDetailQuestions,
  MasterdataChangeType,
  masterDataSelectionTranslateKeys,
  ScoreQuestionType,
  SendMasterDataChangeTicket,
} from '@resident-nx/shared';
import { FormBuilder, ReactiveFormsModule, ValidatorFn, Validators } from '@angular/forms';
import { SideSheetContentDirective } from '../../../organisms/side-sheet-form/directives/side-sheet-content.directive';
import { SideSheetFormComponent } from '../../../organisms/side-sheet-form/side-sheet-form.component';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerComponent } from '../../../atoms/date-picker/date-picker.component';
import { FileListPipe } from '../../../../pipes/file-list.pipe';
import { FileUploadValueAccessorDirective } from '../../../../directives/form/file-upload-value-accessor.directive';
import { ImageWebComponent } from '../../../atoms/image/image.component';
import { Store } from '@ngrx/store';
import { ImageBorderRadiusEnum } from '../../../atoms/image/image.model';
import { TicketDetailQuestionsComponent } from '../../../organisms/ticketing/ticket-creation/ticket-detail-questions/ticket-detail-questions.component';
import { delay, tap } from 'rxjs';
import { maxFileSize } from '../../../../utils';
import { MAX_FILE_SIZE } from '../../../../services/tickets/ticket-creation.service';
import { TicketHelperService } from '../../../../services/tickets/ticket-helper.service';
import { UserProfileEditSummaryComponent } from '../user-profile-edit/user-profile-edit-summary/user-profile-edit-summary.component';
import { UserProfileEditSuccessComponent } from '../user-profile-edit/user-profile-edit-success/user-profile-edit-success.component';
import { UserProfileEditErrorComponent } from '../user-profile-edit/user-profile-edit-error/user-profile-edit-error.component';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { PdfAttachmentComponent } from '../../pdf-attachment/pdf-attachment.component';

@Component({
  selector: 'rs-web-user-profile-edit-by-ticket',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SideSheetContentDirective,
    SideSheetFormComponent,
    TranslateModule,
    DatePickerComponent,
    FileListPipe,
    FileUploadValueAccessorDirective,
    ImageWebComponent,
    TicketDetailQuestionsComponent,
    UserProfileEditSummaryComponent,
    UserProfileEditSuccessComponent,
    UserProfileEditErrorComponent,
    PdfAttachmentComponent,
  ],
  templateUrl: './user-profile-edit-by-ticket.component.html',
  styleUrl: './user-profile-edit-by-ticket.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileEditByTicketComponent implements OnInit {
  #store = inject(Store);
  #fb = inject(FormBuilder);
  #ticketHelperService = inject(TicketHelperService);
  #sidesheetInstance = inject(NgbActiveOffcanvas);
  protected readonly ScoreQuestionType = ScoreQuestionType;
  protected readonly ImageBorderRadiusEnum = ImageBorderRadiusEnum;
  protected readonly selectionTranslateKeys = masterDataSelectionTranslateKeys;
  public ticketCategoryId: string; // injected by SidesheetService.open()
  public changeType: MasterdataChangeType; // injected by SidesheetService.open()
  public form = this.#fb.group({
    detailQuestions: this.#fb.array([]),
  });
  public submitted = signal(false);
  public response = this.#store.selectSignal(getSendMasterDataChangeTicketResponse);
  public showError = computed(() => this.submitted() && this.response() === false);
  public showSuccess = computed(() => this.submitted() && !!this.response());
  public summary = signal([]);
  public rootQuestion = this.#store.selectSignal(getCategoryDetailQuestions);
  public sidesheetFormComponent = viewChild(SideSheetFormComponent);

  public doneButtonText = computed(() => {
    if (this.showSuccess()) {
      return 'general.close';
    } else if (this.showError()) {
      return 'back_to_overview_l';
    } else {
      return 'submit_l';
    }
  });

  public rootQuestion$ = this.#store.select(getCategoryDetailQuestions).pipe(
    delay(10), // prevent expression changed after checked error
    tap(rootQuestion => {
      if (!rootQuestion) {
        return;
      }

      this.createDetailQuestionsFormArray(rootQuestion.questions);
    })
  );

  get detailQuestionsFormArray() {
    return this.form.controls.detailQuestions;
  }

  ngOnInit(): void {
    const { ticketCategoryId } = this;
    if (ticketCategoryId) {
      this.#store.dispatch(LoadCategoryDetailQuestions({ ticketCategoryId }));
    }
  }

  public async questionStepDone(): Promise<void> {
    this.summary.set(this.createSummary());
    this.sidesheetFormComponent().nextTab();
  }

  public lastStepDone(): void {
    if (this.showSuccess()) {
      this.closeSidesheet();
    } else if (this.showError()) {
      this.submitted.set(false);
    } else {
      void this.createTicket();
    }
  }

  public closeSidesheet(): void {
    this.#sidesheetInstance.dismiss();
  }

  private async createTicket(): Promise<void> {
    const payload = await this.createPayloadForTicketCreationRequest();
    this.submitted.set(true);
    this.#store.dispatch(SendMasterDataChangeTicket({ payload }));
  }

  private createDetailQuestionsFormArray(questions: IHierarchicalQuestionTicketOverview[]): void {
    if (!questions) {
      return;
    }

    this.detailQuestionsFormArray.clear();

    for (const question of questions) {
      let validators: ValidatorFn[] | null = null;
      if (question.data.required && question.data.type !== ScoreQuestionType.LABEL) {
        validators = [Validators.required];

        if (question.data.type === ScoreQuestionType.INPUT_ATTACHMENTS) {
          validators.push(maxFileSize(MAX_FILE_SIZE));
        }
      }

      this.detailQuestionsFormArray.push(this.#fb.control(null, validators));
    }
  }

  private createSummary() {
    return this.rootQuestion().questions.map((question, index) => {
      return {
        key: question.data.title,
        type: question.data.type,
        value: this.detailQuestionsFormArray.at(index).value,
      };
    });
  }

  private async createPayloadForTicketCreationRequest(): Promise<IMasterdataChangeTicket> {
    const { ticketCategoryId } = this;
    const formResponses = await this.#ticketHelperService.createFormResponseCreates(
      this.rootQuestion().questions,
      this.detailQuestionsFormArray
    );
    return {
      formResponses,
      ticketCategoryId,
    };
  }
}
