export type CardBorderStyleEnum =
  | 'none'
  | 'normal'
  | 'dashed'
  | 'active'
  | 'state-100'
  | 'alert'
  | 'error';

export enum CardBorderRadiusEnum {
  NONE = 'none',
  SMALL = 'small',
  BIG = 'big',
}
