import tinycolor from 'tinycolor2';
import chroma from 'chroma-js';
import { HexColorCode, UNSET } from '../models';

export const normalizeColor = (color: string | null): HexColorCode => {
  if (color === null || !color) {
    return UNSET;
  }
  return tinycolor(color).toHexString();
};

export const sensitiveHoverColor = (color: string, amount: number): HexColorCode => {
  return isLightEnoughToShade(color) ? shadeColor(color, amount) : lightenColor(color, amount);
};

export const isLightEnoughToShade = (color: HexColorCode): boolean => {
  return tinycolor(color).getLuminance() > 0.049;
};

export const shadeColor = (color: string, amount: number): HexColorCode => {
  return tinycolor.mix(color, '#000', amount).toHexString();
};

export const lightenColor = (color: string, amount: number): HexColorCode => {
  return tinycolor.mix(color, '#fff', amount).toHexString();
};

export const setAlpha = (color: string, alpha: number): HexColorCode => {
  if (color === UNSET) {
    return UNSET;
  }
  return tinycolor(color).setAlpha(alpha).toString();
};

export const shadeAndSaturateColor = (color: string, shadeAmount, saturateAmount) => {
  // need to be seperated steps. Chaining lead to a slightly different output
  const shadedColor = tinycolor.mix(color, '#000', shadeAmount).toHexString();
  return tinycolor(shadedColor).saturate(saturateAmount).toHexString();
};

export const getButtonTextColor = (
  defaultTextColor: string,
  backgroundColor: string,
  alternativeColor: string
): HexColorCode => {
  const tDefaultTextColor = tinycolor(defaultTextColor).toHexString();
  const tBackgroundColor = tinycolor(backgroundColor).toHexString();
  const tAlternativeColor = tinycolor(alternativeColor).toHexString();

  // https://thebespokepixel.github.io/es-tinycolor/#isreadable
  return tinycolor.isReadable(tBackgroundColor, tDefaultTextColor, { level: 'AA', size: 'large' })
    ? tDefaultTextColor
    : tAlternativeColor;
};

export const getColorsVariations = (
  primaryColor: string | HexColorCode,
  secondaryColor: string | HexColorCode,
  amount
): HexColorCode[] => {
  if (!amount) return [];
  const colors: HexColorCode[] = [primaryColor, secondaryColor];

  if (amount === 1) return colors.slice(0, 1);
  if (getColorSimilarityIndex(primaryColor, secondaryColor) >= 0.85) {
    return generateFullPalette(primaryColor, amount);
  }

  const primaryColors = generateColorsBasedOnPercentage(primaryColor, amount).reverse();
  const secondaryColors = generateColorsBasedOnPercentage(secondaryColor, amount).reverse();

  let toggle = true;
  while (colors.length < amount) {
    if (toggle && primaryColors.length) {
      colors.push(primaryColors.pop());
    } else if (!toggle && secondaryColors.length) {
      colors.push(secondaryColors.pop());
    }
    toggle = !toggle;
  }

  return colors;
};

export const generateFullPalette = (
  primaryColor: string | HexColorCode,
  amount
): HexColorCode[] => {
  const lightened = generateLightenedColors(primaryColor, amount);
  const darkened = generateDarkenedColors(primaryColor, amount);

  // Merge the colors with the primary color in the middle
  return [...lightened.reverse(), primaryColor, ...darkened].slice(1, -1);
};

const brightnessToPercentage = (color: string): number => {
  const brightness = Math.round(tinycolor(color).getBrightness()); // range: 0 - 255
  return Math.round((100 / 255) * brightness);
};

const generateDarkenedColors = (color: string, amount: number): HexColorCode[] => {
  const valueInPercentage = brightnessToPercentage(color);
  const stepSize = Math.round(100 / amount);

  const darkenColors = [];
  let maxBrightness = 0;

  while (maxBrightness < valueInPercentage) {
    maxBrightness += stepSize;
    darkenColors.push(tinycolor(color).darken(maxBrightness).toString());
  }

  return darkenColors;
};

const generateLightenedColors = (color: string, amount: number): HexColorCode[] => {
  const valueInPercentage = brightnessToPercentage(color);
  const stepSize = Math.round(100 / amount);

  const lightenColors = [];
  let maxBrightness = 0;

  while (maxBrightness < 100 - valueInPercentage) {
    maxBrightness += stepSize;
    lightenColors.push(tinycolor(color).brighten(maxBrightness).toString());
  }

  return lightenColors;
};

export const generateColorsBasedOnPercentage = (color: string, amount: number): HexColorCode[] => {
  return brightnessToPercentage(color) <= 50
    ? generateLightenedColors(color, amount)
    : generateDarkenedColors(color, amount);
};

const getColorSimilarityIndex = (
  color1: string,
  color2: string,
  weights = { hsl: 0.25, hsv: 0.25, lab: 0.35, lch: 0.15 }
): number => {
  const hsl1 = tinycolor(color1).toHsl();
  const hsl2 = tinycolor(color2).toHsl();
  const hsv1 = tinycolor(color1).toHsv();
  const hsv2 = tinycolor(color2).toHsv();

  const lab1 = chroma(color1).lab();
  const lab2 = chroma(color2).lab();
  const lch1 = chroma(color1).lch();
  const lch2 = chroma(color2).lch();

  const hueDiff = Math.min(Math.abs(hsl1.h - hsl2.h), 360 - Math.abs(hsl1.h - hsl2.h)) / 360;
  const saturationDiff = Math.abs(hsl1.s - hsl2.s);
  const brightnessDiff = Math.abs(hsl1.l - hsl2.l);
  const valueDiff = Math.abs(hsv1.v - hsv2.v);

  // LAB Euclidean Distance
  const labDistance = Math.sqrt(
    Math.pow(lab1[0] - lab2[0], 2) + Math.pow(lab1[1] - lab2[1], 2) + Math.pow(lab1[2] - lab2[2], 2)
  );

  // LCH Euclidean Distance
  const lchDistance = Math.sqrt(
    Math.pow(lch1[0] - lch2[0], 2) +
      Math.pow(lch1[1] - lch2[1], 2) +
      Math.pow(lch1[2] || 0 - lch2[2] || 0, 2)
  );
  // Combine similarities using the provided weights
  const hslSimilarity = 1 - weights.hsl * (hueDiff + saturationDiff + brightnessDiff);
  const hsvSimilarity = 1 - weights.hsv * (hueDiff + saturationDiff + valueDiff);
  const labSimilarity = 1 - weights.lab * (labDistance / 100); // Normalize LAB distance (0-100)
  const lchSimilarity = 1 - weights.lch * (lchDistance / 100); // Normalize LCH distance (0-100)
  // Final similarity score: Average of all models
  const finalSimilarity = (hslSimilarity + hsvSimilarity + labSimilarity + lchSimilarity) / 4;

  return finalSimilarity; // Closer to 1 means more similar
};
