import { gql } from 'apollo-angular';
import { customerSpecificDataFields } from '../common';

export const ticketConversationFields = `
  edges {
    node {
      created {
        editor {
          id
          name
        }
      date
      }
      message
      id
      status
      retryMessageId
      retriedOn
      attachments {
        title
        name
        type
        documentType
        documentFileType
        identifier
        extension
        url
        residentFileType
      }
      ${customerSpecificDataFields}
    }
  }
`;

export const ticketChatQuery = gql`
  query getTicketConversationV2($ticketId: ID!, $limit: Int, $offset: Int) {
    getTicketConversationV2 (ticketId: $ticketId, limit: $limit, offset: $offset) {
      ${ticketConversationFields}
    }
  }
`;

export const addMessageToTicketMutation = gql`
  mutation addMessageToTicketConversationV2($input: TicketConversationEditBeanV2!) {
    addMessageToTicketConversationV2(input: $input) {
      createdMessageId
    }
  }
`;
