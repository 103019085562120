import { Directive, ElementRef, HostListener, inject, input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type=file]',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FileUploadValueAccessorDirective, multi: true },
  ],
  standalone: true,
})
export class FileUploadValueAccessorDirective implements ControlValueAccessor {
  inputField: ElementRef<HTMLInputElement> = inject(ElementRef<HTMLInputElement>);
  value: unknown;
  files: FileList | [] = [];
  onChange: (value: FileList) => void = () => null;
  onTouched: () => void = () => null;

  /**
   * additive = if you add new files, it gets added to the existing files
   *
   * if you change the value of a <input type="file"> element,
   * the previous value gets replaced by default. This is not optimal in a
   * UX scenario where a user wants to add multiple files one after
   * another, e.g. from different folders
   */
  additive = input(false);

  @HostListener('change', ['$event.target.files'])
  emitFiles(files: FileList) {
    // additive describes adding multiple files one after another
    if (this.additive()) {
      const newFiles = this.addFilesToCurrentFileList(files);
      // set new created files for the input element
      this.inputField.nativeElement.files = newFiles;
      // cache current files
      this.files = newFiles;
      this.onChange(newFiles.length ? newFiles : null);
    } else {
      this.onChange(files.length ? files : null);
    }
  }

  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }

  writeValue(value: FileList) {
    if (value) {
      this.inputField.nativeElement.files = value;
      this.files = value;
    }
    if (value === null) {
      this.inputField.nativeElement.files = new DataTransfer().files;
    }
  }
  registerOnChange(fn: (value: FileList) => void) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  addFilesToCurrentFileList(files: FileList): FileList {
    // add new files to existing files by transforming FileLists to Arrays
    const newFiles = [...this.files, ...files];
    // create a DataTransfer object, which is allowed to set as input.files
    const dataTransfer = new DataTransfer();
    // add all files to DataTransfer object
    for (const file of newFiles) {
      dataTransfer.items.add(file);
    }
    return dataTransfer.files;
  }
}
