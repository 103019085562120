import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as querystring from 'querystring';
import { lastValueFrom, map } from 'rxjs';
import {
  AuthService,
  IAuthToken,
  AuthTokenService,
  RouterService,
  IFederatedLoginEnvironmentVariables,
  StorageService,
} from '@resident-nx/shared';

@Injectable({
  providedIn: 'root',
})
export class AuthWebService extends AuthService {
  constructor(
    private authTokenService: AuthTokenService,
    private http: HttpClient,
    private router: RouterService,
    private storageService: StorageService
  ) {
    super();
  }

  // todo refactor this ****
  public login(): Promise<IAuthToken> {
    return undefined;
  }

  public logout(redirection?: string): void {
    switch (redirection) {
      case 'unauthorized':
        this.router.navigate(['unauthorized-user']);
        break;
      default:
        this.router.navigate(['logout']);
    }
  }

  // TODO add token refresh call, update IAuthToken in storage
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public refresh(): Promise<IAuthToken> {
    const authToken = {} as IAuthToken;
    return Promise.resolve(authToken);
  }

  public loginViaApiCall(email: string, password: string): Promise<IAuthToken> {
    const tokenUrl = ((this.storageService.getItem(
      IFederatedLoginEnvironmentVariables.BASE_URL_AUTH
    ) as string) +
      this.storageService.getItem(IFederatedLoginEnvironmentVariables.TOKEN_ENDPOINT)) as string;
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
    };
    const body = querystring.stringify({
      client_id: this.storageService.getItem(
        IFederatedLoginEnvironmentVariables.CLIENT_ID
      ) as string,
      grant_type: 'password',
      username: email,
      password: password,
    });
    return lastValueFrom(
      this.http.post(tokenUrl, body, { headers }).pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((response: any) => {
          if (!response) {
            console.error('Failed logging.');
          } else {
            this.authTokenService.setToken({ accessToken: response.access_token } as IAuthToken);
          }
          return response as IAuthToken;
        })
      )
    );
  }

  public guestLogin(): boolean {
    this.guestMode = true;
    return this.guestMode;
  }

  public isGuestMode(): boolean {
    return this.guestMode;
  }

  public getToken(): IAuthToken {
    return this.authTokenService.getToken();
  }
}
