import { InjectionToken } from '@angular/core';

const DEFAULT_ERROR_MESSAGES: Record<string, string | Record<string, string>> = {
  required: 'forms.required_l',
  email: 'forms.invalid_email_l',
  fileMaxSize: 'forms.max_file_exceeded_l',
  ngbDate: {
    invalid: 'forms.invalid_date_l',
  },
  sameValueRequired: 'forms.same_value_required_l',
  invalidName: 'forms.invalid_name_l',
  invalidPhoneNumber: 'forms.invalid_phone_number_l',
  invalidPhonePrefix: 'forms.invalid_phone_prefix_l',
  invalidZipcode: 'forms.invalid_zipcode_l',
  minlength: 'forms.minlength_l',
  maxlength: 'forms.maxlength_l',
};

export const FORM_ERRORS = new InjectionToken('FORM_ERRORS', {
  providedIn: 'root',
  factory: () => DEFAULT_ERROR_MESSAGES,
});
