<div class="cards-container" [id]="'meter-' + meter.deviceId">
  <div rsWebElevation [elevation]="2" class="chart-container">
    <!-- Datepicker -->
    <div class="date-picker-container">
      <rs-web-consumption-date-picker
        [formControl]="selectedDateControl"
        [selectedTimeframe]="selectedTimeframe$ | async"
        [isMonthAvailable]="meter.defaultAggregation === ConsumptionAggregateType.DAY"
        [isNextDisabled]="(nextPeriodAvailable$ | async) === false"
        [isPreviousDisabled]="(previousPeriodAvailable$ | async) === false"
        (timeframeChange)="onTimeframeSelect($event)"
      ></rs-web-consumption-date-picker>
    </div>
    <!-- Chart -->

    <div class="chart">
      <rs-web-consumption-chart
        [showAdditionalInfos]="true"
        [height]="'220px'"
        [timeframe]="selectedTimeframe$ | async"
        [meter]="meter"
      ></rs-web-consumption-chart>

      @if ((selectedTimeframe$ | async) === ConsumptionTimeframe.YEAR) {
        <div class="d-flex flex-row legend-container">
          <div class="d-flex flex-row gap-1 align-items-center">
            <div class="legend current"></div>
            <span>{{ 'consumption.real_value' | translate }}</span>
          </div>
          <div class="d-flex flex-row gap-1 align-items-center">
            <div class="legend estimation"></div>
            <div>
              <span class="color-primary">*</span>
              <span>{{ 'consumption.estimated_value' | translate }}</span>
            </div>
          </div>
          <div class="d-flex flex-row gap-1 align-items-center">
            <div class="legend past"></div>
            <span>{{ yearBeforeSelectedDate$ | async }}</span>
          </div>
        </div>
      }
    </div>
  </div>

  <!-- Comparison -->
  <div rsWebElevation [elevation]="2" class="benchmark-container d-flex flex-column">
    <rs-web-consumption-comparison
      [meter]="meter"
      [timeframe]="selectedTimeframe$ | async"
      [showLegend]="(selectedTimeframe$ | async) !== ConsumptionTimeframe.YEAR"
    ></rs-web-consumption-comparison>
  </div>
</div>
