<div class="d-inline-block" ngbDropdown>
  <rs-web-icon [cursor]="'pointer'" [icon]="ImmomioIconName.Sorting" [size]="16" ngbDropdownToggle>
  </rs-web-icon>
  <div aria-labelledby="dropdownBasic1" ngbDropdownMenu>
    <button class="sortHeader primary-text" ngbDropdownItem>{{ 'sorting_l' | translate }}</button>
    @for (label of labels; track label.key) {
      <div class="d-flex">
        <button class="d-flex primary-text" ngbDropdownItem (click)="selectSorting(label)">
          {{ label.key | translate }}
          @if (selectedSorting$ | async; as selectedSorting) {
            @if (
              selectedSorting.value === label.value && selectedSorting.direction === label.direction
            ) {
              <rs-web-icon
                class="sortChecked"
                [icon]="ImmomioIconName.Check"
                [size]="20"
                [otherClasses]="['primary-text']"
              ></rs-web-icon>
            }
          }
        </button>
      </div>
    }
  </div>
</div>
