import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnInit, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbActiveOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import {
  contractStatusConverter,
  IActionState,
  IContactPersonResponse,
  TicketIssueType,
} from '@resident-nx/shared';
import { filter, map, tap } from 'rxjs';
import { TicketCreationService } from '../../../../services';
import { TicketDirectAssignmentComponent } from '../ticket-direct-assignment/ticket-direct-assignment.component';
import { SelectContractWebComponent } from './select-contract/select-contract.component';
import { TicketCategoriesComponent } from './ticket-categories/ticket-categories.component';
import { TicketDetailQuestionsComponent } from './ticket-detail-questions/ticket-detail-questions.component';
import { TicketSummaryComponent } from './ticket-summary/ticket-summary.component';
import { TicketsNoCatalogueComponent } from './tickets-no-catalogue/tickets-no-catalogue.component';
import { SideSheetFormComponent } from '../../side-sheet-form/side-sheet-form.component';
import { SideSheetContentDirective } from '../../side-sheet-form/directives/side-sheet-content.directive';

@UntilDestroy()
@Component({
  selector: 'rs-web-ticket-creation-page',
  standalone: true,
  templateUrl: './ticket-creation-page.component.html',
  styleUrl: './ticket-creation-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    TranslateModule,
    SideSheetFormComponent,
    SelectContractWebComponent,
    SideSheetContentDirective,
    TicketCategoriesComponent,
    TicketDetailQuestionsComponent,
    TicketSummaryComponent,
    ReactiveFormsModule,
    AsyncPipe,
    TicketDirectAssignmentComponent,
    TicketsNoCatalogueComponent,
  ],
  providers: [TicketCreationService],
})
export class TicketCreationPageWebComponent implements OnInit {
  #ticketCreationService = inject(TicketCreationService);
  #ngbActiveOffCanvas = inject(NgbActiveOffcanvas);
  public form = this.#ticketCreationService.form;
  public showDetailStep = signal(false);
  public showSummaryStep = signal(false);
  public contacts = signal<IContactPersonResponse[]>([]);
  public saveTicketActionState: IActionState;
  public detailQuestionsActionState$ = this.#ticketCreationService.detailQuestionsActionState;
  public contracts$ = this.#ticketCreationService.contracts$.pipe(
    map(contracts =>
      contracts.filter(contract => contractStatusConverter.isContractActive(contract))
    )
  );
  public detailQuestionsFormArray = this.#ticketCreationService.detailQuestionsFormArray;

  @Input() set type(issueType: TicketIssueType) {
    this.#ticketCreationService.type = issueType;
  }

  get detailQuestionsResponse() {
    return this.#ticketCreationService.detailQuestionsResponse;
  }

  // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
  get type() {
    return this.#ticketCreationService.type;
  }

  ngOnInit() {
    this.#ticketCreationService.saveTicketActionState$
      .pipe(
        tap(state => (this.saveTicketActionState = state)),
        filter(state => state.done),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.#ngbActiveOffCanvas.close();
        this.#ticketCreationService.reFetchTickets();
      });
  }

  public onCategoriesStepDone(sideSheetFormComponent: SideSheetFormComponent) {
    this.#ticketCreationService
      .onCategoriesStepDone()
      .subscribe(({ contacts, detailQuestions }) => {
        if (contacts.length > 0) {
          this.contacts.set(contacts);
        } else {
          if (detailQuestions.detailRootQuestion) {
            this.showDetailStep.set(true);
          }
          this.showSummaryStep.set(true);
          sideSheetFormComponent.nextTab();
        }
      });
  }

  public onContractChange() {
    this.hideSteps();
    this.#ticketCreationService.onContractChange();
  }

  public onCategoryQuestionChange() {
    this.hideSteps();
    this.#ticketCreationService.clearDetailQuestionsFormArray();
  }

  public onDismiss(event: NgbActiveOffcanvas) {
    event.dismiss();
  }

  public onDone() {
    this.#ticketCreationService.createTicket();
  }

  private hideSteps() {
    if (this.showSummaryStep() || this.showDetailStep()) {
      this.showDetailStep.set(false);
      this.showSummaryStep.set(false);
    }
  }

  protected readonly TicketIssueType = TicketIssueType;
}
