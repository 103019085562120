import { KeyValue } from '@angular/common';
import { inject, Injectable, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import {
  contractStatusConverter,
  CreateTicketHQ,
  CreateTicketMetadata,
  getConcernCategories,
  getConcernCategoriesActionState,
  getConcernQuestions,
  getConcernQuestionsActionState,
  getContracts,
  getCreateTicketHQActionState,
  getCreateTicketMetadataActionState,
  getDamageCategories,
  getDamageCategoriesActionState,
  getDamageQuestions,
  getDamageQuestionsActionState,
  getDirectAssignmentContacts,
  getDirectAssignmentContactsActionState,
  getResidentAppSettings,
  getSelectedContract,
  getUploadedFiles,
  getUploadingActionState,
  IContactPersonResponse,
  IContract,
  IHierarchicalQuestionAnswerTicketOverview,
  IHierarchicalQuestionTicketOverview,
  IHierarchicalQuestionTicketResponseOverviewInput,
  IResidentS3File,
  IRootQuestionResponseTicketShortInput,
  IRootQuestionTicketOverview,
  ITicketMetadataCreate,
  ITicketResidentCategory,
  LoadActiveDamageTickets,
  LoadActiveRequestTickets,
  LoadConcernCategoryQuestions,
  LoadConcernQuestions,
  LoadDamageCategoryQuestions,
  LoadDamageQuestions,
  LoadDirectAssignmentContacts,
  ScoreQuestionType,
  TicketingVersion,
  TicketIssueType,
  UploadFilesWeb,
} from '@resident-nx/shared';
import { filter, first, merge, Observable, of, share, skip, switchMap } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { TicketHelperService } from './ticket-helper.service';

export const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20 MB

@Injectable()
export class TicketCreationService {
  public categoryResponse: IRootQuestionTicketOverview;
  public detailQuestionsResponse: ITicketResidentCategory;
  public categoriesSelector$: Observable<IRootQuestionTicketOverview>;
  public detailQuestionsSelector$: Observable<ITicketResidentCategory>;
  public categoryStep = signal(-1);
  #fb = inject(FormBuilder);
  public form = this.#fb.group({
    contract: this.#fb.control<IContract>(null),
    categories: this.#fb.record<FormControl<IHierarchicalQuestionAnswerTicketOverview>>({}),
    currentCategory: this.#fb.control<IHierarchicalQuestionTicketOverview>(null),
    detailQuestions: this.#fb.array<FormControl | FormArray<FormControl>>([]),
  });
  #store = inject(Store);
  public contracts$ = this.#store.select(getContracts).pipe(take(1));
  #type: TicketIssueType;
  #ticketHelperService = inject(TicketHelperService);

  public get type() {
    return this.#type;
  }

  public set type(issueType: TicketIssueType) {
    this.#type = issueType;
    this.initializeSelectors();
  }

  public get categoriesFormRecord() {
    return this.form.controls.categories;
  }

  public get currentCategoryFormControl() {
    return this.form.controls.currentCategory;
  }

  public get detailQuestionsFormArray(): FormArray<FormControl | FormArray<FormControl>> {
    return this.form.controls.detailQuestions as unknown as FormArray<
      FormControl | FormArray<FormControl>
    >;
  }

  public get categoriesActionState() {
    return this.type === TicketIssueType.CONCERN
      ? this.#store.select(getConcernCategoriesActionState)
      : this.#store.select(getDamageCategoriesActionState);
  }

  public get detailQuestionsActionState() {
    return this.type === TicketIssueType.CONCERN
      ? this.#store.select(getConcernQuestionsActionState)
      : this.#store.select(getDamageQuestionsActionState);
  }

  public get saveTicketActionState$() {
    return merge(
      this.#store.select(getUploadingActionState),
      this.#store.select(getCreateTicketHQActionState),
      this.#store.select(getCreateTicketMetadataActionState)
    ).pipe(skip(3));
  }

  public reFetchTickets() {
    const payload = { offset: 0, limit: 100 };
    this.#store.dispatch(
      this.type === TicketIssueType.PROPERTY_DAMAGE
        ? LoadActiveDamageTickets(payload)
        : LoadActiveRequestTickets(payload)
    );
  }

  public fetchCategoryQuestions() {
    if (this.type === TicketIssueType.PROPERTY_DAMAGE) {
      this.#store.dispatch(LoadDamageCategoryQuestions());
    } else if (this.type === TicketIssueType.CONCERN) {
      this.#store.dispatch(LoadConcernCategoryQuestions());
    }
    this.selectCategories();
  }

  public goToPreviousCategory() {
    let previousCategoryKey: string;
    Object.entries(this.categoriesFormRecord.value)
      .filter(([_, value]) => !!value)
      .some(([id, answer]) => {
        if (this.currentCategoryFormControl.value.id === answer.questionIds[0]) {
          previousCategoryKey = id;
          return true;
        }
        return false;
      });
    this.categoriesFormRecord.removeControl(this.currentCategoryFormControl.value.id, {
      emitEvent: false,
    });
    this.categoriesFormRecord.get(previousCategoryKey).patchValue(null);
    const question = this.categoryResponse.questions.find(q => q.id === previousCategoryKey);
    const withOrderedAnswers = {
      ...question,
      answers: question.answers.toSorted((a, b) => a.data.order - b.data.order),
    };
    this.currentCategoryFormControl.patchValue(withOrderedAnswers);
    this.categoryStep.update(v => v - 1);
  }

  public setCurrentCategoryAndRecord(questionId: string) {
    const orderedQuestions = this.categoryResponse.questions.map(question => ({
      ...question,
      answers: question.answers.toSorted((a, b) => a.data.order - b.data.order),
    }));
    this.currentCategoryFormControl.patchValue(orderedQuestions.find(q => q.id === questionId));

    this.categoriesFormRecord.addControl(questionId, new FormControl(null, Validators.required));
    this.categoryStep.update(v => v + 1);
  }

  public onContractChange() {
    Object.keys(this.categoriesFormRecord.value).forEach(control =>
      this.categoriesFormRecord.removeControl(control, { emitEvent: false })
    );
    this.currentCategoryFormControl.reset();
    this.categoryStep.set(-1);
    this.clearDetailQuestionsFormArray();
  }

  public onCategoriesStepDone(): Observable<{
    contacts: IContactPersonResponse[];
    detailQuestions: ITicketResidentCategory;
  }> {
    return this.#store.select(getSelectedContract).pipe(
      take(1),
      map(contract =>
        contract && contractStatusConverter.isContractActive(contract) ? contract : undefined
      ),
      switchMap(contract => {
        this.loadDetailedQuestions();
        const directAssignmentObs = (detailQuestions: ITicketResidentCategory) =>
          this.#store.select(getDirectAssignmentContactsActionState).pipe(
            filter(state => state.done),
            switchMap(() => this.#store.select(getDirectAssignmentContacts)),
            map(contacts => ({ detailQuestions, contacts }))
          );

        return this.detailQuestionsSelector$.pipe(
          switchMap(detailQuestions => {
            if (detailQuestions.id && contract) {
              this.#store.dispatch(LoadDirectAssignmentContacts({ category: detailQuestions.id }));
              return directAssignmentObs(detailQuestions);
            }
            return of<{
              contacts: IContactPersonResponse[];
              detailQuestions: ITicketResidentCategory;
            }>({
              contacts: [],
              detailQuestions,
            });
          }),
          take(1)
        );
      })
    );
  }

  public loadDetailedQuestions(): void {
    const questionsRequest = this.categoryToResponse();

    if (this.type === TicketIssueType.PROPERTY_DAMAGE) {
      this.#store.dispatch(LoadDamageQuestions({ damageCategoryQuestions: questionsRequest }));
    } else if (this.type === TicketIssueType.CONCERN) {
      this.#store.dispatch(LoadConcernQuestions({ concernCategoryQuestions: questionsRequest }));
    }

    this.selectQuestions();
  }

  public clearDetailQuestionsFormArray() {
    this.detailQuestionsFormArray.clear();
  }

  public getSummaryData() {
    const categories = this.categoryResponse.questions
      .toSorted((a, b) => a.data.order - b.data.order)
      .reduce<KeyValue<string, string>[]>((previousValue, currentValue) => {
        const answer = this.categoriesFormRecord.get(currentValue.id)?.value;
        if (answer) {
          previousValue.push({
            key: currentValue.data.title,
            value: answer.data.title,
          });
        }
        return previousValue;
      }, []);

    const detailQuestions = this.detailQuestionsResponse?.detailRootQuestion?.questions.reduce<
      (KeyValue<string, unknown> & { type: ScoreQuestionType })[]
    >((previousValue, currentValue, index) => {
      let answer = this.detailQuestionsFormArray.at(index).value;
      if (currentValue.data.type === ScoreQuestionType.MULTISELECT) {
        answer = this.#ticketHelperService.getCheckedMultiselectValues(currentValue, answer);
      }
      previousValue.push({
        key: currentValue.data.title,
        type: currentValue.data.type,
        value: answer,
      });
      return previousValue;
    }, []);

    return { category: this.detailQuestionsResponse.name, categories, detailQuestions };
  }

  public createTicket() {
    this.#store
      .select(getResidentAppSettings)
      .pipe(take(1))
      .subscribe(settings => {
        if (settings?.ticketingVersion === TicketingVersion.METADATA) {
          this.createTicketMetadata();
        } else {
          this.uploadAttachmentsAndCreateTicket();
        }
      });
  }

  private categoryToResponse() {
    const responses = Object.entries(this.categoriesFormRecord.value).reduce<
      IHierarchicalQuestionTicketResponseOverviewInput[]
    >((previousValue, [questionId, answer]) => {
      const response: IHierarchicalQuestionTicketResponseOverviewInput = {
        questionId,
        selectionData: {
          type: answer.data.type,
          answerIds: [answer.id],
        },
      };
      previousValue.push(response);
      return previousValue;
    }, []);

    return {
      rootQuestionId: this.categoryResponse.id,
      responses,
    };
  }

  private detailedToResponse(files: IResidentS3File[]) {
    const responses = this.detailQuestionsResponse.detailRootQuestion.questions.reduce<
      IHierarchicalQuestionTicketResponseOverviewInput[]
    >((previousValue, question, index) => {
      if (question.data.type === ScoreQuestionType.LABEL) {
        return previousValue;
      }

      const response: IHierarchicalQuestionTicketResponseOverviewInput = {
        questionId: question.id,
      };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const answer: any = this.detailQuestionsFormArray.at(index).value;
      const type = question.data.type;

      switch (question.data.type) {
        case ScoreQuestionType.SELECTION: {
          response.selectionData = {
            type,
            answerIds: [answer.id],
          };
          break;
        }

        case ScoreQuestionType.INPUT_TEXT: {
          response.textData = {
            type,
            response: answer,
          };
          break;
        }

        case ScoreQuestionType.INPUT_DATE: {
          response.dateData = {
            type,
            response: answer,
          };
          break;
        }

        case ScoreQuestionType.INPUT_ATTACHMENTS: {
          response.attachments = {
            type,
            response: files,
          };
          break;
        }
      }
      previousValue.push(response);
      return previousValue;
    }, []);

    return {
      rootQuestionId: this.detailQuestionsResponse.detailRootQuestion.id,
      responses,
    };
  }

  private async detailedMetaDataToResponse(): Promise<ITicketMetadataCreate> {
    const formResponses = await this.#ticketHelperService.createFormResponseCreates(
      this.detailQuestionsResponse.detailRootQuestion.questions,
      this.detailQuestionsFormArray
    );

    return {
      formResponses,
      issueType: this.type,
      contractNumber: null,
      categoryAnswerSetRelationId: this.detailQuestionsResponse.categoryAnswerSetRelationId,
    };
  }

  private async createTicketMetadata() {
    const newTicketMetadata = await this.detailedMetaDataToResponse();
    this.#store.dispatch(CreateTicketMetadata({ newTicketMetadata }));
  }

  private uploadAttachmentsAndCreateTicket(): void {
    const filePaths = this.detailQuestionsFormArray.value
      .filter(value => value instanceof FileList)
      .map((attachmentQuestion: FileList) => [...attachmentQuestion].map(file => file))
      .flat();

    const dispatchTicket = (files?: IResidentS3File[]) => {
      const newTicketHQ = this.getTicketCreationPayload(files);
      this.#store.dispatch(CreateTicketHQ({ newTicketHQ }));
    };

    if (filePaths.length) {
      this.#store.dispatch(UploadFilesWeb({ filePaths }));

      this.#store
        .select(getUploadedFiles)
        .pipe(
          filter(files => !!files.length),
          first()
        )
        .subscribe(files => dispatchTicket(files));
    } else {
      dispatchTicket();
    }
  }

  private getTicketCreationPayload(files?: IResidentS3File[]) {
    let responses: IRootQuestionResponseTicketShortInput;
    if (this.detailQuestionsResponse.detailRootQuestion.questions.length) {
      responses = this.detailedToResponse(files);
    } else {
      responses = this.categoryToResponse();
    }

    return {
      responses,
      categoryId: this.detailQuestionsResponse.id,
      issueType: this.type,
      parentTicketId: '',
      propertyId: '',
      contractNumber: null,
    };
  }

  private initializeSelectors() {
    const isConcern = this.type === TicketIssueType.CONCERN;

    this.categoriesSelector$ = this.#store
      .select(isConcern ? getConcernCategoriesActionState : getDamageCategoriesActionState)
      .pipe(
        filter(state => !state.pending),
        switchMap(() => this.#store.select(isConcern ? getConcernCategories : getDamageCategories)),
        filter(categories => !!categories)
      );

    this.detailQuestionsSelector$ = this.#store
      .select(isConcern ? getConcernQuestionsActionState : getDamageQuestionsActionState)
      .pipe(
        filter(state => !state.pending),
        switchMap(() => this.#store.select(isConcern ? getConcernQuestions : getDamageQuestions)),
        filter(questions => !!questions),
        share(),
        take(1)
      );
  }

  private selectCategories() {
    this.categoriesSelector$
      .pipe(take(1))

      .subscribe(response => this.formatCategoriesResponse(response));
  }

  private selectQuestions(): void {
    this.detailQuestionsSelector$.subscribe(response =>
      this.formatDetailedQuestionsResponse(response)
    );
  }

  private formatDetailedQuestionsResponse(response: ITicketResidentCategory) {
    if (this.detailQuestionsFormArray.length > 0) return;

    const { detailRootQuestion, ...restResponse } = response;
    const { questions = [], ...restDetailRootQuestion } =
      detailRootQuestion || ({} as IRootQuestionTicketOverview);

    const questionsWithOrderedAnswers = questions.map(question => {
      const sortedAnswers = question.answers.toSorted((a, b) => a.data.order - b.data.order);
      return { ...question, answers: sortedAnswers };
    });

    const orderedQuestions = questionsWithOrderedAnswers.toSorted(
      (a, b) => a.data.order - b.data.order
    );

    this.detailQuestionsResponse = {
      ...restResponse,
      detailRootQuestion: {
        ...restDetailRootQuestion,
        questions: orderedQuestions,
      },
    };

    this.detailQuestionsResponse.detailRootQuestion.questions.forEach(question => {
      const control = this.#ticketHelperService.createDetailQuestionsControl(question);
      this.detailQuestionsFormArray.push(control);
    });
  }

  private formatCategoriesResponse(response: IRootQuestionTicketOverview) {
    this.categoryResponse = response;
    const lengthOfCategoriesFormRecord = Object.keys(this.categoriesFormRecord.value).length;
    if (lengthOfCategoriesFormRecord) {
      this.jumpToCurrentCategory(lengthOfCategoriesFormRecord);
    } else if (response.mainQuestionIds) {
      this.setCurrentCategoryAndRecord(response.mainQuestionIds[0]);
    }
  }

  private jumpToCurrentCategory(steps: number) {
    this.categoryStep.set(steps - 1);
  }
}
