import { Directive, Input } from '@angular/core';

import { Store } from '@ngrx/store';

import { RouterService } from '../../services';
import { AuthService, StorageService } from '../../infrastructure';

@Directive()
export abstract class LogoutButtonBaseComponent {
  @Input() text?: string;
  @Input() className?: string;

  constructor(
    private authService: AuthService,
    private router: RouterService,
    protected storageService: StorageService,
    protected store: Store
  ) {}

  public logout() {
    this.authService.logout('auth');
  }
}
