import { FormControl, FormGroup, Validators } from '@angular/forms';

import {
  AnswerId,
  IDisplaySummary,
  IHierarchicalQuestionAnswerTicketOverview,
  IHierarchicalQuestionTicketOverview,
  IHierarchicalQuestionTicketResponseOverviewInput,
  IResidentS3File,
  IRootQuestionResponseTicketShortInput,
  IRootQuestionTicketOverview,
  ITicketCreationSummary,
  ITicketCreationSummaryItem,
  ITicketEdit,
  QuestionId,
  ScoreQuestionType,
  TicketIssueType,
} from '../../models';

export const ticketHierarchicalQuestionsConverter = {
  getFirstQuestion: (
    rootQuestions: IRootQuestionTicketOverview
  ): IHierarchicalQuestionTicketOverview => {
    return rootQuestions.mainQuestionIds?.length
      ? rootQuestions.questions?.find(el => el.id === rootQuestions.mainQuestionIds[0])
      : rootQuestions.questions?.find(el => el.data.order === 0);
  },
  hasNextQuestion: (
    answer: IHierarchicalQuestionAnswerTicketOverview,
    questions: IHierarchicalQuestionTicketOverview[]
  ): boolean => {
    const nextQuestionId = answer.questionIds ? answer.questionIds[0] : null; //assumption(!): only one follow up
    return nextQuestionId ? questions.some(question => question.id === nextQuestionId) : false;
  },
  getNextQuestion: (
    answer: IHierarchicalQuestionAnswerTicketOverview,
    questions: IHierarchicalQuestionTicketOverview[]
  ): IHierarchicalQuestionTicketOverview => {
    const nextQuestionId = answer.questionIds ? answer.questionIds[0] : null; //assumption(!): only one follow up
    return nextQuestionId
      ? ticketHierarchicalQuestionsConverter.getQuestionById(nextQuestionId, questions)
      : null;
  },
  getQuestionById: (
    questionID: QuestionId,
    questions: IHierarchicalQuestionTicketOverview[]
  ): IHierarchicalQuestionTicketOverview => {
    return questions.find(question => question.id === questionID);
  },
  getQuestionByAnswerId: (
    answerId: AnswerId,
    questions: IHierarchicalQuestionTicketOverview[]
  ): IHierarchicalQuestionTicketOverview => {
    return questions.find(question =>
      question.answers.some(
        (answer: IHierarchicalQuestionAnswerTicketOverview) => answer.id === answerId
      )
    );
  },
  formatQuestionToSummaryItem: (
    question: IHierarchicalQuestionTicketOverview
  ): ITicketCreationSummaryItem => {
    return {
      items:
        question.data.type === ScoreQuestionType.SELECTION ||
        question.data.type === ScoreQuestionType.MULTISELECT
          ? [...question.answers]
              .sort((a, b) => a.data.order - b.data.order)
              .map(answer => ({
                key: answer.id,
                value: answer.data.title,
              }))
          : [],
      title: question.data.title,
      response: null,
      displaySummary: null,
      type: question.data.type,
      required: question.data.required,
      multiline: question.answers[0]?.data?.multiline,
      id: question.id,
    };
  },
  formatTicketQuestionsRequest: (
    ticketSummary: ITicketCreationSummary
  ): IRootQuestionResponseTicketShortInput => {
    const request: IRootQuestionResponseTicketShortInput = {
      rootQuestionId: ticketSummary.rootQuestionId,
      responses: [],
    };

    ticketSummary.responses?.forEach(res => {
      request.responses.push(res.response);
    });
    return request;
  },
  createQuestionsAndAnswersForm: (
    ticketSummary: ITicketCreationSummary
  ): FormGroup<Record<number, FormControl>> => {
    const group: Record<number, FormControl> = {};
    ticketSummary.detailedResponses.forEach(el => {
      if (el.type === ScoreQuestionType.INPUT_TEXT) {
        group[el.id] = el.required ? new FormControl('', Validators.required) : new FormControl('');
      }
      if (el.type === ScoreQuestionType.INPUT_DATE) {
        group[el.id] = el.required ? new FormControl('', Validators.required) : new FormControl('');
      }
      if (el.type === ScoreQuestionType.SELECTION) {
        group[el.id] = el.required ? new FormControl('', Validators.required) : new FormControl('');
      }
      if (el.type === ScoreQuestionType.MULTISELECT) {
        group[el.id] = el.required ? new FormControl([], Validators.required) : new FormControl([]);
      }
      if (el.type === ScoreQuestionType.INPUT_ATTACHMENTS) {
        group[el.id] = el.required ? new FormControl('', Validators.required) : new FormControl('');
      }
    });
    return new FormGroup(group);
  },
  formatFormAnswersToDetailedResponses: (
    ticketSummary: ITicketCreationSummary,
    questionsForm: FormGroup<Record<number, FormControl>>
  ): ITicketCreationSummaryItem[] => {
    return ticketSummary.detailedResponses.map(detailedRes => {
      const answer = questionsForm.value[detailedRes.id];
      if (answer) {
        let newResponse: IHierarchicalQuestionTicketResponseOverviewInput;
        let newDisplaySummary: IDisplaySummary;

        switch (detailedRes.type) {
          case ScoreQuestionType.INPUT_TEXT:
            newResponse = {
              questionId: detailedRes.id,
              textData: { response: answer, type: ScoreQuestionType.INPUT_TEXT },
            };
            newDisplaySummary = {
              questionTitle: detailedRes.title,
              answerTitle: answer,
              attachments: null,
            };
            break;

          case ScoreQuestionType.INPUT_DATE:
            newResponse = {
              questionId: detailedRes.id,
              dateData: { response: answer, type: ScoreQuestionType.INPUT_DATE },
            };
            newDisplaySummary = {
              questionTitle: detailedRes.title,
              answerTitle: answer,
              attachments: null,
            };
            break;

          case ScoreQuestionType.INPUT_ATTACHMENTS:
            newResponse = {
              questionId: detailedRes.id,
              attachments: { response: [], type: ScoreQuestionType.INPUT_ATTACHMENTS },
            };
            newDisplaySummary = {
              questionTitle: detailedRes.title,
              answerTitle: '',
              attachments: answer,
            };
            break;

          case ScoreQuestionType.SELECTION:
            newResponse = {
              questionId: detailedRes.id,
              selectionData: { answerIds: [answer], type: ScoreQuestionType.SELECTION },
            };
            newDisplaySummary = {
              questionTitle: detailedRes.title,
              answerTitle: detailedRes.items.find(
                item => item.key === newResponse.selectionData.answerIds[0]
              )?.value,
              attachments: null,
            };
            break;

          case ScoreQuestionType.MULTISELECT:
            newResponse = {
              questionId: detailedRes.id,
              selectionData: { answerIds: [...answer], type: ScoreQuestionType.MULTISELECT },
            };
            newDisplaySummary = {
              questionTitle: detailedRes.title,
              answerTitle: detailedRes.items
                .filter(item => answer.includes(item.key))
                .map(item => item.value),
              attachments: null,
            };
            break;
        }

        return {
          ...detailedRes,
          response: newResponse,
          displaySummary: newDisplaySummary,
        };
      }
      return detailedRes;
    });
  },
  formatCreateTicketRequest: (
    ticketSummary: ITicketCreationSummary,
    issueType: TicketIssueType,
    uploadedFiles?: IResidentS3File[]
  ): ITicketEdit => {
    const answers: IHierarchicalQuestionTicketResponseOverviewInput[] = [];
    if (ticketSummary.detailedResponses.length > 0) {
      ticketSummary.detailedResponses?.forEach(res => {
        if (res.type !== ScoreQuestionType.LABEL) {
          if (res.type === ScoreQuestionType.INPUT_ATTACHMENTS) {
            const response: IHierarchicalQuestionTicketResponseOverviewInput = {
              questionId: res.id,
              attachments: {
                type: ScoreQuestionType.INPUT_ATTACHMENTS,
                response: uploadedFiles,
              },
            };
            answers.push(response);
          } else if (
            res.type === ScoreQuestionType.SELECTION &&
            !ticketSummary.detailedQuestionId
          ) {
            ticketSummary.detailedQuestionId = ticketSummary.rootQuestionId;
            answers.push(res.response);
          } else {
            answers.push(res.response);
          }
        }
      });

      const responses: IRootQuestionResponseTicketShortInput = {
        rootQuestionId: ticketSummary.detailedQuestionId,
        responses: answers,
      };
      return {
        responses,
        categoryId: ticketSummary.categoryId,
        issueType,
        parentTicketId: '',
        propertyId: '',
        contractNumber: null,
      };
    } else {
      ticketSummary.responses?.forEach(res => {
        const response: IHierarchicalQuestionTicketResponseOverviewInput = {
          questionId: res.response.questionId,
          selectionData: res.response.selectionData,
        };
        answers.push(response);
      });
      const responses: IRootQuestionResponseTicketShortInput = {
        rootQuestionId: ticketSummary.rootQuestionId,
        responses: answers,
      };
      return {
        responses,
        categoryId: ticketSummary.categoryId,
        issueType,
        parentTicketId: '',
        propertyId: '',
        contractNumber: null,
      };
    }
  },
};
