import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { ISharedContentParams, ISharedFolderContentResponse } from '../../models';
import { map, Observable } from 'rxjs';
import { getSharedContentQuery } from '../../queries';

@Injectable()
export class SharedContentFacade {
  constructor(private apollo: Apollo) {}

  public getSharedContent(params: ISharedContentParams): Observable<ISharedFolderContentResponse> {
    return this.apollo
      .query<{ getSharedContent: ISharedFolderContentResponse }>({
        query: getSharedContentQuery,
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            folderId: params.folderId,
            contractId: params.contractId,
            sortOrder: params.sortOrder,
            sortField: params.sortField,
          },
        },
      })
      .pipe(
        map(response => {
          if (response?.data?.getSharedContent) {
            return response?.data?.getSharedContent;
          } else {
            throw Error(response.errors[0].message);
          }
        })
      );
  }
}
