export enum ScoreQuestionType {
  SELECTION = 'SELECTION',
  MULTISELECT = 'MULTISELECT',
  INPUT_TEXT = 'INPUT_TEXT',
  INPUT_DATE = 'INPUT_DATE',
  INPUT_ATTACHMENTS = 'INPUT_ATTACHMENTS',
  RANGE_NUMBER = 'RANGE_NUMBER',
  RANGE_DATE = 'RANGE_DATE',
  LABEL = 'LABEL',
}
