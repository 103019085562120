<div formGroupName="name">
  <p class="fw-bold">
    {{ 'user_edit_overview_l' | translate }}: {{ 'user_name_edit.form_l' | translate }}
  </p>

  <div class="mb-3">
    <label class="form-label" for="name">{{ 'user_name_edit_firstname_l' | translate }}</label>
    <input class="form-control" formControlName="name" id="name" type="text" />
  </div>

  <div class="mb-3">
    <label class="form-label" for="lastName">{{ 'user_name_edit_lastname_l' | translate }}</label>
    <input class="form-control" formControlName="lastName" id="lastName" type="text" />
  </div>

  <label class="form-label" for="verification">{{ 'verification_l' | translate }}</label>
  <rs-web-file-upload-button
    (click)="fileInput.click()"
    [label]="'user_name_edit_attactment_l'"
  ></rs-web-file-upload-button>

  <input
    #fileInput
    [accept]="'.pdf'"
    class="d-none"
    formControlName="verificationFile"
    id="verification"
    type="file"
  />

  @if (fileInput.files[0]) {
    <div class="d-flex flex-column mt-4">
      <rs-web-pdf-attachment
        [file]="fileInput.files[0]"
        [maxFileSize]="maxFileSize"
        (remove)="removeFile()"
      ></rs-web-pdf-attachment>
    </div>
  }
</div>
