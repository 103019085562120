import { Directive } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WhitelabelImage, WhitelabelImageType } from '../../models';

@UntilDestroy()
@Directive()
export class UserEditErrorPageBaseComponent {
  public headerTitle = 'user_edit_error_heading';
  public whitelabelImage = WhitelabelImage;
  public whitelabelImageType = WhitelabelImageType;
  public isERPError = false; // TODO-user-edit: handle this in the route

  public openRequestErrors = ['open_request_desc_1', 'open_request_desc_2', 'open_request_desc_3'];
  public erpErrors = ['erp_desc_1', 'erp_desc_2'];
}
