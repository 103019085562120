import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';

export function passwordValidator(control: FormControl): ValidationErrors | null {
  if (control && (control.value === '' || control.value === null)) return null;
  const tests = {
    minLength: /^.{8,}/g.test(control.value),
    hasLowerUpperCase: /^(?=.*[a-z])(?=.*[A-Z]).*$/g.test(control.value),
    hasNumberSpecial: /^(?!.*\s)(?=.*\d)(?=.*[@~#$!%*()_+{}":;'\]|.,-.?&]).*$/g.test(control.value),
    hasAll:
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@~#$!%*()_+{}":;'\][/|.,-.?&])[A-Za-z\d@~#$!%*()_+{}:;"'\][/|.,-.?&]{8,}$/g.test(
        control.value
      ),
  };
  const errors = {};
  Object.keys(tests)
    .filter(key => tests[key] === false)
    .forEach(key => {
      errors[key] = true;
    });
  return Object.values(errors).length > 0 ? errors : null;
}

export function matchingControl(group: AbstractControl): ValidationErrors | null {
  let target;
  for (const key in group['controls']) {
    const control = group.get(key);
    if (!target) {
      target = control.value;
    }
    if (target != control.value) {
      return { isMatching: true };
    }
  }
  return null;
}

export function customCountryCodePhoneValidator(control: FormControl): ValidationErrors | null {
  if (control && (control.value === '' || control.value === null)) return null;
  if (/\s/gi.test(control.value)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    control.patchValue(control.value.replace(/\s/g, ''));
  }
  return /^\+{0,1}[0-9/\-\s]+$/g.test(control.value) ? null : { countryCode: true };
}

export function customEmailValidator(control: FormControl): ValidationErrors | null {
  if (control && (control.value === '' || control.value === null)) return null;
  if (/\s/gi.test(control.value)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    control.patchValue(control.value.replace(/\s/g, ''));
  }
  return Validators.email(control);
}

export function customPhoneValidator(control: FormControl): ValidationErrors | null {
  if (control && (control.value === '' || control.value === null)) return null;
  if (/\s/gi.test(control.value)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    control.patchValue(control.value.replace(/\s/g, ''));
  }
  return /^\+{0,1}[0-9/\-\s]+$/g.test(control.value) ? null : { phone: true };
}

export function lettersOnlyValidator(control: FormControl): ValidationErrors | null {
  if (control && (control.value === '' || control.value === null)) return null;
  if (/\s/gi.test(control.value)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    control.patchValue(control.value.replace(/\s/g, ''));
  }
  return /^[a-zA-Z\s]*$/g.test(control.value) ? null : { onlyLetters: true };
}

export function alphaNumericValidator(control: FormControl): ValidationErrors | null {
  if (control && (control.value === '' || control.value === null)) return null;
  if (/\s/gi.test(control.value)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    control.patchValue(control.value.replace(/\s/g, ''));
  }
  return /^[a-zA-Z0-9\s]*$/g.test(control.value) ? null : { alphaNumeric: true };
}

export function numbersOnlyValidator(control: FormControl): ValidationErrors | null {
  if (control && (control.value === '' || control.value === null)) return null;
  if (/\s/gi.test(control.value)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    control.patchValue(control.value.replace(/\s/g, ''));
  }
  return /^[0-9]*$/g.test(control.value) ? null : { onlyNumbers: true };
}

// does not allow a return/enter key to be pressed as valid
export function noBlankSpaceValidator(control: AbstractControl): ValidationErrors | null {
  if (control.value && control.value.trim() === '') {
    return { whitespace: true };
  }
  return null;
}

export function minNumberOfElementsInArray(min = 1): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (control: AbstractControl): { [key: string]: any } | null => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const array = control.value as any[];
    if (array.length < min) {
      return { minNumberOfElementsRequired: true };
    }
    return null;
  };
}

export function patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      // if control is empty return no error
      return null;
    }

    // test the value of the control against the regexp supplied
    const valid = regex.test(control.value);

    // if true, return no error (no error), else return error passed in the second parameter
    return valid ? null : error;
  };
}

export function phoneNumberValidator(): ValidatorFn {
  return patternValidator(/^[0-9]*$/, { invalidPhoneNumber: true });
}

// only numbers and +
export function phonePrefixValidator(): ValidatorFn {
  return patternValidator(/^[0-9+]+$/, { invalidPhonePrefix: true });
}

// only numbers
export function zipCodeValidator(): ValidatorFn {
  return patternValidator(/^[0-9]*$/, { invalidZipcode: true });
}

// only letters and -
export function nameValidator(): ValidatorFn {
  return patternValidator(/^[A-Za-z]+([ '-][A-Za-z]+)*$/, { invalidName: true });
}

export function emailPatternValidator(): ValidatorFn {
  const emailRegex = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$');
  return patternValidator(emailRegex, {
    invalidEmail: true,
  });
}
