import { Directive, EventEmitter, Input, Output } from '@angular/core';
import {
  DocumentFileType,
  FormResponseQuestionType,
  IMetadataTicketDetailsResponse,
  ImmomioIconName,
  TicketingVersion,
} from '../../../models';

const DESCRIPTION_MAX_LINES = 2;
const DEFAULT_SHOW_QUESTIONS_COUNT = 3;

@Directive()
export abstract class TicketDetailsOverviewMetadataBaseComponent {
  @Input() ticketDetails: IMetadataTicketDetailsResponse;
  @Output() openDocument: EventEmitter<{ path: string; name: string }> = new EventEmitter();
  public ticketingVersion = TicketingVersion.METADATA;
  public descriptionMaxLines = DESCRIPTION_MAX_LINES;
  public shownAnswersCount = DEFAULT_SHOW_QUESTIONS_COUNT;
  public immomioIconName = ImmomioIconName;
  public formResponseQuestionType = FormResponseQuestionType;
  public documentFileType = DocumentFileType;

  public get showMoreButton() {
    return (
      this.ticketDetails.formResponses?.length > DEFAULT_SHOW_QUESTIONS_COUNT &&
      this.shownAnswersCount !== this.ticketDetails.formResponses.length
    );
  }

  public showMore(): void {
    this.shownAnswersCount = this.ticketDetails.formResponses?.length;
  }
}
