import { Injectable } from '@angular/core';

import { ApexAxisChartSeries, ApexChart, ApexXAxis, ApexYAxis } from 'ng-apexcharts';
import * as apexDe from 'apexcharts/dist/locales/de.json';

import { ConsumptionSeriesIndex, ConsumptionUnitType, IConsumptionItem } from '@resident-nx/shared';
import { ConsumptionChartOptions } from './types/consumption-chart-options';
import { ApexDataPointSelection } from './types/apex-data-point-selection';

export interface CreateChartOptionsParams {
  consumptionUnit: ConsumptionUnitType;
  onDataPointSelection: (index: number) => void;
  height: string | number;
}

export interface UpdateSeriesParams {
  series: ApexAxisChartSeries;
  indexToUpdate: ConsumptionSeriesIndex;
  data: IConsumptionItem[];
}

@Injectable()
export class ConsumptionChartService {
  public updateYAxisOption(
    options: ConsumptionChartOptions,
    consumptionUnit: ConsumptionUnitType
  ): ConsumptionChartOptions {
    return {
      ...options,
      yaxis: this.createYAxisOption(consumptionUnit),
    };
  }

  public createOptions({
    consumptionUnit,
    onDataPointSelection,
    height,
  }: CreateChartOptionsParams): ConsumptionChartOptions {
    return {
      chart: this.createChartOption(onDataPointSelection, height),
      xaxis: this.createXAxisOption(),
      yaxis: this.createYAxisOption(consumptionUnit),
      dataLabels: { enabled: false },
      grid: {
        borderColor: '#f1f1f1',
        strokeDashArray: 3,
        padding: {
          right: 70, // otherwise last xaxis label / bar gets cut off
        },
      },
      markers: { size: 2, hover: { sizeOffset: 6 } },
      tooltip: {
        enabled: true,
        hideEmptySeries: true,
        shared: false,
      },
      legend: { show: false },
    };
  }

  private createChartOption(
    onDataPointSelection: (index: number) => void,
    height: string | number
  ): ApexChart {
    return {
      locales: [apexDe],
      fontFamily: 'Source Sans Pro, serif',
      defaultLocale: 'de',
      type: 'bar',
      height,
      toolbar: { show: false },
      zoom: { enabled: false },
      events: {
        // triggered by line chart
        markerClick: (
          _event: MouseEvent,
          _context: unknown,
          { dataPointIndex }: ApexDataPointSelection
        ) => onDataPointSelection(dataPointIndex),
      },
    };
  }

  private createYAxisOption(consumptionUnit: ConsumptionUnitType): ApexYAxis {
    return {
      labels: {
        style: { cssClass: 'consumption-chart-axis-label--yaxis' },
        align: 'left',
        formatter: value => {
          return value === 0 ? `${value} ${ConsumptionUnitType[consumptionUnit]}` : `${value}`;
        },
      },
    };
  }

  private createXAxisOption(): ApexXAxis {
    return {
      type: 'datetime',
      tickAmount: 'dataPoints',
      labels: {
        datetimeUTC: false,
        rotate: 0,
        trim: false,
        style: {
          cssClass: 'consumption-chart-axis-label--xaxis',
        },
      },
      axisBorder: { show: false },
      axisTicks: { show: false },
    };
  }
}
