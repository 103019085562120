<div
  rsWebElevation
  [elevation]="2"
  class="d-flex p-2 align-items-center border-radius-default card-container"
  [class.overdue-border]="isOverdue(paymentRequest.status)"
  (click)="openPaymentRequestPage(paymentRequest.paymentLandingPageUrl)"
>
  <div class="icon-container">
    <i
      class="icon"
      [class.icon-warning]="isError(paymentRequest.status)"
      [class.icon-archive]="isPaid(paymentRequest.status)"
      [class.icon-payment-request]="
        !isError(paymentRequest.status) && !isPaid(paymentRequest.status)
      "
    ></i>
  </div>
  <div class="d-flex flex-column overflow-hidden">
    <div class="d-flex align-items-center mb-2">
      <div class="badge me-2" [ngClass]="paymentRequest.status | paymentBgStatus">
        @if (isOverdue(paymentRequest.status)) {
          <span
            >{{ 'payment_request.overdue_l' | translate | uppercase }}
            {{ paymentRequest.dueDate | date: 'dd.MM.YYYY' }}</span
          >
        }
        @if (isWaitingForPayment(paymentRequest.status)) {
          <span>{{ 'payment_request.open_request_l' | translate | uppercase }} </span>
        }
        @if (isPaid(paymentRequest.status)) {
          <span
            >{{ 'payment_request.paid_l' | translate | uppercase }}
            {{ paymentRequest.dueDate | date: 'dd.MM.YYYY' }}</span
          >
        }
        @if (isCanceled(paymentRequest.status)) {
          <span
            >{{ 'ticket.state.cancelled' | translate | uppercase }}
            {{ paymentRequest.dueDate | date: 'dd.MM.YYYY' }}</span
          >
        }
        @if (
          !isOverdue(paymentRequest.status) &&
          !isPaid(paymentRequest.status) &&
          !isWaitingForPayment(paymentRequest.status) &&
          !isCanceled(paymentRequest.status)
        ) {
          <span
            >{{ 'payment_request.due_l' | translate | uppercase }}
            {{ paymentRequest.dueDate | date: 'dd.MM.YYYY' }}</span
          >
        }
      </div>
      @if (isError(paymentRequest.status)) {
        <div class="badge" [ngClass]="paymentRequest.status | paymentBgStatus">
          <span>{{ 'payment_request.status_error_l' | translate | uppercase }}</span>
        </div>
      }
    </div>
    <div class="label line-clamp-1">
      <strong>{{ paymentRequest.amount | currency: 'EUR' }} </strong
      >{{ 'payment_request.for_l' | translate }}
      {{ paymentRequest.subject }}
    </div>
  </div>
</div>
