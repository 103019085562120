import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { WidgetBaseComponent } from './widget.base-component';
import * as fromHomepageModuleState from '../../+state/homepage-module/';
import { IHomepageModule } from '../../models';
import { Observable } from 'rxjs';
import { IActionState } from '../../utils';

@UntilDestroy()
@Directive()
export abstract class HomePageModuleBaseComponent extends WidgetBaseComponent {
  @Output() moduleTapped = new EventEmitter();
  homepageModule$: Observable<IHomepageModule>;
  homepageModuleActionState$: Observable<IActionState>;
  setVisibility = false;
  homepageModuleToken: string;
  imagePath = '~/assets/shared/images/homepage-module.png';
  @Input() inWidget = false;

  protected constructor(
    protected homepageModuleStore: Store<fromHomepageModuleState.IHomepageModelState>
  ) {
    super();
    this.homepageModule$ = this.homepageModuleStore
      .select(fromHomepageModuleState.getHomepageModuleToken)
      .pipe(untilDestroyed(this));
    this.homepageModuleActionState$ = this.homepageModuleStore
      .select(fromHomepageModuleState.getHomepageModuleActionState)
      .pipe(untilDestroyed(this));
  }
}
