<div class="banner py-2 px-2">
  <div class="col d-flex align-items-center justify-content-center">
    <div class="emergency-icon-container rounded-circle ratio ratio-1x1">
      <rs-web-icon
        class="d-flex justify-content-center pb-0.5"
        [icon]="immomioIconName.Warning"
        [color]="'white'"
        [size]="16"
      ></rs-web-icon>
    </div>
  </div>
  <div class="d-flex flex-column justify-content-center">
    <rs-web-label
      class="emergency-text"
      className="fs-6 text-dark fw-semibold emergency-text"
      [text]="emergencyBannerHeadline | translate"
    ></rs-web-label>
    <rs-web-label
      class="emergency-text"
      className="text-secondary"
      [text]="emergencyBannerText | translate"
    ></rs-web-label>
  </div>
  <div class="col d-flex align-items-center justify-content-center">
    <rs-web-icon
      class="d-flex pb-1"
      [icon]="immomioIconName.ArrowRight"
      [color]="'black'"
      [size]="16"
    ></rs-web-icon>
  </div>
</div>
