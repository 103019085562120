import { Directive } from '@angular/core';
import {
  IMasterDataChange,
  IMasterdataChangeTicket,
  ImmomioIconName,
  MasterdataChangeRequestType,
  MasterdataChangeType,
  masterDataSelectionTranslateKeys,
  ScoreQuestionType,
} from '../../models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import * as fromMasterDataState from '../../+state';
import {
  getSendMasterDataChangeRequestActionState,
  getSendMasterDataChangeTicketActionState,
  IMasterDataState,
  SendMasterDataChangeRequest,
  SendMasterDataChangeTicket,
} from '../../+state';
import { filter, map, Observable, take } from 'rxjs';
import { RouterService, ticketConverter } from '../../services';

@UntilDestroy()
@Directive()
export abstract class UserEditOverviewBaseComponent {
  public masterDataChange$: Observable<IMasterDataChange | null>;
  public masterDataChangeRequestResponse$: Observable<boolean | null>;
  public masterDataChangeTicketResponse$: Observable<boolean | null>;
  public masterDataChangeType = MasterdataChangeType;
  public displayDate = new Date();
  public immomioIconName = ImmomioIconName;
  public masterDataSelectionTranslateKeys = masterDataSelectionTranslateKeys;
  public scoreQuestionType = ScoreQuestionType;
  public masterDataChangeRequestActionState$ = this.masterDataStore.select(
    getSendMasterDataChangeRequestActionState
  );
  public masterDataChangeTicketActionState$ = this.masterDataStore.select(
    getSendMasterDataChangeTicketActionState
  );

  constructor(
    protected masterDataStore: Store<IMasterDataState>,
    protected routerService: RouterService
  ) {
    this.masterDataChange$ = this.masterDataStore
      .select(fromMasterDataState.getMasterDataChange)
      .pipe(
        take(1),
        map(masterDataChange => masterDataChange)
      );

    this.masterDataChangeRequestResponse$ = this.masterDataStore
      .select(fromMasterDataState.getSendMasterDataChangeRequestResponse)
      .pipe(
        untilDestroyed(this),
        filter((success): success is boolean => success !== null)
      );

    this.masterDataChangeTicketResponse$ = this.masterDataStore
      .select(fromMasterDataState.getSendMasterDataChangeTicketResponse)
      .pipe(
        untilDestroyed(this),
        filter((success): success is boolean => success !== null)
      );
  }

  public resetMasterDataChangeState() {
    this.masterDataStore.dispatch(fromMasterDataState.ResetMasterDataChange());
  }

  public createMasterDataChangeRequest() {
    this.masterDataChange$.pipe(take(1)).subscribe(change => {
      const requestType = change.selectedChangeConfiguration.requestType;
      if (requestType === MasterdataChangeRequestType.EXTERNAL) {
        this.masterDataStore.dispatch(
          SendMasterDataChangeRequest({ payload: change.masterDataChangeRequest })
        );
      } else if (requestType === MasterdataChangeRequestType.TICKET) {
        const ticketChangePayload: IMasterdataChangeTicket = {
          formResponses: ticketConverter.formatFormResponses(change.ticketCreationSummary),
          ticketCategoryId: change.selectedChangeConfiguration.ticketCategoryId,
        };
        this.masterDataStore.dispatch(SendMasterDataChangeTicket({ payload: ticketChangePayload }));
      }
    });
  }
}
