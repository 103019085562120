<ng-container formArrayName="detailQuestions">
  @for (question of questions(); track question.id; let questionIndex = $index) {
    <div class="mb-3">
      <div class="mb-1">
        {{ question.data.title }}
        @if (!question.data.required && question.data.type !== ScoreQuestionType.LABEL) {
          ({{ 'optional' | translate }})
        }
      </div>

      @if (question.answers.length > 0) {
        @for (answer of question.answers; track answer.id; let answerIndex = $index) {
          @switch (answer.data.type) {
            @case (ScoreQuestionType.INPUT_DATE) {
              <ng-container
                *ngTemplateOutlet="dateAnswer; context: { $implicit: questionIndex }"
              ></ng-container>
            }
            @case (ScoreQuestionType.SELECTION) {
              <ng-container
                *ngTemplateOutlet="
                  selectionAnswer;
                  context: { $implicit: answer, questionIndex: questionIndex }
                "
              ></ng-container>
            }
            @case (ScoreQuestionType.MULTISELECT) {
              <ng-container
                *ngTemplateOutlet="
                  multiselectAnswer;
                  context: {
                    $implicit: answer,
                    questionIndex: questionIndex,
                    answerIndex: answerIndex,
                  }
                "
              ></ng-container>
            }
            @case (ScoreQuestionType.INPUT_ATTACHMENTS) {
              <ng-container
                *ngTemplateOutlet="attachmentAnswer; context: { $implicit: questionIndex }"
              ></ng-container>
            }
            @case (ScoreQuestionType.INPUT_TEXT) {
              <ng-container
                *ngTemplateOutlet="
                  inputAnswer;
                  context: { $implicit: answer, questionIndex: questionIndex }
                "
              ></ng-container>
            }
          }
        }
      } @else {
        @switch (question.data.type) {
          @case (ScoreQuestionType.INPUT_DATE) {
            <ng-container
              *ngTemplateOutlet="dateAnswer; context: { $implicit: questionIndex }"
            ></ng-container>
          }
          @case (ScoreQuestionType.SELECTION) {
            <ng-container
              *ngTemplateOutlet="
                selectionAnswer;
                context: { $implicit: question, questionIndex: questionIndex }
              "
            ></ng-container>
          }
          @case (ScoreQuestionType.MULTISELECT) {
            <ng-container
              *ngTemplateOutlet="
                multiselectAnswer;
                context: {
                  $implicit: question,
                  questionIndex: questionIndex,
                }
              "
            ></ng-container>
          }
          @case (ScoreQuestionType.INPUT_ATTACHMENTS) {
            <ng-container
              *ngTemplateOutlet="attachmentAnswer; context: { $implicit: questionIndex }"
            ></ng-container>
          }
          @case (ScoreQuestionType.INPUT_TEXT) {
            <ng-container
              *ngTemplateOutlet="
                inputAnswer;
                context: { $implicit: question, questionIndex: questionIndex }
              "
            ></ng-container>
          }
        }
      }
    </div>
  }

  <ng-template #dateAnswer let-questionIndex>
    <rs-web-date-picker
      [formControl]="formArray().at(questionIndex) | rsWebCast: FormControl"
    ></rs-web-date-picker>
  </ng-template>

  <ng-template #selectionAnswer let-answer let-questionIndex="questionIndex">
    <label class="form-check form-check-label">
      <input
        [formControl]="formArray().at(questionIndex) | rsWebCast: FormControl"
        [value]="answer"
        class="form-check-input"
        name="answer"
        type="radio"
      />
      {{ answer.data.title }}
    </label>
  </ng-template>

  <ng-template
    #multiselectAnswer
    let-answer
    let-questionIndex="questionIndex"
    let-answerIndex="answerIndex"
  >
    <label class="form-check form-check-label">
      <input
        [formControl]="$any(formArray().at(questionIndex)).at(answerIndex)"
        [value]="answer"
        class="form-check-input"
        name="answer"
        type="checkbox"
      />
      {{ answer.data.title }}
    </label>
  </ng-template>

  <ng-template #attachmentAnswer let-questionIndex>
    <div class="mb-3">
      <!-- card user interacts with, will trigger hidden file input -->

      <rs-web-file-upload-button (click)="fileInput.click()"></rs-web-file-upload-button>
      <!-- real file input that is not shown to the user -->
      <input
        [formControl]="formArray().at(questionIndex) | rsWebCast: FormControl"
        #fileInput
        [accept]="acceptedFileTypes()"
        [additive]="true"
        class="d-none"
        multiple
        type="file"
      />
    </div>

    <div class="d-flex gap-1 mb-3">
      @for (
        file of $any(formArray().at(questionIndex).value) | rsWebFileList;
        track file.lastModified;
        let fileIndex = $index
      ) {
        @if (file.type.includes('image')) {
          <rs-web-ticket-image-attachment
            [file]="file"
            [maxFileSize]="MAX_FILE_SIZE"
            (remove)="removeAttachment(fileIndex, $any(formArray().at(questionIndex)))"
          ></rs-web-ticket-image-attachment>
        }
      }
    </div>

    <div class="d-flex flex-column gap-1">
      @for (
        file of $any(formArray().at(questionIndex).value) | rsWebFileList;
        track file.lastModified;
        let fileIndex = $index
      ) {
        @if (file.type.includes('pdf')) {
          <rs-web-pdf-attachment
            [file]="file"
            [maxFileSize]="MAX_FILE_SIZE"
            (remove)="removeAttachment(fileIndex, $any(formArray().at(questionIndex)))"
          ></rs-web-pdf-attachment>
        }
      }
    </div>
  </ng-template>

  <ng-template #inputAnswer let-answer let-questionIndex="questionIndex">
    @if (answer.data.multiline) {
      <textarea
        class="form-control"
        rows="3"
        [value]="answer"
        [formControl]="formArray().at(questionIndex) | rsWebCast: FormControl"
      ></textarea>
    } @else {
      <input
        type="text"
        class="form-control"
        [value]="answer"
        [formControl]="formArray().at(questionIndex) | rsWebCast: FormControl"
      />
    }
  </ng-template>
</ng-container>
