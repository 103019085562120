import { ModuleWithProviders, NgModule } from '@angular/core';
import { VarDirective } from './directives';
import { FACADES, GUARDS, SERVICES } from './services';
import {
  AddressPipe,
  ConsumptionIconPipe,
  ConsumptionMonthIsInAvailableDataRangePipe,
  ConsumptionMonthNamePipe,
  ConsumptionNamePipe,
  ConsumptionPeriodPipe,
  ConsumptionStepsPipe,
  ConsumptionUnitTypePipe,
  ConsumptionValuePipe,
  ContractIconPipe,
  ContractNamePipe,
  ContractTypeNamePipe,
  DateRangePipe,
  DisplayCurrencyPipe,
  ExcerptPipe,
  InputLabelOptionalPipe,
  MaxConsumptionPipe,
  PaymentDueStatusPipe,
  PaymentStatusBgPipe,
  PaymentStatusPipe,
  TicketStatusPipe,
  TicketTitlePipe,
  TimeAgoPipe,
  UserInitialsPipe,
  UsernamePipe,
  FileSizePipe,
  MasterDataDisplayTextPipe,
} from './pipes';

const PIPES = [
  ExcerptPipe,
  ConsumptionNamePipe,
  ConsumptionIconPipe,
  ConsumptionPeriodPipe,
  ConsumptionMonthIsInAvailableDataRangePipe,
  ConsumptionUnitTypePipe,
  DisplayCurrencyPipe,
  MaxConsumptionPipe,
  ConsumptionStepsPipe,
  FileSizePipe,
  ConsumptionValuePipe,
  UsernamePipe,
  UserInitialsPipe,
  PaymentStatusPipe,
  DateRangePipe,
  ConsumptionMonthNamePipe,
  TicketTitlePipe,
  TicketStatusPipe,
  PaymentStatusBgPipe,
  PaymentDueStatusPipe,
  ContractTypeNamePipe,
  InputLabelOptionalPipe,
  AddressPipe,
  ContractNamePipe,
  TimeAgoPipe,
  ContractIconPipe,
  FileSizePipe,
  MasterDataDisplayTextPipe,
];
const DIRECTIVES = [VarDirective];

@NgModule({
  imports: [PIPES, DIRECTIVES],
  exports: [PIPES, DIRECTIVES],
  providers: [...FACADES, ...SERVICES, ...PIPES],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...FACADES, ...GUARDS, ...SERVICES],
    };
  }
}
