import { endOfMonth, startOfMonth, subYears } from 'date-fns';

import {
  ByDeviceId,
  IConsumptionBenchmarkItemResponse,
  IConsumptionInfoItem,
  IConsumptionItem,
  IConsumptionsByPeriod,
  IContract,
  IMonth,
  IPeriod,
  ActionStateCreator,
  calculateSelectedConsumption,
  findSavedConsumptionDataForContract,
  findSavedConsumptionDataForDevice,
  IConsumptionState,
  splitPeriodToMonths,
  isSelectedPeriodAllowedAndAvailable,
  getSavedConsumption,
  consumptionTimeframeToAggregate,
  consumptionLegalFilter,
  consumptionConverter,
  ConsumptionFacade,
  ConsumptionsByContractR,
  ConsumptionTimeframe,
  ConsumptionDataVersion,
  Milliseconds,
  ConsumptionAggregateType,
} from '../../../../libs/shared';
import { catchError, forkJoin, map, Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';

export enum ConsumptionPhase {
  Consumption = 'consumption',
  PastConsumption = 'pastConsumption',
  Benchmark = 'benchmark',
}

@Injectable({ providedIn: 'root' })
export class ConsumptionHelpers {
  constructor(private consumptionFacade: ConsumptionFacade) {}

  static canShowPreviousArrow(
    contract: IContract,
    selectedPeriod: IPeriod,
    selectedMeter: IConsumptionInfoItem
  ): boolean {
    if (!contract?.contractStartTime) {
      return false;
    }

    // check month start dates to determine if the arrow can be shown
    // selectedPeriod.start is already start of month
    const contractStartMonth = startOfMonth(contract.contractStartTime).getTime();
    const selectedMeterFirstEntryMonth = startOfMonth(selectedMeter.firstEntry).getTime();

    return (
      contractStartMonth < selectedPeriod.start &&
      selectedMeterFirstEntryMonth < selectedPeriod.start
    );
  }

  static canShowNextArrow(
    contract: IContract,
    selectedPeriod: IPeriod,
    selectedMeter: IConsumptionInfoItem
  ): boolean {
    // check month end dates to determine if the arrow can be shown
    // selectedPeriod.end is already end of month
    const today: number = new Date().getTime();
    const selectedMeterLastEntryMonth = endOfMonth(selectedMeter.lastEntry).getTime();
    if (contract.contractEndTime) {
      const contractEndMonth: number = endOfMonth(contract.contractEndTime).getTime();
      return (
        today < contractEndMonth &&
        contractEndMonth > selectedPeriod.end &&
        selectedMeterLastEntryMonth > selectedPeriod.end
      );
    } else {
      return today > selectedPeriod.end && selectedMeterLastEntryMonth > selectedPeriod.end;
    }
  }

  static addConsumptionItemsToState({
    state,
    deviceIds,
    contract,
    responses,
    type,
  }: {
    state: IConsumptionState;
    deviceIds: string[];
    contract: IContract;
    responses: ByDeviceId<IConsumptionItem[] | IConsumptionBenchmarkItemResponse[]>;
    type: ConsumptionPhase;
  }) {
    if (!state.availableMeters) {
      return state;
    }

    const showPreviousArrow = { ...state.showPreviousArrow };
    const showNextArrow = { ...state.showNextArrow };
    const chartsMonths = { ...state.chartsMonths };
    const selectedConsumptions = { ...state.selectedConsumptions };
    const consumptionCache = { ...state.consumptionCache };

    const consumptionsMap =
      type === ConsumptionPhase.Consumption
        ? { ...state.consumption }
        : type === ConsumptionPhase.PastConsumption
          ? { ...state.pastConsumption }
          : { ...state.benchmark };

    const actionStatesMap =
      type === ConsumptionPhase.Consumption
        ? { ...state.actionStates.consumption }
        : type === ConsumptionPhase.PastConsumption
          ? { ...state.actionStates.pastConsumption }
          : { ...state.actionStates.benchmark };

    for (const deviceId of deviceIds) {
      const meter = state.selectedMeters.find(meter => meter.deviceId === deviceId);
      const selectedPeriod = state.periodsForCharts[deviceId];
      const response = responses[deviceId].filter(
        el => el.consumptionType.toLowerCase() === meter.type.toLowerCase()
      );

      const consumptionCacheForContract = findSavedConsumptionDataForContract(
        consumptionCache,
        contract.id
      );

      const consumptionCacheByMeter = findSavedConsumptionDataForDevice(
        consumptionCacheForContract,
        deviceId
      );

      const periodIndex = consumptionCacheByMeter.findIndex(
        el =>
          el.selectedPeriod.start === selectedPeriod.start &&
          el.selectedPeriod.end === selectedPeriod.end
      );

      const hasSavedData = periodIndex >= 0;

      let consumptionCacheByMeterForPeriod: IConsumptionsByPeriod = {
        selectedPeriod,
        consumption: [],
        pastConsumption: [],
        benchmark: [],
      };

      if (hasSavedData) {
        consumptionCacheByMeterForPeriod = {
          ...consumptionCacheByMeter[periodIndex],
        };
      }

      if (type === ConsumptionPhase.Consumption) {
        consumptionCacheByMeterForPeriod = {
          ...consumptionCacheByMeterForPeriod,
          consumption: response as IConsumptionItem[],
        };
      }

      if (type === ConsumptionPhase.PastConsumption) {
        consumptionCacheByMeterForPeriod = {
          ...consumptionCacheByMeterForPeriod,
          pastConsumption: response as IConsumptionItem[],
        };
      }

      if (type === ConsumptionPhase.Benchmark) {
        consumptionCacheByMeterForPeriod = {
          ...consumptionCacheByMeterForPeriod,
          benchmark: response as IConsumptionBenchmarkItemResponse[],
        };
      }

      if (hasSavedData) {
        consumptionCacheByMeter[periodIndex] = consumptionCacheByMeterForPeriod;
      } else {
        consumptionCacheByMeter[consumptionCacheByMeter.length] = consumptionCacheByMeterForPeriod;
      }

      consumptionCacheForContract[deviceId] = consumptionCacheByMeter;
      consumptionCache[contract.id] = consumptionCacheForContract;

      if (type === ConsumptionPhase.Consumption) {
        showPreviousArrow[deviceId] = ConsumptionHelpers.canShowPreviousArrow(
          contract,
          selectedPeriod,
          meter
        );
        showNextArrow[deviceId] = ConsumptionHelpers.canShowNextArrow(
          contract,
          selectedPeriod,
          meter
        );
      }

      let selectedMonth: IMonth;
      if (
        type === ConsumptionPhase.Consumption &&
        chartsMonths[deviceId] &&
        chartsMonths[deviceId].length
      ) {
        selectedMonth = chartsMonths[deviceId].find(el => el.selected);
      }
      if (type === ConsumptionPhase.Consumption) {
        chartsMonths[deviceId] = splitPeriodToMonths(selectedPeriod);
      }

      selectedConsumptions[deviceId] = calculateSelectedConsumption(
        consumptionCacheByMeterForPeriod,
        state.selectedConsumptionTimeframe[deviceId],
        selectedMonth
      );
      if (type === ConsumptionPhase.Consumption && selectedConsumptions[deviceId]) {
        const month = new Date(
          selectedConsumptions[deviceId]?.consumption?.period?.start
        ).getMonth();
        chartsMonths[deviceId].find(m => m.month === month) &&
          (chartsMonths[deviceId].find(m => m.month === month).selected = true);
      }

      consumptionsMap[deviceId] = response as
        | IConsumptionItem[]
        | IConsumptionBenchmarkItemResponse[];
      actionStatesMap[deviceId] = ActionStateCreator.onSuccess();
    }

    let newState: IConsumptionState = {
      ...state,
      selectedConsumptions,
      consumptionCache,
    };
    if (type === ConsumptionPhase.Consumption) {
      newState = {
        ...newState,
        showPreviousArrow,
        showNextArrow,
        chartsMonths,
        consumption: consumptionsMap as ByDeviceId<IConsumptionItem[]>,
        actionStates: {
          ...state.actionStates,
          consumption: actionStatesMap,
        },
      };
    }
    if (type === ConsumptionPhase.PastConsumption) {
      newState = {
        ...newState,
        pastConsumption: consumptionsMap as ByDeviceId<IConsumptionItem[]>,
        actionStates: {
          ...state.actionStates,
          pastConsumption: actionStatesMap,
        },
      };
    }

    if (type === ConsumptionPhase.Benchmark) {
      newState = {
        ...newState,
        benchmark: consumptionsMap as ByDeviceId<IConsumptionBenchmarkItemResponse[]>,
        actionStates: {
          ...state.actionStates,
          benchmark: actionStatesMap,
        },
      };
    }

    return newState;
  }

  /**
   * Requests consumption items for the provided deviceIds and periods
   * Uses the consumptionCache to prevent unnecessary requests
   * on success: returns the requested items,
   * on failure: throw rxjs catchable error providing the failed deviceIds and a message
   */
  public loadConsumptionData({
    selectedMeters,
    deviceIds,
    periodsForRequests,
    periodsForCharts,
    contract,
    consumptionCache,
    timeframe,
    consumptionDataVersion,
    type,
  }: {
    selectedMeters: IConsumptionInfoItem[];
    deviceIds: string[];
    periodsForRequests: ByDeviceId<IPeriod>;
    periodsForCharts: ByDeviceId<IPeriod>;
    contract: IContract;
    consumptionCache: ConsumptionsByContractR;
    timeframe: ByDeviceId<ConsumptionTimeframe>;
    consumptionDataVersion: ConsumptionDataVersion;
    type: ConsumptionPhase;
  }) {
    const meters = selectedMeters.filter(meter => deviceIds.includes(meter.deviceId));

    const failWithInvalidPeriod = (deviceId: string) => {
      const errorMessage = `Selected period not available for ${deviceId}`;
      const error = { name: errorMessage, message: errorMessage };
      return throwError(() => ({ deviceIds, error }));
    };

    if (meters.length === 0) {
      const errorMessage = 'No selected meters found for deviceIds';
      const error = {
        name: errorMessage,
        message: errorMessage,
      };
      return throwError(() => ({ deviceIds, error }));
    }

    const results$: ByDeviceId<
      Observable<IConsumptionItem[] | IConsumptionBenchmarkItemResponse[]>
    > = {};

    for (const meter of meters) {
      const periodForRequest = periodsForRequests[meter.deviceId];
      const periodForChart = periodsForCharts[meter.deviceId];

      let requestPeriod = periodForRequest;
      if (type === ConsumptionPhase.PastConsumption) {
        requestPeriod = {
          start: subYears(periodForRequest.start, 1).getTime(),
          end: subYears(periodForRequest.end, 1).getTime(),
        };

        if (
          requestPeriod.end < contract.contractStartTime ||
          requestPeriod.end < meter.firstEntry
        ) {
          return failWithInvalidPeriod(meter.deviceId);
        }

        if (
          requestPeriod.start < contract.contractStartTime ||
          requestPeriod.start < meter.firstEntry
        ) {
          requestPeriod.start = Math.max(contract.contractStartTime, meter.firstEntry);
        }
      }

      if (
        !isSelectedPeriodAllowedAndAvailable(contract, requestPeriod, meter) &&
        type !== ConsumptionPhase.PastConsumption
      ) {
        return failWithInvalidPeriod(meter.deviceId);
      }

      const cacheForPeriod = getSavedConsumption(
        consumptionCache,
        contract.id,
        meter.deviceId,
        periodForChart
      );

      const aggregate = consumptionTimeframeToAggregate(timeframe[meter.deviceId]);

      const cachedItems =
        type === ConsumptionPhase.Consumption
          ? cacheForPeriod?.consumption
          : type === ConsumptionPhase.PastConsumption
            ? cacheForPeriod?.pastConsumption
            : cacheForPeriod?.benchmark;

      const request$ = this.createConsumptionRequest({
        type,
        requestPeriod,
        contract,
        aggregate,
        consumptionDataVersion,
        meter,
      });

      results$[meter.deviceId] = cachedItems?.length ? of(cachedItems) : request$;
    }

    return forkJoin(results$).pipe(
      map((responses: ByDeviceId<IConsumptionItem[] & IConsumptionBenchmarkItemResponse[]>) => ({
        responses,
        contract,
        deviceIds,
      })),
      catchError(error => throwError(() => ({ deviceIds, error })))
    );
  }

  private createConsumptionRequest({
    type,
    requestPeriod,
    contract,
    meter,
    aggregate,
    consumptionDataVersion,
  }: {
    type: ConsumptionPhase;
    requestPeriod: { start: Milliseconds; end: Milliseconds };
    contract: IContract;
    meter: IConsumptionInfoItem;
    aggregate: ConsumptionAggregateType;
    consumptionDataVersion: ConsumptionDataVersion;
  }): Observable<IConsumptionItem[] | IConsumptionBenchmarkItemResponse[]> {
    if (type === ConsumptionPhase.Benchmark) {
      return this.consumptionFacade.getSelectedPeriodConsumptionBenchmark(
        requestPeriod,
        contract.id,
        meter,
        aggregate,
        consumptionDataVersion
      );
    }

    return this.consumptionFacade
      .getSelectedPeriodConsumption(
        requestPeriod,
        contract.id,
        meter,
        aggregate,
        consumptionDataVersion
      )
      .pipe(
        map(response => consumptionLegalFilter(response, contract)),
        map(response => consumptionConverter.fromDto(response))
      );
  }
}
