import { Directive, ElementRef, HostListener, inject } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Directive({
  selector: 'input[type=file]',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: FileUploadValueAccessorDirective, multi: true },
  ],
  standalone: true,
})
export class FileUploadValueAccessorDirective implements ControlValueAccessor {
  inputField = inject(ElementRef<HTMLInputElement>);
  value: unknown;
  onChange: (value: FileList) => void;
  onTouched: () => void;

  @HostListener('change', ['$event.target.files'])
  emitFiles(event: FileList) {
    this.onChange(event.length ? event : null);
  }

  @HostListener('blur')
  onBlur() {
    this.onTouched();
  }

  writeValue(value: FileList) {
    if (value) {
      this.inputField.nativeElement.files = value;
    }
    if (value === null) {
      this.inputField.nativeElement.files = new DataTransfer().files;
    }
  }
  registerOnChange(fn: (value: FileList) => void) {
    this.onChange = fn;
  }
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }
}
