import { ChangeDetectionStrategy, Component, computed, inject, input, Signal } from '@angular/core';
import { ControlContainer, FormArray, FormControl, ReactiveFormsModule } from '@angular/forms';
import {
  FileSizePipe,
  getConcernTicketFileInputAccept,
  getDamageTicketFileInputAccept,
  IHierarchicalQuestionTicketOverview,
  ImmomioIconName,
  ScoreQuestionType,
  TicketIssueType,
} from '@resident-nx/shared';
import { NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormControlErrorsDirective } from '../../../../../directives/form/form-control-errors.directive';
import { FileUploadValueAccessorDirective } from '../../../../../directives/form/file-upload-value-accessor.directive';
import { ControlErrorContainerDirective } from '../../../../../directives/form/form-control-error-container.directive';
import { FileListPipe } from '../../../../../pipes/file-list.pipe';
import { IconWebComponent } from '../../../../atoms/icon/icon.component';
import { ImageWebComponent } from '../../../../atoms/image/image.component';
import { DatePickerComponent } from '../../../../atoms/date-picker/date-picker.component';
import { ImageBorderRadiusEnum } from '../../../../atoms/image/image.model';
import { CardWebComponent } from '../../../../atoms/card/card.component';
import { Elevation } from '../../../../../directives/elevation/elevation.model';
import { TicketImageAttachmentComponent } from '../../../../molecules/ticket/ticket-image-attachment/ticket-image-attachment.component';
import { MAX_FILE_SIZE } from '../../../../../services/tickets/ticket-creation.service';
import { Store } from '@ngrx/store';
import { PdfAttachmentComponent } from '../../../../molecules/pdf-attachment/pdf-attachment.component';
import { FileUploadButtonComponent } from '../../../../atoms/file-upload-button/file-upload-button.component';
import { TicketHelperService } from '../../../../../services/tickets/ticket-helper.service';
import { CastPipe } from '../../../../../pipes/cast.pipe';

@Component({
  selector: 'rs-web-ticket-detail-questions',
  standalone: true,
  templateUrl: './ticket-detail-questions.component.html',
  styleUrl: './ticket-detail-questions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
  imports: [
    NgTemplateOutlet,
    ReactiveFormsModule,
    FormControlErrorsDirective,
    FileUploadValueAccessorDirective,
    ControlErrorContainerDirective,
    IconWebComponent,
    ImageWebComponent,
    FileListPipe,
    DatePickerComponent,
    TranslateModule,
    CardWebComponent,
    FileSizePipe,
    TicketImageAttachmentComponent,
    PdfAttachmentComponent,
    FileUploadButtonComponent,
    CastPipe,
  ],
})
export class TicketDetailQuestionsComponent {
  #ticketHelperService = inject(TicketHelperService);
  #store = inject(Store);
  protected readonly ScoreQuestionType = ScoreQuestionType;
  protected readonly ImmomioIconName = ImmomioIconName;
  protected readonly ImageBorderRadiusEnum = ImageBorderRadiusEnum;
  protected readonly Elevation = Elevation;
  protected readonly FormControl = FormControl;
  protected readonly File = File;
  public MAX_FILE_SIZE = MAX_FILE_SIZE;
  public questions = input.required<IHierarchicalQuestionTicketOverview[]>();
  public formArray = input.required<FormArray<FormControl | FormArray<FormControl>>>();
  public ticketType = input.required<TicketIssueType>();

  public damageTicketFileInputAccept = this.#store.selectSignal(getDamageTicketFileInputAccept);
  public concernTicketFileInputAccept = this.#store.selectSignal(getConcernTicketFileInputAccept);

  public acceptedFileTypes: Signal<string> = computed(() => {
    if (this.ticketType() === TicketIssueType.CONCERN) {
      return this.concernTicketFileInputAccept();
    }
    if (this.ticketType() === TicketIssueType.PROPERTY_DAMAGE) {
      return this.damageTicketFileInputAccept();
    }
    return '';
  });

  removeAttachment(index: number, attachmentControl: FormControl): void {
    const current: File[] = [...attachmentControl.value];
    const files = current.toSpliced(index, 1);
    // only way to create a new value instance for <input type="file" multiple>
    // needed to keep file size validation working as expected
    const fileList = this.#ticketHelperService.createFileListFromArray(files);
    attachmentControl.patchValue(fileList);
  }
}
