<rs-web-card class="col-lg-4 col-xxl-3" [borderStyle]="'none'">
  <div class="d-flex align-items-start gap-2">
    <rs-web-icon [icon]="ImmomioIconName.Info"></rs-web-icon>
    <div class="d-flex flex-column gap-1">
      <span class="notice-title">
        {{ 'information_notice_title_l' | translate }}
      </span>
      <span class="primary-text">{{ 'information_notice_description' | translate }}</span>
    </div>
  </div>
</rs-web-card>
