import { Directive, HostBinding, HostListener, Input, OnChanges } from '@angular/core';
import { ELEVATION_DEFAULT_CONFIG, ELEVATION_MAX_VALUE } from './elevation.config';
import { Elevation, ElevationType } from './elevation.model';

const isTouchScreen = (): boolean => 'ontouchstart' in window || navigator.maxTouchPoints > 0;

@Directive({
  selector: '[rsWebElevation]',
  standalone: true,
})
export class ElevationDirective implements OnChanges {
  @Input() elevation = ELEVATION_DEFAULT_CONFIG.elevation;
  @Input() elevationChangeStepOnHover = ELEVATION_DEFAULT_CONFIG.elevationChangeStepOnHover;
  @Input() elevationChangeStepOnClick = ELEVATION_DEFAULT_CONFIG.elevationChangeStepOnClick;
  @Input() elevationChangeStepOnTouch = ELEVATION_DEFAULT_CONFIG.elevationChangeStepOnTouch;
  @Input() clickable = ELEVATION_DEFAULT_CONFIG.clickable; // = with hover & click effect for non-touch devices + touch effect for touch devices
  public isHoverActive = false;
  public isMouseDownActive = false;
  public isTouchActive = false;

  @HostBinding('class') class = this.getClasses();

  ngOnChanges(): void {
    this.updateClasses();
  }

  @HostListener('mouseenter') mouseEnter() {
    this.isHoverActive = true;
    this.updateClasses();
  }

  @HostListener('mouseleave') mouseLeave() {
    this.isHoverActive = false;
    this.isMouseDownActive = false;
    this.updateClasses();
  }

  @HostListener('mousedown') mouseDown() {
    this.isMouseDownActive = true;
    this.updateClasses();
  }

  @HostListener('mouseup') mouseUp() {
    this.isMouseDownActive = false;
    this.updateClasses();
  }

  @HostListener('touchstart') touchStart() {
    this.isTouchActive = true;
    this.updateClasses();
  }

  @HostListener('touchmove') touchMove() {
    this.isTouchActive = false;
    this.updateClasses();
  }

  @HostListener('touchend') touchEnd() {
    this.isTouchActive = false;
    this.updateClasses();
  }

  public updateClasses(): void {
    this.class = this.getClasses();
  }

  public getClasses(): string[] {
    const transition: string[] = this.clickable ? ['mdc-elevation-transition'] : [];

    const activeElevations = Object.values(Elevation)
      .filter(e => this.isElevationActive(e))
      .map(e => `mdc-elevation--z${e}`);

    return [...transition, ...activeElevations];
  }

  public isElevationActive(elevation: ElevationType): boolean {
    const baseElevation = this.elevation;
    const elevationIsBaseElevation = baseElevation === elevation;

    if (isTouchScreen()) {
      const touchState = this.clickable && this.isTouchActive;
      if (
        touchState &&
        !(
          elevationIsBaseElevation &&
          elevation + this.elevationChangeStepOnTouch > ELEVATION_MAX_VALUE
        )
      ) {
        return baseElevation === elevation - this.elevationChangeStepOnTouch;
      } else {
        return elevationIsBaseElevation;
      }
    } else {
      const hoverState = this.clickable && this.isHoverActive;
      const clickState = this.clickable && this.isMouseDownActive;
      if (
        clickState &&
        !(
          elevationIsBaseElevation &&
          elevation + this.elevationChangeStepOnClick > ELEVATION_MAX_VALUE
        )
      ) {
        return baseElevation === elevation - this.elevationChangeStepOnClick;
      } else if (
        hoverState &&
        !(
          elevationIsBaseElevation &&
          elevation + this.elevationChangeStepOnHover > ELEVATION_MAX_VALUE
        )
      ) {
        return baseElevation === elevation - this.elevationChangeStepOnHover;
      } else {
        return elevationIsBaseElevation;
      }
    }
  }
}
