import { Directive } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs';

import {
  AuthService,
  contractStatusConverter,
  getContracts,
  getMasterDataChangeConfiguration,
  getResidentAppSettings,
  IMasterdataChangeConfiguration,
  LoadMasterDataChangeConfiguration,
  masterdataHelper,
} from '../../../shared';
import { CustomerService, RouterService } from '../../services';

@Directive()
export class UserPageBaseComponent {
  public showProfileButton$: Observable<boolean>;
  public masterDataChangeConfiguration: IMasterdataChangeConfiguration[] | null;

  constructor(
    public routerService: RouterService,
    protected activeRoute: ActivatedRoute,
    public authService: AuthService,
    private store: Store,
    private customerService: CustomerService
  ) {
    this.store.dispatch(LoadMasterDataChangeConfiguration());
    this.showProfileButton$ = combineLatest([
      this.store.select(getContracts),
      this.store.select(getResidentAppSettings),
      this.store.select(getMasterDataChangeConfiguration),
    ]).pipe(
      map(([contracts, residentAppSettings, masterDataChangeConfiguration]) => {
        const activeContract =
          contracts?.length && contractStatusConverter.isAnyContractActive(contracts);
        const contractlessTicketCreation =
          residentAppSettings?.allowContractlessTicketCreationForOtherConcerns ?? false;
        const disabledTicketManagement = this.customerService.hideTicketManagement();

        this.masterDataChangeConfiguration = masterDataChangeConfiguration;

        return masterdataHelper.checkShowChangeButton(
          masterDataChangeConfiguration,
          activeContract,
          contractlessTicketCreation,
          disabledTicketManagement
        );
      })
    );
  }

  public goToProfile() {
    void this.routerService.navigate(['profile'], { relativeTo: this.activeRoute });
  }

  public goToPrivacy() {
    void this.routerService.navigate(['privacy'], { relativeTo: this.activeRoute });
  }

  public goToDelete() {
    void this.routerService.navigate(['delete'], { relativeTo: this.activeRoute });
  }

  public goToLegal() {
    void this.routerService.navigate(['legal'], { relativeTo: this.activeRoute });
  }

  public goToFaq() {
    void this.routerService.navigate(['faq'], { relativeTo: this.activeRoute });
  }

  public goBack(): void {
    this.routerService.back();
  }
}
