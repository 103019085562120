@if (contacts().length === 0) {
  <rs-web-side-sheet-form
    #sideSheetFormComponent="rsWebSideSheetForm"
    [formGroup]="form"
    [showBackButton]="true"
    [showNav]="!isXsBreakpoint()"
    [loading]="
      saveTicketActionState?.pending || (detailQuestionsActionState$ | async)?.pending === true
    "
    [doneButtonText]="'ticket_creation.create_ticket_a'"
    (dismiss)="onDismiss($event)"
  >
    <span sidesheet-title class="secondary-text">
      {{
        (type === TicketIssueType.PROPERTY_DAMAGE
          ? 'ticket_damage.create_new_b'
          : 'ticket_request.create_new_b'
        ) | translate
      }}</span
    >

    @if ((contracts$ | async)?.length > 1) {
      <ng-template
        rsWebSideSheetContent
        [label]="'main_navigation.contracts_l'"
        [currentStepValid]="(form.controls.contract.statusChanges | async) === 'VALID'"
        (next)="sideSheetFormComponent.nextTab()"
      >
        <div class="d-flex gap-2 mb-3 font-bold">
          <span class="primary-text">{{ 'consumption.select_contract_l' | translate }}</span>
        </div>
        <rs-web-select-contract
          class="primary-text"
          (contractChange)="onContractChange()"
        ></rs-web-select-contract>
      </ng-template>
    }

    <ng-template
      rsWebSideSheetContent
      [label]="'ticket_creation.categories_tab_l'"
      [currentStepValid]="(form.controls.categories.statusChanges | async) === 'VALID'"
      [customDoneButtonText]="'next'"
      (done)="onCategoriesStepDone(sideSheetFormComponent)"
      (next)="onCategoriesStepDone(sideSheetFormComponent)"
    >
      <rs-web-ticket-categories
        class="primary-text"
        (categoryQuestionChange)="onCategoryQuestionChange()"
      ></rs-web-ticket-categories>
    </ng-template>

    @if (showDetailStep()) {
      <ng-template
        rsWebSideSheetContent
        [label]="'ticket_creation.detail_questions_tab_l'"
        [currentStepValid]="form.controls.detailQuestions.status === 'VALID'"
        (next)="sideSheetFormComponent.nextTab()"
      >
        <rs-web-ticket-detail-questions
          [formArray]="detailQuestionsFormArray"
          [questions]="detailQuestionsResponse.detailRootQuestion.questions"
          [ticketType]="type"
          class="primary-text"
        ></rs-web-ticket-detail-questions>
      </ng-template>
    }

    @if (showSummaryStep()) {
      <ng-template rsWebSideSheetContent [label]="'ticket_summary.overview_l'" (done)="onDone()">
        <rs-web-ticket-summary></rs-web-ticket-summary>
      </ng-template>
    }
  </rs-web-side-sheet-form>
} @else {
  <rs-web-ticket-direct-assignment
    [title]="
      type === TicketIssueType.PROPERTY_DAMAGE
        ? 'ticket_damage.create_new_b'
        : 'ticket_request.create_new_b'
    "
    [contacts]="contacts()"
  ></rs-web-ticket-direct-assignment>
}
