import { createReducer, on } from '@ngrx/store';

import { ActionStateCreator, IActionState } from '../../../utils';
import * as fromActions from './app-settings.actions';
import { IAppSettings } from '../../../models';

export interface IAppSettingsState {
  appSettings: IAppSettings;
  appSettingsActionState: IActionState; // Todo: reset themeActionState on customerIdent change
}

export const initialState: IAppSettingsState = {
  appSettings: {
    theme: null,
    logo: null,
    landingPageImage: null,
  },
  appSettingsActionState: ActionStateCreator.create(),
};

export const appSettingsReducer = createReducer(
  initialState,

  on(fromActions.LoadAppSettings, state => {
    return {
      ...state,
      appSettingsActionState: ActionStateCreator.onStart(),
    };
  }),

  on(fromActions.LoadAppSettingsSuccess, (state, { appSettings }) => {
    return {
      ...state,
      appSettings,
      appSettingsActionState: ActionStateCreator.onSuccess(),
    };
  }),

  on(fromActions.LoadAppSettingsFailed, (state, { error }) => {
    return {
      ...state,
      appSettingsActionState: ActionStateCreator.onError(error),
    };
  })
);

export const getAppSettings = (state: IAppSettingsState) => state.appSettings;
export const getTheme = (state: IAppSettingsState) => state.appSettings.theme;
export const getLogo = (state: IAppSettingsState) => state.appSettings.logo;
export const getAppSettingsActionState = (state: IAppSettingsState) => state.appSettingsActionState;
