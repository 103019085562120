import { Directive, HostListener } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, interval, map, Observable, Subject, takeUntil } from 'rxjs';

import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import * as fromTicketState from '../../../+state/tickets';
import * as fromMasterdataState from '../../../+state/account/masterdata';
import * as fromPermissionState from '../../../+state/account/permissions';
import {
  IContract,
  ImmomioIconName,
  ITicketConversationEdit,
  IHQTicketDetailsResponse,
  ITicketMessageGroup,
  TicketingVersion,
  IMetadataTicketDetailsResponse,
  ITicketMessage,
} from '../../../models';
import { RouterService } from '../../../services';
import { IActionState } from '../../../utils';

@UntilDestroy()
@Directive()
export class TicketChatPageBaseComponent {
  public ticketDetails: IHQTicketDetailsResponse | IMetadataTicketDetailsResponse;
  public ticketChat$: Observable<ITicketMessageGroup[]>;
  public ticketChatActionState$: Observable<IActionState>;
  public newMessageActionState$: Observable<IActionState>;
  public selectedContract$: Observable<IContract>;
  public immomioIconName = ImmomioIconName;
  public ticketingVersion$: Observable<TicketingVersion> = this.masterdataStore
    .select(fromPermissionState.getResidentAppSettings)
    .pipe(
      map(response => response?.ticketingVersion),
      filter(response => response !== null)
    );
  protected destroy$?: Subject<boolean>;
  private ticketId: string;
  private updateInterval = 30000; // 30 seconds

  constructor(
    public route: ActivatedRoute,
    public routerService: RouterService,
    protected ticketStore: Store<fromTicketState.ITicketsState>,
    private masterdataStore: Store<fromMasterdataState.IMasterDataState>
  ) {}

  @HostListener('loaded')
  pageOnInit() {
    if (!this.destroy$) {
      this.destroy$ = new Subject();
    }
    this.selectedContract$ = this.masterdataStore.select(fromMasterdataState.getSelectedContract);
    if (this.route?.snapshot?.params) {
      this.ticketId = this.route.snapshot.params.id;
      this.getTicketDetails();
      this.loadTicketChat();
      this.updateChat();
    }
    this.ticketChat$ = this.ticketStore.select(fromTicketState.getTicketChat);
    this.ticketChatActionState$ = this.ticketStore.select(fromTicketState.getTicketChatActionState);
    this.newMessageActionState$ = this.ticketStore.select(fromTicketState.getNewMessageActionState);
  }

  @HostListener('unloaded')
  pageDestroy() {
    if (this.destroy$) {
      this.destroy$.next(true);
      this.destroy$.complete();
    }
  }

  public sendMessage(newMessage: string | ITicketMessage): void {
    // TODO: Has to be updated for retry
    const message: ITicketConversationEdit = {
      ticketId: this.ticketId,
      message: newMessage as string,
      attachments: [],
    };

    this.ticketStore.dispatch(fromTicketState.AddMessageToTicket({ message }));
  }

  public goBack(): void {
    void this.routerService.navigate(['content', 'concern']);
  }

  public refreshList(args): void {
    const pullRefresh = args.object;
    this.loadTicketChat();
    pullRefresh.refreshing = false;
  }

  private getTicketDetails(): void {
    this.ticketingVersion$.pipe(untilDestroyed(this)).subscribe(version => {
      if (version === TicketingVersion.HIERARCHICAL) {
        this.getHQTicketDetails();
      } else if (version === TicketingVersion.METADATA) {
        this.getMetadataTicketDetails();
      }
    });
  }

  private getHQTicketDetails(): void {
    this.ticketStore
      .select(fromTicketState.getTicketDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response) {
          this.ticketDetails = response;
        }
      });
  }

  private getMetadataTicketDetails(): void {
    this.ticketStore
      .select(fromTicketState.getMetadataTicketDetails)
      .pipe(takeUntil(this.destroy$))
      .subscribe(response => {
        if (response) {
          this.ticketDetails = response;
        }
      });
  }

  private loadTicketChat(): void {
    this.ticketStore.dispatch(fromTicketState.LoadTicketChat({ ticketId: this.ticketId }));
  }

  private updateChat(): void {
    interval(this.updateInterval)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.loadTicketChat());
  }
}
