import {
  IContactPersonResponse,
  IKeyValue,
  IMetadataProperties,
  IResidentS3File,
  ITicketImage,
  ScoreQuestionType,
} from './../';

export type RootQuestionId = string; // id of ticket categories response
export type CategoryId = string; //id of detailed questions response
export type DetailRootQuestionId = string; //detailRootQuestion.id of detailed questions response
export type QuestionId = string; // id of ticket categories question
export type AnswerId = string; // id of ticket categories question
export type CategoryAnswerSetRelationId = string; //new id replacing CategoryId for MetadataTickets

export interface ITicketResidentCategory {
  id: CategoryId;
  name: string;
  categoryAnswerSetRelationId: CategoryAnswerSetRelationId;
  detailRootQuestion: IRootQuestionTicketOverview;
}

export interface IRootQuestionTicketOverview {
  id: string;
  mainQuestionIds: QuestionId[];
  questions: IHierarchicalQuestionTicketOverview[];
  meta: IMetadataProperties[];
}

export interface IHierarchicalQuestionTicketOverview {
  id: QuestionId;
  data: ITicketQuestionData;
  answers: IHierarchicalQuestionAnswerTicketOverview[];
}

export interface ITicketQuestionData {
  type: ScoreQuestionType;
  title: string;
  order: number;
  required: boolean;
  maxAnswers: number;
  meta: IMetadataProperties[];
}

export interface IHierarchicalQuestionAnswerTicketOverview {
  id: AnswerId;
  data: ITicketAnswerData;
  questionIds: QuestionId[];
}

export interface ITicketAnswerData {
  type: ScoreQuestionType;
  order: number;
  title: string;
  multiline: boolean;
  meta: IMetadataProperties[];
}

export interface IHierarchicalQuestionTicketResponseOverviewInput {
  questionId: QuestionId;
  selectionData?: IScoreResponseSelectionInput;
  rangeNumberData?: IScoreResponseRangeNumberInput;
  rangeDateData?: IScoreResponseRangeDateInput;
  textData?: IScoreResponseInputTextInput;
  dateData?: IScoreResponseInputDateInput;
  attachments?: IScoreResponseInputAttachmentInput;
}

export interface IScoreResponseData {
  type: ScoreQuestionType;
  comment?: string;
}

export interface IScoreResponseSelectionInput extends IScoreResponseData {
  answerIds: AnswerId[];
}

export interface IScoreResponseRangeNumberInput extends IScoreResponseData {
  response: number;
}

export interface IScoreResponseRangeDateInput extends IScoreResponseData {
  response: string;
}

export interface IScoreResponseInputTextInput extends IScoreResponseData {
  response: string;
}

export interface IScoreResponseInputDateInput extends IScoreResponseData {
  response: string;
}

export interface IScoreResponseInputAttachmentInput extends IScoreResponseData {
  response: IResidentS3File[];
}

export interface IRootQuestionResponseTicketShortInput {
  rootQuestionId: RootQuestionId | DetailRootQuestionId;
  responses: IHierarchicalQuestionTicketResponseOverviewInput[];
}

export interface ITicketCreationSummary {
  name: string;
  rootQuestionId: RootQuestionId;
  categoryId: CategoryId;
  detailedQuestionId: DetailRootQuestionId;
  responses: ITicketCreationSummaryItem[];
  detailedResponses: ITicketCreationSummaryItem[];
  directAssignmentContacts: IContactPersonResponse[];
  categoryAnswerSetRelationId: CategoryAnswerSetRelationId;
}

export interface ITicketCreationSummaryItem {
  response: IHierarchicalQuestionTicketResponseOverviewInput;
  displaySummary: IDisplaySummary;
  items: IKeyValue<string>[];
  title: string;
  type: ScoreQuestionType;
  required: boolean;
  multiline: boolean;
  id: string;
}

export interface IDisplaySummary {
  questionTitle: string;
  answerTitle: string | string[] | number;
  attachments: ITicketImage[] | null;
  type?: ScoreQuestionType;
  order?: number;
}

export class TicketCreationSummary implements ITicketCreationSummary {
  name: string;
  rootQuestionId: string;
  categoryId: string;
  detailedQuestionId: string;
  responses: ITicketCreationSummaryItem[];
  detailedResponses: ITicketCreationSummaryItem[];
  directAssignmentContacts: IContactPersonResponse[] | null;
  categoryAnswerSetRelationId: string;

  constructor(initialValues?: Partial<ITicketCreationSummary>) {
    this.name = initialValues?.name ?? '';
    this.rootQuestionId = initialValues?.rootQuestionId ?? '';
    this.categoryId = initialValues?.categoryId ?? '';
    this.detailedQuestionId = initialValues?.detailedQuestionId ?? '';
    this.responses = initialValues?.responses ?? [];
    this.detailedResponses = initialValues?.detailedResponses ?? [];
    this.directAssignmentContacts = initialValues?.directAssignmentContacts ?? null;
    this.categoryAnswerSetRelationId = initialValues?.categoryAnswerSetRelationId ?? '';
  }
}
