import { DestroyRef, Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  getContracts,
  getContractsActionState,
  getSelectedContract,
  getTheme,
  LoadContracts,
} from '../../+state';
import {
  EnhancedContract,
  HexColorCode,
  IChartData,
  IChartDataItem,
  IContract,
  ImmomioIconName,
  ITheme,
  LoadingType,
  PaymentType,
} from '../../models';
import { getSimilarColors } from '../../utils';

const paymentKeys = [
  { target: PaymentType.RENT, translateKey: 'contract.payment.type.rent' },
  {
    target: PaymentType.HEATING_PREPAYMENT,
    translateKey: 'contract.payment.type.heating_prepayment',
  },
  {
    target: PaymentType.SERVICE_PAYMENT,
    translateKey: 'contract.payment.type.service_payment',
  },
  {
    target: PaymentType.PARKING,
    translateKey: 'garage_parking_space',
  },
  {
    target: PaymentType.OTHER,
    translateKey: 'contract.payment.type.others',
  },
];

@Directive()
export class ContractsBaseComponent {
  public selectedContractIndex: number;
  public immomioIconName = ImmomioIconName;
  public allContracts: EnhancedContract[] = [];
  public loadingTypes: LoadingType[] = [];
  protected contracts$: Observable<IContract[]> = this.store.select(getContracts);
  protected theme$: Observable<ITheme> = this.store.select(getTheme);
  protected selectedContract$ = this.store.select(getSelectedContract);
  protected contractsActionState$ = this.store.select(getContractsActionState);

  constructor(
    protected store: Store,
    protected destroyRef: DestroyRef
  ) {}

  public prepareChartData(contract: IContract, baseColor): IChartData {
    const colors: HexColorCode[] = getSimilarColors(baseColor, contract.payments.length);
    const set: IChartDataItem[] = contract.payments.map((obj, i) => {
      let label = obj.type.toString();
      const amount = obj.cost;
      const paymentKey = paymentKeys.find(pk => pk.target == obj.type);
      if (paymentKey) {
        label = paymentKey.translateKey;
      }

      return { label, amount, color: colors[i] };
    });

    set.sort((a, b) => {
      const aIndex = paymentKeys.findIndex(key => key.translateKey === a.label);
      const bIndex = paymentKeys.findIndex(key => key.translateKey === b.label);
      return aIndex - bIndex;
    });

    return {
      label: contract.externalId,
      set: set,
    };
  }

  public calculateRent(sets: IChartDataItem[]): number {
    return sets.reduce((acc, prev) => acc + prev.amount, 0);
  }

  protected loadContracts(forced: boolean): void {
    this.store.dispatch(LoadContracts({ forced: forced }));
  }
}
