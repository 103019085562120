import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  AuthTokenService,
  FileDownloadService,
  IActionState,
  IEnvironment,
  IResidentS3File,
  ISharedDocumentsResponse,
  StorageService,
} from '@resident-nx/shared';
import { BehaviorSubject, catchError, map, Observable, of, startWith } from 'rxjs';

@Injectable()
export class FileDownloadWebService extends FileDownloadService {
  public documentDownloadActionState$: BehaviorSubject<IActionState>;

  constructor(
    private authTokenService: AuthTokenService,
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private storageService: StorageService
  ) {
    super();
  }

  private createRequestForDocument(document: ISharedDocumentsResponse) {
    const selectedCustomerIdent = this.storageService.getItem('userApps')['selectedCustomerIdent'];
    const url = this.environment.filerUrl + `documentManagement/download` + `/${document.id}`;
    return {
      url,
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + this.authTokenService.getToken().accessToken,
        'X-Customer-Ident': selectedCustomerIdent,
      },
    };
  }

  public getDocument(
    documentForDownload: ISharedDocumentsResponse
  ): Observable<{ preview: SafeResourceUrl; download: string }> {
    const request = this.createRequestForDocument(documentForDownload);
    return this.http
      .get(request.url, {
        headers: request.headers,
        responseType: 'blob',
      })
      .pipe(
        map(response => {
          const type =
            documentForDownload.extension === 'pdf' ? 'application/pdf' : 'application/json';
          const blob = new Blob([response], { type });
          const blobUrl = URL.createObjectURL(blob);
          return {
            preview: this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl),
            download: blobUrl,
          };
        })
      );
  }

  public getAnnoucementAttachment(file: IResidentS3File) {
    // using just url for pdf doesnt work, using blob displays json...
    // TODO: Fix the issue mentioned above
    return of({
      preview: this.sanitizer.bypassSecurityTrustResourceUrl(file.url),
      download: file.url,
    });
  }

  //TODO rename to downloadDocument when the FileDownloadService gets refactored
  public download(documentUrl: string, documentName: string) {
    const element = document.createElement('a');
    element.href = documentUrl;
    element.download = documentName;
    element.click();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadDocument(): Observable<any> {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public downloadFile(_residentS3File: IResidentS3File): Observable<any> {
    return undefined;
  }

  public downloadTicketAttachment(
    ticketId: string,
    attachmentIdentifier: string
  ): Observable<string> {
    const selectedCustomerIdent = this.storageService.getItem('userApps')['selectedCustomerIdent'];
    const url = this.environment.filerUrl + `download/ticketAttachment`;
    const body = {
      ticketId,
      attachmentIdentifier,
    };

    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.authTokenService.getToken().accessToken,
      'X-Customer-Ident': selectedCustomerIdent,
      'Content-Type': 'application/json',
    });

    return this.http.post(url, body, { headers, responseType: 'blob' }).pipe(
      map(blob => {
        return URL.createObjectURL(blob);
      }),
      catchError(error => {
        throw new Error(error.message);
      }),
      startWith('pending')
    );
  }
}
