import { Directive, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, take } from 'rxjs';
import * as fromTicketState from '../../../+state/tickets';
import {
  CAMERA_DIALOG_ACTIONS,
  ITicketAttachment,
  ITicketCreationSummary,
  ITicketCreationSummaryItem,
  ScoreQuestionType,
  TicketIssueType,
} from '../../../models';
import { IActionState } from '../../../utils';
import { getResidentAppSettings } from '../../../+state';
import { map } from 'rxjs/operators';
import { TicketAttachmentDocumentType } from '../../../models/tickets/ticket-attachment-document-type.enum';

@Directive()
export class TicketQuestionsBaseComponent implements OnInit {
  @Input() issueType: TicketIssueType;
  @Input() ticketCreationSummary: ITicketCreationSummary;
  @Input() step: { index: number };
  @Input() questionsForm: FormGroup;
  @Output() attachmentChanged = new EventEmitter<{ size: number; id: number }>();
  public ticketQuestionsActionsState$: Observable<IActionState>;
  public ScoreQuestionType = ScoreQuestionType;
  public allowedDamageAttachmentTypes: CAMERA_DIALOG_ACTIONS[] = [];
  public allowedConcernAttachmentTypes: CAMERA_DIALOG_ACTIONS[] = [];

  constructor(private ticketStore: Store<fromTicketState.ITicketsState>) {}

  ngOnInit() {
    if (this.issueType === TicketIssueType.PROPERTY_DAMAGE) {
      this.getDamageFileTypes();
      this.ticketQuestionsActionsState$ = this.ticketStore.select(
        fromTicketState.getDamageQuestionsActionState
      );
    } else if (this.issueType === TicketIssueType.CONCERN) {
      this.getConcernFileTypes();
      this.ticketQuestionsActionsState$ = this.ticketStore.select(
        fromTicketState.getConcernQuestionsActionState
      );
    } else {
      this.ticketQuestionsActionsState$ = this.ticketStore.select(
        fromTicketState.getCategoryDetailQuestionsActionState
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public selectAnswer(item: ITicketCreationSummaryItem, answerKey: string): void {
    const formControl = this.questionsForm.controls[item.id];
    if (item.type === ScoreQuestionType.MULTISELECT) {
      formControl.patchValue(this.toggleMultiSelect(formControl.value, answerKey));
    } else {
      formControl.patchValue(answerKey);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public addAttachments(item: any, attachments: ITicketAttachment[]): void {
    const totalAttachmentSize = attachments.reduce(
      (total, attachment) => total + attachment.size,
      0
    );
    this.attachmentChanged.emit({ size: totalAttachmentSize, id: item.id });
    this.questionsForm.controls[item.id].patchValue(attachments);
  }

  public getDamageFileTypes() {
    this.ticketStore
      .select(getResidentAppSettings)
      .pipe(
        take(1),
        map(settings => settings?.allowedDamageTicketAttachmentFileTypes)
      )
      .subscribe(res => {
        if (res.includes(TicketAttachmentDocumentType.IMG)) {
          this.allowedDamageAttachmentTypes.push(
            CAMERA_DIALOG_ACTIONS.Camera,
            CAMERA_DIALOG_ACTIONS.Gallery
          );
        }
        if (res.includes(TicketAttachmentDocumentType.PDF)) {
          this.allowedDamageAttachmentTypes.push(CAMERA_DIALOG_ACTIONS.File);
        }
      });
  }

  public getConcernFileTypes() {
    this.ticketStore
      .select(getResidentAppSettings)
      .pipe(
        take(1),
        map(settings => settings?.allowedConcernTicketAttachmentFileTypes)
      )
      .subscribe(res => {
        if (res.includes(TicketAttachmentDocumentType.IMG)) {
          this.allowedConcernAttachmentTypes.push(
            CAMERA_DIALOG_ACTIONS.Camera,
            CAMERA_DIALOG_ACTIONS.Gallery
          );
        }
        if (res.includes(TicketAttachmentDocumentType.PDF)) {
          this.allowedConcernAttachmentTypes.push(CAMERA_DIALOG_ACTIONS.File);
        }
      });
  }

  public isAnswerSelected(itemId: string, answerKey: string): boolean {
    return this.questionsForm.get(itemId)?.value?.includes(answerKey) ?? false;
  }

  private toggleMultiSelect(selectedAnswers: string[], answerKey: string): string[] {
    return selectedAnswers.includes(answerKey)
      ? selectedAnswers.filter(answer => answer !== answerKey)
      : [...selectedAnswers, answerKey];
  }
}
