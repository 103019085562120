import { Directive, EventEmitter, Output, OnInit } from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, filter } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ImmomioIcons } from '../../models/general/icon-name.type.generated';
import {
  CustomerService,
  contractStatusConverter,
  RouterService,
  masterdataHelper,
} from '../../services';
import { IMasterdataChangeConfiguration } from '../../models';
import {
  getContracts,
  getMasterDataChangeConfiguration,
  getPersonalData,
  getPersonalDataActionState,
  getResidentAppSettings,
  LoadMasterDataChangeConfiguration,
  IMasterDataState,
  IUserProfileState,
  getMasterDataChangeConfigurationActionState,
} from '../../+state';

@UntilDestroy()
@Directive()
export abstract class UserProfileCardBaseComponent implements OnInit {
  public user$ = this.store.select(getPersonalData);
  public userActionState$ = this.store.select(getPersonalDataActionState);
  public immomioIconName = ImmomioIcons;
  public showChangeButton = false;
  public masterDataChangeConfiguration: IMasterdataChangeConfiguration[] | null;

  @Output() profileUpdate = new EventEmitter();

  constructor(
    protected routerService: RouterService,
    protected store: Store<IUserProfileState>,
    protected customerService: CustomerService,
    protected masterDataStore: Store<IMasterDataState>
  ) {
    this.store.dispatch(LoadMasterDataChangeConfiguration());
  }

  ngOnInit(): void {
    this.userActionState$ = this.store.select(getPersonalDataActionState);
    this.store
      .select(getMasterDataChangeConfigurationActionState)
      .pipe(
        filter(state => state.done),
        switchMap(() =>
          combineLatest([
            this.store.select(getMasterDataChangeConfiguration),
            this.store.select(getContracts),
            this.store.select(getResidentAppSettings),
          ]).pipe(untilDestroyed(this))
        )
      )
      .subscribe(([masterDataChangeConfiguration, contracts, residentAppSettings]) => {
        const activeContract =
          contracts?.length && contractStatusConverter.isAnyContractActive(contracts);
        const contractlessTicketCreation =
          residentAppSettings?.allowContractlessTicketCreationForOtherConcerns ?? false;
        const disabledTicketManagement = this.customerService.hideTicketManagement();

        this.showChangeButton = masterdataHelper.checkShowChangeButton(
          masterDataChangeConfiguration,
          activeContract,
          contractlessTicketCreation,
          disabledTicketManagement
        );
        this.masterDataChangeConfiguration = masterDataChangeConfiguration;
      });
  }
}
