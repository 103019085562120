import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { ImmomioIconName } from '@resident-nx/shared';
import { CardWebComponent } from '../card/card.component';
import { IconWebComponent } from '../icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-file-upload-button',
  standalone: true,
  imports: [CardWebComponent, IconWebComponent, TranslateModule],
  templateUrl: './file-upload-button.component.html',
  styleUrl: './file-upload-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadButtonComponent {
  protected readonly ImmomioIconName = ImmomioIconName;

  label = input('upload_file_l');
}
