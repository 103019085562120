<div class="pie-chart">
  <div
    class="pie-chart__pie mb-4"
    [style]="{ 'max-width': width + 'px', 'max-height': height + 'px' }"
  >
    <apx-chart
      [series]="chartOptions.series"
      [chart]="chartOptions.chart"
      [legend]="chartOptions.legend"
      [colors]="chartOptions.colors"
      [dataLabels]="chartOptions.dataLabels"
      [plotOptions]="chartOptions.plotOptions"
      [tooltip]="chartOptions.tooltip"
      [states]="chartOptions.states"
    ></apx-chart>
  </div>

  @if (!areAllLabelNull()) {
    <div class="pie-chart__legend-below">
      @for (item of data.set; track item.label) {
        @if (item.label && item.amount) {
          <div class="pie-chart__legend-item">
            <div class="pie-chart__legend-item-left">
              <div
                class="pie-chart__legend-item-dot"
                [style]="{ 'background-color': item.color }"
              ></div>
              <div>{{ item.label | translate }}</div>
            </div>
            <div class="pie-chart__legend-item-right">
              {{ item.amount | displayCurrency: 'EUR' }}
            </div>
          </div>
        }
      }
    </div>
  } @else {
    <div class="pie-chart__no-cost-available">
      {{ 'contracts.no_contracts_available' | translate }}
    </div>
  }
</div>
