import {
  IAddress,
  ICustomerSpecificDataResponse,
  ITicketCreationSummary,
  PreferredWayOfContact,
} from '../';

export interface IMasterDataUser {
  id: string;
  externalId: string;
  firstName: string;
  lastName: string;
  address: IAddress;
  phone: string;
  mobile: string;
  email: string;
  preferredWayOfContact: PreferredWayOfContact;
  mainContractId: string;
  lastContractEnd: string;
  birthDate: string;
  customerSpecificData: ICustomerSpecificDataResponse[] | null;
  initials: string;
}

export interface IMasterDataResponse {
  residentUser: IMasterDataUserDto;
}

export interface IMasterDataUserDto
  extends Omit<IMasterDataUser, 'id' | 'firstName' | 'lastContractEnd'> {
  id: string | null;
  firstName: string | null;
  lastContractEnd: string | null;
}

export enum MasterdataChangeType {
  NAME = 'NAME',
  EMAIL = 'EMAIL',
  DATE_OF_BIRTH = 'DATE_OF_BIRTH',
  ADDRESS = 'ADDRESS',
  PHONE = 'PHONE',
  MOBILE = 'MOBILE',
}

export enum MasterdataChangeRequestType {
  TICKET = 'TICKET',
  EXTERNAL = 'EXTERNAL',
}

export interface IMasterdataChangeConfiguration {
  changeType: MasterdataChangeType;
  requestType: MasterdataChangeRequestType;
  ticketCategoryId?: string;
}

export interface IMasterdataChangeConfigurationResponse {
  masterdataConfigurationBeans: IMasterdataChangeConfiguration[];
}

export interface IMasterDataChange {
  selectedChangeConfiguration?: IMasterdataChangeConfiguration;
  masterDataChangeRequest?: IMasterdataChangeRequest;
  ticketCreationSummary?: ITicketCreationSummary;
}

export interface IMasterdataChangeRequest {
  type: MasterdataChangeType;
  name?: IMasterdataNameChange;
  dateOfBirth?: IMasterdataDateOfBirthChange;
  address?: IMasterdataAddressChange;
  phone?: IMasterdataPhoneChange;
  mobile?: IMasterdataMobileChange;
  email?: IMasterdataEmailChange;
}

export interface IMasterdataNameChangeAttachment {
  name: string;
  extension: string;
  content: string;
}

export interface IMasterdataNameChange {
  name: string;
  lastName: string;
  attachments: IMasterdataNameChangeAttachment[];
}

export interface IMasterdataDateOfBirthChange {
  dateOfBirth: string;
}

export interface IMasterdataAddressChange {
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
}

export interface IMasterdataPhoneChange {
  countryCode: string;
  prefix: number;
  phoneNumber: string;
}

export interface IMasterdataMobileChange {
  countryCode: string;
  prefix: number;
  phoneNumber: string;
}

export interface IMasterdataEmailChange {
  email: string;
}

export const masterDataSelectionTranslateKeys = {
  [MasterdataChangeType.NAME]: 'user_name_edit.form_l',
  [MasterdataChangeType.DATE_OF_BIRTH]: 'user_birth_date_edit.form_l',
  [MasterdataChangeType.EMAIL]: 'user_email_edit.form_l',
  [MasterdataChangeType.MOBILE]: 'user_mobile_phone_edit.form_l',
  [MasterdataChangeType.ADDRESS]: 'user_address_edit.form_l',
  [MasterdataChangeType.PHONE]: 'user_phone_edit.form_l',
};
