import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ImmomioIconName } from '../../models';

@Directive()
export abstract class SelectIconBaseComponent {
  @Input() className?: string;
  @Input() isSelected = false;
  @Input() isCheckbox = false;
  @Output() tapped?: EventEmitter<boolean> = new EventEmitter();

  public IconNames = ImmomioIconName;

  public checkButton(event): void {
    if (!this.isCheckbox && !this.isSelected) {
      this.tapped.emit(event);
    } else {
      this.tapped.emit(event);
    }
  }
}
