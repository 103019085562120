import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FILE_SIZE_IN_MB, FILE_SIZE_LIMIT, ITicketAttachment } from '../../models';

@Directive()
export class PdfCardBaseComponent {
  @Input() pdf: ITicketAttachment;
  @Input() isValid: boolean;
  @Output() deletePdf = new EventEmitter();

  public isValidFilesize(file: ITicketAttachment): boolean {
    return file.size / FILE_SIZE_IN_MB < FILE_SIZE_LIMIT;
  }
}
