import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PdfAttachmentComponent } from '../../../pdf-attachment/pdf-attachment.component';
import { IconWebComponent } from '../../../../atoms/icon/icon.component';
import {
  AddressPipe,
  ImmomioIconName,
  MasterdataChangeType,
  masterDataSelectionTranslateKeys,
} from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-user-profile-edit-summary',
  standalone: true,
  imports: [CommonModule, PdfAttachmentComponent, IconWebComponent, AddressPipe, TranslateModule],
  templateUrl: './user-profile-edit-summary.component.html',
  styleUrl: './user-profile-edit-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileEditSummaryComponent {
  protected readonly ImmomioIconName = ImmomioIconName;
  protected readonly selectionTranslateKeys = masterDataSelectionTranslateKeys;
  public now = new Date();
  changeType = input<MasterdataChangeType>();
}
