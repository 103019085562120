import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonTypeEnum,
  DocumentFileType,
  IResidentS3File,
  TicketDetailsOverviewMetadataBaseComponent,
} from '@resident-nx/shared';
import { PictureWebComponent } from '../../../../atoms/picture/picture.component';
import { IconWebComponent } from '../../../../atoms/icon/icon.component';
import { LabelWebComponent } from '../../../../atoms/label/label.component';
import { ButtonWebComponent } from '../../../../atoms/button/button.component';
import { TicketImageViewerComponent } from '../../../../atoms/ticket-image-viewer/ticket-image-viewer.component';
import { FileDownloadWebService } from '../../../../../services';
import { take } from 'rxjs/operators';
import { PdfAttachmentComponent } from '../../../../molecules/pdf-attachment/pdf-attachment.component';

@Component({
  selector: 'rs-web-ticket-details-overview-metadata',
  templateUrl: './ticket-details-overview-metadata.component.html',
  styleUrls: ['./ticket-details-overview-metadata.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PictureWebComponent,
    IconWebComponent,
    LabelWebComponent,
    ButtonWebComponent,
    TicketImageViewerComponent,
    PdfAttachmentComponent,
  ],
})
export class TicketDetailsOverviewMetadataWebComponent extends TicketDetailsOverviewMetadataBaseComponent {
  public buttonTypeEnum = ButtonTypeEnum;
  public readonly DocumentFileType = DocumentFileType;
  #downloadService = inject(FileDownloadWebService);

  downloadTicketAttachment(ticketId: string, s3File: IResidentS3File): void {
    this.#downloadService
      .downloadTicketAttachment(ticketId, s3File.identifier)
      .pipe(take(1))
      .subscribe(url => this.#downloadService.download(url, s3File.name));
  }
}
