import { gql } from 'apollo-angular';
import { addressFields, customerSpecificDataFields } from '../common';

export const masterdataFields = `
  residentUser {
    id
    externalId
    firstName
    lastName: name
    ${addressFields}
    phone
    mobile
    email
    preferredWayOfContact
    mainContractId
    lastContractEnd
    birthDate
    ${customerSpecificDataFields}
  }
`;

export const getMasterdataQuery = gql`
  query getMasterdata {
    getMasterdata {
      ${masterdataFields}
    }
  }
`;

export const updateUserMasterdataMutation = gql`
  mutation updateUserMasterdata($input: UpdateMasterdataInputBean!) {
    updateUserMasterdata(input: $input) {
      createdTicketId
    }
  }
`;

export const getMasterdataChangeConfiguration = gql`
  query getMasterdataChangeConfiguration {
    getMasterdataChangeConfiguration {
      masterdataConfigurationBeans {
        changeType
        requestType
        ticketCategoryId
      }
    }
  }
`;

export const sendMasterdataChangeRequest = gql`
  mutation sendMasterdataChangeRequest($requestBean: ResidentMasterdataChangeRequestRequestBean!) {
    sendMasterdataChangeRequest(requestBean: $requestBean)
  }
`;
