import { ConsumptionAggregateType, ConsumptionType, DeviceId } from './';
import { ContractId } from '../masterdata';

export interface IMeterConsumptionInput {
  start: EpochTimeStamp;
  end: EpochTimeStamp;
  requestedContractNumber: ContractId;
  meterId: DeviceId;
  aggregate: ConsumptionAggregateType;
  serviceTypes?: [ConsumptionType];
}
