@if ((offerActionState$ | async).done) {
  @if (offer$ | async; as offer) {
    <div class="container container--with-spacing">
      <div class="d-flex align-items-center mb-3">
        <rs-web-breadcrumbs
          [items]="breadcrumbs"
          (navigateBack)="onNavigateBack()"
          (navigateToUrl)="onNavigate($event)"
        ></rs-web-breadcrumbs>
      </div>
      <div class="card-container">
        <rs-web-card borderStyle="none" [elevation]="Elevation.ONE">
          <div class="container__content">
            <div class="d-flex justify-content-center">
              <rs-web-image
                [borderRadius]="ImageBorderRadiusEnum.BIG"
                [defaultSrc]="offer.imageUrl"
                [maxHeightInPx]="400"
              ></rs-web-image>
            </div>
            <div class="d-flex justify-content-center mt-5">
              <h3>{{ offer.title }}</h3>
            </div>
            <span class="subtitle">{{ offer.publishedAt | rsWebDate }}</span>
            <hr />
            <p [innerHtml]="offer.body" class="primary-text"></p>
            @if (offer?.href) {
              <div class="d-flex justify-content-center mt-4">
                <a [href]="offer?.href" target="_blank">
                  <rs-web-button [type]="buttonTypeEnum.PRIMARY" class="pe-none">
                    {{ 'general.learn_more_b' | translate }}
                  </rs-web-button>
                </a>
              </div>
            }
          </div>
        </rs-web-card>
      </div>
    </div>
  }
} @else {
  <div class="loading-area d-flex justify-content-center">
    <rs-web-loader></rs-web-loader>
  </div>
}
