import { Directive, OnInit } from '@angular/core';

import { interval, Observable, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { IConsumptionInfoItem, IConsumption, PeriodType } from '../../models';
import * as fromConsumptionState from '../../+state/consumption';
import { IActionState } from '../../utils';
import { getMonth } from 'date-fns';

type IntervalOrder = number;

@UntilDestroy()
@Directive()
export abstract class ConsumptionCardBaseComponent implements OnInit {
  public selectedMeter: IConsumptionInfoItem;
  public selectedConsumption$: Observable<IConsumption>;
  public selectedConsumptionState$: Observable<IActionState>;

  constructor(private store: Store<fromConsumptionState.IConsumptionState>) {}

  ngOnInit() {
    this.store
      .select(fromConsumptionState.getFirstSelectedMeter)
      .pipe(untilDestroyed(this))
      .subscribe(meter => {
        this.selectedMeter = meter;
        if (this.selectedMeter?.updateInterval) {
          this.refreshData(this.selectedMeter.updateInterval);
        }
      });
    this.selectedConsumption$ = this.store.select(
      fromConsumptionState.getSelectedConsumptionForFirstMeter
    );
    this.selectedConsumptionState$ = this.store.select(
      fromConsumptionState.getConsumptionActionStateForFirstMeter
    );
  }

  private refreshData(refreshInterval: number): void {
    interval(refreshInterval)
      .pipe(
        withLatestFrom(this.store.select(fromConsumptionState.getSelectedConsumptionForFirstMeter)),
        untilDestroyed(this)
      )
      .subscribe((action: [IntervalOrder, IConsumption]) => {
        if (action && action[1]?.consumption) {
          const selectedMonth = getMonth(action[1].consumption.date);
          const periodType = action[1].periodType;

          // refresh current data only if current month is selected
          if (periodType === PeriodType.MONTH && selectedMonth === getMonth(new Date())) {
            this.store.dispatch(fromConsumptionState.LoadCurrentMonthConsumption());
          }
        }
      });
  }
}
