import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe, DecimalPipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import {
  ConsumptionType,
  ConsumptionUnitType,
  IConsumptionInfoItem,
  IConsumptionItem,
  ImmomioIconName,
  IMonth,
  NameDisplayMode,
  PeriodType,
} from '../models';
import { getMonthName } from '../utils';
import { ConsumptionHelpers } from '../+state/consumption/consumption.helpers';

@Pipe({
  name: 'consumptionUnitTypePipe',
  standalone: true,
})
export class ConsumptionUnitTypePipe implements PipeTransform {
  transform(consumptionType: ConsumptionType): ConsumptionUnitType {
    switch (consumptionType) {
      case ConsumptionType.POWER:
        return ConsumptionUnitType.KWH;
      case ConsumptionType.HOT_WATER:
        return ConsumptionUnitType.M3;
      case ConsumptionType.COLD_WATER:
        return ConsumptionUnitType.M3;
      case ConsumptionType.HEATING:
        return ConsumptionUnitType.HCU;
      case ConsumptionType.PHOTOVOLTAIC:
        return ConsumptionUnitType.KWH;
      case ConsumptionType.COOLING:
        return ConsumptionUnitType.HCU;
      case ConsumptionType.POWER_CONSUMPTION:
        return ConsumptionUnitType.KWH;
      case ConsumptionType.POWER_PRODUCTION:
        return ConsumptionUnitType.KWH;
    }
  }
}

@Pipe({
  name: 'consumptionName',
  standalone: true,
})
export class ConsumptionNamePipe implements PipeTransform {
  transform(consumptionType: ConsumptionType, nameDisplayMode: NameDisplayMode): string {
    switch (consumptionType) {
      case ConsumptionType.POWER:
        return nameDisplayMode === NameDisplayMode.SHORT
          ? 'consumption_type.power_abbrev_l'
          : 'consumption_type.power_l';
      case ConsumptionType.COLD_WATER:
        return nameDisplayMode === NameDisplayMode.SHORT
          ? 'consumption_type.cold_water_abbrev_l'
          : 'consumption_type.cold_water_l';
      case ConsumptionType.HOT_WATER:
        return nameDisplayMode === NameDisplayMode.SHORT
          ? 'consumption_type.hot_water_abbrev_l'
          : 'consumption_type.hot_water_l';
      case ConsumptionType.HEATING:
        return nameDisplayMode === NameDisplayMode.SHORT
          ? 'consumption_type.heating_abbrev_l'
          : 'consumption_type.heating_l';
      case ConsumptionType.PHOTOVOLTAIC:
        return nameDisplayMode === NameDisplayMode.SHORT
          ? 'consumption_type.photovoltaic_abbrev_l'
          : 'consumption_type.photovoltaic_l';
      default:
        return nameDisplayMode === NameDisplayMode.SHORT
          ? 'consumption_type.no_type_abbrev_l'
          : 'consumption_type.no_type_l';
    }
  }
}

@Pipe({
  name: 'consumptionIcon',
  standalone: true,
})
export class ConsumptionIconPipe implements PipeTransform {
  transform(value: ConsumptionType): ImmomioIconName {
    switch (value) {
      case ConsumptionType.POWER:
        return ImmomioIconName.Lightning;
      case ConsumptionType.HOT_WATER:
        return ImmomioIconName.Water;
      case ConsumptionType.COLD_WATER:
        return ImmomioIconName.Water;
      case ConsumptionType.HEATING:
        return ImmomioIconName.Heating;
      case ConsumptionType.PHOTOVOLTAIC:
        return ImmomioIconName.Sun;
      default:
        return ImmomioIconName.Plug;
    }
  }
}

@Pipe({
  name: 'consumptionPeriod',
  standalone: true,
})
export class ConsumptionPeriodPipe extends DatePipe implements PipeTransform {
  override transform(value: Date | string | number): string | null;
  override transform(value: null | undefined): null;
  override transform(value: Date | string | number | null | undefined): string | null;
  override transform(value: Date | number | null, periodType?: PeriodType): string | null {
    if (!value) {
      return null;
    }
    switch (periodType) {
      case PeriodType.DAY:
        return super.transform(value, 'dd. MMMM yyyy');
      case PeriodType.MONTH:
        return super.transform(value, 'MMMM yyyy');
      case PeriodType.YEAR:
        return super.transform(value, 'yyyy');
      default:
        return value.toString();
    }
  }
}

@Pipe({
  name: 'consumptionMonthIsInAvailableDataRange',
  standalone: true,
})
export class ConsumptionMonthIsInAvailableDataRangePipe implements PipeTransform {
  transform(periodMonth: IMonth, selectedMeter: IConsumptionInfoItem): boolean {
    return (
      periodMonth.monthEndTimestamp >= selectedMeter.firstEntry &&
      periodMonth.monthStartTimestamp <= selectedMeter.lastEntry
    );
  }
}

@Pipe({
  name: 'consumptionValue',
  standalone: true,
})
export class ConsumptionValuePipe extends DecimalPipe implements PipeTransform {
  override transform(value: number | string, digitsInfo?: string, locale?: string): string | null;
  override transform(value: null | undefined, digitsInfo?: string, locale?: string): null;
  override transform(
    value: number | string | null | undefined,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    digitsInfo?: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    locale?: string
  ): string | null {
    if (!value) {
      return '0';
    }

    return super.transform(value, '1.0-3');
  }
}

@Pipe({
  name: 'maxConsumption',
  standalone: true,
})
export class MaxConsumptionPipe implements PipeTransform {
  transform(consumptionItems: IConsumptionItem[], startPeriod = 0, endPeriod = 0): number {
    const max =
      ConsumptionHelpers.filterItemsByPeriod(consumptionItems, startPeriod, endPeriod).reduce(
        (prev, current) => (prev.amount > current.amount ? prev : current)
      ).amount || 0;
    /**
     * always increase max consumption for a factor one
     */
    if (max < 5) {
      return max + 0.5;
    } else if (max < 10) {
      return max + 1;
    } else {
      return Math.ceil(max / 4 / 5) * 5 * 4;
    }
  }
}

@Pipe({
  name: 'consumptionStep',
  standalone: true,
})
export class ConsumptionStepsPipe implements PipeTransform {
  transform(
    consumptionItems: IConsumptionItem[],
    startPeriod = 0,
    endPeriod = 0,
    steps = 4
  ): number {
    const max =
      ConsumptionHelpers.filterItemsByPeriod(consumptionItems, startPeriod, endPeriod).reduce(
        (prev, current) => (prev.amount > current.amount ? prev : current)
      ).amount || 0;

    /**
     * have always 4 steps as default
     * round u to the nearest multiple of 5
     */
    if (max < 10) {
      return Math.ceil(max / steps);
    } else {
      return Math.ceil(max / steps / 5) * 5;
    }
  }
}

@Pipe({
  name: 'consumptionMonthName',
  standalone: true,
})
export class ConsumptionMonthNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: IMonth, monthsCount: number): string {
    if (!value) {
      return '';
    }
    const monthName = getMonthName(value.month);
    const translatedValue = this.translateService.instant(monthName);

    // depending on number of months shown in chart calculate name length
    if (monthsCount <= 6) {
      return translatedValue.substring(0, 3);
    } else {
      return translatedValue[0];
    }
  }
}
