import { inject, Injectable } from '@angular/core';
import {
  FormResponseQuestionType,
  IFormResponseCreate,
  IHierarchicalQuestionAnswerTicketOverview,
  IHierarchicalQuestionTicketOverview,
  ScoreQuestionType,
} from '@resident-nx/shared';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  ValidatorFn,
  Validators,
} from '@angular/forms';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  convertScoreQuestionTypeToFormResponseQuestionTypeWeb,
  fileToBase64,
  maxFileSize,
} from '../../utils';
import { MAX_FILE_SIZE } from './ticket-creation.service';

@Injectable({
  providedIn: 'root',
})
export class TicketHelperService {
  #fb = inject(FormBuilder);
  public async createFormResponseCreates(
    questions: IHierarchicalQuestionTicketOverview[],
    formArray: FormArray<FormControl | FormArray<FormControl>>
  ) {
    const formResponses: IFormResponseCreate[] = [];

    for (const question of questions) {
      const index = questions.indexOf(question);
      if (question.data.type === ScoreQuestionType.LABEL) continue;

      const answer = formArray.at(index).value;

      const response: IFormResponseCreate = {
        index: question.data.order,
        questionText: question.data.title,
        type: convertScoreQuestionTypeToFormResponseQuestionTypeWeb({
          type: question.data.type,
          multiline: question.answers[0].data.multiline,
        }),
        responses: [],
        attachments: [],
      };

      switch (response.type) {
        case FormResponseQuestionType.ATTACHMENTS: {
          const attachments =
            answer instanceof FileList
              ? await Promise.all(
                  Array.from(answer).map(async file => {
                    const fileExtensionIndex = file.name.lastIndexOf('.');
                    const name = file.name.substring(0, fileExtensionIndex);
                    const extension = file.name.substring(fileExtensionIndex + 1, file.name.length);

                    return {
                      name,
                      extension,
                      content: await fileToBase64(file),
                    };
                  })
                )
              : [];

          response.attachments.push(...attachments);
          break;
        }

        case FormResponseQuestionType.SELECTION: {
          response.responses.push(
            (answer as IHierarchicalQuestionAnswerTicketOverview)?.data?.title || ''
          );
          break;
        }

        case FormResponseQuestionType.MULTISELECT: {
          response.responses = this.getCheckedMultiselectValues(question, answer);
          break;
        }

        default: {
          response.responses.push((answer as string) || '');
        }
      }

      formResponses.push(response);
    }
    return formResponses;
  }

  /**
   * Given Answers: ["A", "B", "C", "D"]
   * isCheckedStates: [false, true, false, true]
   * Returns: ["B", "D"]
   */
  getCheckedMultiselectValues(
    question: IHierarchicalQuestionTicketOverview,
    isCheckedStates: boolean[]
  ): string[] {
    return isCheckedStates
      .map((isChecked: boolean, answerIndex: number) =>
        isChecked ? question.answers[answerIndex].data.title : null
      )
      .filter((value: string | null) => value !== null);
  }

  /**
   * only way to create a new value instance for <input type="file" multiple>
   * is by using a DataTransfer instance.
   * Needed to keep file size validation working as expected when building
   * value for <input type="file" multiple> programmatically, because FileList
   * is the default value type
   * @param files
   */
  createFileListFromArray(files: File[]): FileList {
    const dataTransfer = new DataTransfer();
    for (const file of files) {
      dataTransfer.items.add(file);
    }
    return dataTransfer.files;
  }

  createDetailQuestionsControl(
    question: IHierarchicalQuestionTicketOverview
  ): FormControl | FormArray<FormControl> {
    const validators: ValidatorFn[] = [];
    if (question.data.required) {
      if (question.data.type === ScoreQuestionType.MULTISELECT) {
        validators.push(this.minimumOneCheckedValidator());
      } else if (question.data.type !== ScoreQuestionType.LABEL) {
        validators.push(Validators.required);
      }
    }

    if (question.data.type === ScoreQuestionType.INPUT_ATTACHMENTS) {
      validators.push(maxFileSize(MAX_FILE_SIZE));
    }

    if (question.data.type === ScoreQuestionType.MULTISELECT) {
      return this.#fb.array(
        question.answers?.map(() => false),
        validators
      );
    } else {
      return this.#fb.control(null, validators);
    }
  }

  minimumOneCheckedValidator() {
    const validator: ValidatorFn = (formArray: AbstractControl) => {
      if (formArray instanceof FormArray) {
        const isOneChecked = formArray.controls
          .map(control => control.value)
          .some(value => value === true);
        return isOneChecked ? null : { required: true };
      } else {
        return null;
      }
    };

    return validator;
  }
}
