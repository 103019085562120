import {
  FormResponseQuestionType,
  IFormResponseCreate,
  IHQTicketDetailsResponse,
  IMetadataTicketDetailsResponse,
  ITicketCreationSummary,
  ITicketCreationSummaryItem,
  ITicketResidentOverview,
  ITicketResidentOverviewEdge,
  ScoreQuestionType,
  TicketingVersion,
  TicketStatus,
} from '../../models';

import { getNameFromFilePath } from '../../utils';
interface ITicketOverviewUpdate {
  activeTickets: ITicketResidentOverviewEdge[];
  archiveTickets: ITicketResidentOverviewEdge[];
}

const activeStatuses = [
  TicketStatus.OPEN,
  TicketStatus.IN_PROGRESS,
  TicketStatus.WAITING_FOR_OTHERS,
];
const archiveStatuses = [TicketStatus.CANCELLED, TicketStatus.CLOSED];

export const ticketConverter = {
  fromHQDetailsToOverview: (details: IHQTicketDetailsResponse): ITicketResidentOverview => {
    return {
      id: details.id,
      ticketNr: details.ticketNr,
      title: details.title,
      state: details.state,
      category: details.category,
      created: details.created,
      updated: details.updated,
      titleImage: details.titleImage,
      issueType: details.issueType,
      customerSpecificData: details.customerSpecificData,
    };
  },
  fromMetadataDetailsToOverview: (
    details: IMetadataTicketDetailsResponse,
    existingTicket?: ITicketResidentOverviewEdge
  ): ITicketResidentOverview => {
    return {
      id: details.id,
      ticketNr: existingTicket?.node ? existingTicket.node.ticketNr : null,
      title: details.title,
      state: details.status,
      category: existingTicket?.node ? existingTicket.node.category : null,
      created: {
        editor: existingTicket?.node ? existingTicket.node.created?.editor : null,
        date: details.created,
      },
      updated: {
        editor: existingTicket?.node ? existingTicket.node.updated?.editor : null,
        date: details.updated,
      },
      titleImage: existingTicket?.node ? existingTicket.node.titleImage : null,
      issueType: details.issueType,
      customerSpecificData: existingTicket?.node ? existingTicket.node.customerSpecificData : null,
    };
  },
  createUpdatedObject: (
    details: IHQTicketDetailsResponse | IMetadataTicketDetailsResponse,
    type: TicketingVersion,
    existingTicket?: ITicketResidentOverviewEdge
  ): ITicketResidentOverview => {
    return type === TicketingVersion.HIERARCHICAL
      ? ticketConverter.fromHQDetailsToOverview(details as IHQTicketDetailsResponse)
      : ticketConverter.fromMetadataDetailsToOverview(
          details as IMetadataTicketDetailsResponse,
          existingTicket
        );
  },
  updateTickets: (
    index: number,
    tickets: ITicketResidentOverviewEdge[],
    updatedTicketsA: ITicketResidentOverviewEdge[],
    updatedTicketsB: ITicketResidentOverviewEdge[],
    type: TicketingVersion,
    nodeToUpdate: IHQTicketDetailsResponse | IMetadataTicketDetailsResponse,
    updatedStatus: TicketStatus
  ) => {
    const currentStatus = tickets[index].node.state;
    const updatedObject = ticketConverter.createUpdatedObject(nodeToUpdate, type, tickets[index]);

    if (activeStatuses.includes(currentStatus) && archiveStatuses.includes(updatedStatus)) {
      updatedTicketsA = updatedTicketsA.splice(index, 1);
      updatedTicketsB.unshift({ cursor: updatedObject.id, node: updatedObject });
    } else {
      updatedTicketsA[index] = {
        ...updatedTicketsA[index],
        node: updatedObject,
      };
    }
  },
  updateTicketInStore: (
    activeTickets: ITicketResidentOverviewEdge[],
    archiveTickets: ITicketResidentOverviewEdge[],
    nodeToUpdate: IHQTicketDetailsResponse | IMetadataTicketDetailsResponse,
    type: TicketingVersion
  ): ITicketOverviewUpdate => {
    const updatedActiveObjects = [...activeTickets];
    const updatedArchiveObjects = [...archiveTickets];
    const activeObjectIndex = activeTickets.findIndex(o => o.node.id === nodeToUpdate.id);
    const archiveObjectIndex = archiveTickets.findIndex(o => o.node.id === nodeToUpdate.id);

    const updatedStatus =
      type === TicketingVersion.HIERARCHICAL
        ? (nodeToUpdate as IHQTicketDetailsResponse).state
        : (nodeToUpdate as IMetadataTicketDetailsResponse).status;

    // existing ticket is active
    if (activeObjectIndex !== -1 && archiveObjectIndex === -1) {
      ticketConverter.updateTickets(
        activeObjectIndex,
        activeTickets,
        updatedActiveObjects,
        updatedArchiveObjects,
        type,
        nodeToUpdate,
        updatedStatus
      );
    }
    // existing ticket is archived
    else if (activeObjectIndex === -1 && archiveObjectIndex !== -1) {
      ticketConverter.updateTickets(
        archiveObjectIndex,
        archiveTickets,
        updatedArchiveObjects,
        updatedActiveObjects,
        type,
        nodeToUpdate,
        updatedStatus
      );
    }
    // there is no existing ticket in store
    else if (activeObjectIndex !== -1 && archiveObjectIndex !== -1) {
      if (activeStatuses.includes(updatedStatus)) {
        const updatedObject = ticketConverter.createUpdatedObject(
          nodeToUpdate,
          type,
          activeTickets[activeObjectIndex]
        );
        updatedActiveObjects.unshift({ cursor: updatedObject.id, node: updatedObject });
      } else if (archiveStatuses.includes(updatedStatus)) {
        const updatedObject = ticketConverter.createUpdatedObject(
          nodeToUpdate,
          type,
          archiveTickets[archiveObjectIndex]
        );
        updatedArchiveObjects.unshift({ cursor: updatedObject.id, node: updatedObject });
      }
    }

    return { activeTickets: updatedActiveObjects, archiveTickets: updatedArchiveObjects };
  },

  convertScoreQuestionTypeToFormResponseQuestionType: (
    response: ITicketCreationSummaryItem
  ): FormResponseQuestionType => {
    switch (response.type) {
      case ScoreQuestionType.SELECTION:
        return FormResponseQuestionType.SELECTION;
      case ScoreQuestionType.INPUT_TEXT:
        return response.multiline
          ? FormResponseQuestionType.TEXTBOX
          : FormResponseQuestionType.TEXTFIELD;
      case ScoreQuestionType.INPUT_DATE:
        return FormResponseQuestionType.DATE;
      case ScoreQuestionType.INPUT_ATTACHMENTS:
        return FormResponseQuestionType.ATTACHMENTS;
      case ScoreQuestionType.LABEL:
        return FormResponseQuestionType.LABEL;
      default:
        return null;
    }
  },

  formatFormResponses: (ticketSummary: ITicketCreationSummary) => {
    const formResponses: IFormResponseCreate[] = [];
    ticketSummary.detailedResponses?.forEach((res, index) => {
      const responseType = ticketConverter.convertScoreQuestionTypeToFormResponseQuestionType(res);
      if (responseType !== FormResponseQuestionType.LABEL) {
        const response: IFormResponseCreate = {
          index,
          questionText: res.displaySummary?.questionTitle || '',
          type: responseType,
          responses: [],
          attachments: [],
        };
        if (responseType === FormResponseQuestionType.ATTACHMENTS) {
          const attachment = res.displaySummary?.attachments?.map(item => {
            return {
              name: getNameFromFilePath(item.filePath),
              extension: 'jpg',
              content: item.content,
            };
          });
          if (attachment) {
            response.attachments = attachment;
          }
        } else {
          let answers: string[] = [];
          if (Array.isArray(res.displaySummary?.answerTitle)) {
            answers = res.displaySummary.answerTitle.map(answer =>
              answer !== null && answer !== undefined ? answer : ''
            );
          } else {
            answers.push(
              res.displaySummary?.answerTitle !== null &&
                res.displaySummary?.answerTitle !== undefined
                ? (res.displaySummary?.answerTitle as string)
                : ''
            );
          }
          response.responses.push(...answers);
        }
        formResponses.push(response);
      }
    });
    return formResponses;
  },
};
