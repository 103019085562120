import { gql } from 'apollo-angular';

const ResidentAppWhiteLabelResponseBean = `
  theme {
    name
    primaryColor
    primaryTextColor
    secondaryColor
    secondaryTextColor
    buttonTextColor
    backgroundColor
    cardBackgroundColor
    menuBarBackgroundColor
    menuBarTextColorActive
    menuBarTextColorInactive
  }
  branding {
    appLogo {
      url
      name
    }
    landingPageImage {
      url
      name
    }
  }
`;

export const appSettingsQuery = gql`
  query getWhiteLabelSettings {
    getWhiteLabelSettings {
      ${ResidentAppWhiteLabelResponseBean}
    }
  }
`;
