import { Directive, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable, Subscription } from 'rxjs';

import {
  DocumentSortField,
  IResidentS3File,
  ISharedDocumentsResponse,
  ISharedFolderContentResponse,
  ISort,
  LoadingType,
} from '../../models';
import { IActionState } from '../../utils';
import { FileDownloadService, RouterService } from '../../services';
import {
  getDocumentSorting,
  getResidentAppSettings,
  getSelectedContract,
  GetSharedContent,
  getSharedContent,
  getSharedContentActionState,
  IMasterDataState,
  ISharedContentState,
  ISortingState,
} from '../../+state';
import { distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Directive()
export class SharedContentWidgetBaseComponent implements OnInit {
  @Input() title = 'shared_content_header_l';
  @Input() documentsActionState: IActionState;
  @Input() id: string;
  @Output() viewAttachment = new EventEmitter();
  public sharedContent$: Observable<ISharedFolderContentResponse>;
  public sharedContentActionState$: Observable<IActionState>;
  public documentDownloadActionState$: Observable<IActionState>;
  public documentSortSupported$: Observable<boolean>;
  public header = true;
  public errorScreenUrl = '/content/home/document-error-screen';
  public loadingTypes: LoadingType[] = [];
  private contractId: string;
  private selectedSort: ISort<DocumentSortField>;
  private documentSub: Subscription;

  constructor(
    protected store: Store<ISharedContentState | IMasterDataState | ISortingState>,
    private routerService: RouterService,
    private fileDownloadService: FileDownloadService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.id) {
      this.header = false;
    }
    this.documentSub = combineLatest([
      this.store.select(getSelectedContract),
      this.store.select(getDocumentSorting),
    ])
      .pipe(distinctUntilChanged())
      .subscribe(([selectedContract, selectedSort]) => {
        this.contractId = selectedContract?.id;
        this.selectedSort = selectedSort;
        this.getSharedContent();
      });
    this.documentSortSupported$ = this.store
      .select(getResidentAppSettings)
      .pipe(map(settings => settings?.documentSortingSupported));
    this.sharedContent$ = this.store.select(getSharedContent);
    this.sharedContentActionState$ = this.store.select(getSharedContentActionState);
    this.documentDownloadActionState$ = this.fileDownloadService.documentDownloadActionState$;
  }

  public openFolder(id: string) {
    this.routerService
      .navigate(['/content'], { skipLocationChange: true })
      .then(() => this.routerService.navigate(['/content/home/shared-content-page', id]));
  }

  public openDocument(document: ISharedDocumentsResponse) {
    this.fileDownloadService
      .downloadDocument(document)
      .pipe(untilDestroyed(this))
      .subscribe(
        (downloadedFile: IResidentS3File) => {
          this.routerService.navigate(['/content'], { skipLocationChange: true }).then(() =>
            this.routerService.navigate([
              '/content/home/shared-content-details-page',
              {
                downloadDocument: JSON.stringify(downloadedFile),
                document: JSON.stringify(document),
              },
            ])
          );
        },
        () => {
          void this.routerService.navigate(
            [this.errorScreenUrl, { id: this.id, url: this.router.url }],
            {
              relativeTo: this.activeRoute,
              skipLocationChange: true,
            }
          );
        }
      );
  }

  public pullToRefresh(args): void {
    const pullRefresh = args.object;
    this.getSharedContent(true);
    pullRefresh.refreshing = false;
  }

  @HostListener('unloaded')
  pageDestroy() {
    this.documentSub?.unsubscribe();
  }

  private getSharedContent(forced?: boolean) {
    this.store.dispatch(
      GetSharedContent({
        params: {
          folderId: this.id || null,
          contractId: this.contractId || null,
          sortOrder: this.selectedSort.direction || null,
          sortField: this.selectedSort.value || null,
        },
        forced: forced ? forced : false,
      })
    );
  }
}
