<div class="d-flex justify-content-between">
  <div class="date-picker-container">
    <rs-web-button
      (click)="selectPreviousPeriod()"
      [disabled]="isPreviousDisabled"
      [zeroPadding]="true"
      [type]="ButtonTypeEnum.LINK"
    >
      <rs-web-icon
        primary
        [icon]="ImmomioIconName.ArrowLeft"
        [size]="14"
        [color]="iconColor"
      ></rs-web-icon>
    </rs-web-button>

    <span class="date-picker-text" [class.month]="isMonthSelected">
      {{ date | date: dateFormat }}
    </span>

    <rs-web-button
      [disabled]="isNextDisabled"
      (click)="selectNextPeriod()"
      [zeroPadding]="true"
      [type]="ButtonTypeEnum.LINK"
    >
      <rs-web-icon
        primary
        [icon]="ImmomioIconName.ArrowRight"
        [size]="14"
        [color]="iconColor"
      ></rs-web-icon>
    </rs-web-button>
  </div>
  @if (isMonthAvailable) {
    <div class="d-flex flex-row gap-2">
      <div
        class="date-picker-toggle-item"
        [class.active]="isMonthSelected"
        (click)="timeframeChange.emit(ConsumptionTimeframe.MONTH)"
      >
        <span>{{ 'month' | translate }}</span>
      </div>
      <div
        class="date-picker-toggle-item"
        [class.active]="!isMonthSelected"
        (click)="timeframeChange.emit(ConsumptionTimeframe.YEAR)"
      >
        <span>{{ 'year' | translate }}</span>
      </div>
    </div>
  }
</div>
