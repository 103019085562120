<div class="tickets-overview-damage">
  <div class="d-flex justify-content-between">
    <rs-web-cards-container-header
      [icon]="immomioIconName.Ticket"
      [title]="'ticket_active_damages_l'"
      [buttonText]="'ticket_damage.create_new_b'"
      [buttonTypeEnum]="ButtonTypeEnum.LIGHT_BORDERED"
      [showButton]="false"
      (tapped)="createTicket()"
    ></rs-web-cards-container-header>
    <div class="d-flex">
      @if ((numberOfContracts | async) >= 2) {
        <!-- desktop view -->
        <rs-web-contract-menu
          class="mb-3 desktop"
          [immomioIconName]="immomioIconName.ContextMenu"
          [iconSize]="12"
          [contractMenuDesign]="ContractMenuDesign.SELECT"
          [showContract]="false"
          [disableExpiredContractSelection]="true"
        ></rs-web-contract-menu>

        <!-- mobile view -->
        <rs-web-contract-menu
          class="mb-3 mobile"
          [immomioIconName]="immomioIconName.ContextMenu"
          [iconSize]="16"
          [contractMenuDesign]="ContractMenuDesign.DROPDOWN"
          [showContract]="false"
          [disableExpiredContractSelection]="true"
          [showIcon]="true"
          [dropdownLeft]="true"
        ></rs-web-contract-menu>
      }
      @if ((ticketingVersion$ | async) === ticketingVersion.METADATA) {
        <rs-web-sorting-menu class="sort-menu" [type]="sortTypes.TICKETS"></rs-web-sorting-menu>
      }
    </div>
  </div>

  <div class="mb-4">
    <rs-web-tickets
      [tickets]="damageTickets$ | async"
      [ticketsActionState]="damageTicketsActionState$ | async"
      [hasNextPage]="hasNextPage$ | async"
      [hasActiveContracts]="selectedContractIsActive"
      [createButtonText]="'ticket_damage.create_new_b'"
      [txtNoTickets]="'active_damage.no_data'"
      (openTicket)="openTicket.emit($event)"
      (loadMoreTickets)="loadMoreDamageTickets()"
      (createTicket)="createTicket()"
    >
      @if (
        (permissions$ | async).internalTicketing === false &&
        (appSettings$ | async).ticketingVersion === ticketingVersion.METADATA
      ) {
        <rs-web-ticket-information-notice
          class="col-lg-4 col-xxl-3"
          new-ticket-info
        ></rs-web-ticket-information-notice>
      }
    </rs-web-tickets>
  </div>
</div>
