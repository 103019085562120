<div class="d-flex gap-2 mb-2 secondary-text">
  <rs-web-icon [icon]="immomioIconName.Calendar" [size]="16"></rs-web-icon>
  <div>{{ ticketDetails.created | date: 'EEEEEE dd.MM.yyyy' }}</div>
</div>

@if (ticketDetails.formResponses.length > 0 || ticketDetails.categoryResponses.length > 0) {
  <div class="mb-3 h4 primary-text">{{ 'damage.damage_details' | translate }}</div>
  <div>
    @if (ticketDetails.categoryResponses.length > 0) {
      @for (response of ticketDetails.categoryResponses; track response.index) {
        <div class="mb-2">
          <rs-web-label [text]="response.questionText" className="secondary-text"></rs-web-label>
        </div>
        <div class="mb-2 primary-text">{{ response.response }}</div>
      }
    }
    @if (ticketDetails.formResponses.length > 0) {
      <hr class="my-3" />
      @for (
        response of ticketDetails.formResponses.slice(0, shownAnswersCount);
        track response.index
      ) {
        @if (
          (response.responses?.length || response.attachments?.length) &&
          response.responses?.[0] !== ''
        ) {
          <div class="mb-2">
            <rs-web-label [text]="response.questionText" className="secondary-text"></rs-web-label>
          </div>
        }
        @switch (response.type) {
          @case (formResponseQuestionType.TEXTBOX) {
            <p class="mb-2 primary-text" [innerHTML]="response.responses[0]"></p>
          }
          @case (formResponseQuestionType.TEXTFIELD) {
            <p class="mb-2 primary-text" [innerHTML]="response.responses[0]"></p>
          }
          @case (formResponseQuestionType.DATE) {
            <div class="mb-2 primary-text">
              {{ response.responses[0] | date: 'EEEEEE dd.MM.yyyy' }}
            </div>
          }
          @case (formResponseQuestionType.ATTACHMENTS) {
            <!-- Images -->
            <div class="row gy-4 mb-2">
              @for (s3File of response.attachments; track s3File.identifier) {
                @if (s3File.extension !== 'pdf') {
                  <div class="col-4">
                    <rs-web-ticket-image-viewer
                      [ticketId]="ticketDetails.id"
                      [attachmentIdentifier]="s3File.identifier"
                    ></rs-web-ticket-image-viewer>
                  </div>
                }
              }
            </div>
            <!-- PDFs -->
            <div class="row gy-4 mb-2">
              <div class="d-flex flex-column">
                @for (s3File of response.attachments; track s3File.identifier) {
                  @if (s3File.extension === 'pdf') {
                    <rs-web-pdf-attachment
                      (click)="downloadTicketAttachment(ticketDetails.id, s3File)"
                      role="button"
                      [s3File]="s3File"
                      [isRemovable]="false"
                    >
                    </rs-web-pdf-attachment>
                  }
                }
              </div>
            </div>
          }
          @case (formResponseQuestionType.SELECTION) {
            <div class="mb-2 primary-text">{{ response.responses[0] }}</div>
          }
          @case (formResponseQuestionType.MULTISELECT) {
            <div class="mb-2 primary-text">
              @for (value of response.responses; track value; let last = $last) {
                {{ value }}{{ !last ? ', ' : '' }}
              }
            </div>
          }
        }
      }
    }
  </div>
}
@if (showMoreButton) {
  <div class="d-flex">
    <rs-web-button [type]="buttonTypeEnum.LIGHT_BORDERED" (tapped)="showMore()"
      >{{ ('general.show_more_b' | translate) + ' +' }}
    </rs-web-button>
  </div>
}
