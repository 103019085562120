import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class FileSizePipe implements PipeTransform {
  transform(input: string | number, decimalPoints = 2): string {
    let bytes: number;

    if (typeof input === 'string') {
      bytes = this.calculateBase64Size(input);
    } else if (typeof input === 'number') {
      bytes = input;
    } else {
      return '-';
    }

    if (bytes === 0) return '0 Bytes';

    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const k = 1024;
    const dm = decimalPoints < 0 ? 0 : decimalPoints;
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const size = bytes / Math.pow(k, i);
    return `${parseFloat(size.toFixed(dm))} ${units[i]}`;
  }

  calculateBase64Size(base64: string): number {
    const base64Str = base64.split(',')[1] || base64; // Remove metadata if present
    const length = base64Str.length;
    const padding = (base64Str.match(/=+$/) || [''])[0].length;
    return (length * 3) / 4 - padding;
  }
}
