import { ConsumptionAggregateType, ConsumptionType, ConsumptionUnitType, PeriodType } from './';
import { Milliseconds } from '../../utils';
import { ContractId } from '../masterdata';
import { IPeriod } from '../general';

export interface IConsumptionResponse {
  consumptionList: IConsumptionItemDto[];
}

export interface IConsumptionItemDto {
  periodStart: EpochTimeStamp; // UTC timezone
  periodEnd: EpochTimeStamp; // UTC timezone
  consumptionType: ConsumptionType;
  amount: number;
  unit: string;
  periodStartValue: number;
  periodEndValue: number;
}

export interface IConsumptionItem {
  amount: number;
  consumptionType: ConsumptionType;
  period: IPeriod; // localTimeZone
  periodStartValue: number;
  periodEndValue: number;
  date: Milliseconds;
  unit: ConsumptionUnitType;
  estimated?: boolean;
}

export interface IConsumptionBenchmarkResponse {
  benchmarks: IConsumptionBenchmarkItemResponse[];
}

export interface IConsumptionBenchmarkItemResponse {
  amount: number;
  periodStart: EpochTimeStamp; // UTC timezone
  periodEnd: EpochTimeStamp; // UTC timezone
  consumptionType: ConsumptionType;
  unit: ConsumptionUnitType;
  meterId: string;
}

export interface IConsumptionInfo {
  meters: IConsumptionInfoItem[];
}

export interface IConsumptionInfoDto {
  meters: IConsumptionInfoItemDto[];
}

export interface IConsumptionInfoItem {
  deviceId: DeviceId;
  currentValue: number;
  defaultAggregation: DefaultAggregationType;
  updateInterval: Milliseconds | null;
  type: ConsumptionType;
  measurementUnit: ConsumptionUnitType;
  max: number;
  firstEntry: Milliseconds;
  lastEntry: Milliseconds;
}
export interface IConsumptionInfoItemDto
  extends Omit<IConsumptionInfoItem, 'firstEntry' | 'lastEntry' | 'defaultAggregation'> {
  defaultAggregation: 'day' | 'month'; // lowerCase of Exclude<ConsumptionAggregateType, ConsumptionAggregateType.CURRENT>
  firstEntry: EpochTimeStamp; // UTC timezone
  lastEntry: EpochTimeStamp; // UTC timezone
}

export type DefaultAggregationType = Exclude<
  ConsumptionAggregateType,
  ConsumptionAggregateType.CURRENT
>;

export interface IConsumption {
  consumption: IConsumptionItem;
  periodType: PeriodType;
  pastConsumption: IConsumptionItem;
  benchmark: IConsumptionBenchmarkItemResponse;
}

export type DeviceId = string;
export type MetersByContractR = Record<ContractId, IConsumptionInfo>;
export type ConsumptionsByContractR = Record<ContractId, ConsumptionsByDeviceR>;
export type ConsumptionsByDeviceR = Record<DeviceId, IConsumptionsByPeriod[]>;
export interface IConsumptionsByPeriod {
  selectedPeriod: IPeriod;
  consumption: IConsumptionItem[];
  pastConsumption: IConsumptionItem[];
  benchmark: IConsumptionBenchmarkItemResponse[];
}
