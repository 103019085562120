<rs-web-side-sheet-form
  #sideSheetFormComponent="rsWebSideSheetForm"
  [formGroup]="form"
  [showBackButton]="!showSuccess() && !showError()"
  [loading]="false"
  [doneButtonText]="'submit_l' | translate"
  [showNav]="false"
  (dismiss)="$event.dismiss()"
>
  <span class="sidesheet-title" sidesheet-title>
    {{ 'user_edit_heading' | translate }}
  </span>

  <ng-template
    rsWebSideSheetContent
    [label]="'Selection'"
    [currentStepValid]="form.controls.selection.valid"
    [customDoneButtonText]="'next'"
    (next)="selectionFinished()"
  >
    <rs-web-user-profile-edit-selection
      (goToConcerns)="goToConcerns()"
    ></rs-web-user-profile-edit-selection>
  </ng-template>

  <ng-template
    rsWebSideSheetContent
    [label]="'user_edit_overview_l' | translate"
    [currentStepValid]="form.valid"
    [customDoneButtonText]="'next'"
    (next)="sideSheetFormComponent.nextTab()"
  >
    @switch (form.value.selection.changeType) {
      @case (MasterDataChangeType.NAME) {
        <rs-web-user-profile-edit-name></rs-web-user-profile-edit-name>
      }
      @case (MasterDataChangeType.DATE_OF_BIRTH) {
        <rs-web-user-profile-edit-date-of-birth></rs-web-user-profile-edit-date-of-birth>
      }
      @case (MasterDataChangeType.ADDRESS) {
        <rs-web-user-profile-edit-address></rs-web-user-profile-edit-address>
      }
      @case (MasterDataChangeType.PHONE) {
        <rs-web-user-profile-edit-phone></rs-web-user-profile-edit-phone>
      }
      @case (MasterDataChangeType.MOBILE) {
        <rs-web-user-profile-edit-phone formGroupName="mobile"></rs-web-user-profile-edit-phone>
      }
      @case (MasterDataChangeType.EMAIL) {
        <rs-web-user-profile-edit-email></rs-web-user-profile-edit-email>
      }
    }
  </ng-template>

  <ng-template
    rsWebSideSheetContent
    [label]="'Summary'"
    [currentStepValid]="true"
    [customDoneButtonText]="doneButtonText() | translate"
    (done)="lastStepDone()"
  >
    @if (showSuccess()) {
      <rs-web-user-profile-edit-success></rs-web-user-profile-edit-success>
    } @else if (showError()) {
      <rs-web-user-profile-edit-error></rs-web-user-profile-edit-error>
    } @else {
      <rs-web-user-profile-edit-summary [changeType]="form.value.selection.changeType">
        @switch (form.value.selection.changeType) {
          @case (MasterDataChangeType.NAME) {
            {{ form.value.name.name }} {{ form.value.name.lastName }}
            <div class="mt-3">
              <rs-web-pdf-attachment
                [file]="form.value.name.verificationFile[0]"
                [isRemovable]="false"
              ></rs-web-pdf-attachment>
            </div>
          }
          @case (MasterDataChangeType.DATE_OF_BIRTH) {
            {{ form.value.dateOfBirth | date: 'dd.MM.YYYY' }}
          }
          @case (MasterDataChangeType.ADDRESS) {
            {{ $any(form.value.address) | address }}
          }
          @case (MasterDataChangeType.PHONE) {
            {{ form.value.phone.countryCode }} {{ form.value.phone.prefix }}
            {{ form.value.phone.phoneNumber }}
          }
          @case (MasterDataChangeType.MOBILE) {
            {{ form.value.mobile.countryCode }} {{ form.value.mobile.prefix }}
            {{ form.value.mobile.phoneNumber }}
          }
          @case (MasterDataChangeType.EMAIL) {
            {{ form.value.email }}
          }
        }
      </rs-web-user-profile-edit-summary>
    }
  </ng-template>
</rs-web-side-sheet-form>
