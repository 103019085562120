import { Directive, inject } from '@angular/core';

import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';

import { WhitelabelImage, WhitelabelImageType } from '../../models';
import { IActionState } from '../../utils';
import { getSendMasterDataChangeRequestActionState } from '../../+state';

@Directive()
export class UserEditErrorPageBaseComponent {
  public headerTitle = 'user_edit_error_heading';
  public whitelabelImage = WhitelabelImage;
  public whitelabelImageType = WhitelabelImageType;
  public errorMsgKeys$: Observable<string[]> = inject(Store)
    .select(getSendMasterDataChangeRequestActionState)
    .pipe(
      map((state: IActionState) => {
        const errorMsg = state?.error?.message;
        return errorMsg === 'MASTERDATA_CHANGE_REQUEST_ALREADY_EXISTS'
          ? ['open_request_desc_1', 'open_request_desc_2', 'open_request_desc_3']
          : ['erp_desc_1', 'erp_desc_2'];
      })
    );
}
