@if ((newsActionState$ | async).done) {
  @if (news$ | async; as news) {
    <div class="container container--with-spacing">
      <div class="d-flex align-items-center mb-3">
        <rs-web-breadcrumbs
          [items]="breadcrumbs"
          (navigateBack)="onNavigateBack()"
          (navigateToUrl)="onNavigate($event)"
        ></rs-web-breadcrumbs>
      </div>
      <div class="container__content">
        <div class="d-flex justify-content-center">
          <rs-web-image [defaultSrc]="news.imageUrl" [maxHeightInPx]="400"></rs-web-image>
        </div>
        <div class="d-flex justify-content-center mt-4">
          <h3>{{ news.title }}</h3>
        </div>
        <span class="subtitle">{{ news.publishedAt | rsWebDate }}</span>
        <hr />
        <p [innerHtml]="news.text" class="primary-text"></p>
        @if (news?.href) {
          <div class="d-flex justify-content-center mt-4">
            <a [href]="news?.href" target="_blank">
              <rs-web-button [type]="buttonTypeEnum.PRIMARY" class="pe-none"
                >{{ 'general.learn_more_b' | translate }}
              </rs-web-button>
            </a>
          </div>
        }
      </div>
    </div>
  }
} @else {
  <div class="loading-area d-flex justify-content-center">
    <rs-web-loader></rs-web-loader>
  </div>
}
