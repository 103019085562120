export enum AccentColors {
  MAGENTA = '#D81B60',
  BERRY = '#AD1357',
  WARM_RED = '#FF6450',
  PAPAYA = '#FF8D85',
  WALNUT = '#E07F5D',
  SIGNAL = '#F4501E',
  CURRY = '#F09300',
  MUSTARD = '#F6BF25',
  MACADAMIA = '#F7C976',
  KELP = '#AFB83B',
  SPINACH = '#5FBF89',
  MINT = '#80E5AC',
  LEAVE = '#6ACCBC',
  PETROL = '#0088B0',
  BRIGHT_BLUE = '#8EC3FF',
  SOFT_BLUE = '#D1F0F8',
  AQUA = '#A2EEFF',
  AZZURRO = '#4FE8E8',
  SKYLIGHT = '#039BE5',
  BLUEHOUSE = '#044ADA',
  NIGHTSKY = '#193F70',
  FLIEDER = '#884DFF',
  LILAC = '#5538D7',
  AUBERGINE = '#8E24AA',
  MELLOW = '#B39DDB',
  POWERPUFF = '#EB96EB',
  PINK = '#E05194',
  TARO = '#808080',
  ROCK = '#9AA4B2',
  HAZELNUT = '#CCAC93',
  CACAO = '#795548',
}

export enum DefaultBrandColors {
  PRIMARY = '#3486EF',
  SECONDARY = '#193F70',
}

export enum AdditionalColors {
  POSITIVE_SUCCESS = '#5FBF89',
  POSITIVE_GOOD = '#80E5AC',
  MODERATE = '#F7C976',
  NEGATIVE_ERROR = '#ED5D57',
  NEGATIVE_LOW = '#E07F5D',
  INFORMATION_INFO = '#039BE5',
  INFORMATION_HELP = '#884DFF',
  PREMIUM = '#F6BF25',
  DEACTIVATED = '#C5CCD6',
}
