import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardWebComponent } from '../../../atoms/card/card.component';
import { IconWebComponent } from '../../../atoms/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { ImmomioIconName } from '@resident-nx/shared';

@Component({
  selector: 'rs-web-ticket-information-notice',
  standalone: true,
  imports: [CommonModule, CardWebComponent, IconWebComponent, TranslateModule],
  templateUrl: './ticket-information-notice.component.html',
  styleUrl: './ticket-information-notice.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketInformationNoticeComponent {
  protected readonly ImmomioIconName = ImmomioIconName;
}
