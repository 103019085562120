import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';

import {
  ApexAxisChartSeries,
  ApexDataLabels,
  ApexPlotOptions,
  ApexStates,
  ApexStroke,
  ApexXAxis,
  ChartComponent,
  NgApexchartsModule,
} from 'ng-apexcharts';
import { ApexColorFunction } from '../../molecules/consumption-chart/types/apex-color-function';
import { ConsumptionChartOptions } from '../../molecules/consumption-chart/types/consumption-chart-options';
import {
  ConsumptionSeriesIndex,
  IConsumptionItem,
  ImmomioIconName,
  NgChanges,
} from '@resident-nx/shared';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ApexDataPointSelection } from '../../molecules/consumption-chart/types/apex-data-point-selection';
import { IconWebComponent } from '../icon/icon.component';

enum SeriesPeriod {
  FULL_YEAR = 'FULL_YEAR',
  FIRST_SEMESTER = 'FIRST_SEMESTER',
  SECOND_SEMESTER = 'SECOND_SEMESTER',
}

interface EstimatedDataPointIndices {
  [seriesIndex: number]: {
    [dataPointIndex: number]: boolean;
  };
}

@Component({
  selector: 'rs-web-consumption-chart-year',
  templateUrl: './consumption-chart-year.component.html',
  styleUrls: ['./consumption-chart-year.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgApexchartsModule, IconWebComponent],
})
export class ConsumptionChartYearWebComponent implements OnChanges {
  @Input() consumption: IConsumptionItem[];
  @Input() pastConsumption: IConsumptionItem[];

  @Input() selectedDataPointIndex: number;
  @Input() options: ConsumptionChartOptions;

  @Output() consumptionSelected = new EventEmitter<IConsumptionItem>();

  public readonly SeriesPeriod = SeriesPeriod;
  public readonly ImmomioIconName = ImmomioIconName;

  // holds series for full year, 1st semester, 2nd semester
  public availableSeries: Record<SeriesPeriod, ApexAxisChartSeries>;
  public availableCategories: Record<SeriesPeriod, string[]>;
  // rendered series will be determined by activeSeries
  public activeSeriesPeriod = SeriesPeriod.FULL_YEAR;
  // true only shows first/last 6 months, determined by activeSeriesPeriod
  public isSemesterMode = false;

  public stroke: ApexStroke;
  public plotOptions: ApexPlotOptions;
  public colors: (ApexColorFunction | string)[];
  public states: ApexStates;
  public dataLabels: ApexDataLabels;

  private estimatedDataPointIndices: EstimatedDataPointIndices = {
    [ConsumptionSeriesIndex.PAST_CONSUMPTION]: {},
    [ConsumptionSeriesIndex.CONSUMPTION]: {},
  };

  @ViewChild(ChartComponent) chart: ChartComponent;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private cdr: ChangeDetectorRef
  ) {
    this.initChart();

    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .pipe(takeUntilDestroyed())
      .subscribe(state => this.reactOnBreakpointStateChange(state));
  }

  ngOnChanges(changes: NgChanges<ConsumptionChartYearWebComponent>): void {
    if (changes.selectedDataPointIndex) {
      // re-execute bar color functions
      void this.chart?.updateOptions({ colors: this.colors });
    }

    if (changes.options) {
      this.updateXAxis();
      this.registerDataPointSelectionEventHandler();
    }

    if (changes.consumption) {
      this.updateSeries(ConsumptionSeriesIndex.CONSUMPTION);

      if (this.consumption?.length > 0) {
        const lastIndex = this.consumption.length - 1;
        this.selectedDataPointIndex = lastIndex;
        const entry = this.consumption[lastIndex];
        this.consumptionSelected.emit(entry);
      }
    }

    if (changes.pastConsumption) {
      this.updateSeries(ConsumptionSeriesIndex.PAST_CONSUMPTION);
    }
  }

  private initChart(): void {
    this.availableSeries = {
      [SeriesPeriod.FULL_YEAR]: [
        { name: 'Previous', data: [] },
        { name: 'Selected', data: [] },
      ],
      [SeriesPeriod.FIRST_SEMESTER]: [
        { name: 'Previous', data: [] },
        { name: 'Selected', data: [] },
      ],
      [SeriesPeriod.SECOND_SEMESTER]: [
        { name: 'Previous', data: [] },
        { name: 'Selected', data: [] },
      ],
    };

    const categoryForEachMonth = this.createMonthNames();

    this.availableCategories = {
      [SeriesPeriod.FULL_YEAR]: categoryForEachMonth,
      [SeriesPeriod.FIRST_SEMESTER]: categoryForEachMonth.slice(0, 6),
      [SeriesPeriod.SECOND_SEMESTER]: categoryForEachMonth.slice(6, 12),
    };

    this.states = {
      hover: {
        filter: { type: 'none' },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: { type: 'none' },
      },
    };
    this.colors = [
      'var(--surface-hover, #F2F2F2)',
      ({ dataPointIndex, seriesIndex }) => {
        const activeColor = 'var(--primary-default, #3486EF)';
        const inactiveColor = 'var(--bg-medium, #EBF2FF)';
        const activeColorEstimated = 'var(--Status-Moderate-Default, #F7C976)';
        const inactiveColorEstimated = 'var(--Status-Moderate-Light, #FEF6E9)';
        const isBarSelected = dataPointIndex === this.selectedDataPointIndex;

        const isEstimated = this.estimatedDataPointIndices[seriesIndex][dataPointIndex];

        if (isEstimated) {
          return isBarSelected ? activeColorEstimated : inactiveColorEstimated;
        }

        return isBarSelected ? activeColor : inactiveColor;
      },
    ];
    this.stroke = {
      show: true,
      width: 4,
      colors: ['transparent'],
    };
    this.plotOptions = {
      bar: {
        columnWidth: '20px',
        borderRadius: 6,
        dataLabels: {
          position: 'top',
        },
      },
    };
    this.dataLabels = {
      enabled: true,
      formatter: (value, options) => {
        const { seriesIndex, dataPointIndex } = options;
        const isEstimatedValue = this.estimatedDataPointIndices[seriesIndex][dataPointIndex];
        return isEstimatedValue ? '*' : '';
      },
      offsetY: -15,
      offsetX: 2,
      style: {
        fontSize: '12px',
        colors: ['var(--primary-default, #3486EF)'],
      },
    };
  }

  selectSeriesPeriod(seriesPeriod: SeriesPeriod): void {
    this.activeSeriesPeriod = seriesPeriod;
    this.updateXAxis();
    if (seriesPeriod !== SeriesPeriod.FULL_YEAR) {
      this.selectedDataPointIndex = null;
    }
    // normally not needed, but chart update via inputs does not always work
    void this.chart?.updateOptions({ xaxis: this.options.xaxis, colors: this.colors });
    void this.chart?.updateSeries(this.availableSeries[this.activeSeriesPeriod]);
    this.cdr.markForCheck();
  }

  private updateSeries(consumptionIndex: ConsumptionSeriesIndex): void {
    const data =
      consumptionIndex === ConsumptionSeriesIndex.CONSUMPTION
        ? this.consumption
        : this.pastConsumption;

    this.estimatedDataPointIndices[consumptionIndex] = {};

    const availableSeries = this.availableSeries;

    const isDataEmpty = !Array.isArray(data) || data.length === 0;

    if (isDataEmpty) {
      const emptySeries: ApexAxisChartSeries[0] = { name: '', data: [] };
      availableSeries[SeriesPeriod.FULL_YEAR][consumptionIndex] = emptySeries;
      availableSeries[SeriesPeriod.FIRST_SEMESTER][consumptionIndex] = emptySeries;
      availableSeries[SeriesPeriod.SECOND_SEMESTER][consumptionIndex] = emptySeries;
    } else {
      // prepare array with one entry for Jan - Dec and value 0
      const fullYearData = Array.from({ length: 12 }).map(() => 0);
      // fill prepared array with real values
      for (const { amount, period, estimated } of data) {
        const monthIndexOfConsumption = new Date(period.start).getMonth();
        fullYearData[monthIndexOfConsumption] = parseFloat(amount.toFixed(2));

        if (estimated) {
          this.estimatedDataPointIndices[consumptionIndex][monthIndexOfConsumption] = true;
        }
      }

      const name = `${this.getConsumptionYearFromData(data)}`;

      availableSeries[SeriesPeriod.FULL_YEAR][consumptionIndex] = {
        name,
        data: fullYearData, // Jan - Dec
      };

      availableSeries[SeriesPeriod.FIRST_SEMESTER][consumptionIndex] = {
        name,
        data: fullYearData.slice(0, 6), // Jan - Jun
      };
      availableSeries[SeriesPeriod.SECOND_SEMESTER][consumptionIndex] = {
        name,
        data: fullYearData.slice(6, 12), // Jul - Dec
      };
    }

    // return new object and arrays to support ChangeDetectionStrategy.OnPush
    this.availableSeries = {
      [SeriesPeriod.FULL_YEAR]: [...availableSeries[SeriesPeriod.FULL_YEAR]],
      [SeriesPeriod.FIRST_SEMESTER]: [...availableSeries[SeriesPeriod.FIRST_SEMESTER]],
      [SeriesPeriod.SECOND_SEMESTER]: [...availableSeries[SeriesPeriod.SECOND_SEMESTER]],
    };
  }

  public registerDataPointSelectionEventHandler() {
    this.options = {
      ...this.options,
      chart: {
        ...this.options.chart,
        events: {
          ...this.options.chart.events,
          dataPointSelection: (
            _event: MouseEvent,
            _context: unknown,
            { dataPointIndex }: ApexDataPointSelection
          ) => this.onDataPointSelection(dataPointIndex),
        },
      },
    };
  }

  public onDataPointSelection(dataPointIndex: number): void {
    this.selectedDataPointIndex = dataPointIndex;
    void this.chart?.updateOptions({ colors: this.colors });

    const indexOffset = this.activeSeriesPeriod === SeriesPeriod.SECOND_SEMESTER ? 6 : 0;
    const selectedEntry = this.consumption[dataPointIndex + indexOffset];
    if (!selectedEntry) {
      return;
    }
    this.consumptionSelected.emit(selectedEntry);
  }

  private reactOnBreakpointStateChange(state: BreakpointState) {
    this.isSemesterMode = state.matches;
    this.isSemesterMode
      ? this.selectSeriesPeriod(SeriesPeriod.FIRST_SEMESTER)
      : this.selectSeriesPeriod(SeriesPeriod.FULL_YEAR);
  }

  private createXAxis(): ApexXAxis {
    const categories = this.availableCategories[this.activeSeriesPeriod];
    return {
      ...this.options?.xaxis,
      type: 'category',
      categories,
      labels: {
        ...this.options?.xaxis.labels,
        style: {
          cssClass: 'consumption-chart-axis-label--xaxis--year',
        },
      },
    };
  }

  private updateXAxis(): void {
    this.options = { ...this.options, xaxis: this.createXAxis() };
  }

  private getConsumptionYearFromData(data: IConsumptionItem[]): number {
    const [firstEntry] = data;
    return new Date(firstEntry.period.start).getFullYear();
  }

  private createMonthNames(): string[] {
    return Array.from({ length: 12 }).map((_value, index) => {
      const date = new Date(2023, index);
      return format(date, 'LLL', { locale: de }).toUpperCase();
    });
  }
}
