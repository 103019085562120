import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonTypeEnum,
  ITicketResidentOverview,
  LoadActiveDamageTickets,
  LoadActiveRequestTickets,
  LoadingType,
  TicketsWidgetBaseComponent,
} from '@resident-nx/shared';
import { SideSheetService } from '../../../../services';
import { LoaderWebComponent } from '../../../atoms/loader/loader.component';
import { TicketCardWebComponent } from '../ticket-card/ticket-card.component';
import { CardsContainerHeaderWebComponent } from '../../../molecules/cards-container-header/cards-container-header.component';
import { CardWebComponent } from '../../../atoms/card/card.component';
import { NoDataWebComponent } from '../../../atoms/no-data/no-data.component';
import { LoadingSkeletonWebComponent } from '../../../atoms/loading-skeleton/loading-skeleton.component';
import { TicketDetailsPageWebComponent } from '../ticket-details/ticket-details-page/ticket-details-page.component';

@Component({
  selector: 'rs-web-ticket-widget',
  standalone: true,
  imports: [
    AsyncPipe,
    CardsContainerHeaderWebComponent,
    CardWebComponent,
    LoaderWebComponent,
    TicketCardWebComponent,
    TranslateModule,
    NoDataWebComponent,
    LoadingSkeletonWebComponent,
  ],
  templateUrl: './tickets-widget.component.html',
  styleUrl: './tickets-widget.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketsWidgetWebComponentComponent
  extends TicketsWidgetBaseComponent
  implements OnInit
{
  public ticketsLimit = 3;
  public ButtonEnumTypes = ButtonTypeEnum;
  protected readonly LoadingType = LoadingType;
  private sideSheetService = inject(SideSheetService);

  ngOnInit() {
    this.store.dispatch(LoadActiveRequestTickets({ limit: 4, offset: 0 }));
    this.store.dispatch(LoadActiveDamageTickets({ limit: 4, offset: 0 }));
  }

  public ticketNodeTabbed(node: ITicketResidentOverview) {
    this.sideSheetService.open(TicketDetailsPageWebComponent, {
      data: {
        ticketId: node.id,
        ticketIssueType: node.issueType,
      },
    });
  }
}
