<div class="cards-container pb-2" (scroll)="onCardsScroll($event.target)" #scrollableContainer>
  @for (meter of metersByContract?.meters; track meter.deviceId) {
    <div class="card-container" [ngClass]="meter?.type">
      <rs-web-consumption-dashboard-card
        [meter]="meter"
        (click)="navigateToDetails(meter.deviceId)"
      ></rs-web-consumption-dashboard-card>
    </div>
  } @empty {
    <div class="no-data-root">
      <div class="no-data-container">
        <rs-web-image [defaultSrc]="'/web/images/svg/consumption-no-data.svg'"></rs-web-image>
        <p class="mb-0 text-size-lg">{{ 'consumption.no_data_l' | translate }}</p>
      </div>
    </div>
  }
</div>

@if (metersByContract?.meters.length > 1) {
  <div class="mobile-controls">
    <rs-web-icon
      [ngClass]="{ hidden: activeCardIndex === 0 }"
      (click)="getPrevConsumption(true)"
      [icon]="immomioIconName.ChevronLeft"
      [size]="16"
    ></rs-web-icon>
    <div class="d-flex circle-container">
      @for (meter of metersByContract?.meters; track meter.deviceId; let index = $index) {
        <div class="circle" [ngClass]="{ active: activeCardIndex === index }"></div>
      }
    </div>
    <rs-web-icon
      [ngClass]="{ hidden: activeCardIndex === metersByContract?.meters.length - 1 }"
      (click)="getNextConsumption(true)"
      [icon]="immomioIconName.ChevronRight"
      [size]="16"
    ></rs-web-icon>
  </div>
}
