import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

import { UserEditErrorPageBaseComponent } from '@resident-nx/shared';
import { ImageWebComponent } from '../../../../atoms/image/image.component';

@Component({
  selector: 'rs-web-user-profile-edit-error',
  standalone: true,
  imports: [CommonModule, ImageWebComponent, TranslateModule],
  templateUrl: './user-profile-edit-error.component.html',
  styleUrl: './user-profile-edit-error.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileEditErrorComponent extends UserEditErrorPageBaseComponent {}
