import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnChanges,
  OnInit,
} from '@angular/core';
import {
  ConsumptionAggregateType,
  ConsumptionTimeframe,
  ConsumptionType,
  ConsumptionUnitType,
  getShowNextArrowForMeter,
  getShowPreviousArrowForMeter,
  IConsumptionInfoItem,
  IConsumptionState,
  NameDisplayMode,
  NgChanges,
  SelectPeriod,
} from '@resident-nx/shared';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable, startWith } from 'rxjs';
import { endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import { ConsumptionComparisonWebComponent } from '../../molecules/consumption-comparison/consumption-comparison.component';
import { ConsumptionChartWebComponent } from '../../molecules/consumption-chart/consumption-chart.component';
import { ConsumptionDatePickerWebComponent } from '../../molecules/consumption-date-picker/consumption-date-picker.component';
import { ElevationDirective } from '../../../directives/elevation/elevation.directive';
import { TranslateModule } from '@ngx-translate/core';

type PossibleTimeframes = ConsumptionTimeframe.MONTH | ConsumptionTimeframe.YEAR;

@Component({
  selector: 'rs-web-consumption-card',
  templateUrl: './consumption-card.component.html',
  styleUrls: ['./consumption-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ElevationDirective,
    ConsumptionDatePickerWebComponent,
    FormsModule,
    ReactiveFormsModule,
    ConsumptionChartWebComponent,
    ConsumptionComparisonWebComponent,
    AsyncPipe,
    TranslateModule,
  ],
})
export class ConsumptionCardWebComponent implements OnInit, OnChanges {
  public readonly NameDisplayMode = NameDisplayMode;
  public readonly ConsumptionAggregateType = ConsumptionAggregateType;
  public readonly ConsumptionTimeframe = ConsumptionTimeframe;
  private destroyRef = inject(DestroyRef);

  // fallback
  @Input() meter: IConsumptionInfoItem = {
    deviceId: '',
    currentValue: 0,
    defaultAggregation: ConsumptionAggregateType.DAY,
    updateInterval: 0,
    type: ConsumptionType.POWER,
    measurementUnit: ConsumptionUnitType.M3,
    max: 0,
    firstEntry: 0,
    lastEntry: 0,
  };

  public selectedTimeframe$ = new BehaviorSubject<PossibleTimeframes>(ConsumptionTimeframe.YEAR);
  public selectedDateControl: FormControl<Date> = new FormControl<Date>(null);
  public nextPeriodAvailable$: Observable<boolean>;
  public previousPeriodAvailable$: Observable<boolean>;

  public yearBeforeSelectedDate$ = this.selectedDateControl.valueChanges.pipe(
    startWith(new Date()),
    map(date => date?.getFullYear() - 1)
  );

  constructor(private store: Store<IConsumptionState>) {}

  ngOnInit(): void {
    combineLatest([this.selectedDateControl.valueChanges, this.selectedTimeframe$])
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(([date, timeframe]) => this.selectPeriod(date, timeframe));

    this.selectedDateControl.setValue(new Date());
  }

  ngOnChanges(changes: NgChanges<ConsumptionCardWebComponent>): void {
    if (changes.meter) {
      this.nextPeriodAvailable$ = this.store.select(getShowNextArrowForMeter(this.meter.deviceId));
      this.previousPeriodAvailable$ = this.store.select(
        getShowPreviousArrowForMeter(this.meter.deviceId)
      );

      if (!changes.meter.firstChange) {
        // needed for mobile view, as meter for card changes dynamically by tab select
        this.selectedDateControl.setValue(new Date());
      }
    }
  }

  private selectPeriod(date: Date, timeframe: PossibleTimeframes): void {
    const isMonthSelected = timeframe === ConsumptionTimeframe.MONTH;
    const { deviceId } = this.meter;
    const start = isMonthSelected ? startOfMonth(date) : startOfYear(date);
    const end = isMonthSelected ? endOfMonth(date) : endOfYear(date);
    if (deviceId) {
      this.store.dispatch(SelectPeriod({ deviceId, start, end }));
    }
  }

  onTimeframeSelect(timeframe: PossibleTimeframes) {
    this.selectedTimeframe$.next(timeframe);
  }
}
