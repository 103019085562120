import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IValidationDataSet, ValidationDataItem } from '../../models/helpers/validation-data';

@Directive()
export class PasswordCheckBaseComponent implements OnInit, OnDestroy {
  @Input() controller: AbstractControl;
  @Input() className: string;

  validationDataSet: IValidationDataSet;
  validationDataArray: ValidationDataItem[];
  subs$: Subscription[] = [];

  ngOnInit(): void {
    this.validationDataSet = {
      minLength: {
        id: 'minLength',
        control: this.controller,
        text: 'pw-check.error.pw-min_length',
      },
      hasLowerUpperCase: {
        id: 'hasLowerUpperCase',
        control: this.controller,
        text: 'pw-check.error.pw-has_lower_upper_case',
      },
      hasNumberSpecial: {
        id: 'hasNumberSpecial',
        control: this.controller,
        text: 'pw-check.error.pw-has_number_special',
      },
    };
    this.validationDataArray = Object.values(this.validationDataSet);
    this.subs$[0] = this.controller.valueChanges.subscribe(() => {
      for (let i = 0; i < this.validationDataArray.length; i++) {
        const validationItem = this.validationDataArray[i];
        validationItem.valid = !this.controller.hasError(validationItem.id);
      }
    });
  }

  ngOnDestroy(): void {
    this.subs$.forEach(sub => sub?.unsubscribe());
  }
}
