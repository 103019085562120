import {
  IFormResponseCreate,
  ITicketCreationSummary,
  ITicketMetadataCreate,
  TicketIssueType,
} from '../../models';

import { ticketConverter } from './ticket.converter';

export const ticketMetadataConverter = {
  formatCreateTicketRequest: (
    ticketSummary: ITicketCreationSummary,
    issueType: TicketIssueType
  ): ITicketMetadataCreate => {
    const formResponses: IFormResponseCreate[] = ticketConverter.formatFormResponses(ticketSummary);

    return {
      formResponses,
      issueType,
      contractNumber: null,
      categoryAnswerSetRelationId: ticketSummary.categoryAnswerSetRelationId,
    };
  },
};
