<rs-web-side-sheet-form
  #sideSheetFormComponent="rsWebSideSheetForm"
  (dismiss)="$event.dismiss()"
  [doneButtonText]="'submit_l' | translate"
  [loading]="false"
  [showBackButton]="!showSuccess() && !showError()"
  [showNav]="false"
>
  <span class="sidesheet-title" sidesheet-title>
    {{ 'user_edit_heading' | translate }}
  </span>

  <ng-template
    (next)="questionStepDone()"
    [currentStepValid]="form.valid"
    [label]="'Questions'"
    rsWebSideSheetContent
  >
    <ng-container [formGroup]="form">
      <div class="fw-bold mb-4">
        {{ 'user_edit_overview_l' | translate }}:
        {{ selectionTranslateKeys[changeType] | translate }}
      </div>
      <rs-web-ticket-detail-questions
        [formArray]="detailQuestionsFormArray"
        [questions]="(rootQuestion$ | async)?.questions"
        [ticketType]="TicketIssueType.CONCERN"
      ></rs-web-ticket-detail-questions>
    </ng-container>
  </ng-template>

  <ng-template
    (done)="lastStepDone()"
    [currentStepValid]="true"
    [customDoneButtonText]="doneButtonText() | translate"
    [label]="'Summary'"
    rsWebSideSheetContent
  >
    @if (showSuccess()) {
      <rs-web-user-profile-edit-success></rs-web-user-profile-edit-success>
    } @else if (showError()) {
      <rs-web-user-profile-edit-error></rs-web-user-profile-edit-error>
    } @else {
      <rs-web-user-profile-edit-summary [changeType]="changeType">
        @for (summaryItem of summary(); track summaryItem.key) {
          @switch (summaryItem.type) {
            @case (ScoreQuestionType.SELECTION) {
              {{ summaryItem.value }}
            }

            @case (ScoreQuestionType.MULTISELECT) {
              @for (answer of summaryItem.value; track answer; let last = $last) {
                {{ answer }}{{ !last ? ', ' : '' }}
              }
            }

            @case (ScoreQuestionType.INPUT_DATE) {
              {{ summaryItem.value | date: 'EEEEEE dd.MM.yyyy' }}
            }

            @case (ScoreQuestionType.INPUT_ATTACHMENTS) {
              <div class="d-flex mt-3">
                @for (file of summaryItem.value | rsWebFileList; track file.lastModified) {
                  <rs-web-pdf-attachment [file]="file"></rs-web-pdf-attachment>
                }
              </div>
            }

            @case (ScoreQuestionType.LABEL) {
              {{ summaryItem.value }}
            }

            @default {
              <p [innerHTML]="summaryItem.value"></p>
            }
          }
        }
      </rs-web-user-profile-edit-summary>
    }
  </ng-template>
</rs-web-side-sheet-form>
