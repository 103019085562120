import { Pipe, PipeTransform } from '@angular/core';
import { PaymentRequestStatus } from '../models';

@Pipe({
  name: 'paymentBgStatus',
  standalone: true,
})
export class PaymentStatusBgPipe implements PipeTransform {
  transform(status: string): string | null {
    if (!status) {
      return 'color-bg-canceled';
    }

    switch (status) {
      case PaymentRequestStatus.OPEN:
        return 'color-bg-moderate';
      case PaymentRequestStatus.ERROR:
        return 'color-bg-error';
      case PaymentRequestStatus.OVERDUE:
        return 'color-bg-error';
      case PaymentRequestStatus.CHECK_MANUALLY:
        return 'color-bg-moderate';
      case PaymentRequestStatus.CANCELED:
        return 'color-bg-canceled';
      case PaymentRequestStatus.PAID:
        return 'color-bg-success';
      case PaymentRequestStatus.PAID_OUT:
        return 'color-bg-success';
      case PaymentRequestStatus.PENDING:
        return 'color-bg-moderate';
      case PaymentRequestStatus.PENDING_PAY_OUT:
        return 'color-bg-moderate';
      case PaymentRequestStatus.REFUNDED:
        return 'color-bg-moderate';
      default:
        return 'color-bg-canceled';
    }
  }
}
