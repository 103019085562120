import { Directive } from '@angular/core';
import { ImmomioIconName, MasterdataChangeType } from '../../models';
import { RouterService } from '../../services';
import { Store } from '@ngrx/store';
import { IMasterDataState } from '../../+state';
import * as fromMasterDataState from '../../+state';
import { map, Observable, take } from 'rxjs';

@Directive()
export abstract class UserEditFormsPageBaseComponent {
  public immomioIconName = ImmomioIconName;
  public closeButton: ImmomioIconName = ImmomioIconName.Close;
  public masterDataChangeTypeSelectedConfiguration$: Observable<string | null>;
  public masterDataChangeType = MasterdataChangeType;

  constructor(
    protected masterDataStore: Store<IMasterDataState>,
    protected routerService: RouterService
  ) {
    this.masterDataChangeTypeSelectedConfiguration$ = this.masterDataStore
      .select(fromMasterDataState.getMasterDataChange)
      .pipe(
        take(1),
        map(masterDataChange => masterDataChange?.selectedChangeConfiguration?.changeType)
      );
  }
  public goToOverviewPage(form_data, type: MasterdataChangeType) {
    this.masterDataStore.dispatch(
      fromMasterDataState.SetMasterDataChange({
        masterDataChange: {
          masterDataChangeRequest: { type, ...form_data },
        },
      })
    );
    this.handleNavigation();
  }

  protected handleNavigation() {
    void null;
  }

  public resetMasterDataChange() {
    this.masterDataStore.dispatch(fromMasterDataState.ResetMasterDataChange());
  }
}
