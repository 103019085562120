import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import {
  ButtonSizeEnum,
  ButtonTypeEnum,
  IUserData,
  UserPageBaseComponent,
} from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentDetailsWebComponent } from '../../organisms/payment-details/payment-details.component';
import { UserContactDetailsWebComponent } from '../../organisms/user-contact-details/user-contact-details.component';
import { ProfileDetailsWebComponent } from '../../organisms/profile-details/profile-details.component';
import { ButtonWebComponent } from '../../atoms/button/button.component';
import { SideSheetService } from '../../../services';
import { AsyncPipe } from '@angular/common';
import { UserProfileEditComponent } from './user-profile-edit/user-profile-edit.component';
import { ROUTE } from '../../../models';

@Component({
  selector: 'rs-web-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonWebComponent,
    ProfileDetailsWebComponent,
    UserContactDetailsWebComponent,
    PaymentDetailsWebComponent,
    TranslateModule,
    AsyncPipe,
  ],
})
export class UserProfileWebComponent extends UserPageBaseComponent {
  @Input() userData: IUserData;
  public buttonType = ButtonTypeEnum;
  public buttonSize = ButtonSizeEnum;
  #sideSheetService = inject(SideSheetService);

  public editProfile(): void {
    if (this.masterDataChangeConfiguration) {
      this.#sideSheetService.open(UserProfileEditComponent);
    } else {
      // todo check if this routing to ticket/requests really goes to requests or only ticketing 2 places ;-)
      this.routerService.navigate([ROUTE.TICKETS, 'requests']);
    }
  }
}
