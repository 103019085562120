export interface IMonth {
  month: number;
  selected: boolean;
  monthStartTimestamp?: number;
  monthEndTimestamp?: number;
  estimated?: boolean;
}

export enum ChartMonths {
  QUARTER = 3,
  SEMESTER = 6,
  YEAR = 12,
}
