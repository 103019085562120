import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { catchError, map, Observable } from 'rxjs';

import {
  IContractDocumentsListResponse,
  IContractResponse,
  IKeyValue,
  IMasterdataChangeConfiguration,
  IMasterdataChangeConfigurationResponse,
  IMasterdataChangeRequest,
  IMasterdataChangeTicket,
  IMasterDataResponse,
  IUpdateUserMasterdataPayload,
} from '../../../models';
import {
  createMasterdataChangeTicketMutation,
  getContractDocumentsList,
  getContractsQuery,
  getMasterdataChangeConfiguration,
  getMasterdataQuery,
  sendMasterdataChangeRequest,
  setMovingAddressSent,
  updateUserMasterdataMutation,
} from '../../../queries';

@Injectable()
export class AccountMasterDataFacade {
  constructor(private apollo: Apollo) {}

  public getMasterData(): Observable<IMasterDataResponse> {
    return this.apollo
      .query<{ getMasterdata: IMasterDataResponse }>({
        query: getMasterdataQuery,
        errorPolicy: 'all',
      })
      .pipe(
        map(response => {
          if (response?.data?.getMasterdata) {
            return response.data.getMasterdata;
          } else if (response?.errors) {
            throw new Error(response.errors[0].message);
          }
          throw new Error();
        })
      );
  }

  public updateUserMasterdata(
    payload: IUpdateUserMasterdataPayload | IKeyValue
  ): Observable<string> {
    return this.apollo
      .mutate<{ createdTicketId: string }>({
        mutation: updateUserMasterdataMutation,
        variables: {
          input: payload,
        },
      })
      .pipe(
        map(response => response?.data?.createdTicketId),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public getContracts(): Observable<IContractResponse> {
    return this.apollo
      .query<{ getContracts: IContractResponse }>({
        query: getContractsQuery,
      })
      .pipe(
        map(response => response?.data?.getContracts),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public getContractDocumentsList(contractId: string) {
    return this.apollo
      .query<{ getContractDocumentsList: IContractDocumentsListResponse }>({
        query: getContractDocumentsList,
        variables: {
          contractId,
        },
      })
      .pipe(map(response => response?.data?.getContractDocumentsList?.documents));
  }

  public setMovingAddressSent(contractId: string, movingAddressSent: boolean) {
    return this.apollo
      .mutate<boolean>({
        mutation: setMovingAddressSent,
        variables: {
          contractId,
          movingAddressSent,
        },
      })
      .pipe(
        map(response => response?.data),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public sendMasterdataChangeRequest(requestBean: IMasterdataChangeRequest) {
    return this.apollo
      .mutate<{ sendMasterdataChangeRequest: boolean }>({
        mutation: sendMasterdataChangeRequest,
        variables: { requestBean },
      })
      .pipe(
        map(response => response?.data.sendMasterdataChangeRequest),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public getMasterdataChangeConfiguration(): Observable<IMasterdataChangeConfiguration[]> {
    return this.apollo
      .query<{ getMasterdataChangeConfiguration: IMasterdataChangeConfigurationResponse }>({
        query: getMasterdataChangeConfiguration,
      })
      .pipe(
        map(
          response => response?.data?.getMasterdataChangeConfiguration?.masterdataConfigurationBeans
        ),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }

  public sendMasterdataChangeTicket(input: IMasterdataChangeTicket): Observable<boolean> {
    return this.apollo
      .mutate<boolean>({
        mutation: createMasterdataChangeTicketMutation,
        variables: { input },
      })
      .pipe(
        map(response => response?.data),
        catchError((error: Error) => {
          console.error(error);
          throw new Error(error.message);
        })
      );
  }
}
