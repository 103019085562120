import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rsWebCast',
  standalone: true,
})
export class CastPipe implements PipeTransform {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  transform<S, T extends S>(value: S, type?: new () => T): T {
    return <T>value;
  }
}
