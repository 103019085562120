import { Directive, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom, map, Observable, switchMap, take } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  IHQTicketDetailsResponse,
  IMetadataTicketDetailsResponse,
  ImmomioIconName,
  ITicketConversationEdit,
  ITicketMessageGroup,
  TicketingVersion,
  TicketIssueType,
} from '../../../models';
import * as fromTickets from '../../../+state/tickets';
import {
  getConcernMessengerAvailability,
  getDamageMessengerAvailability,
  getPermissions,
  getResidentAppSettings,
} from '../../../+state/';
import { IActionState } from '../../../utils';

@UntilDestroy()
@Directive()
export class TicketDetailsPageBaseComponent implements OnInit {
  @Input() ticketId: string;
  @Input() ticketIssueType: TicketIssueType;
  public ticketDetailsActionState$: Observable<IActionState>;
  public ticketDetails$: Observable<IMetadataTicketDetailsResponse | IHQTicketDetailsResponse>;
  public ticketDetailsMetadata$: Observable<IMetadataTicketDetailsResponse>;
  public ticketDetailsHQ$: Observable<IHQTicketDetailsResponse>;
  public ticketChat$: Observable<ITicketMessageGroup[]>;
  public ticketChatActionState$: Observable<IActionState>;
  public newMessageActionState$: Observable<IActionState>;
  public fromTickets = fromTickets;
  public internalTicketingBooked$: Observable<boolean> = this.store.select(getPermissions).pipe(
    map(permissions => permissions?.internalTicketing),
    filter(permission => permission !== null),
    untilDestroyed(this)
  );
  public ticketingVersion$: Observable<TicketingVersion> = this.store
    .select(getResidentAppSettings)
    .pipe(
      map(response => response?.ticketingVersion),
      filter(response => response !== null)
    );
  public immomioIconName = ImmomioIconName;
  public ticketingVersion = TicketingVersion;
  public damageMessengerEnabled: boolean;
  public concernMessengerEnabled: boolean;

  constructor(protected store: Store) {}

  ngOnInit() {
    this.dispatchLoadTicketDetails();
    this.dispatchLoadTicketChatDetails();
    this.getTicketDetails();
    this.getTicketDetailsActionState();
    this.getTicketChat();
    this.getTicketChatActionState();
    this.getNewMessageActionState();
    this.getMessengerAvailability();
  }

  public dispatchLoadTicketDetails(): void {
    this.ticketingVersion$.pipe(untilDestroyed(this)).subscribe(version => {
      if (version === TicketingVersion.METADATA) {
        this.store.dispatch(
          this.fromTickets.LoadMetadataTicketDetails({
            ticketId: this.ticketId,
            ticketIssueType: this.ticketIssueType,
          })
        );
      } else {
        this.store.dispatch(
          this.fromTickets.LoadTicketDetails({
            ticketId: this.ticketId,
            ticketIssueType: this.ticketIssueType,
          })
        );
      }
    });
  }

  public dispatchLoadTicketChatDetails(): void {
    this.store.dispatch(this.fromTickets.LoadTicketChat({ ticketId: this.ticketId }));
  }

  public sendMessage(newMessage: string): void {
    const message: ITicketConversationEdit = {
      ticketId: this.ticketId,
      message: newMessage,
    };

    this.store.dispatch(fromTickets.AddMessageToTicket({ message }));
  }

  public retryMessage(messageId: string) {
    const message = {
      ticketId: this.ticketId,
      retryOfConversationMessageId: messageId,
    };

    this.store.dispatch(fromTickets.AddMessageToTicket({ message }));
  }

  private getTicketDetails(): void {
    this.ticketingVersion$.pipe(untilDestroyed(this)).subscribe(version => {
      if (version === TicketingVersion.METADATA) {
        this.ticketDetailsMetadata$ = this.store.select(fromTickets.getMetadataTicketDetails);
        this.ticketDetails$ = this.store.select(fromTickets.getMetadataTicketDetails);
      } else {
        this.ticketDetailsHQ$ = this.store.select(fromTickets.getTicketDetails);
        this.ticketDetails$ = this.store.select(fromTickets.getTicketDetails);
      }
    });
  }

  private getTicketDetailsActionState(): void {
    this.ticketDetailsActionState$ = this.ticketingVersion$.pipe(
      switchMap(version => {
        if (version === TicketingVersion.HIERARCHICAL) {
          return this.store.select(fromTickets.getTicketDetailsActionState);
        } else if (version === TicketingVersion.METADATA) {
          return this.store.select(fromTickets.getMetadataTicketDetailsActionState);
        } else {
          return this.store.select(fromTickets.getTicketDetailsActionState);
        }
      })
    );
  }

  private getTicketChat(): void {
    this.ticketChat$ = this.store.select(fromTickets.getTicketChat);
  }

  private getTicketChatActionState(): void {
    this.ticketChatActionState$ = this.store.select(fromTickets.getTicketChatActionState);
  }

  private getNewMessageActionState(): void {
    this.newMessageActionState$ = this.store.select(fromTickets.getNewMessageActionState);
  }

  private async getMessengerAvailability(): Promise<void> {
    if (this.ticketIssueType === TicketIssueType.PROPERTY_DAMAGE) {
      this.damageMessengerEnabled = await firstValueFrom(
        this.store.select(getDamageMessengerAvailability).pipe(take(1))
      )
        .then(value => !!value)
        .catch(() => false);
    }
    if (this.ticketIssueType === TicketIssueType.CONCERN) {
      this.concernMessengerEnabled = await firstValueFrom(
        this.store.select(getConcernMessengerAvailability).pipe(take(1))
      )
        .then(value => !!value)
        .catch(() => false);
    }
  }
}
