import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, ReactiveFormsModule } from '@angular/forms';
import { DatePickerComponent } from '../../../../atoms/date-picker/date-picker.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-user-profile-edit-date-of-birth',
  standalone: true,
  imports: [CommonModule, DatePickerComponent, ReactiveFormsModule, TranslateModule],
  templateUrl: './user-profile-edit-date-of-birth.component.html',
  styleUrl: './user-profile-edit-date-of-birth.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class UserProfileEditDateOfBirthComponent {}
