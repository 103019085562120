import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, delay, EMPTY, map, of, switchMap, withLatestFrom } from 'rxjs';
import { endOfMonth, startOfMonth } from 'date-fns';

import {
  consumptionConverter,
  ConsumptionFacade,
  consumptionInfoConverter,
  consumptionLegalFilter,
  isSelectedPeriodAllowedAndAvailable,
} from '../../services';
import { ConsumptionAggregateType, IConsumptionInfo } from '../../models';
import * as fromActions from './consumption.actions';
import {
  LoadBenchmarkFailed,
  LoadBenchmarkSuccess,
  LoadConsumptionFailed,
  LoadConsumptionSuccess,
  LoadPastConsumptionFailed,
  LoadPastConsumptionSuccess,
} from './consumption.actions';
import { getResidentAppSettings } from '../account';
import * as fromReducers from '../account/masterdata/masterdata.reducer';
import * as fromMasterDataState from '../account/masterdata';
import * as fromConsumptionState from './';
import { ConsumptionHelpers, ConsumptionPhase } from './consumption.helpers';

@Injectable()
export class ConsumptionEffects {
  constructor(
    private actions$: Actions,
    private consumptionFacade: ConsumptionFacade,
    private store: Store<fromReducers.IMasterDataState>,
    private consumptionHelpers: ConsumptionHelpers
  ) {}

  loadMetersForSelectedContract$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadMetersByContract),
      withLatestFrom(
        this.store.select(fromMasterDataState.getSelectedContract),
        this.store.select(fromConsumptionState.getMetersByContract),
        this.store.select(getResidentAppSettings)
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, contract, metersByContract, { consumptionDataVersion }]) => {
        // we can only request data if the contract has started
        if (contract?.contractStartTime) {
          const contractId = contract?.id;
          if (contractId && metersByContract && metersByContract[contractId]) {
            const consumptionInfo = metersByContract[contractId];
            return of(fromActions.LoadMetersByContractSuccess({ consumptionInfo, contract }));
          }
          return this.consumptionFacade.getConsumptionInfo(contractId).pipe(
            map(v => consumptionInfoConverter.fromDto(v, consumptionDataVersion)),
            map((consumptionInfo: IConsumptionInfo) =>
              fromActions.LoadMetersByContractSuccess({ consumptionInfo, contract })
            ),
            catchError((error: Error) => [fromActions.LoadMetersByContractFailed({ error })])
          );
        } else {
          return EMPTY;
        }
      })
    );
  });

  /***
   * on LoadMetersSuccess action dispatch SelectMeters
   */
  selectDefaultMeterOnLoadMetersSuccess = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadMetersByContractSuccess),
      withLatestFrom(this.store.select(fromConsumptionState.getAvailableMeters)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, availableMeters]) => {
        if (availableMeters?.meters?.length > 0) {
          return of(
            fromActions.SelectMeters({
              meters: availableMeters.meters,
            })
          );
        }
        return of(
          fromActions.LoadMetersByContractFailed({
            error: {
              name: 'No available meters',
              message: 'No available meters',
            },
          })
        );
      })
    );
  });

  /***
   * on SelectMeters action dispatch ClearSelectedConsumption
   * to remove already selected value
   */
  clearSelectedConsumptionOnSelectMeters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectMeters),
      switchMap(() => {
        return of(fromConsumptionState.ClearSelectedConsumption());
      })
    );
  });

  /***
   * on SelectMeter action dispatch SelectLatestAvailablePeriod
   */
  selectLatestAvailablePeriodOnSelectMeters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectMeters),
      withLatestFrom(this.store.select(fromMasterDataState.getSelectedContract)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, contract]) => {
        return of(fromConsumptionState.SelectLatestAvailablePeriod({ contract }));
      })
    );
  });

  /***
   * on SelectMeter action dispatch LoadSelectedPeriodConsumption
   */
  loadConsumptionOnSelectMeters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectMeters),
      switchMap(({ meters }) => {
        const deviceIds = meters.map(meter => meter.deviceId);
        return of(fromConsumptionState.LoadConsumption({ deviceIds }));
      })
    );
  });

  /***
   * on SelectMeters action dispatch LoadPastConsumptionForMeters
   */
  loadPastConsumptionOnSelectMeters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectMeters),
      switchMap(({ meters }) => {
        const deviceIds = meters.map(meter => meter.deviceId);
        return of(fromConsumptionState.LoadPastConsumption({ deviceIds }));
      })
    );
  });

  /***
   * on SelectMeters action dispatch LoadConsumptionBenchmark
   */
  loadConsumptionBenchmarkOnSelectMeters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectMeters),
      switchMap(({ meters }) => {
        const deviceIds = meters.map(meter => meter.deviceId);
        return of(fromConsumptionState.LoadBenchmark({ deviceIds }));
      })
    );
  });

  triggerLoadConsumptionForAllSelectedMeters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.TriggerLoadConsumptionForAllSelectedMeters),
      withLatestFrom(this.store.select(fromConsumptionState.getSelectedMeters)),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, selectedMeters]) => {
        const deviceIds = selectedMeters.map(meter => meter.deviceId);
        return of(fromConsumptionState.LoadConsumption({ deviceIds }));
      })
    )
  );

  loadConsumptionForMeters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LoadConsumption),
      withLatestFrom(
        this.store.select(fromConsumptionState.getPeriodsForRequests),
        this.store.select(fromConsumptionState.getPeriodsForCharts),
        this.store.select(fromMasterDataState.getSelectedContract),
        this.store.select(fromConsumptionState.getSelectedMeters),
        this.store.select(fromConsumptionState.getConsumptionCache),
        this.store.select(fromConsumptionState.getSelectedTimeframe),
        this.store.select(getResidentAppSettings)
      ),
      concatMap(
        ([
          { deviceIds },
          periodsForRequests,
          periodsForCharts,
          contract,
          selectedMeters,
          consumptionCache,
          timeframe,
          { consumptionDataVersion },
        ]) =>
          this.consumptionHelpers
            .loadConsumptionData({
              selectedMeters,
              deviceIds,
              periodsForRequests,
              periodsForCharts,
              contract,
              consumptionCache,
              timeframe,
              consumptionDataVersion,
              type: ConsumptionPhase.Consumption,
            })
            .pipe(
              map(({ deviceIds, contract, responses }) =>
                LoadConsumptionSuccess({
                  deviceIds,
                  contract,
                  responses,
                })
              ),
              catchError(({ deviceIds, error }) => of(LoadConsumptionFailed({ deviceIds, error })))
            )
      )
    )
  );

  loadPastConsumptionForMeters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadPastConsumption),
      withLatestFrom(
        this.store.select(fromConsumptionState.getPeriodsForRequests),
        this.store.select(fromConsumptionState.getPeriodsForCharts),
        this.store.select(fromMasterDataState.getSelectedContract),
        this.store.select(fromConsumptionState.getSelectedMeters),
        this.store.select(fromConsumptionState.getConsumptionCache),
        this.store.select(fromConsumptionState.getSelectedTimeframe),
        this.store.select(getResidentAppSettings)
      ),
      concatMap(
        ([
          { deviceIds },
          periodsForRequests,
          periodsForCharts,
          contract,
          selectedMeters,
          consumptionCache,
          timeframe,
          { consumptionDataVersion },
        ]) =>
          this.consumptionHelpers
            .loadConsumptionData({
              selectedMeters,
              deviceIds,
              periodsForRequests,
              periodsForCharts,
              contract,
              consumptionCache,
              timeframe,
              consumptionDataVersion,
              type: ConsumptionPhase.PastConsumption,
            })
            .pipe(
              map(({ responses, contract, deviceIds }) =>
                LoadPastConsumptionSuccess({
                  responses,
                  contract,
                  deviceIds,
                })
              ),
              catchError(({ deviceIds, error }) =>
                of(LoadPastConsumptionFailed({ deviceIds, error }))
              )
            )
      )
    );
  });

  loadBenchmarkConsumptionForMeters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadBenchmark),
      withLatestFrom(
        this.store.select(fromConsumptionState.getPeriodsForRequests),
        this.store.select(fromConsumptionState.getPeriodsForCharts),
        this.store.select(fromMasterDataState.getSelectedContract),
        this.store.select(fromConsumptionState.getSelectedMeters),
        this.store.select(fromConsumptionState.getConsumptionCache),
        this.store.select(fromConsumptionState.getSelectedTimeframe),
        this.store.select(getResidentAppSettings)
      ),
      concatMap(
        ([
          { deviceIds },
          periodsForRequests,
          periodsForCharts,
          contract,
          selectedMeters,
          consumptionCache,
          timeframe,
          { consumptionDataVersion },
        ]) =>
          this.consumptionHelpers
            .loadConsumptionData({
              selectedMeters,
              deviceIds,
              periodsForRequests,
              periodsForCharts,
              contract,
              consumptionCache,
              timeframe,
              consumptionDataVersion,
              type: ConsumptionPhase.Benchmark,
            })
            .pipe(
              map(({ responses, contract, deviceIds }) =>
                LoadBenchmarkSuccess({
                  responses,
                  contract,
                  deviceIds,
                })
              ),
              catchError(({ deviceIds, error }) => of(LoadBenchmarkFailed({ deviceIds, error })))
            )
      )
    );
  });

  // delay 100 is added for cases where the graph will switch from ScatterSeries to AreaSeries
  loadConsumptionOnSelectPreviousPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectPreviousPeriod),
      delay(100),
      switchMap(({ deviceId }) => of(fromActions.LoadConsumption({ deviceIds: [deviceId] })))
    );
  });

  loadPastConsumptionOnSelectPreviousPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectPreviousPeriod),
      switchMap(({ deviceId }) => of(fromActions.LoadPastConsumption({ deviceIds: [deviceId] })))
    );
  });

  loadConsumptionBenchmarkOnSelectPreviousPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectPreviousPeriod),
      switchMap(({ deviceId }) => of(fromActions.LoadBenchmark({ deviceIds: [deviceId] })))
    );
  });

  // delay 100 is added for cases where the graph will switch from ScatterSeries to AreaSeries
  loadConsumptionOnSelectNextPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectNextPeriod),
      delay(100),
      switchMap(({ deviceId }) => of(fromActions.LoadConsumption({ deviceIds: [deviceId] })))
    );
  });

  loadPastConsumptionOnSelectNextPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectNextPeriod),
      switchMap(({ deviceId }) => of(fromActions.LoadPastConsumption({ deviceIds: [deviceId] })))
    );
  });

  loadConsumptionBenchmarkOnSelectNextPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectNextPeriod),
      switchMap(({ deviceId }) => of(fromActions.LoadBenchmark({ deviceIds: [deviceId] })))
    );
  });

  // delay 100 is added for cases where the graph will switch from ScatterSeries to AreaSeries
  loadConsumptionOnSelectPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectPeriod),
      delay(100),
      switchMap(({ deviceId }) => of(fromActions.LoadConsumption({ deviceIds: [deviceId] })))
    );
  });

  loadPastConsumptionOnSelectPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectPeriod),
      switchMap(({ deviceId }) => of(fromActions.LoadPastConsumption({ deviceIds: [deviceId] })))
    );
  });

  loadConsumptionBenchmarkOnSelectPeriod = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectPeriod),
      switchMap(({ deviceId }) => of(fromActions.LoadBenchmark({ deviceIds: [deviceId] })))
    );
  });

  /***
   * on SelectMeters action check if updateInterval exist for the meter
   * and dispatch LoadCurrentMonthConsumption if yes
   */
  loadCurrentMonthConsumptionOnSelectMeters = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SelectMeters),
      switchMap(({ meters }) => {
        const [firstMeter] = meters;
        if (firstMeter?.updateInterval) {
          return of(fromConsumptionState.LoadCurrentMonthConsumption());
        } else {
          return EMPTY;
        }
      })
    );
  });

  loadCurrentMonthConsumption$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.LoadCurrentMonthConsumption),
      withLatestFrom(
        this.store.select(fromMasterDataState.getSelectedContract),
        this.store.select(fromConsumptionState.getFirstSelectedMeter),
        this.store.select(getResidentAppSettings)
      ),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(([_, contract, selectedMeter, { consumptionDataVersion }]) => {
        if (!selectedMeter) {
          return of(
            fromActions.LoadCurrentMonthConsumptionFailed({
              error: {
                name: 'Selected period not available',
                message: 'Selected period not available',
              },
            })
          );
        }
        const today = Date.now();
        const start = Math.max(startOfMonth(today).getTime(), selectedMeter.firstEntry); // firstEntry for meter can be after start of current month
        const end = endOfMonth(today).getTime();
        const selectedPeriod = { start, end };
        if (!isSelectedPeriodAllowedAndAvailable(contract, selectedPeriod, selectedMeter)) {
          return of(
            fromActions.LoadCurrentMonthConsumptionFailed({
              error: {
                name: 'Selected period not available',
                message: 'Selected period not available',
              },
            })
          );
        }

        return this.consumptionFacade
          .getSelectedPeriodConsumption(
            selectedPeriod,
            contract.id,
            selectedMeter,
            ConsumptionAggregateType.MONTH,
            consumptionDataVersion
          )
          .pipe(
            map(response => consumptionLegalFilter(response, contract)),
            map(response => consumptionConverter.fromDto(response)),
            map(response =>
              fromActions.LoadCurrentMonthConsumptionSuccess({
                response,
                contract,
                selectedPeriod,
                selectedMeter,
              })
            ),
            catchError((error: Error) => [fromActions.LoadCurrentMonthConsumptionFailed({ error })])
          );
      })
    );
  });

  /**
   * get data on timeframe change
   * find selected meter and period and resolution and get data from BE
   */
  setSelectedTimeframe = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromActions.SetSelectedTimeframe),
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      switchMap(({ timeframe, deviceId }) => {
        return of(fromConsumptionState.LoadConsumption({ deviceIds: [deviceId] }));
      })
    );
  });
}
