import { Component } from '@angular/core';
import {
  PaymentRequestCardBaseComponent,
  PaymentRequestStatus,
  PaymentStatusBgPipe,
} from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass, UpperCasePipe, CurrencyPipe, DatePipe } from '@angular/common';
import { ElevationDirective } from '../../../directives/elevation/elevation.directive';

@Component({
  selector: 'rs-web-payment-request-card',
  templateUrl: './payment-request-card.component.html',
  styleUrls: ['./payment-request-card.component.scss'],
  standalone: true,
  imports: [
    ElevationDirective,
    NgClass,
    UpperCasePipe,
    CurrencyPipe,
    DatePipe,
    TranslateModule,
    PaymentStatusBgPipe,
  ],
})
export class PaymentRequestCardWebComponent extends PaymentRequestCardBaseComponent {
  public paymentStatus = PaymentRequestStatus;

  public isOverdue(status: PaymentRequestStatus): boolean {
    return status === this.paymentStatus.OVERDUE;
  }

  public isPaid(status: PaymentRequestStatus): boolean {
    return status === this.paymentStatus.PAID;
  }

  public isError(status: PaymentRequestStatus): boolean {
    return status === this.paymentStatus.ERROR;
  }

  public isWaitingForPayment(status: PaymentRequestStatus): boolean {
    return status === this.paymentStatus.CHECK_MANUALLY;
  }

  public isCanceled(status: PaymentRequestStatus): boolean {
    return status === this.paymentStatus.CANCELED;
  }
  public openPaymentRequestPage(url: string) {
    window.open(url, '_blank');
  }
}
