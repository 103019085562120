import { Directive } from '@angular/core';

import { WhitelabelImage, WhitelabelImageType } from '../../models';

@Directive()
export class UserEditSuccessPageBaseComponent {
  public headerTitle = 'user_edit_heading';
  public whitelabelImage = WhitelabelImage;
  public whitelabelImageType = WhitelabelImageType;
}
