import { Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromOffersState from '../../+state/news/offers';
import { ITheme } from '../../models';
import { getTheme } from '../../+state';
import { filter, take } from 'rxjs';

@Directive()
export abstract class OfferDetailsBaseComponent {
  offer$ = this.offersStore.select(fromOffersState.getOffer);
  offerActionState$ = this.offersStore.select(fromOffersState.getOfferActionState);
  protected theme: ITheme;

  constructor(private offersStore: Store<fromOffersState.IOffersState>) {
    this.offersStore
      .select(getTheme)
      .pipe(
        filter(theme => theme !== null),
        take(1)
      )
      .subscribe(theme => {
        this.theme = theme;
      });
  }
}
