<p class="h4 mb-4">{{ summary.category }}</p>

<p class="h4">{{ 'ticket_summary.details_damage_l' | translate }}</p>

@for (category of summary.categories; track category.key) {
  <span class="secondary-text">{{ category.key }}</span>
  <p>{{ category.value }}</p>
}

@if (summary.detailQuestions.length) {
  <hr />
  @for (detailQuestion of summary.detailQuestions; track detailQuestion.key) {
    @if (detailQuestion.value) {
      <span class="secondary-text">{{ detailQuestion.key }}</span>
    }
    <p>
      @switch (detailQuestion.type) {
        @case (ScoreQuestionType.SELECTION) {
          <ng-container
            *ngTemplateOutlet="selectionAnswer; context: { $implicit: detailQuestion.value }"
          ></ng-container>
        }

        @case (ScoreQuestionType.MULTISELECT) {
          <ng-container
            *ngTemplateOutlet="multiselectAnswer; context: { $implicit: detailQuestion.value }"
          ></ng-container>
        }

        @case (ScoreQuestionType.INPUT_DATE) {
          <ng-container
            *ngTemplateOutlet="dateAnswer; context: { $implicit: detailQuestion.value }"
          ></ng-container>
        }

        @case (ScoreQuestionType.INPUT_ATTACHMENTS) {
          <ng-container
            *ngTemplateOutlet="attachmentAnswer; context: { $implicit: detailQuestion.value }"
          ></ng-container>
        }

        @case (ScoreQuestionType.LABEL) {
          <ng-container
            *ngTemplateOutlet="labelAnswer; context: { $implicit: detailQuestion.value }"
          ></ng-container>
        }

        @default {
          <ng-container
            *ngTemplateOutlet="textAnswer; context: { $implicit: detailQuestion.value }"
          ></ng-container>
        }
      }
    </p>
  }
}

<ng-template #selectionAnswer let-answer>
  {{ answer?.data?.title }}
</ng-template>

<ng-template #multiselectAnswer let-answer>
  @for (value of answer; track value; let last = $last) {
    {{ value }}{{ !last ? ', ' : '' }}
  }
</ng-template>

<ng-template #dateAnswer let-answer>
  {{ answer | date: 'EEEEEE dd.MM.yyyy' }}
</ng-template>

<ng-template #labelAnswer let-answer>
  {{ answer }}
</ng-template>

<ng-template #textAnswer let-text>
  <p [innerHTML]="text"></p>
</ng-template>

<ng-template #attachmentAnswer let-answer>
  <div class="d-flex mt-3">
    @for (file of answer | rsWebFileList; track file.lastModified) {
      @if (file.type.includes('image')) {
        <rs-web-ticket-image-attachment
          [small]="true"
          [file]="file"
          [isRemovable]="false"
        ></rs-web-ticket-image-attachment>
      }
    }
  </div>

  <div class="d-flex flex-column mt-3">
    @for (file of answer | rsWebFileList; track file.lastModified) {
      @if (file.type.includes('pdf')) {
        <rs-web-pdf-attachment [file]="file" [isRemovable]="false"></rs-web-pdf-attachment>
      }
    }
  </div>
</ng-template>
