export const bAndOConfig = {
  //VHW_SBX
  ['6b557caf-1766-4271-9489-d71873eac482']: {
    web: 'https://vhw.kleinreparatur.schulung.enapt.de',
    mobile: 'https:vhw.kleinreparatur.schulung.enapt.de',
  },
  //VHW_Prod
  ['fd3f402c-70a7-4523-878c-b070c50dd059']: {
    web: 'https://vhw.kleinreparatur.schulung.enapt.de',
    mobile: 'https:vhw.kleinreparatur.schulung.enapt.de',
  },
};
