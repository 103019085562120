<div class="d-flex align-items-center flex-column">
  @if (!showBandO()) {
    <rs-web-image [defaultSrc]="'/web/images/order-placed.png'"></rs-web-image>
    <h1>{{ 'ticket.b_and_o_title_l' | translate }}</h1>
    <p>{{ 'ticket.b_and_o_short_description_l' | translate }}</p>
    <rs-web-button (click)="showBandOTicketing()"
      >{{ 'ticket.b_and_o_button' | translate }}
    </rs-web-button>
  } @else {
    <rs-web-ticket-b-and-o-creation-page class="w-100"></rs-web-ticket-b-and-o-creation-page>
  }
</div>
