<div class="mobile-title title-m">{{ 'user.profile_details_l' | translate }}</div>
<div class="mobile-avatar">
  <rs-web-avatar [name]="userData" [rounded]="true" [size]="avatarSize['LARGE']"></rs-web-avatar>
</div>
<div class="flex-mobile-column row mb-4">
  <div class="d-flex flex-column col-6 custom-margin-bottom">
    <span class="column-title">{{ 'registration_user_first_name_l' | translate }}</span>
    @if (userData?.firstName) {
      <span class="column-desc">{{ userData.firstName }}</span>
    } @else {
      <ng-template [ngTemplateOutlet]="noData"></ng-template>
    }
  </div>
  <div class="d-flex flex-column col-6">
    <span class="column-title">{{ 'registration_user_name_l' | translate }}</span>
    @if (userData?.name) {
      <span class="column-desc">{{ userData.name }}</span>
    } @else {
      <ng-template [ngTemplateOutlet]="noData"></ng-template>
    }
  </div>
</div>
<div class="flex-mobile-column row mb-4">
  <div class="d-flex flex-column col-6">
    <span class="column-title">{{ 'user_profile.date_of_birth_l' | translate }}</span>
    @if (userData?.birthDate) {
      <span class="column-desc">{{ userData.birthDate | date: 'dd.MM.YYYY' }}</span>
    } @else {
      <ng-template [ngTemplateOutlet]="noData"></ng-template>
    }
  </div>
</div>
<div class="flex-mobile-column row mb-4">
  <div class="d-flex flex-column col-6 custom-margin-bottom">
    <span class="column-title">{{ 'street' | translate }}</span>
    @if (userData?.address?.street) {
      @if (userData?.address?.street === userData?.address?.houseNumber) {
        <span class="column-desc">{{ userData.address.street }}</span>
      } @else {
        <span class="column-desc"
          >{{ userData?.address?.street }} {{ userData?.address?.houseNumber }}</span
        >
      }
    } @else {
      <ng-template [ngTemplateOutlet]="noData"></ng-template>
    }
  </div>
  <div class="d-flex flex-column col-6">
    <span class="column-title"> {{ 'zip' | translate }}/{{ 'location' | translate }}</span>
    @if (userData?.address?.zipCode) {
      <span class="column-desc">{{ userData.address.zipCode }} {{ userData.address.city }}</span>
    } @else {
      <ng-template [ngTemplateOutlet]="noData"></ng-template>
    }
  </div>
</div>
