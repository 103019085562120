import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageWebComponent } from '../../../../atoms/image/image.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-user-profile-edit-success',
  standalone: true,
  imports: [CommonModule, ImageWebComponent, TranslateModule],
  templateUrl: './user-profile-edit-success.component.html',
  styleUrl: './user-profile-edit-success.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserProfileEditSuccessComponent {}
