import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ImmomioIconName } from '../../models';

@Directive()
@UntilDestroy()
export abstract class CarouselHeaderBaseComponent {
  @Input() icon;
  @Input() headerLabel: string;
  @Input() headerLabelSuffix?: string = '';
  @Input() headerLabelMeasureMentUnit?: string = '';
  @Input() textColorClass: string;
  @Input() iconColorClass: string;
  @Output() previousTapped = new EventEmitter();
  @Output() nextTapped = new EventEmitter();
  @Input() swipeEnabled = false;
  public immomioIconName = ImmomioIconName;
}
