import { createReducer, on } from '@ngrx/store';
import { addMonths, addYears, subMonths, subYears } from 'date-fns';
import * as fromActions from './consumption.actions';
import {
  ActionStateCreator,
  endOfMonthMilliseconds,
  endOfYearMilliseconds,
  IActionState,
  startOfMonthMilliseconds,
  startOfYearMilliseconds,
} from '../../utils';
import {
  ByDeviceId,
  ConsumptionsByContractR,
  ConsumptionTimeframe,
  IConsumption,
  IConsumptionBenchmarkItemResponse,
  IConsumptionInfo,
  IConsumptionInfoItem,
  IConsumptionItem,
  IContract,
  IMonth,
  IPeriod,
  MetersByContractR,
  PeriodType,
} from '../../models';
import {
  calculateSelectedConsumption,
  consumptionCalculator,
  isSelectedMeterAllowedAndAvailable,
  splitPeriodToMonths,
} from '../../services';
import { ConsumptionHelpers, ConsumptionPhase } from './consumption.helpers';

export interface IConsumptionState {
  // Contract
  selectedContract: IContract;
  // Meters
  metersByContract: MetersByContractR;
  availableMeters: IConsumptionInfo; // all meters available in contract
  selectedMeters: IConsumptionInfoItem[]; // selected meters to fetch consumption data for
  // Consumption Data
  consumption: ByDeviceId<IConsumptionItem[]>;
  pastConsumption: ByDeviceId<IConsumptionItem[]>;
  benchmark: ByDeviceId<IConsumptionBenchmarkItemResponse[]>;
  currentMonthConsumption: IConsumption;
  selectedConsumptions: ByDeviceId<IConsumption>; // for comparison
  consumptionCache: ConsumptionsByContractR; // Stores all already fetched consumption items

  selectedConsumptionTimeframe: ByDeviceId<ConsumptionTimeframe>; // DAY, MONTH, QUARTER, YEAR, MAX

  // in chart to switch to next/previous period
  showPreviousArrow: ByDeviceId<boolean>;
  showNextArrow: ByDeviceId<boolean>;
  //
  chartsMonths: ByDeviceId<IMonth[]>;
  periodsForRequests: ByDeviceId<IPeriod>;
  periodsForCharts: ByDeviceId<IPeriod>;
  // Dashboard
  dashboardPeriodsForCharts: ByDeviceId<IPeriod>;
  dashboardChartsMonths: ByDeviceId<IMonth[]>;

  actionStates: {
    consumption: ByDeviceId<IActionState>;
    pastConsumption: ByDeviceId<IActionState>;
    benchmark: ByDeviceId<IActionState>;
    currentMonthConsumption: IActionState;
    metersByContract: IActionState;
  };
}

export const initialState: IConsumptionState = {
  // Contract
  selectedContract: null,
  // Meters
  availableMeters: null,
  metersByContract: {},
  selectedMeters: [],
  // Consumption Items
  consumption: {},
  pastConsumption: {},
  benchmark: {},
  currentMonthConsumption: null,
  selectedConsumptions: {},
  consumptionCache: {},

  periodsForRequests: {},
  periodsForCharts: {},
  chartsMonths: {},
  showPreviousArrow: {},
  showNextArrow: {},
  dashboardPeriodsForCharts: {},
  dashboardChartsMonths: {},
  selectedConsumptionTimeframe: {},
  actionStates: {
    metersByContract: ActionStateCreator.create(),
    currentMonthConsumption: ActionStateCreator.create(),
    consumption: {},
    pastConsumption: {},
    benchmark: {},
  },
};

export const consumptionReducer = createReducer(
  initialState,

  on(fromActions.LoadMetersByContract, (state): IConsumptionState => {
    return {
      ...state,
      actionStates: {
        ...state.actionStates,
        metersByContract: ActionStateCreator.onStart(),
      },
    };
  }),

  on(fromActions.LoadMetersByContractFailed, (state, { error }): IConsumptionState => {
    return {
      ...state,
      actionStates: {
        ...state.actionStates,
        metersByContract: ActionStateCreator.onError(error),
      },
    };
  }),

  on(
    fromActions.LoadMetersByContractSuccess,
    (state, { consumptionInfo, contract }): IConsumptionState => {
      const allowedMeters = consumptionInfo?.meters?.filter(meter =>
        isSelectedMeterAllowedAndAvailable(contract, meter)
      );
      const availableMeters = allowedMeters.length !== 0 ? { meters: allowedMeters } : null;

      return {
        ...state,
        availableMeters,
        selectedContract: contract,
        metersByContract: { ...state.metersByContract, [contract.id]: availableMeters },
        actionStates: {
          ...state.actionStates,
          metersByContract: ActionStateCreator.onSuccess(),
        },
      };
    }
  ),

  on(fromActions.SelectMeters, (state, { meters }): IConsumptionState => {
    const consumption: IConsumptionState['actionStates']['consumption'] = {};
    const pastConsumption: IConsumptionState['actionStates']['pastConsumption'] = {};
    const benchmark: IConsumptionState['actionStates']['benchmark'] = {};

    const selectedConsumptionTimeframe: IConsumptionState['selectedConsumptionTimeframe'] = {};

    for (const meter of meters) {
      consumption[meter.deviceId] = ActionStateCreator.create();
      pastConsumption[meter.deviceId] = ActionStateCreator.create();
      benchmark[meter.deviceId] = ActionStateCreator.create();
      selectedConsumptionTimeframe[meter.deviceId] = ConsumptionTimeframe.YEAR;
    }

    return {
      ...state,
      selectedMeters: meters,
      selectedConsumptionTimeframe,
      actionStates: {
        ...state.actionStates,
        consumption,
        pastConsumption,
        benchmark,
      },
    };
  }),

  on(fromActions.SelectLatestAvailablePeriod, (state, { contract }): IConsumptionState => {
    const periodsForCharts: IConsumptionState['periodsForCharts'] = {};
    const periodsForRequests: IConsumptionState['periodsForRequests'] = {};
    const chartsMonths: IConsumptionState['chartsMonths'] = {};
    const dashboardPeriodsForCharts: IConsumptionState['dashboardPeriodsForCharts'] = {};
    const dashboardChartsMonths: IConsumptionState['dashboardChartsMonths'] = {};

    if (!contract?.contractStartTime) {
      return {
        ...state,
        periodsForCharts,
        periodsForRequests,
        chartsMonths,
        dashboardPeriodsForCharts,
        dashboardChartsMonths,
      };
    }

    if (contract?.contractStartTime) {
      const contractEndTime: number = contract.contractEndTime || Date.now();

      for (const meter of state.selectedMeters) {
        const periodForChart: IPeriod = { start: null, end: null };
        const periodForRequest: IPeriod = { start: null, end: null };
        // lastEntry can be after contractEnd, in that case we only want data until lastEntry
        if (meter.lastEntry <= contractEndTime) {
          periodForChart.end = endOfYearMilliseconds(meter.lastEntry);
          periodForRequest.end = meter.lastEntry;
        } else {
          periodForChart.end = endOfYearMilliseconds(contractEndTime);
          periodForRequest.end = contractEndTime;
        }

        periodForChart.start = startOfYearMilliseconds(periodForChart.end);
        if (meter.firstEntry >= contract.contractStartTime) {
          periodForRequest.start = Math.max(periodForChart.start, meter.firstEntry);
        }
        // firstEntry can be before contractStart, get later date between contractStartTime and start of selectedChartPeriod
        else {
          periodForRequest.start = Math.max(periodForChart.start, contract.contractStartTime);
        }

        const dashboardPeriodForChart: IPeriod = {
          start: startOfMonthMilliseconds(subMonths(periodForRequest.end, 2).getTime()),
          end: endOfMonthMilliseconds(periodForRequest.end),
        };

        const { deviceId } = meter;
        chartsMonths[deviceId] = splitPeriodToMonths(periodForChart);
        periodsForCharts[deviceId] = periodForChart;
        periodsForRequests[deviceId] = periodForRequest;
        dashboardPeriodsForCharts[deviceId] = dashboardPeriodForChart;
        dashboardChartsMonths[deviceId] = splitPeriodToMonths(dashboardPeriodForChart);
      }
    }

    return {
      ...state,
      periodsForCharts,
      periodsForRequests,
      chartsMonths,
      dashboardPeriodsForCharts,
      dashboardChartsMonths,
    };
  }),

  on(fromActions.ClearSelectedConsumption, (state): IConsumptionState => {
    return {
      ...state,
      selectedConsumptions: {},
    };
  }),

  on(fromActions.SelectPreviousPeriod, (state, { deviceId }): IConsumptionState => {
    /**
     * if the select previous period is shown to the user we know it's allowed by contract and available on device
     */
    const currentPeriodForChart = state.periodsForCharts[deviceId];

    const newPeriodForChart: IPeriod = { start: null, end: null };
    const timeframe = state.selectedConsumptionTimeframe[deviceId];
    if (timeframe === ConsumptionTimeframe.YEAR) {
      newPeriodForChart.start = startOfYearMilliseconds(
        subYears(currentPeriodForChart.start, 1).getTime()
      );
      newPeriodForChart.end = endOfYearMilliseconds(
        subYears(currentPeriodForChart.end, 1).getTime()
      );
    } else if (timeframe === ConsumptionTimeframe.MONTH) {
      newPeriodForChart.start = startOfMonthMilliseconds(
        subMonths(currentPeriodForChart.start, 1).getTime()
      );
      newPeriodForChart.end = endOfMonthMilliseconds(
        subMonths(currentPeriodForChart.end, 1).getTime()
      );
    }
    const periodsForCharts = {
      ...state.periodsForCharts,
      [deviceId]: newPeriodForChart,
    };

    // we take the latest date between contractStartTime, firstEntry and selectedPeriodForChart.start
    // to prevent requesting data for not allowed or unavailable period
    const meter = state.selectedMeters.find(meter => meter.deviceId === deviceId);

    const periodsForRequests = {
      ...state.periodsForRequests,
      [deviceId]: {
        start: Math.max(
          state.selectedContract.contractStartTime,
          meter.firstEntry,
          newPeriodForChart.start
        ),
        end: newPeriodForChart.end,
      },
    };

    const chartsMonths = {
      ...state.chartsMonths,
    };
    if (timeframe === ConsumptionTimeframe.YEAR) {
      chartsMonths[deviceId] = splitPeriodToMonths(newPeriodForChart);
    }

    return {
      ...state,
      periodsForCharts,
      periodsForRequests,
      chartsMonths,
    };
  }),

  on(fromActions.SelectNextPeriod, (state, { deviceId }): IConsumptionState => {
    /**
     * if the select next period is shown to the user we know it's allowed by contract and available on device     */
    const currentPeriodForChart = state.periodsForCharts[deviceId];
    const newPeriodForChart: IPeriod = { start: null, end: null };
    const timeframe = state.selectedConsumptionTimeframe[deviceId];
    if (timeframe === ConsumptionTimeframe.YEAR) {
      newPeriodForChart.start = startOfYearMilliseconds(
        addYears(currentPeriodForChart.start, 1).getTime()
      );
      newPeriodForChart.end = endOfYearMilliseconds(
        addYears(currentPeriodForChart.end, 1).getTime()
      );
    } else if (timeframe === ConsumptionTimeframe.MONTH) {
      newPeriodForChart.start = startOfMonthMilliseconds(
        addMonths(currentPeriodForChart.start, 1).getTime()
      );
      newPeriodForChart.end = endOfMonthMilliseconds(
        addMonths(currentPeriodForChart.end, 1).getTime()
      );
    }
    const periodsForCharts = {
      ...state.periodsForCharts,
      [deviceId]: newPeriodForChart,
    };

    const meter = state.selectedMeters.find(meter => meter.deviceId === deviceId);
    // we just need to check if the data for meter is available for the last day of chart period
    const periodsForRequests = {
      ...state.periodsForRequests,
      [deviceId]: {
        start: newPeriodForChart.start,
        end: meter.lastEntry >= newPeriodForChart.end ? newPeriodForChart.end : meter.lastEntry,
      },
    };

    const chartsMonths = {
      ...state.chartsMonths,
      [deviceId]: splitPeriodToMonths(newPeriodForChart),
    };

    return {
      ...state,
      periodsForCharts,
      periodsForRequests,
      chartsMonths,
    };
  }),

  on(fromActions.SelectPeriod, (state, { deviceId, start, end }): IConsumptionState => {
    const newPeriodForChart: IPeriod = {
      start: startOfMonthMilliseconds(start.getTime()),
      end: endOfMonthMilliseconds(end.getTime()),
    };
    const periodsForCharts = {
      ...state.periodsForCharts,
      [deviceId]: newPeriodForChart,
    };

    const meter = state.selectedMeters.find(meter => meter.deviceId === deviceId);
    // we just need to check if the data for meter is available for the last day of chart period
    const periodsForRequests = {
      ...state.periodsForRequests,
      [deviceId]: {
        start:
          meter.firstEntry <= newPeriodForChart.start ? newPeriodForChart.start : meter.firstEntry,
        end: meter.lastEntry >= newPeriodForChart.end ? newPeriodForChart.end : meter.lastEntry,
      },
    };

    const chartsMonths = {
      ...state.chartsMonths,
      [deviceId]: splitPeriodToMonths(newPeriodForChart),
    };

    return {
      ...state,
      periodsForCharts,
      periodsForRequests,
      chartsMonths,
    };
  }),

  on(fromActions.LoadConsumption, (state, { deviceIds }): IConsumptionState => {
    const consumptionActionStates = {
      ...state.actionStates.consumption,
    };
    for (const deviceId of deviceIds) {
      consumptionActionStates[deviceId] = ActionStateCreator.onStart();
    }
    return {
      ...state,
      actionStates: {
        ...state.actionStates,
        consumption: consumptionActionStates,
      },
    };
  }),

  /***
   * save data for selected period in store
   * also create or add to the existing record ConsumptionsByContractR
   */
  on(
    fromActions.LoadConsumptionSuccess,
    (state, { responses, contract, deviceIds }): IConsumptionState =>
      ConsumptionHelpers.addConsumptionItemsToState({
        state,
        deviceIds,
        contract,
        responses,
        type: ConsumptionPhase.Consumption,
      })
  ),

  on(fromActions.LoadConsumptionFailed, (state, { deviceIds, error }): IConsumptionState => {
    const consumptionActionStates = {
      ...state.actionStates.consumption,
    };
    const consumption = {
      ...state.consumption,
    };

    const showPreviousArrow = { ...state.showPreviousArrow };
    const showNextArrow = { ...state.showNextArrow };

    for (const deviceId of deviceIds) {
      consumptionActionStates[deviceId] = ActionStateCreator.onError(error);
      // if fetch failed, remove previous fetched consumption
      delete consumption[deviceId];

      const selectedPeriod = state.periodsForCharts[deviceId];
      const meter = state.selectedMeters.find(meter => meter.deviceId === deviceId);
      showPreviousArrow[deviceId] = ConsumptionHelpers.canShowPreviousArrow(
        state.selectedContract,
        selectedPeriod,
        meter
      );
      showNextArrow[deviceId] = ConsumptionHelpers.canShowNextArrow(
        state.selectedContract,
        selectedPeriod,
        meter
      );
    }
    return {
      ...state,
      actionStates: {
        ...state.actionStates,
        consumption: consumptionActionStates,
      },
      consumption,
      showPreviousArrow,
      showNextArrow,
    };
  }),

  on(fromActions.LoadPastConsumption, (state, { deviceIds }): IConsumptionState => {
    const pastConsumptionActionStates = {
      ...state.actionStates.pastConsumption,
    };
    for (const deviceId of deviceIds) {
      pastConsumptionActionStates[deviceId] = ActionStateCreator.onStart();
    }
    return {
      ...state,
      actionStates: {
        ...state.actionStates,
        pastConsumption: pastConsumptionActionStates,
      },
    };
  }),

  on(
    fromActions.LoadPastConsumptionSuccess,
    (state, { responses, contract, deviceIds }): IConsumptionState =>
      ConsumptionHelpers.addConsumptionItemsToState({
        state,
        deviceIds,
        responses,
        contract,
        type: ConsumptionPhase.PastConsumption,
      })
  ),

  on(fromActions.LoadPastConsumptionFailed, (state, { deviceIds, error }): IConsumptionState => {
    const pastConsumptionActionStates = {
      ...state.actionStates.pastConsumption,
    };
    const pastConsumption = {
      ...state.pastConsumption,
    };

    for (const deviceId of deviceIds) {
      pastConsumptionActionStates[deviceId] = ActionStateCreator.onError(error);
      // if failed fetching past consumption, remove (previous) fetched past consumption
      delete pastConsumption[deviceId];
    }
    return {
      ...state,
      actionStates: {
        ...state.actionStates,
        pastConsumption: pastConsumptionActionStates,
      },

      pastConsumption,
    };
  }),

  on(fromActions.LoadBenchmark, (state, { deviceIds }): IConsumptionState => {
    const benchmarkActionStates = {
      ...state.actionStates.benchmark,
    };
    for (const deviceId of deviceIds) {
      benchmarkActionStates[deviceId] = ActionStateCreator.onStart();
    }
    return {
      ...state,
      actionStates: {
        ...state.actionStates,
        benchmark: benchmarkActionStates,
      },
    };
  }),

  on(
    fromActions.LoadBenchmarkSuccess,
    (state, { responses, contract, deviceIds }): IConsumptionState =>
      ConsumptionHelpers.addConsumptionItemsToState({
        state,
        deviceIds,
        contract,
        responses,
        type: ConsumptionPhase.Benchmark,
      })
  ),

  on(fromActions.LoadBenchmarkFailed, (state, { deviceIds, error }): IConsumptionState => {
    const benchmarkActionStates = {
      ...state.actionStates.benchmark,
    };
    const benchmark = {
      ...state.benchmark,
    };
    for (const deviceId of deviceIds) {
      benchmarkActionStates[deviceId] = ActionStateCreator.onError(error);
      delete benchmark[deviceId];
    }
    return {
      ...state,
      actionStates: {
        ...state.actionStates,
        benchmark: benchmarkActionStates,
      },
      benchmark,
    };
  }),

  on(fromActions.LoadCurrentMonthConsumption, (state): IConsumptionState => {
    return {
      ...state,
      actionStates: {
        ...state.actionStates,
        currentMonthConsumption: ActionStateCreator.onStart(),
      },
    };
  }),

  on(
    fromActions.LoadCurrentMonthConsumptionSuccess,
    (state, { response, selectedPeriod, selectedMeter }): IConsumptionState => {
      response = response.filter(el => el.consumptionType === selectedMeter.type);
      const { deviceId } = selectedMeter;
      const selectedConsumptions = {
        ...state.selectedConsumptions,
        [deviceId]: calculateSelectedConsumption(
          {
            consumption: response,
            pastConsumption: state.pastConsumption[deviceId],
            benchmark: state.benchmark[deviceId],
            selectedPeriod,
          },
          state.selectedConsumptionTimeframe[deviceId]
        ),
      };
      return {
        ...state,
        selectedConsumptions,
        actionStates: {
          ...state.actionStates,
          currentMonthConsumption: ActionStateCreator.onSuccess(),
        },
      };
    }
  ),

  on(fromActions.LoadCurrentMonthConsumptionFailed, (state, { error }): IConsumptionState => {
    return {
      ...state,
      actionStates: {
        ...state.actionStates,
        currentMonthConsumption: ActionStateCreator.onError(error),
      },
    };
  }),

  /***
   * on selecting consumption item in chart, depending on the period type (day or month),
   * find and calculate date and value to be shown in the consumption value card
   */

  on(fromActions.SelectConsumption, (state, selected): IConsumptionState => {
    // Find selected period in chart, month or day
    const { deviceId } = selected;
    let chartMonths = [...state.chartsMonths[deviceId]];

    const periodType = selected.periodType;
    const consumptions = state.consumption[deviceId];
    const pastConsumptions = state.pastConsumption[deviceId];
    const consumptionBenchmark = state.benchmark[deviceId];
    let consumption: IConsumptionItem;
    let pastConsumption: IConsumptionItem;
    let benchmark: IConsumptionBenchmarkItemResponse;

    if (periodType === PeriodType.MONTH) {
      const month = new Date(selected.selectedPeriod.start).getMonth();
      const year = new Date(selected.selectedPeriod.start).getFullYear();

      consumption = consumptionCalculator.monthConsumption(consumptions, month, year);
      chartMonths = chartMonths.map(el => {
        return { ...el, selected: el.month === month };
      });

      if (pastConsumptions) {
        pastConsumption = consumptionCalculator.monthConsumption(pastConsumptions, month, year - 1);
      }
      if (consumptionBenchmark) {
        benchmark = consumptionCalculator.benchmarkMonthConsumption(
          consumptionBenchmark,
          month,
          year
        );
      }
    } else if (periodType === PeriodType.DAY) {
      const date = new Date(selected.selectedPeriod.start).getDate();
      const month = new Date(selected.selectedPeriod.start).getMonth();
      const year = new Date(selected.selectedPeriod.start).getFullYear();
      consumption = consumptionCalculator.dayConsumption(consumptions, date, month, year);

      if (pastConsumptions) {
        pastConsumption = consumptionCalculator.dayConsumption(
          pastConsumptions,
          date,
          month,
          year - 1
        );
      }

      // no benchmark for day
      /*if (consumptionBenchmark) {
        benchmark = consumptionBenchmark[0] || { amount: 0 };
      }*/
    }

    const selectedConsumptions = {
      ...state.selectedConsumptions,
      [deviceId]: { consumption, pastConsumption, benchmark, periodType },
    };
    const chartsMonths = { ...state.chartsMonths, [selected.deviceId]: chartMonths };

    return {
      ...state,
      selectedConsumptions,
      chartsMonths,
    };
  }),

  on(fromActions.SetSelectedTimeframe, (state, { timeframe, deviceId }): IConsumptionState => {
    const selectedConsumptionTimeframe = { ...state.selectedConsumptionTimeframe };
    selectedConsumptionTimeframe[deviceId] = timeframe;
    const meter = state.selectedMeters.find(el => el.deviceId === deviceId);
    const selectedConsumption = state.selectedConsumptions[deviceId];
    const periodsForRequests = { ...state.periodsForRequests };
    const periodsForCharts = { ...state.periodsForCharts };

    let periodForChart: IPeriod;
    if (timeframe === ConsumptionTimeframe.MONTH) {
      periodForChart = {
        start: startOfMonthMilliseconds(selectedConsumption.consumption.period.start),
        end: endOfMonthMilliseconds(selectedConsumption.consumption.period.end),
      };
    } else if (timeframe === ConsumptionTimeframe.YEAR) {
      periodForChart = {
        start: startOfYearMilliseconds(selectedConsumption.consumption.period.start),
        end: endOfYearMilliseconds(selectedConsumption.consumption.period.end),
      };
    }

    const periodForRequest: IPeriod = {
      start: Math.max(
        state.selectedContract.contractStartTime,
        meter.firstEntry,
        periodForChart.start
      ),
      end: Math.min(
        state.selectedContract.contractEndTime || Date.now(),
        meter.lastEntry,
        periodForChart.end
      ),
    };

    periodsForCharts[deviceId] = periodForChart;
    periodsForRequests[deviceId] = periodForRequest;
    return {
      ...state,
      selectedConsumptionTimeframe,
      periodsForRequests,
      periodsForCharts,
    };
  })
);
