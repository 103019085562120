import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ImmomioIconName, InputType, IUserData, MasterdataChangeType } from '../../models';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { getPersonalData } from '../../+state';
import { take } from 'rxjs';
import {
  nameValidator,
  phoneNumberValidator,
  phonePrefixValidator,
  zipCodeValidator,
} from '../../utils';

@UntilDestroy()
@Directive()
export abstract class UserEditFormBaseComponent {
  @Output() formSubmitted = new EventEmitter();
  @Input() public masterDataChangeType: MasterdataChangeType;
  public inputType = InputType;
  public immomioIconName = ImmomioIconName;

  nameEditForm: FormGroup;
  birthDateEditForm: FormGroup;
  addressEditForm: FormGroup;
  phoneEditForm: FormGroup;
  emailEditForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private store: Store
  ) {
    this.initializeForms();
    this.store
      .select(getPersonalData)
      .pipe(take(1))
      .subscribe(personalData => this.prefillForms(personalData));
  }

  getAttachment(event): void {
    if (event) {
      this.nameEditForm.patchValue({ name: { attachments: event } });
    } else {
      this.nameEditForm.get('name.attachments')?.patchValue(event);
    }
  }

  private initializeForms(): void {
    this.nameEditForm = this.fb.group({
      name: this.fb.group({
        name: ['', [Validators.required, nameValidator()]],
        lastName: ['', [Validators.required, nameValidator()]],
        attachments: ['', [Validators.required]],
      }),
    });

    this.birthDateEditForm = this.fb.group({
      dateOfBirth: this.fb.group({
        dateOfBirth: ['', [Validators.required]],
      }),
    });

    this.addressEditForm = this.fb.group({
      address: this.fb.group({
        street: ['', [Validators.required]],
        houseNumber: ['', [Validators.required]],
        zipCode: [
          '',
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(5),
            zipCodeValidator(),
          ],
        ],
        city: ['', [Validators.required]],
      }),
    });

    this.phoneEditForm = this.fb.group({
      countryCode: [
        '',
        [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(4),
          phonePrefixValidator(),
        ],
      ],
      prefix: ['', [Validators.required, phoneNumberValidator()]],
      phoneNumber: ['', [Validators.required, phoneNumberValidator()]],
    });

    this.emailEditForm = this.fb.group({
      email: this.fb.group({
        email: ['', [Validators.required, Validators.email]],
      }),
    });
  }

  private prefillForms(data: IUserData | null): void {
    if (!data) return;

    this.nameEditForm.patchValue({
      name: {
        name: data.firstName,
        lastName: data.name,
      },
    });

    this.birthDateEditForm.patchValue({
      dateOfBirth: { dateOfBirth: data.birthDate },
    });

    this.emailEditForm.patchValue({
      email: { email: data.contactEmail },
    });

    this.addressEditForm.patchValue({
      address: {
        street: data.address.street,
        houseNumber: data.address.houseNumber,
        zipCode: data.address.zipCode,
        city: data.address.city,
      },
    });
  }
}
