import { Directive, EventEmitter, Inject, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';

import {
  getPermissions,
  getResidentAppSettings,
  getTicketSorting,
  IConsumptionState,
} from '../../../+state';
import * as fromMasterDataState from '../../../+state/account/masterdata';
import * as fromTicketsState from '../../../+state/tickets';
import {
  ContractStatus,
  IContract,
  IEnvironment,
  ImmomioIconName,
  ISort,
  LoadingType,
  SortType,
  TicketingVersion,
  TicketIssueType,
  TicketSortField,
} from '../../../models';
import { contractStatusConverter, RouterService } from '../../../services';

@UntilDestroy()
@Directive()
export abstract class TicketsOverviewDamageBaseComponent {
  @Output() openTicket = new EventEmitter();
  public damageTickets$ = this.ticketsStore.select(
    fromTicketsState.getActiveDamageTicketsForSelectedContract
  );
  public hasNextPage$ = this.ticketsStore.select(
    fromTicketsState.hasNextPageActiveDamageTicketsForSelectedContract
  );
  public damageTicketsActionState$ = this.ticketsStore.select(
    fromTicketsState.getActiveDamageTicketsForSelectedContractActionState
  );
  public ticketingVersion$ = this.ticketsStore.select(getResidentAppSettings).pipe(
    map(settings => settings?.ticketingVersion),
    untilDestroyed(this)
  );
  public selectedSorting$ = this.masterDataStore.select(getTicketSorting);
  public selectedContractIsActive = true;
  public immomioIconName = ImmomioIconName;
  public numberOfContracts = this.masterDataStore
    .select(fromMasterDataState.getContractsCount)
    .pipe(untilDestroyed(this));
  public loadingTypes: LoadingType[] = [];
  public selectedContract$ = this.masterDataStore
    .select(fromMasterDataState.getSelectedContract)
    .pipe(untilDestroyed(this));
  public sort: ISort<TicketSortField>;
  public permissions$ = this.masterDataStore.select(getPermissions);
  public appSettings$ = this.masterDataStore.select(getResidentAppSettings);
  protected readonly sortTypes = SortType;
  protected readonly ticketingVersion = TicketingVersion;
  protected pagination = {
    offset: 0,
    limit: 20,
  };

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private routerService: RouterService,
    private route: ActivatedRoute,
    protected ticketsStore: Store<fromTicketsState.ITicketsState | IConsumptionState>,
    private masterDataStore: Store<fromMasterDataState.IMasterDataState>
  ) {
    this.selectedContract$.subscribe(res => {
      this.selectedContractIsActive = res?.status !== ContractStatus.ENDED;
    });
  }

  public createTicket() {
    this.masterDataStore
      .select(fromMasterDataState.getContracts)
      .pipe(
        map((contracts: IContract[]) =>
          contracts?.filter(contract => contractStatusConverter.isContractActive(contract))
        ),
        take(1)
      )
      .subscribe({
        next: res => {
          if (res.length === 1) {
            // prevent expired Contract to be used for ticket creation
            this.masterDataStore.dispatch(fromMasterDataState.SelectContract({ id: res[0].id }));
            this.goToRoute('ticket-creation');
          } else if (res.length >= 2) {
            this.goToRoute('select-contract');
          } else {
            this.goToRoute('ticket-creation');
          }
        },
        error: () => this.goToRoute('ticket-creation'),
      });
  }

  public loadMoreDamageTickets(): void {
    this.pagination = { offset: this.pagination.offset + 20, limit: 20 };
    this.ticketsStore.dispatch(
      fromTicketsState.LoadActiveDamageTicketsForSelectedContract({
        ...this.pagination,
        sort: this.sort,
      })
    );
  }

  private goToRoute(path: string) {
    void this.routerService.navigate([`./${path}`, TicketIssueType.PROPERTY_DAMAGE], {
      relativeTo: this.route,
    });
  }
}
