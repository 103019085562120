import { IResidentAppWhiteLabelResponse, IResidentBrandingTheme, ITheme } from '../../../models';
import {
  getButtonTextColor,
  normalizeColor,
  sensitiveHoverColor,
  shadeAndSaturateColor,
  shadeColor,
} from '../../../utils';
import { IAppSettings } from '../../../models/app-settings/app-settings.model';

export const appSettingsConverter = {
  fromThemeDto: (response: IResidentBrandingTheme): ITheme => {
    return {
      colorPrimary: normalizeColor(response.primaryColor), // whiteLabel
      colorPrimaryHover: sensitiveHoverColor(response.primaryColor, 20), // inherit from whiteLabel
      colorSecondary: normalizeColor(response.secondaryColor), // whiteLabel
      colorSecondaryHover: sensitiveHoverColor(response.secondaryColor, 20), // inherit from whiteLabel
      colorSurface: normalizeColor(response.cardBackgroundColor), // whiteLabel
      colorSurfaceHover: shadeColor(response.cardBackgroundColor, 5), // inherit from whiteLabel
      colorBackgroundLight: normalizeColor(response.backgroundColor), // whiteLabel
      colorBackgroundMedium: shadeAndSaturateColor(response.backgroundColor, 3, 50), // inherit from whiteLabel
      colorTextPrimary: normalizeColor(response.primaryTextColor), // whiteLabel
      colorTextSecondary: normalizeColor(response.secondaryTextColor), // whiteLabel
      colorTextButtonPrimary: getButtonTextColor('#fff', response.primaryColor, '#000'), // white or black depending if white will be visible on primary color
      colorTextButtonSecondary: getButtonTextColor('#fff', response.secondaryColor, '#000'), // white or black depending if white will be visible on secondary color
      colorMenuBarBackground: normalizeColor(response.menuBarBackgroundColor),
      colorMenuBarTextActive: normalizeColor(response.menuBarTextColorActive),
      colorMenuBarTextInactive: normalizeColor(response.menuBarTextColorInactive),
    };
  },
  fromDto: (response: IResidentAppWhiteLabelResponse): IAppSettings => {
    return {
      theme: appSettingsConverter.fromThemeDto(response.theme),
      logo: response.branding?.appLogo || null,
      landingPageImage: response.branding?.landingPageImage || null,
    };
  },
};
