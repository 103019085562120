import {
  FormResponseQuestionType,
  ITicketAnswerData,
  ScoreQuestionType,
} from '@resident-nx/shared';

export const convertScoreQuestionTypeToFormResponseQuestionTypeWeb = (
  answer: Partial<ITicketAnswerData>
): FormResponseQuestionType => {
  switch (answer.type) {
    case ScoreQuestionType.SELECTION:
      return FormResponseQuestionType.SELECTION;
    case ScoreQuestionType.MULTISELECT:
      return FormResponseQuestionType.MULTISELECT;
    case ScoreQuestionType.INPUT_TEXT:
      return answer.multiline
        ? FormResponseQuestionType.TEXTBOX
        : FormResponseQuestionType.TEXTFIELD;
    case ScoreQuestionType.INPUT_DATE:
      return FormResponseQuestionType.DATE;
    case ScoreQuestionType.INPUT_ATTACHMENTS:
      return FormResponseQuestionType.ATTACHMENTS;
    case ScoreQuestionType.LABEL:
      return FormResponseQuestionType.LABEL;
    default:
      return null;
  }
};
