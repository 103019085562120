import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { TicketsBAndOInformationScreenBaseComponent } from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';
import { RouterOutlet } from '@angular/router';
import { TicketBandOCreationPageWebComponent } from '../ticket-creation/b-and-o/ticket-b-and-o-creation-page.component';
import { ButtonWebComponent } from '../../../atoms/button/button.component';
import { ImageWebComponent } from '../../../atoms/image/image.component';

@Component({
  selector: 'rs-web-tickets-overview-b-and-o',
  styleUrls: ['./tickets-overview-b-and-o.component.scss'],
  templateUrl: './tickets-overview-b-and-o.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ImageWebComponent,
    TranslateModule,
    ButtonWebComponent,
    RouterOutlet,
    TicketBandOCreationPageWebComponent,
  ],
})
export class TicketsBAndOInformationScreenComponent extends TicketsBAndOInformationScreenBaseComponent {
  public showBandO = signal(false);

  public showBandOTicketing(): void {
    this.showBandO.set(true);
  }
}
