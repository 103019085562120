import { AsyncPipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  ButtonTypeEnum,
  LoadActiveRequestTicketsForSelectedContract,
  TicketIssueType,
  TicketsOverviewRequestBaseComponent,
} from '@resident-nx/shared';
import { combineLatest, tap } from 'rxjs';
import { SideSheetService } from '../../../../services';
import {
  ContractMenuDesign,
  ContractMenuWebComponent,
} from '../../contract-menu/contract-menu.component';
import { SortingMenuComponent } from '../../sorting-menu/sorting-menu.component';
import { CardsContainerHeaderWebComponent } from '../../../molecules/cards-container-header/cards-container-header.component';
import { TicketCreationPageWebComponent } from '../ticket-creation/ticket-creation-page.component';
import { TicketsWebComponent } from '../tickets/tickets.component';
import { CardWebComponent } from '../../../atoms/card/card.component';
import { IconWebComponent } from '../../../atoms/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { TicketInformationNoticeComponent } from '../ticket-information-notice/ticket-information-notice.component';

@UntilDestroy()
@Component({
  selector: 'rs-web-tickets-overview-request',
  standalone: true,
  imports: [
    TicketsWebComponent,
    CardsContainerHeaderWebComponent,
    SortingMenuComponent,
    ContractMenuWebComponent,
    AsyncPipe,
    CardWebComponent,
    IconWebComponent,
    TranslateModule,
    TicketInformationNoticeComponent,
  ],
  templateUrl: './tickets-overview-request.component.html',
  styleUrl: './tickets-overview-request.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TicketsOverviewRequestWebComponent
  extends TicketsOverviewRequestBaseComponent
  implements OnInit
{
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly ContractMenuDesign = ContractMenuDesign;
  #sideSheetService = inject(SideSheetService);

  ngOnInit(): void {
    combineLatest([this.selectedContract$, this.selectedSorting$])
      .pipe(
        tap(([_, sort]) => {
          this.sort = sort;
          this.ticketsStore.dispatch(
            LoadActiveRequestTicketsForSelectedContract({ ...this.pagination, sort })
          );
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  public override createTicket() {
    this.#sideSheetService.open(TicketCreationPageWebComponent, {
      data: {
        type: TicketIssueType.CONCERN,
      },
    });
  }
}
