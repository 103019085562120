<div class="d-flex flex-column h-100 justify-content-between">
  <div>
    <p class="description">
      <span class="fw-bold">{{ 'hint_l' | translate }}: </span>
      {{ 'user_edit_desc_no_hint' | translate }}
      <a (click)="goToConcerns.emit()" href="#" routerLink="/tickets/requests">{{
        'concern_l' | translate
      }}</a
      >.
    </p>

    <h5>{{ 'user_edit_l' | translate }}</h5>

    @for (selection of config(); track selection.changeType) {
      <label
        class="form-check form-check-label d-flex flex-column flex-sm-row justify-content-between"
        [for]="'user-profile-edit-select-' + selection.changeType"
      >
        <div class="d-flex align-items-center">
          <input
            [id]="'user-profile-edit-select-' + selection.changeType"
            [value]="selection"
            class="form-check-input me-2"
            type="radio"
            name="selection"
            formControlName="selection"
          />
          {{ translateKeys[selection.changeType] | translate
          }}{{ selection.requestType === MasterDataChangeRequestType.TICKET ? '*' : '' }}
        </div>
      </label>
    }
  </div>
  @if (activeHint) {
    <p class="description mt-4">* {{ 'edit_profile_ticket_hint_l' | translate }}</p>
  }
</div>
