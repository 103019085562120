<div [formGroupName]="formGroupName()" class="d-flex flex-column">
  <p class="fw-bold">{{ 'user_edit_overview_l' | translate }}: {{ label() | translate }}</p>

  <div class="mb-3 d-flex flex-row gap-3">
    <div>
      <label for="country-code" class="form-label"
        >{{ 'user_phone_edit_country_code_l' | translate }} (+xx)</label
      >
      <input type="text" class="form-control" id="country-code" formControlName="countryCode" />
    </div>

    <div>
      <label for="prefix" class="form-label">{{ 'user_phone_edit_prefix_l' | translate }}</label>
      <input type="text" class="form-control" id="prefix" formControlName="prefix" />
    </div>
    <div>
      <label for="number" class="form-label">{{ 'user_phone_edit_number_l' | translate }}</label>
      <input type="text" class="form-control" id="number" formControlName="phoneNumber" />
    </div>
  </div>
</div>
