export interface ISepaMandate {
  iban: string;
  bic: string;
  issuedAt: string;
  issuedUntil: string;
}

export interface ISepaMandates {
  contractExternalId: string;
  iban: string;
  bic: string;
  issuedAt: string;
  issuedUntil: string;
}
