<div class="d-flex flex-column" formGroupName="address">
  <p class="fw-bold">
    {{ 'user_edit_overview_l' | translate }}: {{ 'user_address_edit.form_l' | translate }}
  </p>

  <div class="mb-3 d-flex flex-row gap-3">
    <div class="flex-grow-1">
      <label class="form-label" for="street">{{ 'user_address_edit_street_l' | translate }}</label>
      <input class="form-control" formControlName="street" id="street" type="text" />
    </div>

    <div>
      <label class="form-label" for="houseNumber">
        {{ 'user_address_edit_house_number_l' | translate }}
      </label>
      <input class="form-control" formControlName="houseNumber" id="houseNumber" type="text" />
    </div>
  </div>

  <div class="mb-3 d-flex flex-row gap-3">
    <div>
      <label class="form-label" for="zipCode">
        {{ 'user_address_edit_postcode_l' | translate }}
      </label>
      <input
        minlength="4"
        maxlength="5"
        class="form-control"
        formControlName="zipCode"
        id="zipCode"
        type="text"
      />
    </div>

    <div class="flex-grow-1">
      <label class="form-label" for="city">{{ 'user_address_edit_city_l' | translate }}</label>
      <input class="form-control" formControlName="city" id="city" type="text" />
    </div>
  </div>
</div>
