import { gql } from 'apollo-angular';
import { customerSpecificDataFields } from '../common';

export const ticketsOverviewFields = `
  edges {
    cursor
    node {
      id
      ticketNr
      state
      title
      category {
        id
        name
      }
      created {
        editor {
          id
          name
        }
        date
      }
      updated {
        editor {
          id
          name
        }
        date
      }
      titleImage {
        title
        name
        type
        identifier
        extension
        encrypted
        url
        residentFileType
        location
        atlasMeta {
          key
          value
        }
      }
      issueType
      ${customerSpecificDataFields}
    }
  }
  pageInfo {
    hasPreviousPage
    hasNextPage
    startCursor
    endCursor
  }
`;

export const ticketsOverviewQuery = gql`
  query ticketsOverview($input: TicketListRequestBean!, $limit: Int, $offset: Int) {
    ticketsOverview (input: $input, limit: $limit, offset: $offset) {
      ${ticketsOverviewFields}
    }
  }
`;

export const createTicketHQMutation = gql`
  mutation createTicket($input: TicketEditBean!) {
    createTicket(input: $input) {
      id
      ticketNr
      ticketTitle
      documents {
        title
        name
        type
        identifier
        extension
        encrypted
        url
        residentFileType
        location
        atlasMeta {
          key
          value
        }
      }
    }
  }
`;

export const cancelTicketQuery = gql`
  mutation cancelTicket($input: CancelTicketInputBean!, $issueType: TicketIssueType!) {
    cancelTicket(input: $input, issueType: $issueType)
  }
`;

export const createTicketMetadataMutation = gql`
  mutation createMetadataTicket($input: TicketMetadataCreateBean!) {
    createMetadataTicket(input: $input)
  }
`;

export const createMasterdataChangeTicketMutation = gql`
  mutation createMasterdataChangeTicket($input: MasterdataChangeTicketCreateBean!) {
    createMasterdataChangeTicket(input: $input)
  }
`;
