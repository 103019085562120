import { IMasterdataChangeConfiguration, MasterdataChangeRequestType } from '../../../models';

export const masterdataHelper = {
  checkShowChangeButton(
    masterDataChangeConfiguration: IMasterdataChangeConfiguration[] | null,
    activeContract: boolean,
    contractlessTicketCreation: boolean,
    disabledTicketManagement: boolean
  ): boolean {
    const confContainsExternalChanges = masterDataChangeConfiguration?.some(
      config => config.requestType === MasterdataChangeRequestType.EXTERNAL
    );
    // If no masterDataChangeConfiguration is set && ticket management is not hidden (hardcoded per customerIdent like Gebausie)
    if (!masterDataChangeConfiguration && !disabledTicketManagement) {
      return true;
      // If masterDataChangeConfiguration is set, check other conditions for showing the change button
    } else if (
      masterDataChangeConfiguration &&
      (confContainsExternalChanges ||
        (!disabledTicketManagement && (activeContract || contractlessTicketCreation)))
    ) {
      return true;
    } else {
      return false;
    }
  },
};
