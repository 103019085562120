import {
  ICustomerSpecificDataResponse,
  IEditAction,
  IEditorContact,
  IPageInfo,
  IResidentS3File,
} from '../';

export interface ITicketConversation {
  edges: {
    cursor: string;
    node: IConversationMessage;
  }[];
  pageInfo: IPageInfo;
}

export interface IConversationMessage {
  created: IEditAction;
  message: string;
  id: string;
  attachments: IResidentS3File[];
  customerSpecificData: ICustomerSpecificDataResponse[];
  status?: ConversationMessageStatus;
  retryMessageId?: string;
  retriedOn?: Date;
}

export interface ITicketConversationEdit {
  ticketId: string;
  message?: string;
  attachments?: IResidentS3File[];
  retryOfConversationMessageId?: string;
}

export interface IConversationEditResponse {
  createdMessageId: string;
}

export interface ITicketMessageGroup {
  user: IEditorContact;
  userInitials: string;
  isIncoming: boolean;
  messages: ITicketMessage[];
  color?: string;
}

export interface ITicketMessage {
  id: string;
  message: string;
  attachments: IResidentS3File[];
  timestamp: number;
  isIncoming: boolean;
  status?: ConversationMessageStatus;
  retryMessageId?: string;
  retriedOn?: Date;
}

export type ConversationMessageStatus = 'ACKNOWLEDGED' | 'SENT' | 'PENDING' | 'FAILED' | 'RETRIED';
