import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromEmergencyContactsState from './account/emergency-contacts/emergency-contacts.reducer';
import * as fromLoginState from './account/login/login.reducer';
import * as fromMasterDataState from './account/masterdata/masterdata.reducer';
import * as fromPermissionsState from './account/permissions/permissions.reducer';
import * as fromRegistrationState from './account/registration/registration.reducer';
import * as fromUserAppsState from './account/user-apps/user-apps.reducer';
import * as fromUserProfileState from './account/user-profile/user-profile.reducer';
import * as fromConsumptionState from './consumption/consumption.reducers';
import * as fromAppSettingsState from './general/app-settings/app-settings-reducer';
import * as fromSortingState from './general/sortings/sorting.reducer';
import * as fromHomepageModuleState from './homepage-module/homepage-module.reducer';
import * as fromAnnouncementsState from './news/announcements/announcements.reducer';
import * as fromNewsState from './news/news/news.reducer';
import * as fromOffersState from './news/offers/offers.reducer';
import * as fromEmailSettingsState from './notifications/email-settings/email-settings.reducers';
import * as fromNotificationSettingsState from './notifications/notification-settings/notification-settings.reducers';
import * as fromNotificationsState from './notifications/notifications.reducer';
import * as fromNotificationPopupsState from './notifications/popup-notifications/popup-notifications.reducers';
import * as fromPaymentsState from './payment/payment.reducer';
import * as fromSearchState from './search/search.reducer';
import * as fromServiceState from './service/service.reducer';
import * as fromDocumentsState from './shared-content/shared-content.reducers';
import * as fromSupportState from './support/support.reducer';
import * as fromTicketsState from './tickets/tickets.reducer';
import * as fromHideWelcomeScreenState from './general/welcome_screen/welcome_screen.reducer';
import * as fromLoadingIndicatorState from './general/loading-indicator/loading-indicator.reducer';

export interface IAppState {
  news: fromNewsState.INewsState;
  offers: fromOffersState.IOffersState;
  announcements: fromAnnouncementsState.IAnnouncementsState;
  tickets: fromTicketsState.ITicketsState;
  payments: fromPaymentsState.IPaymentsState;
  registration: fromRegistrationState.IRegistrationState;
  masterData: fromMasterDataState.IMasterDataState;
  userProfile: fromUserProfileState.IUserProfileState;
  support: fromSupportState.ISupportState;
  service: fromServiceState.IServiceState;
  search: fromSearchState.ISearchState;
  appSettings: fromAppSettingsState.IAppSettingsState;
  consumption: fromConsumptionState.IConsumptionState;
  documents: fromDocumentsState.ISharedContentState;
  permissions: fromPermissionsState.IPermissionsState;
  notifications: fromNotificationsState.INotificationsState;
  homepageModule: fromHomepageModuleState.IHomepageModelState;
  userApps: fromUserAppsState.IUserAppsState;
  login: fromLoginState.ILoginState;
  emergencyContacts: fromEmergencyContactsState.IEmergencyContactsState;
  notificationSettings: fromNotificationSettingsState.INotificationSettingsState;
  emailSettings: fromEmailSettingsState.IEmailSettingsState;
  sortings: fromSortingState.ISortingState;
  popupNotifications: fromNotificationPopupsState.IPopupNotificationsState;
  hideWelcomeScreen: fromHideWelcomeScreenState.IWelcomeScreenState;
  loadingIndicator: fromLoadingIndicatorState.ILoadingIndicatorState;
}

export const appReducers: ActionReducerMap<IAppState> = {
  news: fromNewsState.newsReducer,
  offers: fromOffersState.offersReducer,
  announcements: fromAnnouncementsState.announcementsReducer,
  payments: fromPaymentsState.paymentsReducer,
  tickets: fromTicketsState.ticketsReducer,
  registration: fromRegistrationState.registrationReducer,
  masterData: fromMasterDataState.masterDataReducer,
  userProfile: fromUserProfileState.userProfileReducer,
  support: fromSupportState.supportReducer,
  service: fromServiceState.serviceReducer,
  search: fromSearchState.searchReducer,
  appSettings: fromAppSettingsState.appSettingsReducer,
  consumption: fromConsumptionState.consumptionReducer,
  documents: fromDocumentsState.sharedContentReducer,
  permissions: fromPermissionsState.permissionsReducer,
  notifications: fromNotificationsState.notificationsReducer,
  homepageModule: fromHomepageModuleState.hompageModuleReducer,
  userApps: fromUserAppsState.userAppsReducer,
  login: fromLoginState.loginReducer,
  emergencyContacts: fromEmergencyContactsState.emergencyContactsReducer,
  notificationSettings: fromNotificationSettingsState.notificationSettingsReducer,
  emailSettings: fromEmailSettingsState.emailSettingsReducer,
  sortings: fromSortingState.documentSortingReducer,
  popupNotifications: fromNotificationPopupsState.popupNotificationsReducer,
  hideWelcomeScreen: fromHideWelcomeScreenState.hideWelcomeScreenReducer,
  loadingIndicator: fromLoadingIndicatorState.loadingIndicatorReducer,
};

export const getAppState = createFeatureSelector<IAppState>('AppState');
export const getNewsState = createFeatureSelector<fromNewsState.INewsState>('news');
export const getOffersState = createFeatureSelector<fromOffersState.IOffersState>('offers');
export const getAnnouncementsState =
  createFeatureSelector<fromAnnouncementsState.IAnnouncementsState>('announcements');
export const getTicketsState = createFeatureSelector<fromTicketsState.ITicketsState>('tickets');
export const getPaymentRequestsState =
  createFeatureSelector<fromPaymentsState.IPaymentsState>('payments');
export const getUserProfileState =
  createFeatureSelector<fromUserProfileState.IUserProfileState>('userProfile');
export const getRegistrationState =
  createFeatureSelector<fromRegistrationState.IRegistrationState>('registration');
export const getMasterDataState =
  createFeatureSelector<fromMasterDataState.IMasterDataState>('masterData');
export const getSupportState = createFeatureSelector<fromSupportState.ISupportState>('support');
export const getServiceState = createFeatureSelector<fromServiceState.IServiceState>('service');
export const getSearchState = createFeatureSelector<fromSearchState.ISearchState>('search');
export const getAppSettingsState =
  createFeatureSelector<fromAppSettingsState.IAppSettingsState>('appSettings');
export const getConsumptionState =
  createFeatureSelector<fromConsumptionState.IConsumptionState>('consumption');
export const getDocumentsState =
  createFeatureSelector<fromDocumentsState.ISharedContentState>('documents');
export const getUserAppsState = createFeatureSelector<fromUserAppsState.IUserAppsState>('userApps');
export const getPermissionsState =
  createFeatureSelector<fromPermissionsState.IPermissionsState>('permissions');
export const getNotificationsState =
  createFeatureSelector<fromNotificationsState.INotificationsState>('notifications');
export const getHomepageModuleState =
  createFeatureSelector<fromHomepageModuleState.IHomepageModelState>('homepageModule');
export const getLoginState = createFeatureSelector<fromLoginState.ILoginState>('login');
export const getEmergencyContactsState =
  createFeatureSelector<fromEmergencyContactsState.IEmergencyContactsState>('emergencyContacts');
export const getNotificationSettingsState =
  createFeatureSelector<fromNotificationSettingsState.INotificationSettingsState>(
    'notificationSettings'
  );
export const getEmailSettingsState =
  createFeatureSelector<fromEmailSettingsState.IEmailSettingsState>('emailSettings');

export const getSortingState = createFeatureSelector<fromSortingState.ISortingState>('sortings');

export const getNotificationPopupsState =
  createFeatureSelector<fromNotificationPopupsState.IPopupNotificationsState>('popupNotifications');

export const getHideWelcomeScreenState =
  createFeatureSelector<fromHideWelcomeScreenState.IWelcomeScreenState>('hideWelcomeScreen');

export const getLoadingIndicatorState =
  createFeatureSelector<fromLoadingIndicatorState.ILoadingIndicatorState>('loadingIndicator');
