import { Injectable } from '@angular/core';
import {
  FormResponseQuestionType,
  IFormResponseCreate,
  IHierarchicalQuestionAnswerTicketOverview,
  IHierarchicalQuestionTicketOverview,
  ScoreQuestionType,
} from '@resident-nx/shared';
import { FormArray, FormControl } from '@angular/forms';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { convertScoreQuestionTypeToFormResponseQuestionTypeWeb, fileToBase64 } from '../../utils';

@Injectable({
  providedIn: 'root',
})
export class TicketHelperService {
  public async createFormResponseCreates(
    questions: IHierarchicalQuestionTicketOverview[],
    formArray: FormArray<FormControl<unknown>>
  ) {
    const formResponses: IFormResponseCreate[] = [];

    for (const question of questions) {
      const index = questions.indexOf(question);
      if (question.data.type === ScoreQuestionType.LABEL) continue;

      const answer = formArray.at(index).value;

      const response: IFormResponseCreate = {
        index: question.data.order,
        questionText: question.data.title,
        type: convertScoreQuestionTypeToFormResponseQuestionTypeWeb({
          type: question.data.type,
          multiline: question.answers[0].data.multiline,
        }),
        responses: [],
        attachments: [],
      };

      switch (response.type) {
        case FormResponseQuestionType.ATTACHMENTS: {
          const attachments =
            answer instanceof FileList
              ? await Promise.all(
                  Array.from(answer).map(async file => ({
                    name: file.name,
                    extension: file.type.includes('pdf') ? 'pdf' : 'jpg',
                    content: await fileToBase64(file),
                  }))
                )
              : [];

          response.attachments.push(...attachments);
          break;
        }

        case FormResponseQuestionType.SELECTION: {
          response.responses.push(
            (answer as IHierarchicalQuestionAnswerTicketOverview)?.data?.title || ''
          );
          break;
        }

        default: {
          response.responses.push((answer as string) || '');
        }
      }

      formResponses.push(response);
    }
    return formResponses;
  }
}
