@if (meter?.deviceId) {
  <div class="d-flex justify-content-between align-items-center mb-4">
    <div class="d-flex">
      <rs-web-icon [icon]="meter.type | consumptionIcon" [size]="24"></rs-web-icon>
      <span class="ms-1">
        {{ meter.type | consumptionName: NameDisplayMode.SHORT | translate }}
        {{ 'general.in_l' | translate }}
        {{ ConsumptionUnitType[meter.measurementUnit] }}
      </span>
    </div>

    <div class="d-flex">
      <span class="me-1 default-s">{{ 'dashboard.consumption_details_link_l' | translate }}</span>
      <rs-web-icon [icon]="ImmomioIconName.ArrowRight" [size]="12"></rs-web-icon>
    </div>
  </div>
}
<div>
  <rs-web-consumption-chart
    [meter]="meter"
    [height]="'152px'"
    [timeframe]="ConsumptionTimeframe.YEAR"
    [small]="true"
  ></rs-web-consumption-chart>
</div>
