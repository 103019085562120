import { IPersonalDataResponse, ISepaMandate, ISepaMandates, IUserData } from '../../../models';
import { obfuscateString } from '../../../utils';

export const userProfileConverter = {
  fromDto: (response: IPersonalDataResponse): IUserData => {
    function obfuscateIban(sepaMandate: ISepaMandate): ISepaMandate {
      return {
        ...sepaMandate,
        iban: sepaMandate.iban ? obfuscateString(sepaMandate.iban) : null,
        bic: sepaMandate.bic ? obfuscateString(sepaMandate.bic, 4, 0) : null,
      };
    }

    function obfuscateSepaMandates(sepaMandates: ISepaMandates[]): ISepaMandates[] {
      return sepaMandates.map(mandate => ({
        ...mandate,
        iban: mandate.iban ? obfuscateString(mandate.iban) : null,
        bic: mandate.bic ? obfuscateString(mandate.bic, 4, 0) : null,
      }));
    }

    return {
      ...response.userDataResponseBean,
      sepaMandate: response?.userDataResponseBean?.sepaMandate
        ? obfuscateIban(response.userDataResponseBean.sepaMandate)
        : null,
      sepaMandates: response?.userDataResponseBean.sepaMandates
        ? obfuscateSepaMandates(response.userDataResponseBean.sepaMandates)
        : null,
    };
  },
};
