@if (contracts$ | async) {
  <div>
    <div class="contracts__carousel">
      @if (pageSize === 1) {
        <div class="contracts__carousel-top-navigation mt-3 mb-3">
          <div class="contracts__card-title title-m">
            <rs-web-icon
              [icon]="allContracts[selectedContractIndex]?.type | contractIcon"
              [size]="18"
            ></rs-web-icon>
            <div class="m-l-10">
              <rs-web-label
                [text]="allContracts[selectedContractIndex]?.type | contractTypeName | translate"
              ></rs-web-label>
            </div>
          </div>
          <div class="contracts__carousel-top-buttons">
            <rs-web-button
              [ghost]="true"
              [iconLeft]="'chevron-left'"
              [type]="ButtonTypeEnum.EMPTY_LIGHT"
              (tapped)="onPrevious()"
            ></rs-web-button>
            <rs-web-button
              [ghost]="true"
              [iconLeft]="'chevron-right'"
              [type]="ButtonTypeEnum.EMPTY_LIGHT"
              (tapped)="onNext()"
            ></rs-web-button>
          </div>
        </div>
      }
      <div class="contracts__carousel-row">
        @if (pageSize > 1 && splitContracts.length > 1) {
          <rs-web-button
            [ghost]="true"
            [iconLeft]="'chevron-left'"
            [type]="ButtonTypeEnum.EMPTY_LIGHT"
            (tapped)="onPrevious()"
          ></rs-web-button>
        }
        <div class="contracts__carousel">
          <ngb-carousel
            #carousel
            [showNavigationArrows]="false"
            [showNavigationIndicators]="false"
            [interval]="0"
            (slide)="onSlide()"
          >
            @for (contracts of splitContracts; track contracts; let pageIndex = $index) {
              <ng-template ngbSlide>
                <div class="contracts__contract-container">
                  @for (contract of contracts; track contract.id; let contractIndex = $index) {
                    <div class="contracts__card">
                      <rs-web-card [elevation]="2">
                        @if (pageSize > 1) {
                          <div class="contracts__card-title mb-4 title-m">
                            <rs-web-icon
                              [icon]="contracts[contractIndex]?.type | contractIcon"
                              [size]="18"
                              [otherClasses]="['primary-text']"
                            ></rs-web-icon>
                            <div class="m-l-10">
                              <rs-web-label
                                [text]="
                                  contracts[contractIndex]?.type | contractTypeName | translate
                                "
                                class="primary-text"
                              ></rs-web-label>
                            </div>
                          </div>
                        }
                        <div class="contracts__card-info mb-4">
                          <div class="contracts__card-address">
                            <div>
                              {{ contract?.property?.address?.zipCode }}
                              {{ contract?.property?.address?.city }}
                            </div>
                            <div class="contracts__card-address-down">
                              @if (
                                contract.property?.address?.street ===
                                contract.property?.address?.houseNumber
                              ) {
                                {{ contract.property?.address?.street }}
                              } @else {
                                {{ contract?.property?.address?.street }}
                                {{ contract?.property?.address?.houseNumber }}
                              }
                            </div>
                          </div>
                          <div class="contracts__card-details">
                            <div>{{ 'contract_id_l' | translate }} {{ contract?.externalId }}</div>
                            <div>
                              {{ 'valid_from_l' | translate }}
                              {{ contract?.contractStartTime | date: 'dd.MM.YYYY' }}
                            </div>
                          </div>
                        </div>
                        <rs-web-pie-chart
                          [data]="contract.chartData"
                          [height]="300"
                          [width]="300"
                        ></rs-web-pie-chart>
                      </rs-web-card>

                      @let accountBalanceAmount = contract?.accountBalance?.amount;

                      @if (accountBalanceAmount !== null || contract?.accountBalance?.infoText) {
                        <rs-web-card [elevation]="2">
                          <div class="contracts__balance-card">
                            <div class="contracts__balance-icon-container">
                              <rs-web-icon
                                [icon]="immomioIconName.ChartLine"
                                [size]="36"
                              ></rs-web-icon>
                            </div>
                            <div class="contracts__balance-content">
                              <div class="contracts__balance-content__title-container">
                                <span
                                  ><strong>{{
                                    'contracts.account_balance_title' | translate
                                  }}</strong>
                                </span>
                                @if (contract?.accountBalance?.infoText) {
                                  <rs-web-icon
                                    [icon]="immomioIconName.QuestionOutlined"
                                    [size]="16"
                                    [style.cursor]="'pointer'"
                                    (click)="openModal(contract?.accountBalance?.infoText)"
                                  ></rs-web-icon>
                                }
                              </div>
                              <span
                                [class]="{
                                  negative: accountBalanceAmount < 0,
                                  neutral: accountBalanceAmount === 0,
                                  positive: accountBalanceAmount > 0,
                                }"
                              >
                                @switch (true) {
                                  @case (accountBalanceAmount === null) {
                                    {{ '-' }}
                                  }
                                  @case (accountBalanceAmount < 0) {
                                    {{
                                      (accountBalanceAmount | displayCurrency: 'EUR') +
                                        ' ' +
                                        ('contracts.account_balance_negative_l' | translate)
                                    }}
                                  }
                                  @case (accountBalanceAmount > 0) {
                                    {{ '+ ' + (accountBalanceAmount | displayCurrency: 'EUR') }}
                                  }
                                  @default {
                                    {{ accountBalanceAmount | displayCurrency: 'EUR' }}
                                  }
                                }
                              </span>
                            </div>
                          </div>
                        </rs-web-card>
                      }
                      <rs-web-emergency-contacts-overview
                        [contract]="contract.id"
                        [class.mt-lg-auto]="accountBalanceAmount === null"
                      ></rs-web-emergency-contacts-overview>
                    </div>
                  }
                </div>
              </ng-template>
            }
          </ngb-carousel>
        </div>
        @if (pageSize > 1 && splitContracts.length > 1) {
          <rs-web-button
            [ghost]="true"
            [iconLeft]="'chevron-right'"
            [type]="ButtonTypeEnum.EMPTY_LIGHT"
            (tapped)="onNext()"
          ></rs-web-button>
        }
      </div>
    </div>
  </div>
}
