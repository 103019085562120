import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileSizePipe, ImmomioIconName, IResidentS3File } from '@resident-nx/shared';
import { Elevation } from '../../../directives/elevation/elevation.model';
import { CardWebComponent } from '../../atoms/card/card.component';
import { IconWebComponent } from '../../atoms/icon/icon.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'rs-web-pdf-attachment',
  standalone: true,
  imports: [CommonModule, IconWebComponent, CardWebComponent, TranslateModule, FileSizePipe],
  templateUrl: './pdf-attachment.component.html',
  styleUrl: './pdf-attachment.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfAttachmentComponent {
  // we want to support File and S3File type. We can't use a union type because s3File is missing size property
  file = input<File>();
  s3File = input<IResidentS3File>();
  fileOrS3File = computed(() => this.file() ?? this.s3File());
  isRemovable = input(true);
  maxFileSize = input<number>(null);
  name = computed(() => this.s3File()?.title || this.fileOrS3File().name);
  isFileTooBig = computed(() => {
    if (!this.maxFileSize()) {
      return false;
    }
    return this.file().size > this.maxFileSize();
  });
  remove = output();
  protected readonly Elevation = Elevation;
  protected readonly ImmomioIconName = ImmomioIconName;
}
