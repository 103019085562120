import { Directive, EventEmitter, inject, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, map, Observable } from 'rxjs';
import {
  getActiveDamageTickets,
  getActiveDamageTicketsActionState,
  getActiveRequestTickets,
  getActiveRequestTicketsActionState,
} from '../../../+state';
import { ITicketResidentOverview, LoadingType } from '../../../models';
import { WidgetBaseComponent } from '../widget.base-component';

export interface ITicketData extends ITicketResidentOverview {
  category: {
    id: string;
    name: string;
    primary?: string;
    secondary?: string;
  };
}

@Directive()
export class TicketsWidgetBaseComponent extends WidgetBaseComponent {
  @Output() tapped = new EventEmitter();
  store = inject(Store);
  damageTickets$ = this.store.select(getActiveDamageTickets);
  damageTicketsState$ = this.store.select(getActiveDamageTicketsActionState);
  requestTickets$ = this.store.select(getActiveRequestTickets);
  requestTicketsState$ = this.store.select(getActiveRequestTicketsActionState);
  loadingTickets$: Observable<boolean> = combineLatest([
    this.damageTicketsState$,
    this.requestTicketsState$,
  ]).pipe(map(([damageState, requestState]) => damageState.pending || requestState.pending));
  combinedTickets$ = combineLatest([this.damageTickets$, this.requestTickets$]).pipe(
    map(([damageTickets, requestTickets]) => [
      ...damageTickets.slice(0, 2),
      ...requestTickets.slice(0, 2),
    ]),
    map(combinedTickets => {
      return combinedTickets.sort((a, b) => {
        const datumA = new Date(a.node.updated.date).getTime();
        const datumB = new Date(b.node.updated.date).getTime();
        return datumB - datumA;
      });
    })
  );
  isMenuVisible = false;
  public loadingTypes: LoadingType[] = [];
}
