import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { IconWebComponent } from '../../../atoms/icon/icon.component';
import { ImageBorderRadiusEnum } from '../../../atoms/image/image.model';
import { ImageWebComponent } from '../../../atoms/image/image.component';
import { ImmomioIconName } from '@resident-nx/shared';
import { TranslateModule } from '@ngx-translate/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'rs-web-ticket-image-attachment',
  standalone: true,
  imports: [IconWebComponent, ImageWebComponent, TranslateModule, NgClass],
  templateUrl: './ticket-image-attachment.component.html',
  styleUrl: './ticket-image-attachment.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketImageAttachmentComponent {
  protected readonly ImageBorderRadiusEnum = ImageBorderRadiusEnum;
  protected readonly ImmomioIconName = ImmomioIconName;

  file = input<File>();
  isRemovable = input(true);
  small = input(false);
  maxFileSize = input<number>(null);

  isFileTooBig = computed(() => {
    if (!this.maxFileSize()) {
      return false;
    }
    return this.file().size > this.maxFileSize();
  });

  remove = output();
}
