import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TicketCreationService } from '../../../../../services';
import { ScoreQuestionType } from '@resident-nx/shared';
import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FileListPipe } from '../../../../../pipes/file-list.pipe';
import { ImageWebComponent } from '../../../../atoms/image/image.component';
import { ImageBorderRadiusEnum } from '../../../../atoms/image/image.model';
import { TicketImageAttachmentComponent } from '../../../../molecules/ticket/ticket-image-attachment/ticket-image-attachment.component';
import { PdfAttachmentComponent } from '../../../../molecules/pdf-attachment/pdf-attachment.component';

@Component({
  selector: 'rs-web-ticket-summary',
  standalone: true,
  templateUrl: './ticket-summary.component.html',
  styleUrl: './ticket-summary.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgTemplateOutlet,
    ImageWebComponent,
    FileListPipe,
    TranslateModule,
    DatePipe,
    TicketImageAttachmentComponent,
    PdfAttachmentComponent,
  ],
})
export class TicketSummaryComponent {
  #ticketCreationService = inject(TicketCreationService);
  public summary = this.#ticketCreationService.getSummaryData();
  protected readonly ScoreQuestionType = ScoreQuestionType;
  protected readonly ImageBorderRadiusEnum = ImageBorderRadiusEnum;
}
